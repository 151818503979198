import { ProductService } from './../../../../../src/app/product/products/service/product.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CloudFolioService } from './../cloud-folio.service';
import { Injectable } from '@angular/core';
import { loyaltyFolio, loyaltyBasketItems, loyaltyBasket, loyalty_service_result } from '../models/folio-loyalty';
import { folioRow } from '../models/folio';
import { IdGeneratorService } from 'cloud-labs-core';
import { throwError } from 'rxjs';

const loyaltyBasketApiGet = "https://api.herkul.kerzz.com:5000/api/loyalty/getbasketbenefit"
const loyaltyBasketApiUse = "https://api.herkul.kerzz.com:5000/api/loyalty/usebasketbenefit"

@Injectable({
  providedIn: 'root'
})
export class LoyaltyFolioService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', })
  }

  fls: CloudFolioService

  constructor(private http: HttpClient, private ig: IdGeneratorService, private ps: ProductService) { }

  //init service, inject CloudFolio first
  init(fls: CloudFolioService) {
    this.fls = fls
  }

  // Proccess Coupon
  processCoupon(item) {
    if (item.program == "free-product") {
      let product_id = item.products[0]
      let product_name = this.ps.data.find(product => product.id = product_id).name
      this.fls.createRow(this.ig.generateMaxi(), product_id, product_name, 1, "0", 0, undefined, true)
    }
    else if (item.program == "discount-basket") {
      if (item.basketDiscountPercent) {
        this.fls.addDiscount("Discount", "Kupon " + item.name, this.fls.getFolioTotal() * item.basketDiscountPercent)
      } else {
        this.fls.addDiscount("Discount", "Kupon " + item.name, this.fls.getFolioTotal() * item.basketDiscountPercent)
      }
    }
  }

  processQr(result) {
    // else continue to QR Proccess
    this.fls.activeQrUser = result.userName
    this.fls.totalPoint = result.totalPoint || 0
    let basketItems: loyaltyBasketItems[] = result.benefitProducts

    basketItems.forEach(item => {
      let r: folioRow = this.fls.folio.rows.find(o => o.productID == item.itemCode)
      r.isLoyalty = false
      r.isFree = true
      r.spendPoint = item.spendPoint
    })
  }

  // Check QR 
  getLoyalty() {
    if (!this.fls) {
      throwError("Define cloud folio service first!")
    }

    if (!this.fls.activeQrCode) {
      return
    }

    let lf = this.createLoyaltyFolio()


    this.http.post(loyaltyBasketApiGet, JSON.stringify(lf), this.httpOptions).subscribe(o => {
      this.fls.loyaltyServiceResult = Object.assign(o)
      // if QR is a Coupon, Goto Coupon Proccess
      if (this.fls.loyaltyServiceResult.objectType == 'coupon') {
        this.processCoupon(this.fls.loyaltyServiceResult)
      } else {
        this.processQr(this.fls.loyaltyServiceResult)
      }

      // notify component
      this.fls.loyaltyActive = true
      this.fls.subject_folio_loyalty.next({ status: 'ok', data: this.fls.loyaltyServiceResult })
    }, e => {
      this.fls.subject_folio_loyalty.next({ status: 'error', data: e.error.message })
      console.log(e)
    })

  }

  // Save loyalty
  useLoyalty() {

    if (!this.fls.loyaltyActive) {
      return
    }

    if (!this.fls) {
      throwError("Define cloud folio service first!")
    }



    let lf = this.createLoyaltyFolio(true)
    this.http.post(loyaltyBasketApiUse, JSON.stringify(lf), this.httpOptions).subscribe(o => {
      this.fls.loyaltyActive = false
      this.fls.totalPoint = 0
      this.fls.activeQrCode = undefined
      this.fls.activeQrUser = undefined
      this.fls.loyaltyServiceResult = undefined
    })

  }

  // Create loyalty folio, send using service
  createLoyaltyFolio(confirm?: boolean): loyaltyFolio {
    let lf: loyaltyFolio = new loyaltyFolio()
    lf.qrCode = this.fls.activeQrCode
    lf.basket.folioNo = this.fls.folio.id
    lf.basket.branchcode = "0"
    lf.basket.discountTotal = this.fls.folio.discount
    lf.basket.grandTotal = this.fls.getFolioTotal()
    lf.basket.waiter = "cloudie"


    this.fls.folio.rows.forEach(element => {
      let bi: loyaltyBasketItems = new loyaltyBasketItems
      bi.itemCode = element.productID
      bi.itemName = element.name
      bi.quantity = element.qty
      bi.unitPrice = element.unitPrice
      bi.totalPrice = this.fls.getRowTotal(element)

      //if closeing
      if (confirm) {
        bi.isUse = element.isLoyalty
        bi.spendPoint = element.spendPoint
      }

      lf.basket.basketItems.push(bi)
    });

    return lf

  }

}






/*
  basketDiscountAmount: 0
    basketDiscountPercent: 0
    branches: []
    creation: "2019-08-01T10:17:00.000Z"
    description: ""
    id: "U2ODsTXWKEWm8RWCGnqS"
    image: ""
    maxUsage: 0
    name: "Hoş Geldin Pastası"
    objectType: "coupon"
    products: ["E8ZK5tjBOgLNmfDjkhkZ"]
    program: "free-product"
    qrCode: "5d5e84a9970804242f0b9315"
    segments: []
    status: 0
    trigger: "new-membership"
    type: "auto-coupon"
    userId: "5d5e84a9970804242f0b9314"
    users: []
    validThru: "2119-08-31T10:17:00.000Z"
    _id: "5d5e84a9970804242f0b9317"
 */