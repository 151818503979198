import { Component, OnInit } from '@angular/core';
import { ProductService } from '../products/service/product.service';
import { ProductGroupService } from '../product-groups/service/product-group.service';
import { Advices } from './advices-model';

@Component({
  selector: 'app-advices',
  templateUrl: './advices.component.html',
  styleUrls: ['./advices.component.scss']
})
export class AdvicesComponent implements OnInit {

  advices  : Advices[] = []

  constructor(private ps: ProductService, public pg: ProductGroupService) { }

  ngOnInit(): void {

    this.pg.load().then(o => {

      this.ps.getAdvices().subscribe(o => {
        this.advices = Object.assign(o)
      })

    })

  
  }


  cellTemplate(container, options) {
    const noBreakSpace = '\u00A0';

    const assignees = (options.value || []).map(
      (assigneeId: number) => options.column!.lookup!.calculateCellValue!(assigneeId),
    );
    const text = assignees.join(', ');

    container.textContent = text || noBreakSpace;
    container.title = text;
  }


  updatedRow(e) {
    this.ps.saveAdvices(e.data)
  }

  insertedRow(e) {
    this.ps.saveAdvices(e.data)
  }

  deleteRow(e) {
    this.ps.deleteAdvices(e.data)
  }



  initRow(e) {
    e.data.vat = 8
  }

}
