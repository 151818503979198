import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { GlobalsService } from '../services/globals.service';
import { StartupService } from '../services/startup.service';
import { CloudLoginService, CloudDbService } from 'cloud-labs-core';
import { AppPermissionsService } from '../settings/services/app-permissions.service';
import {InvoiceService} from '../invoice/services/invoice.service'



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  first: boolean = true;
  status: string = "login"
  loadedData: string = ".."
  user: string
  pass: string
  error: string
  loaded: boolean = true
  waitlogin = false

  cloudApiKey = "e5788b40-ec7e-0fe0-daf8-80c17445ab9d!?@f8a4!e347**11d3" // cloudie

  constructor(
    private router: Router,
    private invService : InvoiceService,
    private glb: GlobalsService,
    private ss: StartupService,
    private cls: CloudLoginService,
    private cdb: CloudDbService,
    private translate: TranslateService,
    private perm: AppPermissionsService
  ) { }

  private db: any;
  ngOnInit() {
    
  }

  loginEvent(e) {    
    console.groupCollapsed("Login Status Changed")
    console.log("Event",e)
    console.log("User Info",this.cls.userInfo)
    this.glb.licances = this.cls.userInfo.licances
    this.cdb.accessToken = this.cls.acessToken
    if (this.glb.licances.length > 1) {
      console.log("Multi Licance",this.cls.userInfo)
      this.glb.filteredStores = this.cls.userInfo.licances
      this.glb.showLicanceSelectPopup = true
    } else {
   
      this.glb.filteredStores = this.cls.userInfo.licances              
      console.log("Single Licance",this.cls.userInfo)
      this.cls.setActiveLicance(this.cls.userInfo.licances[0].licanceId)
      this.glb.activeLicance = this.cls.userInfo.licances[0].licanceId
      this.glb.activeBrand = this.cls.userInfo.licances[0].brand     
      this.ss.loadData().then(x=> {
        this.perm.loadPerms()     
        this.ss.observer_startup_loading.subscribe(p => {
          this.translate.setDefaultLang('tr');
          this.first = false
          this.waitlogin = false
          this.status = "login"
          if (this.glb.activeRoute) {
            this.router.navigateByUrl(this.glb.activeRoute)
          } else {
            this.router.navigate(['main'])
          }
         
        })
      })
  
    }
    console.groupEnd()
  }



  /*  login() {
 
 
     this.waitlogin = true
     this.sso.login(this.user, this.pass).subscribe(
       result => {
 
         console.log("login", result.token)
         console.log("login", jwt_decode(result.token))
         let UserObj = jwt_decode(result.token)
         this.glb.ssoUsers = UserObj
         this.glb.kerzzUser = UserObj.userInfo
 
 
 
         this.glb.licances = this.glb.ssoUsers.userInfo.licances
         localStorage.setItem("userinfo", JSON.stringify(UserObj))
         localStorage.setItem("user", JSON.stringify({ email: this.user, password: this.pass }))
         this.waitlogin = false
         if (this.glb.licances.length > 1) {
           this.glb.showLicanceSelectPopup = true
         } else {
           this.glb.activeLicance = this.glb.ssoUsers.userInfo.licances[0].id
 
           this.ss.loadData()
           this.ss.observer_startup_loading.subscribe(p => {
             this.translate.setDefaultLang('tr');
             this.first = false
             this.waitlogin = false
             this.status = "login"
             this.router.navigate(['main'])
           })
 
         }
       },
       error => {
         console.log("login error", error)
         this.waitlogin = false
         this.error = error.error
       }
     )
   } */




}

export class Licances {
  brand: string
  email: string
  licanceID: string
  type: string
}