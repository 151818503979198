import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { menu } from "src/app/tools/button-menu/button-menu.component";
import {
  InvoiceService,
  Invoice,
  invoiceDetail,
  logType,
  invoiceLog,
  InvoiceDeliveryInofo,
} from "../services/invoice.service";
import { ModalService } from "projects/cloud-components/src/lib/modal/modal.service";
import {
  trigger,
  transition,
  animate,
  style,
  state,
  group,
} from "@angular/animations";
import {
  ClModalDialogService,
  clPrompt,
} from "projects/cloud-components/src/lib/cl-modal-dialog/cl-modal-dialog.service";
import { CloudLoginService } from "cloud-labs-core";
import { AppUsersService } from "src/app/settings/services/app-users.service";
import moment from "moment";
import { AppPermissionsService } from "src/app/settings/services/app-permissions.service";
import jsPDF from "jspdf";
import { confirm } from "devextreme/ui/dialog";
import { GlobalsService } from "src/app/services/globals.service";
import { saveAs } from "file-saver";
import { PublicApiService } from "src/app/services/public-service/public-api.service";
import { InvoiceSettingsService } from "../services/invoice-settings.service";

@Component({
  selector: "app-invoice-list",
  templateUrl: "./invoice-list.component.html",
  styleUrls: ["./invoice-list.component.scss"],
  animations: [
    trigger("slider", [
      state(
        "in",
        style({
          "max-height": "500px",
          opacity: "1",
          visibility: "visible",
        })
      ),
      state(
        "out",
        style({
          "max-height": "0px",
          opacity: "0",
          visibility: "hidden",
        })
      ),
      transition("in => out", [
        group([
          animate(
            "400ms ease-in-out",
            style({
              opacity: "0",
            })
          ),
          animate(
            "600ms ease-in-out",
            style({
              "max-height": "0px",
            })
          ),
          animate(
            "700ms ease-in-out",
            style({
              visibility: "hidden",
            })
          ),
        ]),
      ]),
      transition("out => in", [
        group([
          animate(
            "1ms ease-in-out",
            style({
              visibility: "visible",
            })
          ),
          animate(
            "600ms ease-in-out",
            style({
              "max-height": "500px",
            })
          ),
          animate(
            "800ms ease-in-out",
            style({
              opacity: "1",
            })
          ),
        ]),
      ]),
    ]),
  ],
})
export class InvoiceListComponent implements OnInit {
  dateFilterEnabled = true;

  now: Date = new Date();

  startDate: Date = new Date("01.01.1900");
  endDate: Date = new Date("01.02.2100");

  activeInvoice: Invoice = new Invoice();
  invoiceMenu: menu[] = [];
  inoviceError = "";
  popupVisible = false;
  selectedRows: number[];
  loadingVisible = false;
  loadIndicatorVisible = false;
  invoiceSendEmailAddress: string = "";
  filterStatus = "standby";
  filterDocumentType = "eInvoice";
  animationState = "in";
  detail: invoiceDetail = new invoiceDetail();
  loadingMessage = "Yükleniyor...";
  activeFilter = {};
  typeList: any;
  activeTab = "eInvoice";
  activeLicances: any;
  activeBranches: number[] = [];
  selectedInovices: Invoice[] = [];

  mockData: any[] = [];
  constructor(
    public inoviceSrc: InvoiceService,
    private dialog: ClModalDialogService,
    private modalService: ModalService,
    private ls: CloudLoginService,
    public perm: AppPermissionsService,
    public appUserService: AppUsersService,
    private elementref: ElementRef,
    private glb: GlobalsService,
    private cls: CloudLoginService,
    private publicApiService: PublicApiService,
    private iSet: InvoiceSettingsService,
  ) {
    this.typeList = [
      { id: 0, name: "Stok" },
      { id: 1, name: "Hizmet" },
    ];

    // console.log("LoginUserInfo",this.ls.userInfo.licances.find(x=>x.licanceId === this.glb.activeLicance))
    this.activeLicances = this.ls.userInfo.licances.find(
      (x) => x.licanceId === this.glb.activeLicance
    );
    if (this.activeLicances) {
      if (this.activeLicances.branchCodes) {
        this.activeLicances.branchCodes.map((x) => {
          this.activeBranches.push(parseInt(x));
        });
      }
    }

    // this.activeLicances.branchCodes = Array.stream(his.activeLicances.branchCodes).mapToInt(Int::parseInt).toArray()
    // console.log("LoginUserInfo", this.activeLicances,this.activeBranches)
    // Tarihçenin servisi gelince silinecek
    this.mockData = [
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Uygulama Yanıtı",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        saveType: "Fatura",
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
      {
        id: Math.random() * 100,
        date: new Date(),
        ettn: "b1cc1b60-11ekd-973jdjdu-dsdffff101",
        zarfNo: "102fbe-baaa11011-31ff1a-ddf1f1f",
        zarfDuruKodu: Math.random() * 500,
        description: "KYD2019000000000 System application",
      },
    ];
  }

  ngOnInit() {
    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);

    this.startDate = moment().startOf("month").toDate();
    this.endDate = moment().endOf("month").toDate();

    this.invoiceMenu.push({
      title: "Gönder",
      faicon: "fa-share-square",
      icon: "restaurant_menu",
      tag: "send",
      selectable: false,
      disabled: true,
    });
    this.invoiceMenu.push({
      title: "Önizleme",
      faicon: "fa-file-invoice",
      icon: "money",
      tag: "preview",
      selectable: false,
      disabled: true,
    });
    this.invoiceMenu.push({
      title: "EPosta",
      faicon: "fa-envelope-open-text",
      icon: "card_giftcard",
      tag: "sendmail",
      selectable: false,
      disabled: true,
    });
    this.invoiceMenu.push({
      title: "Düzenle",
      faicon: "fa-edit",
      icon: "card_giftcard",
      tag: "edit",
      selectable: false,
      disabled: true,
    });
    this.invoiceMenu.push({
      title: "XML İndir",
      faicon: "fa-file-download",
      icon: "card_giftcard",
      tag: "xmlDownload",
      selectable: false,
      disabled: true,
    });

    // this.invoiceMenu.push({
    //   title: "Yazdır",
    //   faicon: "fa-print",
    //   icon: "fa-print",
    //   tag: "print",
    //   selectable: false,
    //   disabled: true,
    // });
    this.invoiceMenu.push({
      title: "Ayrıntılar",
      faicon: "fa-list-alt",
      icon: "card_giftcard",
      tag: "detail",
      selectable: false,
      disabled: true,
    });
    this.invoiceMenu.push({
      title: "İptal",
      faicon: "fa-trash-alt",
      icon: "card_giftcard",
      tag: "cancel",
      selectable: false,
      disabled: false,
    });
    this.invoiceMenu.push({
      title: "Yoksay",
      faicon: "fa-unlink",
      icon: "card_giftcard",
      tag: "ignore",
      selectable: false,
      disabled: true,
    });
    //this.invoiceMenu.push({ title: "Excele", faicon: "fa-file-excel", icon: "card_giftcard", tag: "emp", selectable: false, disabled: true })

    this.invoiceMenu.push({
      title: "ERP Aktar",
      faicon: "fa-sitemap",
      icon: "card_giftcard",
      tag: "erp",
      selectable: false,
      disabled: true,
    });
    this.invoiceMenu.push({
      title: "Loglar",
      faicon: "fa-clipboard-list",
      icon: "card_giftcard",
      tag: "logs",
      selectable: false,
      disabled: false,
    });
    this.invoiceMenu.push({
      title: "Yenile",
      faicon: "fa-clipboard-list",
      icon: "card_giftcard",
      tag: "refresh",
      selectable: false,
      disabled: false,
    });

    this.elementref.nativeElement.style.setProperty(
      "--button-menu-width",
      "50px"
    );
    this.elementref.nativeElement.style.setProperty(
      "--button-menu-height",
      "60px"
    );

    //this.startDate = new Date(this.now.getFullYear(), this.now.getMonth(), 1)
    //this.endDate = new Date(this.now.getFullYear(), this.now.getMonth() + 1, 0)

    this.loadStatics();
  }

  calculateCellValue(data) {
    return data.grandTotal - data.taxTotal;
  }

  ngAfterViewInit() {
    console.log("this.appUserService.users", this.appUserService.users);
    this.inoviceSrc.data = [];
    // this.activeFilter = { direction: "outBound" };
    if (this.activeLicances.branchCodes.length > 0) {
      this.activeFilter = {
        $and: [
          { branchCode: { $in: this.activeBranches } },
          { documentType: "eInvoice" },
          { source: "pos" },
          { direction: "outBound" },
          {
            $and: [
              { invoiceDate: { $gte: this.startDate.toISOString() } },
              { invoiceDate: { $lte: this.endDate.toISOString() } },
            ],
          },
        ],
      };
    } else {
      this.activeFilter = {
        $and: [
          { documentType: "eInvoice" },
          { source: "pos" },
          { direction: "outBound" },
          {
            $and: [
              { invoiceDate: { $gte: this.startDate.toISOString() } },
              { invoiceDate: { $lte: this.endDate.toISOString() } },
            ],
          },
        ],
      };
    }

    setTimeout(() => {
      //this.refreshWithFilter()
      //this.inoviceSrc.runCalculateStatics()
    }, 1);
  }

  loadData() {
    let x = {
      sendDate: { $gte: { $dateFromString: this.startDate.toISOString() } },
    };
    let filter: any;
    if (this.activeLicances.branchCodes.length > 0) {
      filter = {
        $and: [
          {
            $or: [
              { invoiceDate: { $gte: this.startDate.toISOString } },
              { invoiceDate: { $lte: this.endDate.toISOString } },
            ],
          },
          { branchCode: { $in: this.activeBranches } },
          { documentType: this.filterDocumentType },
          { status: this.filterStatus },
          /* { direction: 'outBound' } */
        ],
      };
    } else {
      filter = {
        $and: [
          {
            $or: [
              { invoiceDate: { $gte: this.startDate.toISOString } },
              { invoiceDate: { $lte: this.endDate.toISOString } },
            ],
          },
          { documentType: this.filterDocumentType },
          { status: this.filterStatus },
          /* { direction: 'outBound' } */
        ],
      };
    }

    console.log(filter);

    this.loadingVisible = true;
    let o = { status: this.filterStatus };
    this.inoviceSrc.load(filter).then((o) => {
      console.log(o);
      this.loadingVisible = false;
    });
  }

  selectionChangedHandler(e) {
    if (e.selectedRowsData.length == 0) return;
    console.log(e);

    this.selectedInovices = e.selectedRowsData;
    if (this.selectedInovices.length > 1) {
      this.invoiceMenu.find((f) => f.tag == "xmlDownload").disabled = true;
    } else {
      this.invoiceMenu.find((f) => f.tag == "xmlDownload").disabled = false;
    }
    // this.invoiceMenu.find((f) => f.tag == "print").disabled = false
    this.activeInvoice = e.selectedRowsData[0];
    this.activeInvoice.deliveryInfo =
      this.activeInvoice.deliveryInfo ?? new InvoiceDeliveryInofo();

    let mn = (this.invoiceMenu.find((f) => f.tag == "ignore").title =
      "Faturayı Yoksay");

    this.invoiceMenu.find((f) => f.tag == "preview").disabled = false; //(this.selectedRows.length > 1 || (this.selectedInovices[0].status != 'success' && this.selectedInovices[0].status != 'standby' && this.selectedInovices[0].status != 'ignored'))
    this.invoiceMenu.find((f) => f.tag == "edit").disabled =
      this.selectedRows.length > 1 ||
      this.selectedInovices[0].status == "success";
    this.invoiceMenu.find((f) => f.tag == "detail").disabled =
      this.selectedRows.length > 1 ||
      this.selectedInovices[0].status != "success";

    this.invoiceMenu.find((f) => f.tag == "send").disabled =
      this.selectedInovices[0].status == "success" ||
      this.selectedInovices[0].status == "cancelled" ||
      this.selectedInovices[0].status == "ignored";

    this.invoiceMenu.find((f) => f.tag == "sendmail").disabled =
      this.selectedInovices[0].status != "success";

    this.invoiceMenu.find((f) => f.tag == "cancel").disabled =
      this.selectedRows.length > 1 ||
      this.selectedInovices[0].status != "success";
    this.invoiceMenu.find((f) => f.tag == "ignore").disabled =
      this.selectedInovices[0].status != "standby";

    this.invoiceMenu.find((f) => f.tag == "erp").disabled =
      this.selectedInovices[0].status != "success";

    /* if (this.selectedInovices[0].erp == "success") {
      this.invoiceMenu.find(f => f.tag == "erp").disabled = true
    } */

    this.inoviceSrc.erpStatus;

    if (this.selectedInovices[0].status == "ignored") {
      let mn = this.invoiceMenu.find((f) => f.tag == "ignore");
      mn.title = "Geri AL";
      mn.disabled = false;
    }
  }
  expiredInvoices: Invoice[] = [];
  showExpiredInvoicesModal: boolean = false;
  inovice_menu_click(e) {
    console.log(e);
    this.expiredInvoices = [];

    if (e.tag == "preview") {
      if (this.selectedInovices.length > 20) {
        this.glb.notice(
          "Bir defa da en çok 20 faturada işlem yapılabilir.",
          "warning"
        );
        return;
      }
      this.previewInvoice();
    }

    if (e.tag == "send") {
      if (this.selectedInovices.length > 20) {
        this.glb.notice(
          "Bir defa da en çok 20 faturada işlem yapılabilir.",
          "warning"
        );
        return;
      }
      const expared: Invoice[] = [];
      console.log("this.selectedInovices: ", this.selectedInovices);
      this.selectedInovices.forEach((el) => {
        let days: number = moment(el.invoiceDate).diff(new Date(), "days");
        if (days < 0) {
          days = days * -1;
        }

        if (days > 7) {
          expared.push({ ...el });
          console.log("expared: ", el);
        }
        console.log("moment: ", days);
      });

      this.showExpiredInvoicesModal = expared.length > 0;
      if (this.showExpiredInvoicesModal) {
        this.expiredInvoices = expared;
        return;
      }

      this.sendInvoice();
    }

    if (e.tag == "erp") {
      if (this.selectedInovices.length > 100) {
        this.glb.notice(
          "Bir defa da en çok 100 faturada işlem yapılabilir.",
          "warning"
        );
        return;
      }
      this.sendErp();
    }

    if (e.tag == "sendmail") {
      this.senMailInvoice();
    }

    if (e.tag == "xmlDownload") {
      this.getInvoicesXml();
    }

    if (e.tag == "edit") {
      this.activeInvoice = this.selectedInovices[0];
      this.activeInvoice.deliveryInfo =
        this.activeInvoice.deliveryInfo ?? new InvoiceDeliveryInofo();
      this.modalService.open("invoiceEditModal");
    }

    if (e.tag == "detail") {
      this.detailInvoice();
    }

    if (e.tag == "cancel") {
      this.cancelInvoice();
    }

    // if (e.tag == "print") {
    //   this.printInvoice()
    // }

    if (e.tag == "ignore") {
      if (this.selectedInovices.length > 1) {
        const result = confirm(
          this.selectedInovices.length +
            " Adet Fatura Yoksay/Yoksaydan Geri Al işlemi yapılcak.Onaylıyor musunuz?",
          "Yoksay/Geri Al"
        );
        result.then(async (dialogResult: any) => {
          if (dialogResult) {
            for (let index = 0; index < this.selectedInovices.length; index++) {
              this.activeInvoice = this.selectedInovices[index];
              if (this.activeInvoice.status == "ignored") {
                this.goBackIgnoredAll();
              } else {
                this.ignoreAll();
              }
            }
          }
        });
      } else {
        if (this.selectedInovices[0].status == "ignored") {
          this.goBackIgnored();
        } else {
          this.ignoreInvoice();
        }
      }
    }

    if (e.tag == "logs") {
      this.modalService.open("invoiceLogsModal");
    }

    if (e.tag == "refresh") {
      this.inoviceSrc.getInvoiceErp().subscribe((x) => {
        this.inoviceSrc.runCalculateStatics();
        this.refreshWithFilter();
      });
    }
  }

  getInvoicesXml() {
    this.loadingVisible = true;
    this.loadingMessage = "İndiriliyor...";
    this.inoviceSrc
      .requestXmlInvoices(this.selectedInovices[0].uuid)
      .subscribe((o: any) => {
        console.log("xml", o);
        this.loadingVisible = false;
        var element = document.createElement("a");
        var blob = new Blob([o], {
          type: "text/xml",
        });
        var url = URL.createObjectURL(blob);
        element.href = url;
        element.setAttribute(
          "download",
          this.selectedInovices[0].invoiceNumber + ".xml"
        );
        document.body.appendChild(element);
        element.click();
      });
  }

  currencies = [
    {
      id: "try",
      name: "TRY",
    },
    {
      id: "usd",
      name: "USD",
    },
    {
      id: "eur",
      name: "EUR",
    },
  ];

  sendMail(isValid, mail) {
    if (!isValid) return;
    this.modalService.close("invoiceEmail");
    this.selectedInovices.forEach((el) => {
      this.loadingVisible = true;
      this.inoviceSrc.mail(el.uuid, mail).subscribe(
        (o) => {
          this.addLog("log", el, "fatura-mail-gönderildi", mail);
          this.loadingVisible = false;
          let resInvoice: Invoice = Object.assign(o);
          let pobj = this.inoviceSrc.data.findIndex(
            (X) => X.uuid == resInvoice.uuid
          );
          el = Object.assign(o);
          console.log(o, pobj);
          this.inoviceSrc.data[pobj] = Object.assign(o);
        },
        (e) => {
          this.loadingVisible = false;
          this.ShowError(e.error.message);
        }
      );
    });
  }

  isColumnVisible() {
    if (this.activeTab == "eDispatch") {
      return true;
    } else {
      return false;
    }
  }

  sendMailFromPreview() {
    this.modalService.close("inoviceModal");
    setTimeout(() => {
      this.modalService.open("invoiceEmail");
    }, 500);
  }
  @ViewChild("invHtml", { static: true }) pdfTable: ElementRef;

  public captureScreen(d) {
    const pdf = new jsPDF();
    pdf.fromHTML(d, 15, 15);
    pdf.save("tableToPdf.pdf");
  }

  private previewInvoice() {
    this.loadingVisible = true;
    let uuids = [];
    this.activeInvoice = this.selectedInovices[0];
    console.log(JSON.stringify(this.activeInvoice));
    this.activeInvoice.deliveryInfo =
      this.activeInvoice.deliveryInfo ?? new InvoiceDeliveryInofo();
    // window.matchMedia("print").addListener(function() {alert("Print Dialog open.")})
    for (const iterator of this.selectedInovices) {
      uuids.push(iterator.uuid);
    }

    this.inoviceSrc.requestPdfInvoices(uuids).subscribe(
      (o: any) => {
        this.loadingVisible = false;
        this.modalService.open("inoviceModal");
        const byteArray = new Uint8Array(
          atob(o.pdf)
            .split("")
            .map((char) => char.charCodeAt(0))
        );
        const file = new Blob([byteArray], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        document.getElementById(
          "invHtml"
        ).innerHTML = `<iframe src='${fileURL}#toolbar=0' type="application/pdf" width="100%" height="600px" />`;
        this.addLog(
          "log",
          this.selectedInovices[0],
          "fatura-ön-izleme",
          "başarılı"
        );
      },
      (e) => {
        this.ShowError(e.error.message);
      }
    );

    /* this.inoviceSrc.preview(this.activeInvoice.uuid).subscribe(o => {
      this.loadingVisible = false
      this.modalService.open("inoviceModal");
      document.getElementById('invHtml').innerHTML = `<embed src='data:application/pdf;base64,${Object.assign(o).pdf}' type="application/pdf" width="100%" height="600px" />`
      this.addLog('log', this.selectedInovices[0], "fatura-ön-izleme", "başarılı")
    }, e => {

      this.ShowError(e.error.message)
    }); */
  }

  saveFatura(id: string) {
    let iframe = document.querySelector(
      "#" + id + " iframe"
    ) as HTMLIFrameElement;

    if (iframe) {
      saveAs(iframe.src, `invoice-${new Date().toISOString()}.pdf`);
    }
  }

  async printFatura(id: string) {
    let iframe = document.querySelector(
      "#" + id + " iframe"
    ) as HTMLIFrameElement;
    console.log("#" + id + " iframe");
    if (iframe) {
      iframe.contentWindow.print();
      try {
        this.loadingVisible = true;
        const promises = [];
        this.selectedInovices.forEach((elm) => {
          elm.printed = true;
          promises.push(
            this.publicApiService
              .upsert({
                _db: this.iSet.settings.licanceId,
                col: 'invoices',
                filter: {
                  uuid: elm.uuid,
                },
                data: {
                  ...elm,
                  printed: true,
                }
              })
          );
        });
        await Promise.allSettled(promises);
        this.loadingVisible = false;
      } catch (error) {
        console.log(error);
        this.loadingVisible = false;
      } finally {
        this.loadingVisible = false;
      }
    }
  }

  public async sendInvoice() {
    this.selectedInovices.sort((a, b) => {
      return moment(a.invoiceDate).diff(b.invoiceDate, "hours");
    });

    for (const num of this.selectedInovices) {
      this.loadingVisible = true;
      try {
        num.senderUserId = this.ls.userInfo.id;
        let o = await this.inoviceSrc.send(num.uuid).toPromise();
        let inv: Invoice = Object.assign(o);
        inv.senderUserId = this.ls.userInfo.id;
        this.inoviceSrc.upsert(inv);
        this.updateLocalInvoiceObjects(inv, true);
        this.addLog("log", Object.assign(o), "fatura-gönderimi", "başarılı");
      } catch (error) {
        this.ShowError(error.error.message);
      }
    }

    this.inoviceSrc.runCalculateStatics();
    this.loadingVisible = false;
    this.refreshWithFilter();
  }

  onCellPrepared(e) {
    if (e.rowType === "data" && e.column.dataField === "status") {
      e.cellElement.style.color =
        e.data.status === "cancelled" ? "red" : "white";
    }
  }

  private sendInvoice2() {
    this.selectedInovices.sort((a, b) => {
      return moment(a.invoiceDate).diff(b.invoiceDate, "hours");
    });

    let invSend = new Promise((resolve, reject) => {
      this.selectedInovices.forEach((el, index, array) => {
        this.loadingVisible = true;
        this.inoviceSrc.send(el.uuid).subscribe(
          (o) => {
            this.updateLocalInvoiceObjects(Object.assign(o), true);
            this.addLog(
              "log",
              Object.assign(o),
              "fatura-gönderimi",
              "başarılı"
            );
            if (index === array.length - 1) resolve("");
          },
          (e) => {
            this.ShowError(e.error.message);
            reject();
          }
        );
      });
    });

    invSend
      .then((o) => {
        this.inoviceSrc.runCalculateStatics();
        this.loadingVisible = false;
        this.refreshWithFilter();
      })
      .catch((o) => {
        this.loadingVisible = false;
        this.refreshWithFilter();
      });
  }

  private senMailInvoice() {
    this.modalService.open("invoiceEmail");
  }

  getUser(userId: string) {}
  getSenderUser(logs: invoiceLog[]) {
    if (logs.length == 0) {
      return "Sistem";
    }
    let user = this.appUserService.users.find(
      (c) => c.id == logs.find((x) => x.title == "fatura-gönderimi")?.userId
    );
    if (user) {
      return user.name;
    } else {
      ("Sistem");
    }
  }

  getLastEditUser(logs: invoiceLog[]) {
    if (logs.length == 0) {
      return "Sistem";
    }
    let user = this.appUserService.users.find(
      (x) => x.id == logs[logs.length - 1].userId
    );
    if (user) {
      return user.name;
    } else {
      ("Sistem");
    }
  }

  private detailInvoice() {
    this.loadingVisible = true;
    this.activeInvoice = this.selectedInovices[0];

    this.activeInvoice.deliveryInfo =
      this.activeInvoice.deliveryInfo ?? new InvoiceDeliveryInofo();
    let uuid = this.selectedInovices[0].uuid;
    this.inoviceSrc.detail(uuid).subscribe(
      (o) => {
        this.loadingVisible = false;
        this.detail = Object.assign(o);
        this.modalService.open("inoviceDetailModal");
      },
      (e) => {
        this.ShowError(e.error.message);
      }
    );
  }

  private cancelInvoice() {
    this.dialog.title = "Fatura İptal";
    this.dialog.message =
      "E-Faturaların iptali harici olarak yapılmaktadır. Portal üzerinden yapılan iptal işlemi sadece bilgi amaçlıdır. Onaylıyor musunuz?";
    if (this.activeTab == "eDispatch") {
      this.dialog.title = "İrsaliye İptal";

      this.dialog.message =
        "E-İrsaliyelerin iptali GIB tarafında mümkün değildir. Bu İrsaliye sadece portal tarafından iptal statüsüne alınacaktır. Onaylıyor musunuz?";
    } else if (this.activeTab == "eArchive") {
      this.dialog.title = "Arşiv İptal";

      this.dialog.message =
        "Bu E-Arşiv GIB tarafında da iptal edilecektir ve bu işlemin geri dönüşü yoktur. İptal etmek istediğinize emin misiniz?";
    }
    this.dialog.openWithPompt([
      { title: "İptal nedeni nedir", value: "", required: true },
    ]);
    this.dialog.statPrompt().then((promptsResp) => {
      if (promptsResp.stat) {
        this.loadingVisible = true;
        this.activeInvoice = this.selectedInovices[0];
        this.activeInvoice.deliveryInfo =
          this.activeInvoice.deliveryInfo ?? new InvoiceDeliveryInofo();
        this.inoviceSrc.cancel(this.activeInvoice.uuid).subscribe(
          (o) => {
            this.inoviceSrc.runCalculateStatics();
            this.refreshWithFilter();
            this.addLog(
              "cancel",
              this.activeInvoice,
              "iptal",
              Object.assign(promptsResp).prompts[0].value
            );
            this.updateLocalInvoiceObjects(Object.assign(o));
          },
          (e) => {
            this.loadingVisible = false;
            this.ShowError(e.error.message);
          }
        );
      }
    });
  }

  private ignoreAll() {
    this.loadingVisible = true;
    this.activeInvoice.deliveryInfo =
      this.activeInvoice.deliveryInfo ?? new InvoiceDeliveryInofo();
    this.inoviceSrc.ignore(this.activeInvoice.uuid).subscribe(
      (o) => {
        this.refreshWithFilter();
        this.inoviceSrc.runCalculateStatics();
        this.addLog(
          "ignore",
          this.activeInvoice,
          "yok sayıldı",
          "Toplu Yoksayma"
        );
        this.updateLocalInvoiceObjects(Object.assign(o));
      },
      (e) => {
        this.ShowError(e.error.message);
      }
    );
    this.loadingVisible = false;
  }

  private goBackIgnoredAll() {
    this.loadingVisible = true;
    this.activeInvoice.deliveryInfo =
      this.activeInvoice.deliveryInfo ?? new InvoiceDeliveryInofo();
    this.activeInvoice.status = "standby";
    this.inoviceSrc.getBackIgnoredInvoice(this.activeInvoice.uuid).subscribe(
      (o) => {
        this.inoviceSrc.runCalculateStatics();
        this.refreshWithFilter();
        this.addLog(
          "ignore",
          this.activeInvoice,
          "yoksay-geri-alındı",
          "Toplu Yoksay Geri Alma"
        );
        this.updateLocalInvoiceObjects(Object.assign(o));
      },
      (e) => {
        this.ShowError(e.error.message);
      }
    );
  }

  private ignoreInvoice() {
    this.dialog.title = "Faturayı Yoksay";
    this.dialog.message = "Bu Fatura Yoksayılacak, Onaylıyor musunuz?";

    this.dialog.openWithPompt([
      { title: "Neden yoksayılıyor?", value: "", required: true },
    ]);
    this.dialog.statPrompt().then((promptsResp) => {
      if (promptsResp.stat) {
        this.loadingVisible = true;
        this.activeInvoice = this.selectedInovices[0];
        this.activeInvoice.deliveryInfo =
          this.activeInvoice.deliveryInfo ?? new InvoiceDeliveryInofo();
        this.inoviceSrc.ignore(this.activeInvoice.uuid).subscribe(
          (o) => {
            this.refreshWithFilter();
            this.inoviceSrc.runCalculateStatics();
            this.addLog(
              "ignore",
              this.activeInvoice,
              "yok sayıldı",
              Object.assign(promptsResp).prompts[0].value
            );
            this.updateLocalInvoiceObjects(Object.assign(o));
          },
          (e) => {
            this.ShowError(e.error.message);
          }
        );
      }
    });
  }

  private goBackIgnored() {
    this.dialog.title = "Faturayı Bekleyenlere Geri Al";
    this.dialog.message =
      "Bu Fatura Beklemedeye Geri Alınacak, Onaylıyor musunuz?";
    this.dialog.openWithPompt([
      { title: "Neden geri alınıyor?", value: "", required: true },
    ]);
    this.dialog.statPrompt().then((promptsResp) => {
      if (promptsResp.stat) {
        this.loadingVisible = true;
        this.activeInvoice = this.selectedInovices[0];
        this.activeInvoice.deliveryInfo =
          this.activeInvoice.deliveryInfo ?? new InvoiceDeliveryInofo();
        this.activeInvoice.status = "standby";
        this.inoviceSrc
          .getBackIgnoredInvoice(this.activeInvoice.uuid)
          .subscribe(
            (o) => {
              this.inoviceSrc.runCalculateStatics();
              this.refreshWithFilter();
              this.addLog(
                "ignore",
                this.activeInvoice,
                "yoksay-geri-alındı",
                Object.assign(promptsResp).prompts[0].value
              );
              this.updateLocalInvoiceObjects(Object.assign(o));
            },
            (e) => {
              this.ShowError(e.error.message);
            }
          );
      }
    });
  }

  printInvoice() {
    this.loadingVisible = true;
    let uuids = [];
    this.activeInvoice = this.selectedInovices[0];
    this.activeInvoice.deliveryInfo =
      this.activeInvoice.deliveryInfo ?? new InvoiceDeliveryInofo();

    for (const iterator of this.selectedInovices) {
      uuids.push(iterator.uuid);
    }

    this.inoviceSrc.requestPdfInvoices(uuids).subscribe(
      (o: any) => {
        this.loadingVisible = false;
        document.getElementById(
          "invHtml"
        ).innerHTML = `<embed src='data:application/pdf;base64,${o.pdf}' type="application/pdf" width="100%" height="600px" />`;
        debugger;

        const printContent = document.getElementById("invHtml");
        // this.addLog(
        //   "log",
        //   this.selectedInovices[0],
        //   "fatura-ön-izleme",
        //   "başarılı"
        // );
        const WindowPrt = window.open(
          "asdasd",
          "altintqs",
          "left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0"
        );
        WindowPrt.document.write(printContent.innerHTML);
        WindowPrt.document.close();
        WindowPrt.focus();
        WindowPrt.print();
        WindowPrt.close();
        // var blob = new Blob([o.pdf], {type: 'application/pdf'});
        // const blobUrl = URL.createObjectURL(blob);
        //   const iframe = document.createElement('iframe');
        //   iframe.style.display = 'none';
        //   iframe.src = blobUrl;
        //   document.body.appendChild(iframe);
        //   iframe.contentWindow.print();
      },
      (e) => {
        this.ShowError(e.error.message);
      }
    );
  }

  private async sendErp() {
    this.selectedRows = [];
    this.selectedInovices.sort((a, b) => {
      return moment(a.invoiceDate).diff(b.invoiceDate, "hours");
    });

    let i = 0;
    for (const element of this.selectedInovices) {
      this.loadingVisible = true;
      i++;
      try {
        let o = await this.inoviceSrc
          .sendInvoiceToErp(element.uuid)
          .toPromise();

        this.loadingMessage = "Erp'ye gönderiliyor (" + i + ")";
        let ps = Object.assign(o);

        if (ps.message == "success") {
          if (element.erp) element["erp"] = "new";
          element.erp = "success";
          this.addLog("erp", element, "entegrasyon", "başarılı");
        } else {
          this.addLog("erp", element, "entegrasyon-hatası", ps.message);
        }
      } catch (error) {
        this.addLog("erp", element, "entegrasyon-error", error.error.message);
        this.ShowError(error.error.message);
      }
    }

    this.inoviceSrc.runCalculateStatics();
    this.loadingVisible = false;
    this.refreshWithFilter();
  }

  private sendErp_deprecated() {
    this.loadingVisible = true;
    let erpsender = new Promise((resolve, reject) => {
      this.selectedInovices.forEach((el, index, array) => {
        this.inoviceSrc.sendInvoiceToErp(el.uuid).subscribe(
          (o) => {
            this.loadingMessage = "Erp'ye gönderiliyor (" + index + ")";
            let ps = Object.assign(o);

            if (ps.message == "success") {
              if (el.erp) el["erp"] = "new";
              el.erp = "success";
              this.addLog("erp", el, "integration", "success");
            } else {
              this.addLog("erp", el, "integration-error", ps.message);
            }
            if (index === array.length - 1) resolve("");
          },
          (e) => {
            if (index === array.length - 1) reject(e.error.message);
            //this.ShowError(e.error.message)
            this.addLog("erp", el, "integration-error", e.error.message);
          }
        );
      });
    });

    erpsender
      .then((o) => {
        this.inoviceSrc.runCalculateStatics();
        this.loadingVisible = false;
        this.loadingMessage = "Yükleniyor...";
      })
      .catch((p) => {
        this.loadingVisible = false;
        this.ShowError(p);
        this.loadingMessage = "Yükleniyor...";
      });
  }

  private ShowError(e) {
    console.log(e);
    this.loadingVisible = false;
    this.inoviceError = e;
    this.modalService.open("inoviceError");
  }

  updateLocalInvoiceObjects(respInvObj: Invoice, visible: boolean = false) {
    this.loadingVisible = visible;
    this.activeInvoice = respInvObj;
    this.activeInvoice.deliveryInfo =
      this.activeInvoice.deliveryInfo ?? new InvoiceDeliveryInofo();
    let dataIndex = this.inoviceSrc.data.findIndex(
      (X) => X.uuid == this.activeInvoice.uuid
    );
    this.inoviceSrc.data[dataIndex] = respInvObj;
  }

  closeModal(i) {
    document.getElementById("invHtml").innerHTML = "";
    this.modalService.close(i);
  }

  refresh() {
    this.inoviceSrc.refresh();
  }

  eInvoicePos = true;
  eInvoiceErp = false;
  eArchivePos = false;
  eArchiveErp = false;
  showStatusMenu = false;
  activeStat = { docType: "", status: "", source: "" };

  statMenu() {
    this.showStatusMenu = this.showStatusMenu ? false : true;
    this.inoviceSrc.runCalculateStatics();
  }

  loadStatics() {
    this.loadingVisible = true;
    this.inoviceSrc
      .loadOutBoundStaticsWithDate(
        this.startDate.toISOString(),
        this.endDate.toISOString(),
        this.activeBranches
      )
      .then((o) => {
        this.loadingVisible = false;
      });
  }

  //?click Toolbar buttons ; eg: all, new, success e-Fatura & e-Arşiv
  loadGrid(item) {
    if (item.id == "success") {
      this.invoiceMenu.find((f) => f.tag == "xmlDownload").disabled = false;
    } else {
      this.invoiceMenu.find((f) => f.tag == "xmlDownload").disabled = true;
    }
    if (item.status != "standBy" || item.status != "ignored") {
      this.invoiceMenu.find((f) => f.tag == "ignore").disabled = true;
    } else {
      this.invoiceMenu.find((f) => f.tag == "ignore").disabled = false;
    }
    this.inoviceSrc.loadOutBoundStaticsWithDate(
      this.startDate.toISOString(),
      this.endDate.toISOString(),
      this.activeBranches
    );
    debugger;
    if (item.count == 0) return;
    this.activeFilter = {};
    if (item.id == "all") {
      if (this.activeLicances.branchCodes.length > 0) {
        this.activeFilter = {
          $and: [
            { branchCode: { $in: this.activeBranches } },
            { documentType: item.docType },
            { source: item.source },
            { direction: "outBound" },
            {
              $and: [
                { invoiceDate: { $gte: this.startDate.toISOString() } },
                { invoiceDate: { $lte: this.endDate.toISOString() } },
              ],
            },
          ],
        };
      } else {
        this.activeFilter = {
          $and: [
            { documentType: item.docType },
            { source: item.source },
            { direction: "outBound" },
            {
              $and: [
                { invoiceDate: { $gte: this.startDate.toISOString() } },
                { invoiceDate: { $lte: this.endDate.toISOString() } },
              ],
            },
          ],
        };
      }
    } else {
      if (this.activeLicances.branchCodes.length > 0) {
        this.activeFilter = {
          $and: [
            { branchCode: { $in: this.activeBranches } },
            { documentType: item.docType },
            { status: item.id },
            { source: item.source },
            { direction: "outBound" },
            {
              $and: [
                { invoiceDate: { $gte: this.startDate.toISOString() } },
                { invoiceDate: { $lte: this.endDate.toISOString() } },
              ],
            },
          ],
        };
      } else {
        this.activeFilter = {
          $and: [
            { documentType: item.docType },
            { status: item.id },
            { source: item.source },
            { direction: "outBound" },
            {
              $and: [
                { invoiceDate: { $gte: this.startDate.toISOString() } },
                { invoiceDate: { $lte: this.endDate.toISOString() } },
              ],
            },
          ],
        };
      }

      console.log("activeFilter: ", this.activeFilter);
    }

    console.log(this.activeFilter);
    this.activeStat = {
      docType: item.docType,
      status: item.id,
      source: item.source,
    };
    this.refreshWithFilter();
  }

  initGrid(docType) {
    debugger;
    if (this.activeLicances.branchCodes.length > 0) {
      this.activeFilter = {
        $and: [
          { branchCode: { $in: this.activeBranches } },
          { documentType: docType },
          { status: "standby" },
          { source: "pos" },
          { direction: "outBound" },
          {
            $and: [
              { invoiceDate: { $gte: this.startDate.toISOString() } },
              { invoiceDate: { $lte: this.endDate.toISOString() } },
            ],
          },
        ],
      };
    } else {
      this.activeFilter = {
        $and: [
          { documentType: docType },
          { status: "standby" },
          { source: "pos" },
          { direction: "outBound" },
          {
            $and: [
              { invoiceDate: { $gte: this.startDate.toISOString() } },
              { invoiceDate: { $lte: this.endDate.toISOString() } },
            ],
          },
        ],
      };
    }

    this.activeStat = { docType: docType, status: "standby", source: "pos" };
    this.refreshWithFilter();
  }

  refreshWithFilter() {
    this.loadingVisible = true;
    debugger;
    this.inoviceSrc.load(this.activeFilter).then((o) => {
      console.log("return val", o);
      this.loadingVisible = false;
    });
  }

  // addLog(lType: logType, inv: Invoice, title, message) {
  //   let log: invoiceLog = new invoiceLog();
  //   log.type = lType;
  //   log.title = title;
  //   log.message = message;
  //   log.userId = this.ls.userInfo.id;
  //   if (!inv.logs) {
  //     inv["logs"] = [];
  //   }
  //   inv.logs.push(log);
  //   let invx = {
  //     id: inv.id,
  //     logs: inv.logs,
  //     senderUserId: this.ls.userInfo.id,
  //   };

  //   this.inoviceSrc.upsert(inv);
  // }

  addLog(lType: logType, inv: Invoice, title, message) {
    let log: invoiceLog = new invoiceLog();
    log.type = lType;
    log.title = title;
    log.message = message;
    log.userId = this.ls.userInfo.id;
    log.uuid = inv.uuid;
    if (!inv.logs) {
      inv["logs"] = [];
    }
    inv.logs.push(log);
    this.inoviceSrc.addInvoiceLog(log).subscribe((x: any) => {});
  }

  getCss(item) {
    if (item.count == 0) {
      return "gray-stat";
    } else {
      if (
        this.activeStat.docType == item.docType &&
        this.activeStat.status == item.id &&
        this.activeStat.source == item.source
      ) {
        return item.id + " active-stat";
      } else {
        return item.id;
      }
    }
  }
}
