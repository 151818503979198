import { Component, OnInit, ContentChild, TemplateRef, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { ClBaseComponent } from '../cl-base/cl-base.component';

@Component({
  selector: 'cl-text-area',
  templateUrl: './cl-text-area.component.html',
  styleUrls: ['./cl-text-area.component.scss']
})
export class ClTextAreaComponent extends ClBaseComponent implements OnInit {
 
  @Input() rows = 4
  @Input() cols = 50

  constructor() {
    super()
   }

  ngOnInit() {
  }

  changed(e) {
    this.valueChange.emit(e.target.value)
    this.objChanged.emit(this.changeEvnt)
    this.validate(e.target.value)
  }

}
