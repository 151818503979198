import { Component, OnInit } from '@angular/core';
import { async } from '@angular/core/testing';
import { GlobalsService } from 'src/app/services/globals.service';
import { CentralOrderService } from '../services/central-order.service';

@Component({
  selector: 'app-central-order-main',
  templateUrl: './central-order-main.component.html',
  styleUrls: ['./central-order-main.component.scss']
})
export class CentralOrderMainComponent implements OnInit {
  loadingVisible : boolean = false;
  loadingMessage : string = "Siparişler Yükleniyor."
  constructor(private centralOrderService : CentralOrderService,private glb : GlobalsService) { }

  ngOnInit(): void {  
    this.loadingVisible = true;
    this.getBranchList()
    this.centralOrderService.getOrderStatus()
    this.centralOrderService.loadOrders()
   this.loadingVisible = false;
   this.centralOrderService.selectedOrder = this.centralOrderService.OrderList[0]
  }

  async getBranchList()
  {
   await this.centralOrderService.getBrancList()
  }



  

}
