<div
  [ngClass]="{ 'cloud-form': !invoiceEntry }"
  style="max-width: 900px !important"
>
  <div class="cloud-header">{{ "INVOICE FORM" | translate }}</div>

  <app-cl-select-box
    [bottomLine]="true"
    label="{{ 'Company Name' | translate }}"
    [displayExpr]="'accountName'"
    (selectionChanged)="sel($event)"
    [showRecordStatus]="true"
    [(value)]="invoice.account.accountName"
    [searchExpr]="'accountName'"
    [clStore]="accountStore"
  >
  </app-cl-select-box>

  <!-- <ng-container *ngIf="invoice?.documentType === 'eDispatch'"> -->
    <!-- "eDispatch"-->
    <div class="cloud-sub-group">Gönderi Bilgileri</div>

    <div
      style="
        display: flex;
        flex-direction: column;
        width: 50%;
        font-size: 12px;
        padding: 5px;
      "
    >
      <div>Sevk Tipi</div>
      <dx-radio-group
        [dataSource]="typesOfDispatch"
        displayExpr="title"
        valueExpr="value"
        layout="horizontal"
        [(value)]="invoice.deliveryInfo.isShippingCompany"
      >
      </dx-radio-group>
    </div>

    <ng-container *ngIf="invoice.deliveryInfo.isShippingCompany">
      <div
        style="
          display: flex;
          flex-direction: column;
          width: 50%;
          font-size: 12px;
          padding: 5px;
        "
      >
        <div>Taşıyıcı Firmanın VKN</div>
        <dx-text-box [(value)]="invoice.deliveryInfo.carrierTaxNumber">
        </dx-text-box>
      </div>
    </ng-container>

    <ng-container *ngIf="!invoice.deliveryInfo.isShippingCompany">
      <div style="display: flex; justify-content: space-between">
        <div
          style="
            display: flex;
            flex-direction: column;
            width: 100%;
            font-size: 12px;
            padding: 5px;
          "
        >
          <div>Adı Soyad</div>
          <dx-text-box [(value)]="invoice.deliveryInfo.carrierName">
          </dx-text-box>
        </div>
        <!-- <div
        style="
          display: flex;
          flex-direction: column;
          width: 50%;
          font-size: 12px;
          padding: 5px;
        "
      >
        <div>Soyadı</div>
        <dx-text-box> </dx-text-box>
      </div> -->
      </div>
      <div style="display: flex; justify-content: space-between">
        <div
          style="
            display: flex;
            flex-direction: column;
            width: 50%;
            font-size: 12px;
            padding: 5px;
          "
        >
          <div>Araç Plaka</div>
          <dx-text-box [(value)]="invoice.deliveryInfo.vehiclePlateNo">
          </dx-text-box>
        </div>

        <div
          style="
            display: flex;
            flex-direction: column;
            width: 50%;
            font-size: 12px;
            padding: 5px;
          "
        >
          <div>TCKN</div>
          <dx-text-box [(value)]="invoice.deliveryInfo.carrierTaxNumber">
          </dx-text-box>
        </div>
      </div>

    </ng-container>
  <!-- </ng-container> -->


  <lib-cl-collapsable-title [collapsed]="true" title="Vergi & Adres Bilgileri">
    <div style="display: flex; justify-content: space-between">
      <div
        style="
          display: flex;
          flex-direction: column;
          width: 50%;
          font-size: 12px;
          padding: 5px;
        "
      >
        <div>Vergi No</div>
        <dx-text-box [(value)]="invoice.account.taxNumber"></dx-text-box>
      </div>

      <div
        style="
          display: flex;
          flex-direction: column;
          width: 50%;
          font-size: 12px;
          padding: 5px;
        "
      >
        <div>Vergi Dairesi</div>
        <dx-text-box [(value)]="invoice.account.taxOffice"></dx-text-box>
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          width: 50%;
          font-size: 12px;
          padding: 5px;
        "
      >
        <div>Posta Kodu</div>
        <dx-text-box [(value)]="invoice.account.postalCode"></dx-text-box>
      </div>

      <div
      style="
        display: flex;
        flex-direction: column;
        width: 50%;
        font-size: 12px;
        padding: 5px;
      "
    >
      <div>Harcama Birimi (VKN)</div>
      <dx-text-box [(value)]="invoice.account.buyerTaxNumber"></dx-text-box>
    </div>
    </div>



    <div class="cloud-field">
      <app-cl-select-box
        [required]="true"
        label="İLÇE"
        style="width: 100%"
        [(value)]="invoice.account.town"
        (selectionChanged)="townSelected($event)"
        [ItemTemplate]="pls2"
        searchExpr="name"
        [clStore]="this.townService.clStore"
      >
        <ng-template #pls2 let-data="data">
          <div style="display: flex; justify-content: space-between">
            <div>
              {{ data.name }}
            </div>
            <div style="font-size: 10px; color: gray">
              {{ data.cityName }}
            </div>
          </div>
        </ng-template>
      </app-cl-select-box>

      <app-cl-select-box
        [required]="true"
        label="İL"
        style="width: 100%"
        [(value)]="invoice.account.city"
        [ItemTemplate]="pls"
        searchExpr="name"
        [clStore]="this.cityService.clStore"
      >
        <ng-template #pls let-data="data">
          <div style="display: flex; justify-content: space-between">
            <div>
              {{ data.name }}
            </div>
          </div>
        </ng-template>
      </app-cl-select-box>
    </div>

    <div
      style="
        display: flex;
        flex-direction: column;
        width: 100%;
        font-size: 12px;
        padding: 5px;
      "
    >
      <div>ADRES</div>
      <dx-text-area
        [(value)]="invoice.account.address"
        height="60"
      ></dx-text-area>
    </div>
  </lib-cl-collapsable-title>

  <div class="cloud-sub-group">E-Fatura Bilgileri</div>

  <div style="display: flex; justify-content: space-between">
    <div
      style="
        display: flex;
        flex-direction: column;
        width: 50%;
        font-size: 12px;
        padding: 5px;
      "
    >
      <div>
        {{ "Document Type" | translate }}
      </div>
      <dx-select-box
        [readOnly]="true"
        [(value)]="invoice.documentType"
        [dataSource]="documentTypes"
        valueExpr="id"
        displayExpr="name"
      >
      </dx-select-box>
    </div>

    <div
      style="
        display: flex;
        flex-direction: column;
        width: 50%;
        font-size: 12px;
        padding: 5px;
      "
    >
      <div>
        {{ "Invoice Type" | translate }}
      </div>
      <dx-select-box
        [readOnly]="true"
        [(value)]="invoice.invoiceType"
        [dataSource]="invoiceTypes"
        valueExpr="id"
        displayExpr="name"
      >
      </dx-select-box>
    </div>
  </div>

  <div style="display: flex; justify-content: space-between">
    <div
      style="
        display: flex;
        flex-direction: column;
        width: 50%;
        font-size: 12px;
        padding: 5px;
      "
    >
      <div>
        {{ "Scenario" | translate }}
      </div>
      <dx-select-box
        [(value)]="invoice.typeScenario"
        [dataSource]="scenarios"
        valueExpr="id"       
        displayExpr="name"
      >
      </dx-select-box>
    </div>

    <div
      style="
        display: flex;
        flex-direction: column;
        width: 50%;
        font-size: 12px;
        padding: 5px;
      "
    >
      <div>
        {{ "Labels" | translate }}
      </div>
      <dx-select-box
        [(value)]="invoice.label"
        [dataSource]="labels"
        valueExpr="alias"
        displayExpr="alias"
      >
      </dx-select-box>
    </div>
  </div>

  <div
    style="
      display: flex;
      flex-direction: column;
      width: 100%;
      font-size: 12px;
      padding: 5px;
    "
  >
    <div>Email</div>
    <dx-text-box [(value)]="invoice.account.mail"> </dx-text-box>
  </div>

  <div
    *ngIf="invoice.invoiceType == 'exemptions'"
    style="
      display: flex;
      flex-direction: column;
      width: 100%;
      font-size: 12px;
      padding: 5px;
    "
  >
    <div>İstisna Kodu</div>
    <dx-select-box
      [(value)]="invoice.exemptionReason"
      [searchEnabled]="true"
      [showClearButton]="true"
      [dataSource]="exemptions"
      [displayExpr]="'displayValue'"
      [valueExpr]="'id'"
    >
    </dx-select-box>
  </div>

  <div
    *ngIf="invoice.invoiceType == 'stoppage'"
    style="
      display: flex;
      flex-direction: column;
      width: 100%;
      font-size: 12px;
      padding: 5px;
    "
  >
    <div>Tevkifat Kodu</div>
    <dx-select-box
      [(value)]="invoice.stoppageReason"
      [searchEnabled]="true"
      [showClearButton]="true"
      [dataSource]="stoppages"
      [displayExpr]="'displayValue'"
      [valueExpr]="'id'"
    ></dx-select-box>
  </div>

  <div
    style="
      display: flex;
      flex-direction: column;
      width: 100%;
      font-size: 12px;
      padding: 5px;
    "
  >
    <div>Açıklama</div>
    <dx-text-box [(value)]="invoice.description"> </dx-text-box>
  </div>
  <div
    *ngIf="errors.length !== 0"
    style="width: 100%; display: flex; align-items: flex-start"
  >
    <ul
      style="
        padding: 10px;
        background-color: #7458b6;
        border: 1px solid #532b2b;
        border-radius: 3px;
      "
    >
      <li *ngFor="let item of errors" style="color: white">{{ item }}</li>
    </ul>
  </div>
  <div
    *ngIf="!invoiceEntry"
    style="
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    "
  >
    <button class="cl-cancel-button" (click)="close()">İptal</button>
    <button class="cl-ok-button" (click)="save()">Kaydet</button>
  </div>
  </div>



