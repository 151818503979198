<h2 style="text-align:center;">{{'Central Order' | translate}}</h2>
<div style="display: flex;justify-content: space-between;padding: 25px;width: 94%;">    
    <div style="max-width: 55%;min-width: 55%;margin-right: 10px;" >
     <app-orders></app-orders>
       
    </div>
    <div style="max-width: 45%;min-width: 45%;">
        <app-order-details></app-order-details>
    </div>
</div>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingVisible" [showIndicator]="true"
    [showPane]="true" [message]="loadingMessage" [shading]="true" [closeOnOutsideClick]="false">
  </dx-load-panel>