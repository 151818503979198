<div [ngStyle]="{'width': divWith, 'height': divHeight}" class="cover">
  <div class="cl-image-loader-header">
    <div class="cl-header-text">
     {{'Image Upload' | translate}}
    </div>
    <div class="cl-header-close-icon" (click)="cancelUpload()">
        <i  title="{{'Close' | translate}}" class="fas fa-times-circle cl-close-icon"></i>
    </div>
  </div>
  <div [ngStyle]="{'width': initalWidth, 'height': initalHeight}" class="item-image">
    <img [ngStyle]="{'display': image_ok ? 'block':'none'}" src="{{value}}" #itemImage
      (load)="itemImageOnload($event)" />
  </div>

  <div class="image-error">
    <div>
      {{error_message}}
    </div>

  </div>
  <app-upload #imageUpload (fileInfo)="fileInfo($event)" (base64)="setbase64($event)" [saveFirebase]="false">
  </app-upload>
  <div style="display: flex; justify-content:space-evenly; padding: 5px">
    <div>
        {{'Max File Size (KB)' | translate}}: {{maxSize/1024}}
    </div>
    <div>
        {{'Dimensions' | translate}}: {{initalWidth}} * {{initalHeight}}
    </div>

  </div>
  <div style="display: flex; justify-content:space-evenly; padding: 5px;">
      <button class="cl-ok-button" (click)="clearImage()">{{'Clear Image' | translate}}</button>
    <button class="cl-ok-button" (click)="uploadImage()">{{'Select File' | translate}}</button>
    <button [disabled]="!image_ok" class="cl-ok-button" (click)="closeUpload()">{{'Save' | translate}}</button>
  </div>

</div>