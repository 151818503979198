<div style="overflow:scroll; height:100vh;">
    <div class="section-title">
        Sözleşmeler
    </div>

    <div class="card">
        <div class="card">

            <dx-data-grid id="gridContainer" [dataSource]="agreementService.data" [showBorders]="true" style="margin:auto;" (onInitNewRow)="initRow($event)" (onRowInserted)="insertedRow($event)" (onRowUpdated)="updatedRow($event)" (onRowRemoved)="deleteRow($event)">
                <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
                <dxo-header-filter [visible]="true"></dxo-header-filter>
                <dxo-search-panel [visible]="true" [width]="200" placeholder="Ara..."></dxo-search-panel>
                <dxo-editing mode="form" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
                </dxo-editing>
                <dxi-column dataField="name" [width]="100" caption="{{'Name' | translate}}"></dxi-column>
                <dxi-column dataField="description" [width]="150" caption="{{'Description' | translate}}">
                    <dxo-form-item [colSpan]="2" editorType="dxHtmlEditor" [editorOptions]="{ height: 100 }">
                    </dxo-form-item>
                </dxi-column>
            </dx-data-grid>
        </div>

        <div style="height: 200px;">

        </div>
    </div>