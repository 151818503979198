import { Component, OnInit } from '@angular/core';
import { CloudLoginService } from 'cloud-labs-core';
import { PublicApiService } from 'src/app/services/public-service/public-api.service';
import { ConvertInvoiceAccount, InvoiceService } from '../services/invoice.service';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { PosAccount } from '../models/global-model';
import { GlobalsService } from 'src/app/services/globals.service';

@Component({
  selector: 'app-invoice-convert-accounts',
  templateUrl: './invoice-convert-accounts.component.html',
  styleUrls: ['./invoice-convert-accounts.component.scss']
})
export class InvoiceConvertAccountsComponent implements OnInit {
  accountInvoiceList: ConvertInvoiceAccount[] = []
  loadingMessage: string = "Yükleniyor..."
  loadingVisible: boolean = false;
  accountList: any[] = [];
  dxAccount: DataSource;
  taxNo: string = "";
  constructor(private publicApi: PublicApiService, private ls: CloudLoginService, private invoiceService: InvoiceService, private glb: GlobalsService) {


    this.dxAccount = new DataSource({
      store: new CustomStore({
        key: "taxNo",
        load: (loadOptions) => {
          console.log("taxxNo", loadOptions, this.taxNo);
          // return this.getAccountList(
          //   this.branchCode,
          //   loadOptions.searchValue ?? this.accountCode
          return this.getAccountList(0, loadOptions.searchValue);
          // );
          return []
        },
        byKey: (key) => {
          console.log(key);
          return this.getAccountList(0, key);
        },
      }),
    });
  }

  ngOnInit(): void {
    this.getData()
  }

  getData() {
    this.loadingVisible = true
    this.publicApi.getCollection({
      _db: this.ls.getActiveLicance().licanceId,
      col: 'invoice-convert-accounts',
      _filter: {}
    })
      .toPromise()
      .then(async (o: any) => {
        console.log(o)
        this.loadingVisible = false;
        this.accountInvoiceList = o
      })
  }

   removeQuotesUsingReplace(str: string): string {
    return str.replace(/['"]+/g, '');
}

  getAccountList(branchCode, searchKey = "") {
    if(!searchKey) {
      return
    }
    if (searchKey === "")
    {
      return []
    }

    if (searchKey.length < 10)
    {
      return []
    }
    // _filter: { $or: [{'accountName': {'$regex': searchKey}},{'taxNo': {'$regex': searchKey}}] }
    this.loadingMessage = "Yükleniyor..."
    this.loadingVisible = true
    let filterText  = '/' + searchKey+ '/'
    console.log(this.removeQuotesUsingReplace(filterText))
    return new Promise((resolve, reject) => {
      this.publicApi.getCollection({
        _db: 'helpers',
        col: 'accounts',
        _filter:  {'taxNo': {'$regex': searchKey} }
      }).subscribe(x => {
        this.loadingVisible = false
        this.accountList = x;
   resolve(x);
      })
      // this.invoiceService.getAccountList(searchKey).subscribe((x) => {
      //   this.accountList = x;
      //   resolve(x);
      // });
    });
  }



  onRowUpdated(e) {
    this.publicApi.upsertv2({
      _db: this.ls.getActiveLicance().licanceId,
      col: 'invoice-convert-accounts',
      data: e.data,
    }).then((o) => {
      this.getData()
      this.glb.notice("Kayıt Güncellendi.", "info")
    })
  }

  accountChanged(e) {
    this.taxNo = e.selectedItem?.taxNo;
  }

  add() {
    if (!this.taxNo) {
      this.glb.notice("Lütfen Cari Seçiniz", 'info')
      return;
    }
    let accountInvoice: ConvertInvoiceAccount = new ConvertInvoiceAccount()
    accountInvoice.taxNumber = this.taxNo
    accountInvoice.isActive = true
    this.publicApi.upsertv2({
      _db: this.ls.getActiveLicance().licanceId,
      col: 'invoice-convert-accounts',
      data: accountInvoice,
    }).then((o) => {
       this.getData()
      this.glb.notice("Kayıt Edildi.", "info")
    })
  }


}
