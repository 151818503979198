import { Injectable } from "@angular/core";
import { GenericDataService } from "projects/cloud-components/src/lib/database/generic-data.service";
import { CloudDbService, CloudLoginService } from "cloud-labs-core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { InvoiceSettingsService } from "./invoice-settings.service";
import { from, Observable, of, throwError } from "rxjs";
import {
  groupBy,
  mergeMap,
  toArray,
  distinct,
  zip,
  reduce,
  map,
  scan,
  flatMap,
  retry,
  catchError,
} from "rxjs/operators";
import { group } from "@angular/animations";
import notify from "devextreme/ui/notify";
import { GlobalsService } from "src/app/services/globals.service";
import {
  Branch,
  Inventory,
  MappingItem,
  PosAccount,
  WareHouse,
} from "../models/global-model";

@Injectable({
  providedIn: "root",
})
export class InvoiceService extends GenericDataService {
  activeDispatch: Invoice;

  staticsLoadingStatus: "loading" | "loaded" | "init" | "error" = "init";
  inboundStaticsLoadingStatus: "loading" | "loaded" | "init" | "error" =
    "loaded";

  statuses;
  scenarios;
  invoiceTypes;
  documentTypes;
  erpStatus;
  invoiceRecordType;
  statistics: stat[] = [];

  //!baseUrl = "http://192.168.2.171:4250"
  //baseUrl = "https://sso.api.kerzz.com:4250"

  baseUrl = "https://invoice-service.kerzz.com:4250";
  //baseUrl = "https://192.168.1.87:4250";
  kerzzapiUrl = "https://api.kerzz.com:5000/api/data/action";
  // baseUrl = "https://invoice-service-test.kerzz.com:4250";
  //baseUrl = "https://127.0.0.1:4250"
  previewInvoiceUrl = "/api/invoice/getpdfinvoice";
  //previewInvoiceUrl = "/api/invoice/gethtmlinvoice"
  sendInvoiceUrl = "/api/invoice/senddigitalinvoice";
  sendMailInvoice = "/api/invoice/sendmailinvoice";
  getInvoiceStatıs = "/api/invoice/getinvoicestatus";
  cancelInvoice = "/api/invoice/cancelinvoice";
  ignoreInvoice = "/api/invoice/ignoreinvoice";
  getBackIgnoreInvoice = "/api/invoice/getBackIgnoredInvoice";
  calcStatics = "/api/helpers/calcstatistics";
  getInbound = "/api/invoice/getinboundinvoices";
  dispatchReply = "/api/invoice/senddispatchreply";
  getstatisticswithdate = "/api/helpers/getoutstatisticswithdate";
  saveWithObject = "/api/invoice/saveinvoicewithobject";
  saveInvoiceKerzzApi = "/api/data/action";
  getPosInventoryUnitApi = "/api/pos/getPosInventoryUnits";

  sendErp = "/api/invoice/senderp";

  data: Invoice[] = [];

  collection = "invoices";
  id_key = "TaxNo";

  eInvoicePOS: any[];
  eInvoiceERP: any[];

  eArchicePOS: any[];
  eArchiveERP: any[];

  eFolioPOS: any[];
  eFolioERP: any[];

  eDispatchPOS: any[];
  eDispatchERP: any[];

  branchList: Branch[] = [];

  eDispatchReplyPos: any[];
  eDispatchReplyERP: any[];

  eProducesPOS: any[];
  eProducesERP: any[];

  eInvoiceInbound: any[];

  constructor(
    public cs: CloudDbService,
    private iSet: InvoiceSettingsService,
    private http: HttpClient,
    private ls: CloudLoginService,
    private glb: GlobalsService
  ) {
    super(cs);

    this.statuses = [
      { id: "all", name: "Hepsi", sort: 1 },
      { id: "standby", name: "Yeni", sort: 2 },
      { id: "success", name: "Tamam", sort: 3 },
      { id: "error", name: "Hata", sort: 4 },
      { id: "ignored", name: "Yoksay", sort: 5 },
      { id: "cancelled", name: "İptal", sort: 6 },
    ];

    this.scenarios = [
      { id: "base", name: "Temel" },
      { id: "merchant", name: "Ticari" },
      { id: "export", name: "İhracat" },
      { id: "baseDispatch", name: "Sevk" },
    ];

    this.invoiceTypes = [
      { id: "sale", name: "Satış" },
      { id: "buy", name: "Alış" },
      { id: "return", name: "İade" },
      { id: "stoppage", name: "Tevkifat" },
      { id: "exemptions", name: "İstisna" },
      { id: "export", name: "İthalat" },
      { id: "special", name: "Özel" },
      { id: "ssn", name: "Sgk" },
      { id: "dispatch", name: "İrsaliye" },
    ];

    this.documentTypes = [
      { id: "eInvoice", name: "E-Fatura" },
      { id: "eArchive", name: "E-Arşiv" },
      { id: "eDispatch", name: "E-İrsaliye" },
    ];

    this.invoiceRecordType = [
      { id: "0", name: "Açık" },
      { id: "1", name: "Kapalı" },
    ];

    this.erpStatus = [
      { id: "new", name: "Bekliyor" },
      { id: "success", name: "Tamamlandı" },
      { id: "error", name: "Hatalı" },
    ];

    this.eInvoicePOS = this.getOutBoundStatics("eInvoice", "pos");
    this.eInvoiceERP = this.getOutBoundStatics("eInvoice", "erp");

    this.eArchicePOS = this.getOutBoundStatics("eArchive", "pos");
    this.eArchiveERP = this.getOutBoundStatics("eArchive", "erp");

    this.eDispatchPOS = this.getOutBoundStatics("eDispatch", "pos");
    this.eDispatchERP = this.getOutBoundStatics("eDispatch", "erp");

    this.eDispatchReplyPos = this.getOutBoundStatics("eDispatchReply", "pos");
    this.eDispatchReplyERP = this.getOutBoundStatics("eDispatchReply", "erp");

    this.eProducesPOS = this.getOutBoundStatics("EProducerReceipt", "pos");
    this.eProducesERP = this.getOutBoundStatics("EProducerReceipt", "erp");

    this.eFolioPOS = this.getOutBoundStatics("eFolio", "pos");
    this.eFolioERP = this.getOutBoundStatics("eFolio", "erp");

    this.runCalculateStatics();
  }

  changeDatabase() {
    if ((this.subject.action = "loaded")) {
      this.data = [];
      this.pagedData = [];
      this.runCalculateStatics();
    }
  }

  approveInbond(uuid, reason) {
    let url = `${this.baseUrl}/api/invoice/merchantinvoiceaccept`;
    return this.http.post(url, {
      uuid: uuid,
      licanceId: this.iSet.settings.licanceId,
      description: reason,
    });
  }

  rejectInbound(uuid, reason) {
    let url = `${this.baseUrl}/api/invoice/merchantinvoicereject`;
    return this.http.post(url, {
      uuid: uuid,
      licanceId: this.iSet.settings.licanceId,
      description: reason,
    }).pipe(
      catchError(this.handleError)
    );
  }

  requestPdfInvoices(uuids) {
    let url = `${this.baseUrl}/api/invoice/getpdfinvoices`;

    let pos = {
      uuids: uuids,
      licanceId: this.iSet.settings.licanceId,
      requestFile: false,
    };
    console.log(pos);
    console.log(JSON.stringify(pos));

    return this.http.post(url, pos, {
      headers: new HttpHeaders({ timeout: `${20000}` }),
    });
  }

  requestXmlInvoices(uuid: string) {
    let url = `${this.baseUrl}/api/invoice/getSignedXmlInvoice`;

    let HTTPOptions: Object = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      responseType: "text",
    };

    let params = {
      uuid: uuid,
      licanceId: this.iSet.settings.licanceId,
    };
    console.log(JSON.stringify(params));
    return this.http.post(url, params, HTTPOptions);
  }

  //preview html inovice
  preview(uuid) {
    this.runCalculateStatics();
    return this.http.post(this.baseUrl + this.previewInvoiceUrl, {
      uuid: uuid,
      licanceId: this.iSet.settings.licanceId,
    });
  }

  getPosDispatchs(taxNo: string, recordType?: string) {
    return this.http.post(this.baseUrl + "/api/pos/getPosDispatchs", {
      licanceId: this.iSet.settings.licanceId,
      taxNo: taxNo,
      invoiceRecordType: recordType,
    });
  }

  // getList(): Observable<Sponsor[]> {

  getBranchList(): Observable<Branch[]> {
    debugger
    return this.http.post<Branch[]>(
      this.baseUrl + "/api/pos/getPosBranchList",
      {
        licanceId: this.iSet.settings.licanceId,
      }
    );
  }

  getWarehouseList(branchCode): Observable<WareHouse[]> {
    return this.http.post<WareHouse[]>(
      this.baseUrl + "/api/pos/getPosWarehouseList",
      {
        licanceId: this.iSet.settings.licanceId,
        branchCode: branchCode,
      }
    );
  }

  getAccountList(filter): Observable<PosAccount[]> {
    return this.http.post<PosAccount[]>(
      this.baseUrl + "/api/pos/getPosAccountList",
      {
        licanceId: this.iSet.settings.licanceId,
        filter: filter,
      }
    );
  }

  getInventoryList(branchCode, filter): Observable<Inventory[]> {
    return this.http.post<Inventory[]>(
      this.baseUrl + "/api/pos/getPosInventoryList",
      {
        licanceId: this.iSet.settings.licanceId,
        branchCode: branchCode,
        filter: filter,
      }
    );
  }

  getMappingList(
    branchCode: string,
    itemCode: string,
    itemType: string
  ): Observable<MappingItem[]> {
    return this.http.post<MappingItem[]>(
      this.baseUrl + "/api/mapping/getMappingList",
      {
        licanceId: this.iSet.settings.licanceId,
        branchCode: 0,
        itemCode: itemCode,
        itemType: itemType,
      }
    );
  }

  saveMappingItem(mappingItem: MappingItem): Observable<any> {
    return this.http.post<any>(this.baseUrl + "/api/mapping/saveMapping", {
      licanceId: this.iSet.settings.licanceId,
      item: mappingItem,
    });
  }

  getUnits(itemCode: string) { }
  // this.http.post(url, postData, this.getHeaders()).subscribe(o => {
  //   resolve(o)
  // }, e => {
  //   this.glb.consolelog("error", e)
  //   this.glb.closeLoading()
  //   if (e.status == 0) {
  //     this.glb.closeLoading()
  //     setTimeout(() => {
  //       this.glb.toast("Opss!.", this.transloco.translate("There is a problem, try again after a while"), "middle", "danger")
  //     }, 500);
  //   } else {
  //     this.glb.closeLoading()
  //     setTimeout(() => {
  //       this.glb.toast("Opss!..", e.error.message, "middle", "danger")
  //     }, 500);
  //   }
  //   reject(e.error.message)
  // })
  sendDigitalInvoiceToPos(uuid: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(this.baseUrl + "/api/pos/saveInvoiceToPOS", {
          licanceId: this.iSet.settings.licanceId,
          uuid: uuid,
        })
        .subscribe(
          (o) => {
            console.log(o);
            resolve(o);
          },
          (e) => {
            console.log(e);
            resolve(e);
          }
        );
    });
  }

  convertDispatchToInvoice(
    uuid: string,
    dispatchNumbers: string[],
    branchCode: string,
    recordType: string
  ) {
    return this.http.post(
      this.baseUrl + "/api/pos/convertPosDipatchsToInvoice",
      {
        licanceId: this.iSet.settings.licanceId,
        uuid: uuid,
        branchCode: branchCode,
        userName: this.ls.name,
        invoiceRecordType: recordType,
        dispatchNumbers: dispatchNumbers,
      }
    );
  }

  //send inovice to e-proccess
  send(uuid) {
    this.runCalculateStatics();
    return this.http.post(this.baseUrl + this.sendInvoiceUrl, {
      uuid: uuid,
      licanceId: this.iSet.settings.licanceId,
    });
  }

  //get inovice details
  detail(uuid) {
    this.runCalculateStatics();
    return this.http.post(this.baseUrl + this.getInvoiceStatıs, {
      uuid: uuid,
      licanceId: this.iSet.settings.licanceId,
    });
  }

  //cancel invoice
  cancel(uuid) {
    this.runCalculateStatics();
    return this.http.post(this.baseUrl + this.cancelInvoice, {
      uuid: uuid,
      licanceId: this.iSet.settings.licanceId,
    });
  }

  //ignore invoice
  ignore(uuid) {
    this.runCalculateStatics();
    return this.http.post(this.baseUrl + this.ignoreInvoice, {
      uuid: uuid,
      licanceId: this.iSet.settings.licanceId,
    });
  }

  //cancel ignored status to standby
  ignoreCancel(uuid) {
    this.runCalculateStatics();
    return this.http.post(this.baseUrl + this.ignoreInvoice, {
      uuid: uuid,
      licanceId: this.iSet.settings.licanceId,
    });
  }

  getBackIgnoredInvoice(uuid) {
    this.runCalculateStatics();
    return this.http.post(this.baseUrl + this.getBackIgnoreInvoice, {
      uuid: uuid,
      licanceId: this.iSet.settings.licanceId,
    });
  }

  sendInvoiceToErp(uuid) {
    //this.runCalculateStatics()
    return this.http.post(this.baseUrl + this.sendErp, {
      uuid: uuid,
      licanceId: this.iSet.settings.licanceId,
    });
  }

  getInvoiceErp() {
    return this.http.post(this.baseUrl + "/api/invoice/getinvoiceserp", {
      licanceId: this.iSet.settings.licanceId,
    });
  }

  getInboundInvoices() {
    return this.http.post(this.baseUrl + this.getInbound, {
      licanceId: this.iSet.settings.licanceId,
    });
  }

  sendDispatchReply(uuid) {
    return this.http
      .post(this.baseUrl + this.dispatchReply, {
        licanceId: this.iSet.settings.licanceId,
        uuid: uuid,
      })
      .pipe(retry(3), catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = "Unknown error!";
    if (error.error instanceof ErrorEvent) {
      // Client-side errors

      errorMessage = `Error: ${error.error.message}`;
      this.glb.notice(errorMessage, "error");
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      // this.glb.notice(errorMessage, "error");

    }
    return throwError(errorMessage);
  }

  //send invoice to mail
  mail(uuid, mail) {
    let sendMail = {
      uuid: uuid,
      licanceId: this.iSet.settings.licanceId,
      to: [
        {
          name: mail,
          mail: mail,
        },
      ],
    };
    return this.http.post(this.baseUrl + this.sendMailInvoice, sendMail);
  }

  //send calculate signal for invoiceStatuses
  runCalculateStatics() {
    return true;
    setTimeout(() => {
      this.http
        .post(this.baseUrl + this.calcStatics, {
          licanceId: this.iSet.settings.licanceId,
        })
        .subscribe(
          (o) => {
            this.loadOutBoundStatics();
            this.loadInboundStatics();
          },
          (e) => {
            //this.loadStatics()
            this.staticsLoadingStatus = "error";
          }
        );
    }, 2000);
  }

  //loadstatics from server
  loadInboundStatics() {
    this.staticsLoadingStatus = "loading";
    this.cs.getCollectionToPromise({ col: "statistics-inBound" }).then((o) => {
      let statInBound = o;
      let base = statInBound.find((o) => o.scenario == "base").count;
      let merchant = statInBound.find((o) => o.scenario == "merchant").count;
      this.staticsLoadingStatus = "loaded";
      this.eInvoiceInbound = [
        { id: "base", name: "Temel", count: base },
        { id: "merchant", name: "Ticari", count: merchant },
      ];
    });
  }

  //loadstatics from server
  loadOutBoundStatics() {
    return true;
    this.staticsLoadingStatus = "loading";
    this.cs.getCollectionToPromise({ col: "statistics-outBound" }).then((o) => {
      this.statistics = o;
      this.staticsLoadingStatus = "loaded";

      this.eInvoicePOS = this.getOutBoundStatics("eInvoice", "pos");
      this.eInvoiceERP = this.getOutBoundStatics("eInvoice", "erp");
      this.eArchicePOS = this.getOutBoundStatics("eArchive", "pos");
      this.eArchiveERP = this.getOutBoundStatics("eArchive", "erp");
    });
  }

  //loadstatics from server
  loadOutBoundStaticsWithDate(startDate, endDate,branchCodes): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.baseUrl + this.getstatisticswithdate, {
          licanceId: this.iSet.settings.licanceId,
          startDate: startDate,
          endDate: endDate,
          branchCodes : branchCodes
        })
        .subscribe(
          async (o) => {
            this.statistics = Object.assign(o);
            this.staticsLoadingStatus = "loaded";
            this.eInvoicePOS = await this.getOutBoundStatics("eInvoice", "pos");
            this.eInvoiceERP = await this.getOutBoundStatics("eInvoice", "erp");

            this.eArchicePOS = await this.getOutBoundStatics("eArchive", "pos");
            this.eArchiveERP = await this.getOutBoundStatics("eArchive", "erp");

            this.eProducesERP = await this.getOutBoundStatics("EProducerReceipt", "erp");
            this.eProducesPOS = await this.getOutBoundStatics("EProducerReceipt", "pos");


            this.eDispatchPOS = await this.getOutBoundStatics(
              "eDispatch",
              "pos"
            );
            this.eDispatchERP = await this.getOutBoundStatics(
              "eDispatch",
              "erp"
            );

            this.eFolioPOS = this.getOutBoundStatics("eFolio", "pos");
            this.eFolioERP = this.getOutBoundStatics("eFolio", "erp");

            this.eDispatchReplyPos = this.getOutBoundStatics(
              "eDispatchReply",
              "pos"
            );
            this.eDispatchReplyERP = this.getOutBoundStatics(
              "eDispatchReply",
              "erp"
            );

            resolve("ok");
          },
          (e) => {
            this.staticsLoadingStatus = "error";
            reject("error");
          }
        );
    });
  }

  //get outbound statics for filters
  getOutBoundStatics(docType: typeDocument, source: source) {
    let filtered = this.statistics.filter(
      (stat) => stat.documentType == docType && stat.source == source
    );
    let st: any[] = [];
    filtered.forEach((el) => {
      let stat = {
        docType: docType,
        count: el.count,
        status: this.statuses.find((st) => st.id == el.status).name,
        source: el.source,
        id: el.status,
        sort: this.statuses.find((st) => st.id == el.status).sort,
      };
    });

    let xcs = [...this.statuses];

    xcs.forEach((element) => {
      let count = 0;
      let pp = filtered.find((pl) => pl.status == element.id);
      if (pp) {
        count = pp.count;
      }

      let stat = {
        docType: docType,
        count: count,
        status: element.name,
        source: source,
        id: element.id,
        sort: element.sort,
      };

      st.push(stat);
    });

    st.find((p) => p.id == "all").count = st
      .filter((o) => o.id != "all")
      .map((item) => parseInt(item.count))
      .reduce((sum, current) => sum + current);

    st = st.sort((a) => a.sort);
    return st;
  }

  addInvoiceLog(log: invoiceLog) {
    debugger;
    log.licanceId = this.iSet.settings.licanceId;
    let url = `${this.baseUrl}/api/invoice/addInvoiceLog`;
    return this.http.post(url, {
      uuid: log.uuid,
      licanceId: log.licanceId,
      title: log.title,
      message: log.message,
      userId: log.userId,
    });
  }

  saveInvoice(inv: Invoice) {
    return this.http.post(this.baseUrl + this.saveWithObject, {
      licanceId: this.iSet.settings.licanceId,
      invoice: inv,
    });
  }

  getPosInventoryUnits(itemCode: string) {
    return this.http.post(this.baseUrl + this.getPosInventoryUnitApi, {
      licanceId: this.iSet.settings.licanceId,
      itemCode: itemCode,
    });
  }

  // saveInvoiceWithKerzzApi(inv: Invoice) : Promise<any> {
  //   let filter = { uuid: inv.uuid }
  //   return this.http.post(this.kerzzapiUrl, {
  //     job: "upsert",
  //     database: this.iSet.settings.licanceId,
  //     collection: 'invoices',
  //     filter: filter,
  //     data: inv
  //   }, this.getHeaders()
  //   ).toPromise()
  // }

  getHeaders() {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: this.ls.acessToken,
      }),
    };
    return httpOptions;
  }
}

// CLASSES

export class label {
  documentType = "";
  alias = "";
}

export class Account {
  accountName = "";
  taxNumber = "";
  taxOffice = "";
  mail = "";
  city = "";
  town = "";
  address = "";
  postalCode = "";
  externalCode = "";
  buyerTaxNumber =""
}

export class invoiceRow {
  constructor() {
    this.name = "";
    this.quantity = 0;
  }

  id = "";
  code = "";
  name = "";
  quantity = 0;
  unitPrice = 0;
  taxRate = 0;
  taxTotal = 0;
  total = 0;
  grandTotal = 0;
  discount = 0;
  stoppageAmount = 0;
  cityTaxTotal = 0
  unitName = "AD";
  approvedUnit = "";
  approvedQty = 0; // kabul edilen
  rejectedUnit = "";
  rejectedQty = 0; //kabul edilmeyen
  rejectReason = "";
  missingQty = 0; //eksik gonderlien
  missingUnit = "";
  exceesQty = 0; //fazla gonderilen
  exceesUnit = "";
  externalCode = "";
  warehouseCode = 0;
  isHide = false;
}

export class PayRow {
  payCode: number = 0;
  payName: string = "";
  total: number = 0;
}

export class folio {
  ecrSerial = "";
  ecrZReportNo = "";
  folioNo = "";
  folioRowNo = 0;
  folioNote = "";
}

export class AccountingType {
  id : number = 0
  name : string = ""
}

export class Invoice {
  id = "";
  account: Account;
  branchCode: string = "";
  branchName: string = "";
  discountTotal: number = 0;
  documentType: typeDocument = "eArchive";
  sgkPrim = 0
  accountingType : 0
  borsaTescilBedeli = 0
  stopaj = 0;
  folio: folio;
  grandTotal: number = 0;
  invoiceRows: invoiceRow[];
  invoiceDate: Date; // fat tarih // irs tarih
  invoiceNumber: string = ""; // fatno - isrsno
  payRows: PayRow[];
  status: status = "standby";
  taxTotal: number = 0;
  //
  editUser: string = "";
  invoiceType: typeInvoice = "sale"; //irsaliye type
  typeScenario: typeScenario = "base";
  label: string = "";
  labels: label[] = [];
  description: string = "";
  reference: string = "";
  gibCode: number = 0;
  sendDate: Date;
  userId: string = "";
  uuid: string = "";
  source: source = "pos";
  senderUserId: string = "";
  createDate = "";
  logs: invoiceLog[] = [];

  erp: erpIntegrationStatus = "new";
  autoConvertMsg = ""
  readed = false;
  stoppageAmount: 0;
  stock = false;
  stoppageReason = "";
  stoppageRate = "";
  exemptionReason = "";
  merchantStatus: "accepted" | "rejected" | "";
  merchantDesc = "";
  direction: "inBound" | "outBound" = "inBound";

  deliveryInfo: InvoiceDeliveryInofo = {
    carrierName: "",
    carrierTaxNumber: "",
    isShippingCompany: true,
    vehiclePlateNo: "",
  };
  printed = false;

  constructor() {
    this.account = new Account();
    this.folio = new folio();
    this.invoiceRows = [] = [];
  }
}
export class InvoiceDeliveryInofo {
  isShippingCompany?: boolean = true;
  carrierName?: string = "";
  carrierTaxNumber?: string = "";
  vehiclePlateNo?: string = "";
}

export class invoiceLog {
  type: logType = "log";
  time = new Date();
  title = "";
  message = "";
  userId: string;
  licanceId: string;
  uuid: string;
}

export class ConvertInvoiceAccount{
  taxNumber : string;
  isActive : boolean;
}

export class InvoiceAccountType {
taxNumber : string
name : string
isActive: boolean
type : InvoiceAccountingTypes
}

export enum InvoiceAccountingTypes {
  inventory = 0,
  expense = 1
}

export class invoiceDetail {
  Code = 0;
  CreateDate = "";
  Detail = "";
  DigestValue = "";
  Direction = "";
  DocumentId = "";
  DocumentType = "";
  FinalState = false;
  InvoiceEnvelopeGUID = "";
  InvoiceResponseExpired = "";
  InvoiceState = "";
  ReceiverAlias = "";
  ReceiverName = "";
  ReceiverTaxNumber = "";
  SenderAlias = "";
  SenderName = "";
  SenderTaxNumber = "";
  SubDocumentType = "";
}

export class statics_statuses {
  standby: 0;
  succcess: 0;
  error: 0;
  ignored: 0;
  cancelled: 0;
}

export class stat {
  count = 0;
  documentType: typeDocument;
  status: status;
  total: 0;
  source: source;
}

export type typeInvoice =
  | "sale"
  | "return"
  | "stoppage"
  | "exemptions"
  | "export"
  | "special"
  | "ssn"
  | "dispatch";
export type typeDocument =
  | "eInvoice"
  | "eArchive"
  | "invoice"
  | "dispatch"
  | "eDispatch"
  | "eDispatchReply"
  | "eFolio"
  | "EProducerReceipt";
export type typeScenario = "base" | "merchant" | "export" | "baseDispatch";
export type status = "standby" | "success" | "error" | "ignored" | "cancelled";
export type source = "pos" | "erp" | "back-office";
export type logType =
  | "log"
  | "cancel"
  | "ignore"
  | "erp"
  | "approve"
  | "reject";
export type erpIntegrationStatus = "new" | "success" | "error";
