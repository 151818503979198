<div class="parent">
    <div class="cover">
        <div style="text-align:center;">
            <dx-number-box class="number-box" [(value)]="this.ns.value" [showSpinButtons]="true"></dx-number-box>
        </div>
        <div class="row">
            <div (click)="clicked('7')" class="number-item">7</div>
            <div (click)="clicked('8')" class="number-item">8</div>
            <div (click)="clicked('9')" class="number-item">9</div>
        </div>
        <div class="row">
            <div (click)="clicked('4')" class="number-item">4</div>
            <div (click)="clicked('5')" class="number-item">5</div>
            <div (click)="clicked('6')" class="number-item">6</div>
        </div>
        <div class="row">
            <div (click)="clicked('1')" class="number-item">1</div>
            <div (click)="clicked('2')" class="number-item">2</div>
            <div (click)="clicked('3')" class="number-item">3</div>
        </div>
        <div class="row">
            <div (click)="clicked('0')" class="number-item">0</div>
            <div (click)="clicked('.')" class="number-item">.</div>
            <div (click)="del('0')" class="number-item"> {{'Del' | translate}}</div>
        </div>
        <div class="row">
            <div (click)="clicked('OK')" class="number-item-text"> {{'Ok' | translate}}</div>
            <div *ngIf="saveEnabled" (click)="clicked('SAVE')" class="number-item-text"> {{'Save & Close' | translate}}</div>
        </div>
        <!-- <div class="row">
      <div (click)="del('0')" class="number-item-del">Sil</div>
      <div (click)="clicked('.')" class="number-item"></div>
    </div> -->
    </div>
</div>