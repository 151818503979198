<div class="cl-line"></div>
<div class="cl-label" *ngIf="label">
    {{label}} <span *ngIf="required">*</span>
</div>
<div class="cl-text-info-cover">
  <span class="cl-sub-header" *ngIf="ilabel != ''">
    {{ilabel}} <span *ngIf="required">*</span>
  </span>
  <div [ngClass]="{'cl-error': !isValid, 'cl-text-bottom-line': bottomLine}" style="width: 100%; display: flex; align-items: center;">
    <input (blur)="blur($event)" class="cl-input" type="text" [(value)]="value" #clSelectBoxInput />
    <img *ngIf="loading" style="height: 12px; width: 12px;" class="loading-image" src="/assets/spinner.gif" />
    <i *ngIf="new == 'new' && showRecordStatus" title="{{'New Record' | translate}}" class="far fa-star cl-close-icon"></i>
    <i *ngIf="new == 'old' && showRecordStatus" title="{{'Old Record' | translate}}" class="far fa-save cl-close-icon"></i>
    <i *ngIf="!loading && showClearButton" style="font-size:16px;" class="fas fa-times-circle cl-close-icon"  (click)="clear()"></i>
  </div>
</div>


<div class="search-list" *ngIf="list.length > 0 ">
  <div *ngFor="let item of list" class="search-item" (click)="select(item)">
    <div id="tax-{{item.taxNo}}" style="width:100%;" class="item-name" [ngClass]="{'selected': item.selected}"
      title="{{item[displayExpr]}}">
      <ng-container *ngTemplateOutlet="ItemTemplate ? ItemTemplate : def; context: {data: item}">
      </ng-container>
      <ng-template #def>
          <span [innerHTML]="higlight(item[displayExpr])"></span>
      </ng-template>
    </div>
  </div>
</div>