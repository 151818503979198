<div [ngClass]="{ 'cloud-form': !invoiceEntry }" style="max-width: 900px !important">
    <div class="cloud-header">CARİ : {{this.invoice.account.accountName}}</div>
    <div class="cloud-header">FATURA NO : {{this.invoice.invoiceNumber}}</div>
    <!-- <div class="cloud-header">FATURA TARIHI : {{this.invoice.invoiceDate}}</div> -->
    <div style="display: flex; justify-content: space-between; width: 100%;margin-bottom: 20px;">
        <div>Kayıt Tipi</div>
        <dx-select-box [(value)]="this.recordType" (onSelectionChanged)="recordTypeChanged($event)" [dataSource]="this.invoiceService.invoiceRecordType" valueExpr="id"
            displayExpr="name">
        </dx-select-box>    
        <div>Şube</div>
        <dx-select-box  item="item"   [(value)]="this.branchCode" searchEnabled="true" searchMode="contains"  [searchExpr]="['branchCode','branchName']" [dataSource]="this.invoiceService.branchList" valueExpr="branchCode"
            displayExpr="branchName">
            <div *dxTemplate="let i of 'item'">  
                <span>{{i.branchCode}} - {{i.branchName}}</span>  
              </div>  
        </dx-select-box>  
        <dx-button icon="refresh" (onClick)="refreshDataGrid($event)">
            Yenile
        </dx-button>  
    </div>


    <div style="display: flex; width: 100%">
        <dx-data-grid #grid style="    font-size: 12px;
    margin-left: auto;
    margin-bottom: 30px;
    margin-right: auto;
    height: calc(100vh - 360px);
    background-color: #2a2a2a;
    border-radius: 10px;" id="gridContainer" [dataSource]="this.dispatchList" [columnWidth]="100"
            [hoverStateEnabled]="true" [columnHidingEnabled]="false" [(selectedRowKeys)]="selectedRows"
            [allowColumnResizing]="true" columnResizingMode="widget"
            (onSelectionChanged)="selectionChangedHandler($event)" keyExpr="dispatchNumber"
            [allowColumnReordering]="true" [showBorders]="true">
            <dxo-selection mode="multiple"></dxo-selection>
            <dxo-filter-panel [visible]="true"></dxo-filter-panel>
            <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 500, 1000]" [showInfo]="true"></dxo-pager>
            <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
            <dxo-search-panel [visible]="true" [width]="240" placeholder="Ara..."></dxo-search-panel>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxi-column dataField="dispatchDate" caption="{{ 'Date' | translate }}" alignment="right"
                dataType="datetime" format="dd/MM/yyyy" dataType="date" [width]="80"></dxi-column>
            <dxi-column dataField="branchCode" caption="Şube Kodu" [width]="40"></dxi-column>
            <dxi-column dataField="branchName" caption="Şube" [width]="240"></dxi-column>
            <dxi-column dataField="dispatchNumber" caption="Fatura No" [width]="240"></dxi-column>
            <dxi-column dataField="grandTotal" caption="{{ 'Tutar' | translate }}" [width]="80">
                <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
            </dxi-column>
         
        </dx-data-grid>
    
    </div>

    <div style="display: flex; justify-content: space-between; width: 100%;margin-bottom: 20px;">
        <div>Fatura Toplamı :  {{this.invoice.grandTotal| kerzzCurrency}}</div>
        <div>Seçilen Fatura Toplamı : {{this.selectedTotal | kerzzCurrency}} </div>
    </div>

    <div style="display: flex; justify-content: space-between; width: 100%;margin-bottom: 20px;">
        <button class="cl-cancel-button" (click)="close()">İptal</button>
    <button class="cl-ok-button" (click)="convertToInvoice()">Fatura Dönüştür</button>
    </div>

</div>



<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingVisible" [showIndicator]="true"
    [showPane]="true" [message]="loadingMessage" [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>