import { Component, OnInit } from '@angular/core';
import { InvoiceSettingsService, invoiceBranch, InvoiceSerial } from '../services/invoice-settings.service';
import { ClModalDialogService } from 'projects/cloud-components/src/lib/cl-modal-dialog/cl-modal-dialog.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { IdGeneratorService } from 'cloud-labs-core';
import { PublicApiService } from 'src/app/services/public-service/public-api.service';
import { mongo_upsert_params } from 'src/app/services/public-service/dto';

@Component({
  selector: 'app-invoice-settings',
  templateUrl: './invoice-settings.component.html',
  styleUrls: ['./invoice-settings.component.scss']
})
export class InvoiceSettingsComponent implements OnInit {
  newSerials: InvoiceSerial[] = []
  serialTypes = [{ id: "eArchive", name: "E-Arşiv" }, { id: "eInvoice", name: "E-Fatura" }, { id: "eFolio", name: "E-Adisyon" }, { id: "eDispatch", name: "E-İrsaliye" }, { id: "eDispatchReply", name: "Yanıt İrsaliye" }, { id: 'EProducerReceipt', name: 'E-Müstahsil' }]
  sourceList = [{ id: "pos", name: 'pos' }, { id: "erp", name: 'erp' }]
  wsProviders = [{ id: "ibm", name: "IBM" }, { id: "uyumsoft", name: "UyumSoft" }]
  erpApps = [{ id: 'kerzz', name: 'Kerzz' }, { id: "netsis", name: 'Netsis' }, { id: 'logo', name: 'Logo' }]
  allowEditing = true;
  allowSerialNoEditing = true;
  pattern = '[a-z]'
  constructor(
    private glb: GlobalsService,
    public invSetSrvc: InvoiceSettingsService, private dialog: ClModalDialogService, private ig: IdGeneratorService, private publicService: PublicApiService) {

  }

  ngOnInit() {
    this.invSetSrvc.load()
  }

  ngAfterViewInit() {
    this.invSetSrvc.superLoad()
  }

  async save() {

    this.publicService.upsert({
      _db: this.invSetSrvc.settings.licanceId,
      col: "invoice-settings",
      data: this.invSetSrvc.settings,
    }).then((o) => {
      this.invSetSrvc.load()
      this.glb.notice("Kayıt Edildi.", "info")
    })

  }


  cancel() {
    this.invSetSrvc.load()
    this.invSetSrvc.superLoad()
    this.glb.notice("İptal Edildi.", "info")
  }


  addNewSerial(e) {
    this.newSerials = []
    console.log('addnewSerial', e.data)
    let serial: InvoiceSerial = e.data
    serial.lastUse.setDate(-15)
    console.log(serial)
    this.publicService.upsert({
      _db: this.invSetSrvc.settings.licanceId,
      col: "invoice-serials",
      data: serial,
    }).then((o) => {
      console.log(o)
      this.glb.notice("Fatura Serisi Kaydedildi.", "success");
    })



    // this.newSerials.push(serial)
  }

  updateSerial(e) {
    console.log("edit-serial", e.data)
    let serial: InvoiceSerial = e.data
    this.publicService.upsert({
      _db: this.invSetSrvc.settings.licanceId,
      col: "invoice-serials",
      data: serial,
    }).then((o) => {
      console.log(o)
      this.glb.notice("Fatura Serisi Güncellendi.", "success");
    })
  }
  editintSerial(e) {
    console.log(e)
    this.allowSerialNoEditing = false;
    if (!e.data.orjSerial) {
      this.allowEditing = true
    }
    else {
      this.allowEditing = false
    }
  }


  onRowUpdating(e) {
    if (e.dataField == 'orjSerial' || e.dataField == 'serial') {
      console.log(e)
    }
  }

  onEditorPreparing(e) {
    
  }

  onSetValue(e)
  {
    console.log(e)
  }

  async deleteSerial(e) {
    console.log("delete-serial", e.data)
    await this.publicService.delete({
      _db: this.invSetSrvc.settings.licanceId,
      col: "invoice-serials",
      filter: { id: e.data.id }
    }).then((o) => {
      this.glb.notice("Seri Silme İşlemi Başarılı.", "success")
    })
  }

  initRow(e) {
    this.allowSerialNoEditing = true;
    this.allowEditing = true
    e.data = new InvoiceSerial()
    e.data.id = this.ig.generate()
  }


  addBranch() {
    let o = new invoiceBranch()
    this.invSetSrvc.settings.branches.push(o)
  }

  removeBranch(i) {

    this.dialog.title = "Şubeyi Sil"
    this.dialog.message = "Bu Şube Kaydını Silmek İstiyor musunuz?"
    this.dialog.open()
    this.dialog.stat().then(o => {
      if (o) {
        this.invSetSrvc.settings.branches.splice(i, 1)
        this.glb.notice("Şube silindi, kayıt etmeyi unutmayın..", "info")
      }
    })

  }



  addSerial() {
    let o = new InvoiceSerial()
    this.invSetSrvc.serials.push(o)
  }

  // removeSerial(i) {
  //   this.dialog.title = "Seriyi Sil"
  //   this.dialog.message = "Bu Seriyi Silmek İstiyor musunuz?"
  //   this.dialog.open()
  //   this.dialog.stat().then(o => {
  //     if (o) {
  //       this.invSetSrvc.serials.splice(i, 1)
  //       this.glb.notice("Seri silindi, kayıt etmeyi unutmayın..", "info")
  //     }
  //   })

  // }

}
