<div class="content" id="menucover">


    <!-- LOADING TEMPLATES -->
    <ng-template #loading>
        <span class="template">
      <img src="/assets/myicons/103.gif" />
    </span>
    </ng-template>
    <ng-template class="template" #noSubGroup>
        <span class="template"></span>
    </ng-template>
    <ng-template class="template" #noProduct>
        <span class="template">{{'No Product' | translate}}</span>
    </ng-template>


    <!-- TABLE -->
    <div *ngIf="activeOperation == 'table'" class="table-cover" [ngClass]="{'animated fadeOut':activeOperation != 'table', 'animated fadeIn':activeOperation == 'table'}">
        <app-table (tableSelected)="tableSelected($event)" [drag]="false"></app-table>
    </div>


    <!-- MENU -->
    <div #container *ngIf="activeOperation == 'menu'" class="menu-cover" [ngClass]="{'animated fadeOut':activeOperation != 'menu', 'animated fadeIn':activeOperation == 'menu'}">

        <div style="display: flex; flex-direction: row; width: 100%; line-height: 50px;align-items: center;">

            <!-- BACK BUTTON GROUP SELECTOR -->
            <div style="display: flex; flex-direction: row; width: 100%;" *ngFor="let item of activeGroup; let i = index" [ngClass]="{'animated flipInY':activeGroup.length > 0}">
                <div (click)="groupSelectorClick(item)" class="group-selector">
                    <span *ngIf="i == 0"><i class="material-icons">subdirectory_arrow_left</i> {{'Main Group' | translate}}</span>
                    <span *ngIf="i > 0"><i class="material-icons">subdirectory_arrow_left</i>{{activeGroup[i-1].group}}</span>
                </div>
                <div style="line-height: 40px;
                font-family: var(--folio-font);
                font-size: 14px;">
                    {{item.group}}
                </div>
            </div>

            <div *ngIf="selectMustGroup == undefined" [ngClass]="{'animated zoomInUp':selectMustGroup == undefined}">
                <dx-text-box #searchBox stylingMode="filled" style="width:100%" valueChangeEvent="keyup" (onValueChanged)="search(searchBox.value)" placeholder=" {{'Type for search' | translate}}" [showClearButton]="true">
                </dx-text-box>
            </div>

        </div>


        <!-- IF SEARCH ACTIVE -->
        <div *ngIf="searchProducts && selectMustGroup == undefined" [ngClass]="{'animated zoomInUp':selectMustGroup == undefined}">
            <div class="product" *ngIf="searchProducts.length; else noProduct">
                <!-- SEARCH ITEMS -->
                <div *ngFor="let productItem of searchProducts">
                    <div class="product-item search-item" hammertime (tap)="productClick(productItem)">
                        <img class="product-image" src="{{ productItem.image || 'https://dummyimage.com/150x112.5/665f66/fcfcff.png' }}" />
                        <div class="product-name">
                            {{productItem.name}}
                        </div>
                        <div class="product-price">
                            {{productItem.price | kerzzCurrency}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- IF SEARCH NOT ACTIVE -->
        <div *ngIf="!searchProducts && productActive && selectMustGroup == undefined">

            <!-- product groups  -->
            <div class="grp" *ngIf="!itemActive && productGroups  as pGroups; " [ngClass]="{'animated fadeIn':selectMustGroup == undefined}">
                <div *ngFor="let item of pGroups">
                    <div #grpitem class="grp-item badge1" [attr.data-badge]="ps.getProductCountOnGroup(item.id)" (click)="groupClick(item)">
                        <img class="product-image" src="{{ item.image || 'https://dummyimage.com/150x112.5/665f66/fcfcff.png' }}" />
                        <div class="group-name">
                            {{item.name}}
                        </div>
                    </div>
                </div>
            </div>

            <!-- SUB GROUPS -->
            <div *ngIf="activeGroup.length != 0">
                <div *ngFor="let item of levelGroups">
                    <div class="grp" *ngIf="item.id - activeGroup[activeGroup.length-1].id == 1 && item.groups.length > 0" [ngClass]="{'animated fadeIn':selectMustGroup == undefined}">
                        <div *ngFor="let subGroupItem of item.groups">
                            <div class="grp-item badge1" [attr.data-badge]="ps.getProductCountOnGroup(subGroupItem.id)" (click)="subGroupClick(subGroupItem)">
                                <img class="product-image" src="{{ subGroupItem.image || 'https://dummyimage.com/150x112.5/665f66/fcfcff.png' }}" />
                                <div class="group-name">
                                    {{subGroupItem.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- END ->  IF SEARCH NOT ACTIVE -->

        <!-- product list  -->
        <div *ngIf="productActive && selectMustGroup == undefined">
            <div *ngIf="products  as prdct; else loading">
                <div class="product" *ngIf="prdct.length">
                    <div *ngFor="let productItem of prdct" [ngClass]="{'animated flipInY':selectMustGroup == undefined}" [ngClass]="{'animated flipOutY':selectMustGroup != undefined}">
                        <div class="product-item" (click)="productClick(productItem)">
                            <img class="product-image" src="{{ productItem.image || 'https://dummyimage.com/150x112.5/665f66/fcfcff.png' }}" />
                            <div class="product-name">
                                {{productItem.name}}
                            </div>
                            <div class="product-price">
                                {{productItem.price | kerzzCurrency}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- product list  -->

        <!-- MODIFIERS -->
        <div *ngIf="selectMustGroup != undefined" style="width:100%">
            <app-select-must-group [parentID]="parentID" (selected)=modifiersFinished($event) (cancel)="modifiersCancelled()" [group]="selectMustGroup" deep="1" [changeModifiers]="editModifiers"></app-select-must-group>
        </div>
    </div>

    <!-- FOLIO -->
    <div *ngIf="activeOperation == 'menu'" id="appfolio-div" [ngClass]="{'animated slideOutRight':activeOperation != 'menu', 'animated slideInRight':activeOperation == 'menu'}">
        <app-folio #appfolio (showModifiers)="showModifiers($event)" (changeModifiers)="changeModifiers($event)" (changeSubModifiers)="changeSubModifiers($event)"></app-folio>
    </div>


    <!-- PAYMENT -->
    <div style="width: 100%;" *ngIf="activeOperation == 'payment'" [ngClass]="{'animated fadeOut':activeOperation != 'payment', 'animated fadeIn':activeOperation == 'payment'}">
        <app-payment></app-payment>
    </div>

    <dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#menucover' }" [(visible)]="loadingVisible" [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false">
    </dx-load-panel>


    <!--     <div class="footer" *ngIf="Property">

        <div *ngIf="activeOperation == 'table'" class="footer-menu-section" [@slideInOutFooter]>
            <app-footer-button icon="/assets/myicons/news.png" text="Masaya Servis" [disabled]="!this.fls.footerButtonsEnabled "></app-footer-button>
            <app-footer-button icon="/assets/myicons/groups.png" text="Paket Servis" [disabled]="!this.fls.footerButtonsEnabled "></app-footer-button>
            <app-footer-button icon="/assets/myicons/sales.png" text="Self Servis" [disabled]="!this.fls.footerButtonsEnabled "></app-footer-button>
        </div>

        <div *ngIf="activeOperation == 'menu'" class="footer-menu-section" [@slideInOutFooter]>
            <app-footer-button icon="/assets/myicons/check.png" text="Kaydet" [disabled]=" !this.fls.footerButtonsEnabled " (clicked)="fls.doneFolio(); activeOperation = 'table'"></app-footer-button>
            <app-footer-button icon="/assets/myicons/news.png" text="İptal" [disabled]=" !this.fls.footerButtonsEnabled " (clicked)="fls.cancelFolio(); activeOperation = 'table'"></app-footer-button>
            <app-footer-button icon="/assets/myicons/sales.png" text="Adisyonu Kapat" [disabled]=" !this.fls.footerButtonsEnabled " (clicked)="fls.closeFolio(); activeOperation = 'table'"></app-footer-button>
        </div>

    </div> -->

</div>