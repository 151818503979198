<!-- FOLIO -->
<div class="payment-cover">

    <app-button-menu class="payment-menu" type="row" (clicked)="payment_menu_click($event)" [menu]="paymentMenu"></app-button-menu>
    <div id="appfolio-div" [ngClass]="{'animated slideOutRight':activeOperation != 'menu', 'animated slideInRight':activeOperation == 'menu'}">
        <app-folio #appfolio [selectionMode]="'multi'" [mode]="'payment'"></app-folio>
    </div>
</div>


<dx-popover position="center" [width]="280"  [(visible)]="numPadVisible" style="border-radius: 10px;">
    <app-numeric-buttons [saveEnabled]="true" id="numPad-div" (valueChanged)="makePayment($event); numPadVisible = false" (save)="makePaymentAndClose($event); numPadVisible = false" class="numericBox"></app-numeric-buttons>
</dx-popover>


<dx-toast [(visible)]="toastVisible" type="error" message="Connection problem">
    <dxo-position my="left" at="left" of="#appfolio">
    </dxo-position>
</dx-toast>