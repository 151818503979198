import { Component, OnInit, ContentChild, TemplateRef, Input, Output, ViewChildren, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { isNullOrUndefined, isNumber } from 'util';
import { Observable, Subject, fromEvent } from 'rxjs';
import { filter, map, switchMap, mergeMap, merge, debounceTime, distinctUntilKeyChanged, distinctUntilChanged } from 'rxjs/operators';
import { ClBaseComponent, changeEvent, characterCase } from '../cl-base/cl-base.component';
import { CurrencyPipe } from '@angular/common';
import { MycurrencyPipe } from '../pipe/mycurrency.pipe';

@Component({
  selector: 'app-cl-text-box',
  templateUrl: './cl-text-box.component.html',
  styleUrls: ['./cl-text-box.component.scss']
})
export class ClTextBoxComponent extends ClBaseComponent implements OnInit, OnChanges {

  @Input() pointer: string
  @Input() type: type = "text"
  @Input() disabled = false


  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {

    if (changes.value.isFirstChange) {
      this.oldValue = changes.value.currentValue

    } else {
      this.modelChanged.next(changes.value.currentValue)
    }

    let modelVal = { previous: changes.value.previousValue, current: changes.value.currentValue }
    this.modelChanged.next(changes.value.currentValue)
    if (!this._focus && this.type == "number") {
      this.value = this.mcp.transform(this.value, 2)
    }
  }

  constructor(private mcp: MycurrencyPipe) {
    super()
  }

  //?model change for loading animation and info box
  modelChanged: Subject<any> = new Subject<any>();

  @ViewChild('clinput') clInput: ElementRef;

  //?input type, types defined end of file


  ngOnInit() {
    this.subscribeModelChange()
  }

  //?value changed event, fire every keyups
  changed(e) {
    let val = e.target.value
    // if (this.type == "number") {
    //   val = parseFloat(val)
    // } 

    // console.log("nan",isNaN(val))

    // if (isNaN(val)) return

    //?validate value
    this.validate(val)
    //?fire value change event
    this.valueChange.emit(val)
    //?next model change, prepare to fire
  }

  subscribeModelChange() {
    this.modelChanged.pipe(
      debounceTime(700),
    ).subscribe(val => {
      this.changeEvnt.value = val
      this.changeEvnt.valid = this.isValid
      this.objChanged.emit(this.changeEvnt)
    })
  }


  validate(val): boolean {
    if (this.type == "email") {
      this.validateEmail(val)
    }

    if (this.type == "number") {
      //this.validateEmail(val)
    }

    this.isValid =  this.required ? !isNullOrUndefined(val) && (val !== '') : true
    return this.isValid
  }

  validateEmail(val): boolean {
    if (val == "") {
      this.isValid = true
      return true
    }
    let reg = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)i/
    this.isValid = RegExp(reg).test(val)
    return this.isValid
  }

  validateDate(val): boolean {
    return true
  }

  validateNumber(val): boolean {
    return true
  }

  getPattern() {
    if (this.type == "number") {
      return "[0-9]"
    }
  }

  /*  oVal = 0
   blur(e) {
     console.log(e)
     if (this.value == "") return
     if (this.value != 0) {
       this.oVal = this.value
       this.value = this.cp.transform(this.oVal)
     }
     
   }
 
   focus(e) {
     this.value = this.oVal
   } */

}

export type type = 'text' | 'email' | 'number'


