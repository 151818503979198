import { CloudDbService } from 'cloud-labs-core';
import { GenericDataService } from '../../../../projects/cloud-components/src/lib/database/generic-data.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CouponService extends GenericDataService {

  data: coupon[] = []
  collection = "loyalty-coupons"
  constructor(public cs: CloudDbService) {
    super(cs)

  }

  pretty_load(): Promise<any> {

    let activeFilter = {
      $and: [
        { type: { $ne: 'auto-coupon' } },
        { type: { $ne: 'scratch' } }
      ]
    }

    return this.load(activeFilter)
  }


}


export class coupon {
  id: string = ""
  name: string = ""
  type: couponType = "coupon"
  trigger: triggers = "none"
  creation: Date
  validThru: Date
  qrCode: string = ""
  segments: [] = []
  branches: [] = []
  users: [] = []
  image: string = ""
  program: program = "free-product" // basket discount value // basket discount percent // free product // product discount value // product discount percent
  products: [] = []
  status: number = 0
  maxUsage: number = 0
  basketDiscountPercent: number = 0
  basketDiscountAmount: number = 0
  description: string = ""
}

export class selectBox {
  id: string
  name: string
}

type program =
  'free-product' | 'discount-basket'
type couponType =
  'trigger' | 'template' | 'coupon'
type triggers =
  'none' | 'new-membership' | 'complate-profile' | 'offer-friend'

