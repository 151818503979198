<dx-data-grid #grid style="    font-size: 12px;
margin-left: auto;
margin-bottom: 30px;
width: 100%;
margin-right: auto;
min-height: calc(100vh - 360px);
background-color: #2a2a2a;
border-radius: 10px;" id="gridContainer" [columnWidth]="100" [dataSource]="this.centralOrderService.selectedOrder.OrderRows"
        [hoverStateEnabled]="true" [columnHidingEnabled]="false" 
        [allowColumnResizing]="true" columnResizingMode="widget"
        keyExpr="RowNumber"
        [allowColumnReordering]="true" [showBorders]="true">
        <dxo-selection mode="multiple"></dxo-selection>
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 500, 1000]" [showInfo]="true"></dxo-pager>
        <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
        <dxo-search-panel [visible]="true" [width]="240" placeholder="Ara..."></dxo-search-panel>
        <dxo-header-filter [visible]="true"></dxo-header-filter>       
        <dxi-column dataField="StockCode" caption="Stok Kodu" [width]="90"></dxi-column>
        <dxi-column dataField="StockName" caption="Stok Adı" [width]="150"></dxi-column>
        <dxi-column dataField="Quantity" caption="Miktarı" dataType = "number" [width]="80"></dxi-column>
        <dxi-column dataField="UnitName" caption="Birim" [width]="150"></dxi-column>
        <dxi-column dataField="UnitPrice" caption="Birim Fiyatı" [width]="80">
            <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
        </dxi-column>
        <dxi-column dataField="VatRate" caption="KDV O.%" [width]="100"></dxi-column>
        <dxi-column dataField="VatTotal" caption="KDV Tutarı" [width]="80">
            <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
        </dxi-column>
        <dxi-column dataField="Total" caption="Tutar" [width]="80">
            <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
        </dxi-column>      
        <dxi-column dataField="SupplierAccountCode" caption="Tedarikçi" [width]="150"></dxi-column>
        <dxi-column dataField="Note" caption="Sipariş Notu" [width]="150"></dxi-column>       
    </dx-data-grid>