<div style="overflow:scroll; height:100vh;">
    <div class="section-title" style="width: 95% !important">
        {{'Product list' | translate}}
    </div>



    <div style="display: flex; width: 95%; margin: auto">



        <dx-button [width]="50" icon="refresh" [disabled]="false" (click)="this.ps.refresh()">
        </dx-button>

        <dx-data-grid width="95%" style="font-size: 12px;
      margin-left: auto;
      margin-right: auto;
      height: calc(100vh - 130px);
      padding: 20px;
      width: 95%;
      background-color: #2a2a2a;
      border-radius: 10px;" #grid id="gridContainer" [dataSource]="ps.data" [columnHidingEnabled]="false"
            [allowColumnResizing]="true" columnResizingMode="widget" [hoverStateEnabled]="true" keyExpr="id"
            (onRowInserting)="initRow($event)" (onEditorPreparing)="editorPreparing($event)"
            (onRowRemoving)="deleteRow($event)" [allowColumnReordering]="true" (onRowUpdated)="updatedRow($event)"
            (onRowInserted)="insertedRow($event)" [showBorders]="true">

            <dxo-editing mode="popup" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
            </dxo-editing>
            <dxo-group-panel [visible]="true"></dxo-group-panel>
            <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>
            <dxo-export [enabled]="true" fileName="products" [allowExportSelectedData]="true"></dxo-export>
            <dxo-selection mode="multiple"></dxo-selection>
            <dxo-scrolling columnRenderingMode="virtual" mode="virtual"></dxo-scrolling>
            <dxo-filter-panel [visible]="true"></dxo-filter-panel>
            <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
            <dxo-search-panel [visible]="true" [width]="240" placeholder="Ara..."></dxo-search-panel>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
            <dxo-paging [pageSize]="15"></dxo-paging>




            <dxi-column dataField="name" caption="{{'Name' | translate}}" [width]="205">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>

            <dxi-column dataField="description" caption="{{'Description' | translate}}" [width]="205">
            </dxi-column>


            <dxi-column dataField="group" caption="{{'Group' | translate}}" [width]="150">
                <dxo-lookup [dataSource]="this.pg.data" displayExpr="name" valueExpr="id">
                </dxo-lookup>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>

            <dxi-column dataField="id" caption="ID" [formItem]="{visible: false}" [visible]="false">
            </dxi-column>

            <dxi-column dataField="price" caption="{{'Price' | translate}}" dataType="number">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>

            <dxi-column dataField="dlPrice" caption="{{'Dlv. Price' | translate}}" dataType="number">
            </dxi-column>

            <dxi-column dataField="slfPrice" caption="{{'Self Price' | translate}}" dataType="number">
            </dxi-column>

            <dxi-column dataField="vat" caption="{{'VAT' | translate}}" dataType="number">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>

            <dxi-column dataField="printer" caption="{{'Printer' | translate}}" dataType="string">
            </dxi-column>

            <dxi-column dataField="color" caption="{{'Color' | translate}}" dataType="string">
            </dxi-column>

            <dxi-column dataField="kerzzID" caption="{{'KerzzID' | translate}}" dataType="string">
            </dxi-column>

            <dxi-column dataField="image" [width]="60" caption="{{'Image' | translate}}" [allowFiltering]="false"
                [visible]="true" [formItem]="{visible: false}" [allowSorting]="false" cellTemplate="cellTemplate">
            </dxi-column>

            <div *dxTemplate="let data of 'cellTemplate'">
               
                <span title="Kontratı güncelle" class="link-icon" (click)="showUpload(data)">
                    <i class="far fa-file-image"></i>
                </span>
            </div>

         
           
    

            <!--     <dxo-editing [useIcons]="true" saveRowChanges="Kayder" mode="popup" [allowDeleting]="true" [allowAdding]="true" [allowUpdating]="true">
          <dxo-popup title="Product Info" [showTitle]="true" [position]="{ my: 'top', at: 'top', of: window }">
          </dxo-popup>
        </dxo-editing> -->

            <dxo-filter-row [visible]="showFilterRow"></dxo-filter-row>
            <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
            <dxo-search-panel [visible]="true" [width]="240" placeholder="{{'Search' | translate}}"></dxo-search-panel>

        </dx-data-grid>
    </div>
</div>



<div>
    <app-upload #productImage (uploadError)="setError($event)" (url)="setURL($event)" (percent)="setPercent($event)"
        folder="product"></app-upload>
</div>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#grid' }" [(visible)]="loadingVisible"
    [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>


<jw-modal id="image-upload">
    <app-cl-image-upload [(value)]="activeImage" #clUpload initalWidth="400px" initalHeight="300px" [maxSize]="500*1024"
        (error)="errorImage($event)" (success)="successImage($event)"></app-cl-image-upload>

</jw-modal>


<img id="imageid" src="">
<canvas id="imgCanvas"></canvas>