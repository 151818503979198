import { Component, OnInit, Input, ContentChild, TemplateRef, Output, EventEmitter, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { GenericDataService } from 'projects/cloud-components/src/lib/database/generic-data.service';
import { debounceTime, map, filter } from 'rxjs/operators';
import { Subject, fromEvent } from 'rxjs';
import { changeEvent } from '../cl-base/cl-base.component';
import { ModalService } from '../modal/modal.service';
import { ClImageUploadComponent } from '../cl-image-upload/cl-image-upload.component';
import { ClStore } from '../database/cl-store';

@Component({
  selector: 'app-cl-grid',
  templateUrl: './cl-grid.component.html',
  styleUrls: ['./cl-grid.component.scss']
})

export class ClGridComponent implements OnInit, OnChanges {

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {

    if (changes.pageSize) {
      this.clStore.clDataOptions.page.limit = changes.pageSize.currentValue
    }

    if (this.cols) {
      let sum = this.cols.reduce((sum, b) => sum += b.width, 0)
      this.cols.forEach(o => {
        o['percent'] = ((o.width / sum) * 100).toFixed(2)
      })
    }
  }

  oldValue
  loading = false
  focusRecord = { row: 0, col: 0 }
  totalCount = 0
  activePage = 1


  searchExpr = ""
  pages: any[] = []

  activeItem
  activeField
  activeImage = ""
  sortDirection = 0
  sortField = ""
  modelChanged: Subject<any> = new Subject<any>();

  constructor(private modalService: ModalService, ) { }

  @ContentChild(TemplateRef, { static: true })
  @ViewChild('clUpload', { static: true }) clUplod: ClImageUploadComponent

  @Input() ItemTemplate: TemplateRef<any>
  @Input() data: any[] = []
  @Input() dataService: GenericDataService
  @Input() cols: any[]
  @Input() filterField
  @Input() pageSize = 10
  @Input() clStore: ClStore
  @Input() readonly = true

  @Output() selectionChanged = new EventEmitter();

  ngOnInit() {


    if (this.clStore) {
      this.clStore.clDataOptions.page.limit = this.pageSize
      this.initSearch()
      this.loadData()

    } else {

    }


    const keyDowns = fromEvent(document, 'keydown');

    const nav = keyDowns.pipe(
      map((event: any) => {
        return event;
      }),
      filter((e: any) => e.key == "ArrowLeft" || e.key == "ArrowRight" || e.key == "ArrowDown" || e.key == "ArrowUp" || e.key == 'Enter')
    );

    nav.subscribe(o => {


      console.group("arrowKeys")

      let row = this.focusRecord.row
      let col = this.focusRecord.col

      let valueLength = o.target.value.toString().length
      let cursorPosition = o.target.selectionStart

      if (cursorPosition == valueLength && o.key == 'ArrowRight' && !o.shiftKey && col < (this.cols.length - 1)) {
        col++
      }

      if (cursorPosition == 0 && o.key == 'ArrowLeft' && !o.shiftKey && col > 0) {
        col--
      }

      if (o.key == "ArrowDown" && row < (this.clStore.pagedData.length - 1)) row++

      if (o.key == "ArrowUp" && row > 0) row--

      if (o.key == "ArrowRight" && o.shiftKey && col < (this.cols.length - 1)) col++

      if (o.key == "ArrowLeft" && o.shiftKey && col > 0) col--

      if (o.key == 'Enter') {

        if (col < (this.cols.length - 1)) {
          col++
        } else {
          if (row < (this.clStore.pagedData.length - 1)) {
            row++
          }
        }


      }


      document.getElementById(col + "-" + row).focus()
      console.groupEnd()

    })

  }

  select(item) {
    this.selectionChanged.emit(item)
  }

  objChange(e: changeEvent) {
    this.modelChanged.next(e)
  }

  loadData(): Promise<any> {

    return new Promise((resolve, reject) => {
      this.loading = true
      this.clStore.filter().then(o => {
        this.loading = false
        this.totalCount = o.totalCount
        let pg = this.totalCount / this.clStore.clDataOptions.page.limit
        pg = Math.ceil(pg)
        if (pg <= 50) {
          this.pages = []
          for (let index = 0; index < pg; index++) {
            this.pages.push((index + 1))
          }
        }
        resolve("ok")
      }, e => {
        reject(e)
      })
    })


  }

  gotoPage(i) {
    let val = parseInt(i.target.value)
    this.clStore.clDataOptions.page.start = (val - 1) * this.clStore.clDataOptions.page.limit
    this.loadData().then(o => {
      this.activePage = val
    })
  }

  gotoNext() {
    this.clStore.clDataOptions.page.start = (this.activePage + 1) * this.clStore.clDataOptions.page.limit
    this.loadData().then(o => {
      this.activePage++
    })
  }

  gotoPrev() {
    if (this.activePage == 1) {
      this.clStore.clDataOptions.page.start = 0
      this.loadData().then(o => {
      })

    } else {
      this.clStore.clDataOptions.page.start = (this.activePage - 1) * this.clStore.clDataOptions.page.limit
      this.loadData().then(o => {
        this.activePage--
      })
    }

  }

  initSearch() {

    this.modelChanged.pipe(
      debounceTime(700)
    ).subscribe(event => {
      if (event.valid && this.oldValue != event.value) {
        this.oldValue = event.value
        this.loading = event.loading = true
        this.clStore.clDataOptions.filter.condition = "startswith"
        this.clStore.clDataOptions.filter.field = this.filterField
        this.clStore.clDataOptions.filter.value = event.value
        this.clStore.clDataOptions.page.limit = this.pageSize
        this.clStore.clDataOptions.page.start = 0
        this.clStore.clDataOptions.sort.field = this.filterField


        this.clStore.pageLoad().then(o => {
          this.loading = event.loading = false
          this.totalCount = o.totalCount
          let pg = this.totalCount / this.clStore.clDataOptions.page.limit
          pg = Math.ceil(pg)
          if (pg <= 50) {
            this.pages = []
            for (let index = 0; index < pg; index++) {
              this.pages.push((index + 1))
            }
          }
        })
      }
    })

  }

  focus(cindex, jindex) {
    this.focusRecord = { row: jindex, col: cindex }
  }

  obj(e: changeEvent, item) {
    e.loading = true
    //! todo
    /* this.dataService.upsert(item).then(o => {
      e.loading = false
    }) */
  }

  error(e) {

  }

  success(e) {
    this.activeItem[this.activeField] = e
    //!this.dataService.upsert(this.activeItem)
  }

  closeUpload() {
    this.modalService.close("image-upload")
    this.clUplod.init()
  }

  showUpload(item, field) {
    this.activeItem = item
    this.activeField = field
    this.activeImage = this.activeItem[this.activeField]
    this.clUplod.value = this.activeImage
    this.clUplod.init()
    this.modalService.open("image-upload")
  }


  

  sort(col) {
    this.clStore.clDataOptions.sort.field = col.name
    this.clStore.clDataOptions.sort.direction = (this.clStore.clDataOptions.sort.direction == 1) ? -1 : 1
    this.sortDirection = this.clStore.clDataOptions.sort.direction
    this.sortField = col.name
    this.loadData()
  }

  filter(e, p: changeEvent) {

    // TODO: Multiple filter operations
    let ops = this.cols.filter(o => o.filter != null && o.filter != '')

    this.clStore.clDataOptions.filter.condition = "startswith"
    this.clStore.clDataOptions.filter.field = e
    this.clStore.clDataOptions.filter.value = p.value
    this.loading = true
    this.loadData()
  }

}
