import { Component, OnInit, Input } from '@angular/core';
import { InvoiceService, Invoice } from 'src/app/invoice/services/invoice.service';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-dispatch-form',
  templateUrl: './dispatch-form.component.html',
  styleUrls: ['./dispatch-form.component.scss']
})
export class DispatchFormComponent implements OnInit {


  selectedRows = []
  constructor(public invSrc: InvoiceService) { }

  ngOnInit(): void {
  }


  selectionChangedHandler(e) {

  }


  async acceptAll() {

    for (const iterator of this.invSrc.activeDispatch.invoiceRows) {
      iterator.approvedQty = iterator.quantity
      iterator.approvedUnit = iterator.unitName
    }


    await this.invSrc.upsert(this.invSrc.activeDispatch)
    this.invSrc.sendDispatchReply(this.invSrc.activeDispatch.uuid).subscribe(o => {
      notify("Yanıt Gönderildi", "info")
    })
  }

  onEditorPreparing(e) {
    // if (e.parentType == 'dataRow') {
    //   if (e.row.rowType == "data") {
    //     console.log(e)
    //     e.row.data.approvedQty = e.row.data.quantity
    //   }
    // }
  }

  async acceptPartial() {
    await this.invSrc.upsert(this.invSrc.activeDispatch)
    this.invSrc.sendDispatchReply(this.invSrc.activeDispatch.uuid).subscribe(o => {
      notify("Yanıt Gönderildi", "info")
    })
  }
}
