<div style="overflow:scroll; height:100vh;">
    <div class="section-title">
        {{"Sections & Tables" | translate}}
    </div>

    <div class="card">
        <div class="section-title">
            {{"Sections" | translate}}
        </div>
        <dx-data-grid id="gridContainer" [selectedRowKeys]="[]" (onSelectionChanged)="selectionChangedHandler($event)" [dataSource]="ts.data" [showBorders]="true" style="margin:auto;" (onInitNewRow)="initRow($event)" (onRowInserted)="insertedRow($event)" (onRowUpdated)="updatedRow($event)"
            (onRowRemoved)="deleteRow($event)">
            <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-search-panel [visible]="true" [width]="200" placeholder="Ara..."></dxo-search-panel>
            <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
            </dxo-editing>
            <dxo-selection mode="single"></dxo-selection>
            <dxi-column dataField="tableGroup" [width]="100" caption="{{'Group' | translate}}"></dxi-column>
            <dxi-column dataField="name" [width]="100" caption="{{'Name' | translate}}"></dxi-column>
            <dxi-column dataField="description" [width]="150" caption="{{'Description' | translate}}">
                <dxo-form-item [colSpan]="2" editorType="dxHtmlEditor" [editorOptions]="{ height: 100 }">
                </dxo-form-item>
            </dxi-column>
        </dx-data-grid>
        <div class="section-title">
            {{"Tables" | translate}}
        </div>
        <dx-data-grid id="gridContainer" [dataSource]="this.activeTables" [showBorders]="true" style="margin:auto;" (onInitNewRow)="t_initRow($event)" (onRowInserted)="t_insertedRow($event)" (onRowUpdated)="t_updatedRow($event)" (onRowRemoved)="t_deleteRow($event)">
            <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-search-panel [visible]="true" [width]="200" placeholder="Ara..."></dxo-search-panel>
            <dxo-editing mode="cell" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
            </dxo-editing>
            <dxi-column dataField="name" [width]="100" caption="{{'Name' | translate}}"></dxi-column>
            <dxi-column dataField="type" [width]="100" caption="{{'Pax' | translate}}"></dxi-column>
        </dx-data-grid>
    </div>


    <div style="height: 200px;">

    </div>