import { Router, ActivatedRoute } from '@angular/router';
import { AppPermissionsService } from './settings/services/app-permissions.service';
import { StartupService } from './services/startup.service';
import { ProductGroupService } from './product/product-groups/service/product-group.service';
import { GlobalsService } from './services/globals.service';
import { Component, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import dx from 'devextreme/ui/themes';


import { locale, loadMessages, formatMessage } from 'devextreme/localization';
import 'devextreme-intl';
import { MatSnackBar } from '@angular/material/snack-bar';


declare var require: any;
const deMessages = require('./tr.json');


loadMessages(deMessages);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  activeRoute
  return: string = '';


  constructor(
    private translate: TranslateService,
    private elementref: ElementRef,
    private _snackBar : MatSnackBar,
    private ss: StartupService,
    private glb: GlobalsService) {

    loadMessages(deMessages);
    locale('tr')
    this.translate.setDefaultLang('tr');
    this.glb.customerID = "BISQ"
    this.glb.restoranID = "FOLKART"

    //dx.current('generic.darky');
    //this.changeBaseTheme("darky")

      this.glb.getExchanges()

    this.ss.observer_global_events.subscribe(o => {
      if (o.id == "changeTheme") {
        this.changeBaseTheme(o.data)
        this.changeButtonMenuTheme(o.data)
        this.changeFoliotheme(o.data)
        this.changeMenuTheme(o.data)
        this.changeClComponentsTheme(o.data)
      }
    })


  }

  changeButtonMenuTheme(theme: string) {
    /*   --button-menu-color: #0F1642;
        --button-menu-text: white;
        --button-menu-disabled-color: #0F1642;
        --button-menu-disabled-text: white;
        --button-menu-hover-color: rgb(136, 123, 253);
        --button-menu-selected-color: #9E94FF; */


    //dark
    let button_menu_color = "#0F1642"
    let button_menu_text = "white"
    let button_menu_disabled_color = "#0F1642"
    let button_menu_disabled_text = "white"
    let button_menu_hover_color = "rgb(136, 123, 253)"
    let button_menu_selected_color = "#9E94FF"


    if (theme.indexOf("lighty") > -1) {
      //light
      button_menu_color = "#dbddea"
      button_menu_text = "#3e3d3d"
      button_menu_disabled_color = "#e5e5e5"
      button_menu_disabled_text = "#4f5043"
      button_menu_hover_color = "#7ab8eb"
      button_menu_selected_color = "#7ab8eb"
    }



    this.elementref.nativeElement.style.setProperty('--button-menu-color', button_menu_color)
    this.elementref.nativeElement.style.setProperty('--button-menu-text', button_menu_text)
    this.elementref.nativeElement.style.setProperty('--button-menu-disabled-color', button_menu_disabled_color)
    this.elementref.nativeElement.style.setProperty('--button-menu-disabled-text', button_menu_disabled_text)
    this.elementref.nativeElement.style.setProperty('--button-menu-hover-color', button_menu_hover_color)
    this.elementref.nativeElement.style.setProperty('--button-menu-selected-color', button_menu_selected_color)


  }

  changeFoliotheme(theme: string) {
    /*   __folio_background: #0F1642;
      --folio-text: white;
      --folio-row-selected: #9E94FF;
      --folio-row-selected-text: rgb(12, 10, 29);
      --folio-row-hover: rgb(47, 59, 126); 
      
          --folio-total-row: #20b2aa;
    --folio-discount-row: #cebd0f;
    --folio-payment-row: #9acd32;
    --folio-balance-row: white;
    --folio-change-row: #20b2aa;
        --folio-row-cover-border: #1919d8;
      
          --folio-price-line-throuh: red;
    --folio-row-gift: red;
    --folio-row-note: red;
      
        */

    //dark
    let folio_background = "#0F1642"
    let folio_text = "white"
    let folio_row_selected = "#9E94FF"
    let folio_row_selected_text = "rgb(12, 10, 29)"
    let folio_row_hover = "rgb(47, 59, 126)"
    let folio_row_cover_border = "#1919d8"


    let folio_total_row = "#20b2aa"
    let folio_discount_row = "#cebd0f"
    let folio_payment_row = "#9acd32"
    let folio_balance_row = "white"
    let folio_change_row = "#20b2aa"

    let folio_price_line_throuh = "red"
    let folio_row_gift = "red"
    let folio_row_note = "red"



    if (theme.indexOf("lighty") > -1) {
      //lighy
      folio_background = "#dddd"
      folio_text = "#373535"
      folio_row_selected = "#7ab8eb"
      folio_row_selected_text = "black"
      folio_row_hover = "#7ab8eb"
      folio_row_cover_border = "#83839c"


      folio_total_row = "#03524e"
      folio_discount_row = "#bf0b53"
      folio_payment_row = "#070ac3"
      folio_balance_row = "#070ac3"
      folio_change_row = "#070ac3"

      folio_price_line_throuh = "red"
      folio_row_gift = "red"
      folio_row_note = "red"
    }


    this.elementref.nativeElement.style.setProperty('--folio-background', folio_background)
    this.elementref.nativeElement.style.setProperty('--folio-text', folio_text)
    this.elementref.nativeElement.style.setProperty('--folio-row-selected', folio_row_selected)
    this.elementref.nativeElement.style.setProperty('--folio-row-selected-text', folio_row_selected_text)
    this.elementref.nativeElement.style.setProperty('--folio-row-hover', folio_row_hover)
    this.elementref.nativeElement.style.setProperty('--folio-row-cover-border', folio_row_cover_border)

    this.elementref.nativeElement.style.setProperty('--folio-total-row', folio_total_row)
    this.elementref.nativeElement.style.setProperty('--folio-discount-row', folio_discount_row)
    this.elementref.nativeElement.style.setProperty('--folio-payment-row', folio_payment_row)
    this.elementref.nativeElement.style.setProperty('--folio-balance-row', folio_balance_row)
    this.elementref.nativeElement.style.setProperty('--folio-change-row:', folio_change_row)

    this.elementref.nativeElement.style.setProperty('--folio-price-line-throuh', folio_price_line_throuh)
    this.elementref.nativeElement.style.setProperty('--folio-row-gift', folio_row_gift)
    this.elementref.nativeElement.style.setProperty('--folio-row-note:', folio_row_note)
  }

  changeBaseTheme(theme: string) {

    let menu_background = "#fafafa"
    let menu_selected = "#7ab8eb"
    let menu_sub_selected = "#7ab8eb"
    let menu_text = "black"
    let menu_logo_text = "#7E72F2"
    let workspace_background = "#fafafa"
    let workspace_text = "#000000"
    let header_background = "#fafafa"
    let header_text = "black"





    if (theme.indexOf("darky") > -1) {
      menu_background = "#0F1642"
      menu_selected = "#9E94FF"
      menu_sub_selected = "#978CFC"
      menu_text = "white"
      menu_logo_text = "#7E72F2"
      workspace_background = "#2B3253"
      workspace_text = "white"
      header_background = "#0F1642"
      header_text = "white"
    }

    this.elementref.nativeElement.style.setProperty('--menu-background', menu_background)
    this.elementref.nativeElement.style.setProperty('--menu-selected', menu_selected)
    this.elementref.nativeElement.style.setProperty('--menu-sub-selected', menu_sub_selected)
    this.elementref.nativeElement.style.setProperty('--menu-text', menu_text)
    this.elementref.nativeElement.style.setProperty('--menu-logo-text', menu_logo_text)
    this.elementref.nativeElement.style.setProperty('--workspace-background', workspace_background)
    this.elementref.nativeElement.style.setProperty('--workspace-text', workspace_text)
    this.elementref.nativeElement.style.setProperty('--header-background', header_background)
    this.elementref.nativeElement.style.setProperty('--menu-background', menu_background)
    this.elementref.nativeElement.style.setProperty('--header-text', header_text)

  }

  changeMenuTheme(theme: string) {
    /*--popup-background: black;
      --popup-text: white;
      --product-background: #0F1642;
      --product-group-background: #0F1642;
      --product-text: white;
      --product-group-text: white; */

    //dark
    let popup_background = "black"
    let popup_text = "white"
    let product_background = "#0F1642"
    let product_group_background = "#0F1642"
    let product_text = "white"
    let product_group_text = "white"

    if (theme.indexOf("lighty") > -1) {

      //light
      popup_background = "#373535"
      popup_text = "black"
      product_background = "#dddd"
      product_group_background = "#dddd"
      product_text = "#373535"
      product_group_text = "#373535"

    }

    this.elementref.nativeElement.style.setProperty('--popup-background', popup_background)
    this.elementref.nativeElement.style.setProperty('--popup-text', popup_text)
    this.elementref.nativeElement.style.setProperty('--product-background', product_background)
    this.elementref.nativeElement.style.setProperty('--product-group-background', product_group_background)
    this.elementref.nativeElement.style.setProperty('--product-text', product_text)
    this.elementref.nativeElement.style.setProperty('--product-group-text', product_group_text)


  }

  changeClComponentsTheme(theme: string) {
    /*
    --focused-input : #091a82;;
    --cl-input-font-familiy: 'Montserrat';
    --cl-input-font-size: 14px;
    --cl-toolbar-background: #0d1231;
    --cl-toolbar-text: white;
    --cl-label-color: #c1c39b;
    --cl-input-bottom-border: #0F1642; 
    --cl-input-sub-group-text: #0F1642; 
    --cl-input-group-text: #0F1642; 

    --cl-grid-toolbar-background:#0d1231;
    --cl-grid-toolbar-text:white;

    --cl-grid-background:#2B3253;
    --cl-grid-text:white;
    

    */

    //dark
    let focused_input = "#091a82"
    let cl_toolbar_background = "#0d1231"
    let cl_toolbar_text = "white"
    let cl_label_color = "#c1c39b"
    let cl_input_bottom_border = "#0F1642"
    let cl_input_sub_group_text = "#0F1642"
    let cl_input_group_text = "white"

    let cl_grid_toolbar_background = "#0d1231"
    let cl_grid_toolbar_text = "white"

    let cl_grid_background = "#2B3253"
    let cl_grid_text = "white"

    let cl_search_list_shadow = "0px 0px 8px 3px #020929"


    if (theme.indexOf("lighty") > -1) {

      //light
      focused_input = "#091a82"
      cl_toolbar_background = "#eeeeee"
      cl_toolbar_text = "#292929"
      cl_label_color = "#7a747b"
      cl_input_bottom_border = "#dbdce2"
      cl_input_sub_group_text = "#135ce4"
      cl_input_group_text = "#135ce4"


      cl_grid_toolbar_background = "#eeeeee"
      cl_grid_toolbar_text = "#292929"

      cl_grid_background = "#eeeeee"
      cl_grid_text = "#292929"

      cl_search_list_shadow = "0px 0px 8px 3px #d1d4e4"

    }

    this.elementref.nativeElement.style.setProperty('--cl-toolbar-background', cl_toolbar_background)
    this.elementref.nativeElement.style.setProperty('--cl-toolbar-text', cl_toolbar_text)
    this.elementref.nativeElement.style.setProperty('--cl-label-color', cl_label_color)
    this.elementref.nativeElement.style.setProperty('--cl-input-bottom-border', cl_input_bottom_border)
    this.elementref.nativeElement.style.setProperty('--cl-input-sub-group-text', cl_input_sub_group_text)
    this.elementref.nativeElement.style.setProperty('--cl-input-group-text', cl_input_group_text)

    this.elementref.nativeElement.style.setProperty('--cl-grid-toolbar-background', cl_grid_toolbar_background)
    this.elementref.nativeElement.style.setProperty('--cl-grid-toolbar-text', cl_grid_toolbar_text)

    this.elementref.nativeElement.style.setProperty('--cl-grid-background', cl_grid_background)
    this.elementref.nativeElement.style.setProperty('--cl-grid-text', cl_grid_text)

    this.elementref.nativeElement.style.setProperty('--cl-search-list-shadow', cl_search_list_shadow)

  }




}
