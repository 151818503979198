import { Component, OnInit } from '@angular/core';
import { InvoiceService, Invoice, logType, invoiceLog } from 'src/app/invoice/services/invoice.service';
import { AppUsersService } from 'src/app/settings/services/app-users.service';
import { CloudLabsLoginService } from 'cloud-labs-login';
import { CloudLoginService } from 'cloud-labs-core';
import { ModalService } from 'projects/cloud-components/src/lib/modal/modal.service';

@Component({
  selector: 'app-erp-form',
  templateUrl: './erp-form.component.html',
  styleUrls: ['./erp-form.component.scss']
})
export class ErpFormComponent implements OnInit {
  activeInvoice: Invoice = new Invoice()
  selectedInovices: Invoice[] = []
  activeFilter = {}
  stats: integrationStat[] = []
  selectedRows: number[];
  loadingVisible = false
  inoviceError = ""
  constructor(public is: InvoiceService,
    private ls: CloudLoginService,
    private modalService: ModalService,
    public appUserService: AppUsersService, ) { }

  ngOnInit() {

    let isNew = new integrationStat("new", 5)
    let isSuccess = new integrationStat("success", 15)
    let isError = new integrationStat("error", 3)
    this.stats.push(isNew)
    this.stats.push(isSuccess)
    this.stats.push(isError)

    this.is.load({id: -1}).then(o => {

    })
  }

  selectionChangedHandler(e) {
    if (e.selectedRowsData.length == 0) return
    console.log(e)
    this.selectedInovices = e.selectedRowsData
    this.activeInvoice = e.selectedRowsData[0]
  }

  loadGrid(e) {

    this.activeFilter = {
      $and: [
        { $or: [ {erp: e.id}, {erp: false} ]  },
        { status: "success" }
      ]
    }

    this.is.load(this.activeFilter)

  }

  getCSS(e) {

  }

  integration() {
    this.sendErp()
  }
  logs() {
    this.modalService.open('invoiceLogsModal')
  }


  private sendErp() {

    this.loadingVisible = true
    let erpsender = new Promise((resolve, reject) => {
      this.selectedInovices.forEach((el, index, array) => {

        this.is.sendInvoiceToErp(el.uuid).subscribe(o => {
          let ps = Object.assign(o)

          if (ps.message == "success") {
            if (el.erp) el["erp"] = "new"
            el.erp = "success"
            this.addLog("erp", el, "integration", "success")
          } else {
            this.addLog("erp", el, "integration-error", ps.message)
          }
          if (index === array.length - 1) resolve("");

        }, e => {
          if (index === array.length - 1) reject(e.error.message);
          //this.ShowError(e.error.message)
          this.addLog("erp", el, "integration-error", e.error.message)
        })

      })

    })

    erpsender.then(o => {
      this.is.runCalculateStatics()
      this.loadingVisible = false

    }).catch(p => {
      this.loadingVisible = false
      this.ShowError(p)

    })






  }

  private ShowError(e) {
    console.log(e)
    this.loadingVisible = false
    this.inoviceError = e
    this.modalService.open('inoviceError');
  }



  addLog(lType: logType, inv: Invoice, title, message) {
    let log: invoiceLog = new invoiceLog
    log.type = lType
    log.title = title
    log.message = message
    log.userId = this.ls.userInfo.id
    if (!inv.logs) {
      inv["logs"] = []
    }
    inv.logs.push(log)
    this.is.upsert(inv)
  }

  closeModal(i) {
    this.modalService.close(i)
  }


}



export class integrationStat {

  constructor(id, count) {
    this.id = id
    this.count = count
  }

  id: 'new' | 'success' | 'error' = "new"
  count: 0

  getName(id) {
    switch (id) {
      case "new":
        return "Yeni"
        break;
      case "success":
        return "Entegre"
        break;
      case "error":
        return "Hatalı"
        break;
      default:
        break;
    }
  }
}

export class integration {
  status: 'new' | 'success' | 'error' = "new"
  statusMessage = ""
  userId: ""
  dateTime = new Date()
  type: "invoice" | "z-report"
}