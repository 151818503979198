<div style="overflow:scroll; height:100vh;">
    <div class="section-title" style="width: 95% !important">
        {{'Advices' | translate}}
    </div>



    <div style="display: flex; width: 95%; margin: auto">



        <!-- <dx-button [width]="50" icon="refresh" [disabled]="false" (click)="this.ps.refresh()">
        </dx-button> -->

        <dx-data-grid width="95%" style="font-size: 12px;
      margin-left: auto;
      margin-right: auto;
      height: calc(100vh - 130px);
      padding: 20px;
      width: 95%;
      background-color: #2a2a2a;
      border-radius: 10px;" #grid id="gridContainer" [dataSource]="advices" [columnHidingEnabled]="false"
            [allowColumnResizing]="true" columnResizingMode="widget" [hoverStateEnabled]="true" keyExpr="id"
            (onRowInserting)="initRow($event)" (onRowRemoving)="deleteRow($event)" [allowColumnReordering]="true"
            (onRowUpdated)="updatedRow($event)" (onRowInserted)="insertedRow($event)" [showBorders]="true">

            <dxo-editing mode="popup" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
            </dxo-editing>
            <dxo-group-panel [visible]="true"></dxo-group-panel>
            <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>
            <dxo-export [enabled]="true" fileName="products" [allowExportSelectedData]="true"></dxo-export>
            <dxo-selection mode="multiple"></dxo-selection>
            <dxo-scrolling columnRenderingMode="virtual" mode="virtual"></dxo-scrolling>
            <dxo-filter-panel [visible]="true"></dxo-filter-panel>
            <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
            <dxo-search-panel [visible]="true" [width]="240" placeholder="Ara..."></dxo-search-panel>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
            <dxo-paging [pageSize]="15"></dxo-paging>


            <dxi-column dataField="id" caption="ID" [formItem]="{visible: false}" [visible]="false">
            </dxi-column>


            <dxi-column dataField="incGroup" editCellTemplate="tagBoxEditor" [cellTemplate]="cellTemplate"
                calculateFilterExpression="calculateFilterExpression" caption="{{'Group' | translate}}" [width]="150">
                <dxo-lookup [dataSource]="this.pg.data" displayExpr="name" valueExpr="id">
                </dxo-lookup>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>

            <dxi-column dataField="outGroup" editCellTemplate="tagBoxEditor" [cellTemplate]="cellTemplate"
            calculateFilterExpression="calculateFilterExpression" caption="{{'Öneri Grubu' | translate}}" [width]="150">
            <dxo-lookup [dataSource]="this.pg.data" displayExpr="name" valueExpr="id">
            </dxo-lookup>
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>
            
            <!-- <dxi-column dataField="outGroup" caption="{{'Tavsiye Grubu' | translate}}" [width]="150">
                <dxo-lookup [dataSource]="this.pg.data" displayExpr="name" valueExpr="id">
                </dxo-lookup>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column> -->

            <dxi-column dataField="description" caption="{{'Description' | translate}}" [width]="205">
            </dxi-column>

            <dxi-column dataField="order" caption="{{'Order' | translate}}" dataType="number">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <div *dxTemplate="let cellInfo of 'tagBoxEditor'">
                <dx-tag-box [dataSource]="this.pg.data" [value]="cellInfo.value" valueExpr="id" displayExpr="name"
                    [showSelectionControls]="true" [maxDisplayedTags]="3" [showMultiTagOnly]="false"
                    [inputAttr]="{ 'aria-label': 'Name' }" applyValueMode="useButtons" [searchEnabled]="true"
                    (onValueChanged)="cellInfo.setValue($event.value)"
                    (onSelectionChanged)="cellInfo.component.updateDimensions()">
                </dx-tag-box>
            </div>

        </dx-data-grid>
    </div>
</div>