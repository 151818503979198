import { CloudDbService, CloudLoginService } from 'cloud-labs-core';
import { GenericDataService } from '../../../../projects/cloud-components/src/lib/database/generic-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { loyaltyUserObject } from '../users/service/loyalty-users';
import { loyaltySettings, SettingsService } from '../settings/service/settings.service';
@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService extends GenericDataService {

  data: notification[]
  collection = "loyalty-notifications"

  psa = {
    "notification": {
      "title": "BeX Coffee & Chocolate",
      "body": "100 çekirdek geldi :)",
      "sound": "default",
      "click_action": "FCM_PLUGIN_ACTIVITY",
      "icon": "fcm_push_icon"
    },
    "data": {
      "landing_page": "scratch"
    },
    "to": "egc8wx9Gteo:APA91bGnHXPxsQdC8cd9aIv27Twp0Mz6xrP6w7mF6oh6eqoXxNBZxJnBU1T-7lHfLJ_K0KBDeqdLlmBwDTbAEyKDpHRzWvtNvn6oJfiNxjtXHR5gAZATSdajkSoWj4wuj_S5kggAkZSB",
    "priority": "high",
    "restricted_package_name": ""
  }

  constructor(public cs: CloudDbService, private http: HttpClient, private ls: CloudLoginService, private lSettings: SettingsService) {
    super(cs)
    this.load()
    this.lSettings.load()

  }
  
 

  sendNotificationToUser(user: loyaltyUserObject) {
    console.groupCollapsed("Send Notifivation")

    let key = ""



    key = this.lSettings.data[0].fcmToken
    this.psa.notification.title = this.lSettings.data[0].brand
    this.psa.notification.body = "Hello From " +  this.lSettings.data[0].brand 

    this.psa.to = user.pushToken


    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': key })
    }
    this.http.post("https://fcm.googleapis.com/fcm/send", this.psa, httpOptions).subscribe(o => {
    })

    console.groupEnd()

  }

 





}


export class notification {

  id: string = ""
  name: string = ""
  filter: string = ""
  title: string = ""
  body: string = ""
  to: string = ""
  timeFilter =""

}

