import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lib-cl-collapsable-title',
  templateUrl: './cl-collapsable-title.component.html',
  styleUrls: ['./cl-collapsable-title.component.scss']
})
export class ClCollapsableTitleComponent implements OnInit {

  collapsable = false
  @Input() title = ""
  @Input() collapsed = false

  constructor() { }

  ngOnInit() {
  }

  collapse() {
    this.collapsed = this.collapsed ? false : true
  }
}
