<div [ngClass]="{'cl-line': label != ''}" ></div>
<div class="switch-cover">
  <div [ngClass]="{'cl-label': label != ''}">{{label}}</div>
  <div>
   
   
  <span *ngIf="type == 'light'">
    <input (change)="changed($event)" [checked]="value" class="tgl tgl-light" id="{{cb5}}" type="checkbox"/>
    <label class="tgl-btn" for="{{cb5}}"></label>
  </span>
  <span *ngIf="type == 'ios'">
    <input class="tgl tgl-ios" (change)="changed($event)" [checked]="value" id="{{cb5}}" type="checkbox"/>
    <label class="tgl-btn" for="{{cb5}}"></label>
  </span>
  <span *ngIf="type == 'skewed'">
    <input class="tgl tgl-skewed" (change)="changed($event)" [checked]="value" id="{{cb5}}" type="checkbox"/>
    <label class="tgl-btn" [attr.data-tg-off]="off" [attr.data-tg-on]="on" for="{{cb5}}"></label>
  </span>
  <span *ngIf="type == 'flat'">
    <input class="tgl tgl-flat" (change)="changed($event)" [checked]="value" id="{{cb5}}" type="checkbox"/>
    <label class="tgl-btn" for="{{cb5}}"></label>
  </span>
  <span *ngIf="type == 'flip'">
    <input class="tgl tgl-flip" (change)="changed($event)" [checked]="value" id="{{cb5}}" type="checkbox"/>
    <label class="tgl-btn" [attr.data-tg-off]="off" [attr.data-tg-on]="on" for="{{cb5}}"></label>
  </span>



  </div>
</div>