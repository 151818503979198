import { CloudFolioService } from './../../../../../projects/cloud-folio/src/lib/cloud-folio.service';
import { GlobalsService } from './../../../services/globals.service';
import { menu } from './../../../tools/button-menu/button-menu.component';
import { Component, OnInit, ElementRef } from '@angular/core';
import { StartupService } from 'src/app/services/startup.service';
import { NumericButtonsService } from 'src/app/services/tools/numeric-buttons.service';
import { PayTypesService } from 'src/app/settings/services/pay-types.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  toastVisible = false

  paymentMenu: menu[] = []
  numPadVisible: boolean = false;

  activePayment: menu
  activeOperation: string

  constructor(
    private elementref: ElementRef,
    private ns: NumericButtonsService,
    private ss: StartupService,
    private glb: GlobalsService,
    private pts: PayTypesService,
    public fls: CloudFolioService) { }

  ngOnInit() {

    this.pts.load().then(o => {
      this.pts.data.forEach(o => {
        let icon = "fa-money-bill"
        if (o.paytype == "creditcard") {
          icon = "fa-credit-card"
        } else if (o.paytype == "foodcheck") {
          icon = "fa-money-check"
        }
        this.paymentMenu.push({ title: o.name, faicon: icon, icon: o.currency, tag: o.id, selectable: false, type: "payment" })
      })
    }).then(b => {
      this.paymentMenu.push({ type: "save", title: "Kaydet", color: "yellow", faicon: "fa-thumbs-up", icon: "card_giftcard", tag: "save", selectable: false, disabled: false })
      this.paymentMenu.push({ type: "close", title: "Kapat", color: "yellow", faicon: "fa-cash-register", icon: "card_giftcard", tag: "close", selectable: false, disabled: !this.isCloseable() })
      this.paymentMenu.push({ type: "cancel", title: "İptal", color: "yellow", faicon: "fa-window-close", icon: "card_giftcard", tag: "cancel", selectable: false })
    }
    )


    /* this.paymentMenu.push({ title: "Nakit", faicon: "fa-money-bill", icon: "restaurant_menu", tag: "cash", selectable: false })
    this.paymentMenu.push({ title: "Kredi Kartı", faicon: "fa-credit-card", icon: "money", tag: "card", selectable: false })
    this.paymentMenu.push({ title: "Yemek Çeki",faicon: "fa-money-check", icon: "card_giftcard", tag: "payment", selectable: false })
    this.paymentMenu.push({ title: "Ödenmez", faicon: "fa-file-invoice", icon: "card_giftcard", tag: "payment", selectable: false })
    this.paymentMenu.push({ title: "App Card", faicon: "fa-file-invoice", icon: "card_giftcard", tag: "payment", selectable: false })
    this.paymentMenu.push({ title: "%10 İskonto", faicon: "fa-percent" , icon: "card_giftcard", tag: "discount", selectable: false })
    this.paymentMenu.push({ title: "Personel İskontosu", faicon: "fa-percent" , icon: "card_giftcard", tag: "emp", selectable: false, disabled: true })
     */


    this.elementref.nativeElement.style.setProperty('--button-width', "80px")
    this.elementref.nativeElement.style.setProperty('--button-height', "80px")

    this.fls.folio.rows.map(r => r.selected = false)


  }

  isCloseable(): boolean {
   return (this.fls.getUnPaidTotal() == 0 && this.fls.getRows().length > 0) 
  } 

  makePayment(e) {

    this.doPayment(e)

  }

  makePaymentAndClose(e) {
    if (this.doPayment(e)) {
      this.doClose()
    }
  }

  doPayment(e) {
    let paymentAmount = e
    if (this.fls.getUnPaidTotal() == 0) {
      this.glb.notice("Adisyonun tamamı ödendi", "error")
      return false
    }

    if (e > this.calculatePaymentTotal()) {
      paymentAmount = this.calculatePaymentTotal()
      this.fls.outstanding = e - this.calculatePaymentTotal()
    }

    if (this.fls.folio.rows.filter(o => o.selected).length > 0) {
      this.fls.folio.rows.filter(o => o.selected).map(o => o.isPaidFull = true)
      this.fls.folio.rows.filter(o => o.isPaidFull).map(o => o.selected = false)
    }

    this.fls.addPayment(this.activePayment.tag, this.activePayment.title, paymentAmount)

    this.paymentMenu.find(o => o.tag == "close").disabled = (this.fls.getUnPaidTotal() != 0)

    return true
  }

  doClose() {


    if (this.fls.getUnPaidTotal() > 0) {
      this.glb.notice(this.fls.getUnPaidTotal() + " daha ödenmesi gerekiyor.", "error")
      return
    } else {
      this.fls.closeFolio()
      this.ss.subject_pos_screen.next({ id: "change-view", data: "table" })
    }


  }

  activeCurrency = "TRY"
  payment_menu_click(item: menu) {
    switch (item.type) {
      case "cancel":
        this.ss.subject_pos_screen.next({ id: "change-view", data: "menu" })
        break;
      case "payment":
        if (item.icon) {
          this.activeCurrency = item.icon
        }
        this.activePayment = item
        this.calculatePaymentTotal()
        this.numPadVisible = true
        break;
      case "discount":
        this.fls.addDiscount("Discount", "Discount %15", this.fls.getFolioTotal() * 0.15)
        break;
      case "close":
        this.doClose()
        break;
      case "save":
        this.fls.saveFolio()
        this.ss.subject_pos_screen.next({ id: "change-view", data: "menu" })
      default:
        break;
    }
  }

  calculatePaymentTotal() {
    let total = 0
    if (this.fls.folio.rows.filter(o => o.selected).length > 0) {
      total = this.fls.folio.rows.filter(o => o.selected && !o.isGift && !o.isPayment).reduce((sum, item) => sum + (item.unitPrice * item.qty), 0);
    } else {
      total = this.fls.getUnPaidTotal()
    }
    this.ns.value = total
    let xtotal = total
    return xtotal
  }

}
