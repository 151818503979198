<div style="overflow:scroll; height:100vh; width: Calc(100% - 50px);">


  <div *ngIf="false" class="invoice-toolbar">
    <div>Tarih Aralığı</div>
    <dx-date-box applyValueMode="useButtons" [(value)]="startDate">
    </dx-date-box>
    <dx-date-box applyValueMode="useButtons" [(value)]="endDate">
    </dx-date-box>
    <div>Fatura Türü</div>
    <cl-drop-box [(value)]="filterDocumentType" [list]="this.inoviceSrc.documentTypes" valueExpr="id"
      displayExpr="name"></cl-drop-box>
    <div>Durumu</div>
    <cl-drop-box [(value)]="filterStatus" [list]="this.inoviceSrc.statuses" valueExpr="id" displayExpr="name">
    </cl-drop-box>
    <button class="cl-ok-button" (click)="loadData()">Tamam</button>
  </div>

  <!--NAVIGATION FILTER TOOLBAR-->
  <div class="invoice-toolbar">

    <div class="invoice-toolbar-inset" style="padding:20px">
      <div style="display: flex; justify-content: space-between;">

        <div style="padding: 10px;">
          E-FATURA GELEN
        </div>

        <div style="display: flex;
      justify-content: space-between;
      align-items: center;
      width: 550px;">
          Tarih Aralığı
          <dx-date-box [(value)]="startDate">
          </dx-date-box>
          <dx-date-box [(value)]="endDate">
          </dx-date-box>
          <dx-button [text]="'Tamam'" (onClick)="loadData()"></dx-button>
        </div>
      </div>



      <div *ngIf="false" style="display: flex; justify-content: space-around;">

        <!--E-INVOICE, POS SOURCE STATS-->
        <div style="width: 50%;">
          <div class="stat-sub-cover"></div>
          <div style="display: flex; justify-content: space-around">
            <div *ngFor="let itemA of this.inoviceSrc.eInvoiceInbound">
              <div (click)="loadGrid(itemA)" class="stat-cover">
                <div class="stat-text">
                  {{itemA.name}}
                </div>
                <div class="banner1 {{getCss(itemA)}}" *ngIf="this.inoviceSrc.inboundStaticsLoadingStatus == 'loaded'">
                  {{itemA.count}}
                </div>
                <div class="banner1" *ngIf="this.inoviceSrc.inboundStaticsLoadingStatus != 'loaded'">
                  <img style="width: 30px; height:30px;" src="/assets/loading2.gif" />
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

    </div>


    <!--
        #9c2207 error
        #d44b2c yok sayıldı
        #800076 iptal edildi
        #8759e2 beklemede
        #29c11f gönderildi -->

  </div>

  <!--MENU BAR-->
  <div>
    <app-button-menu class="invoice-menu" type="row" (clicked)="inovice_menu_click($event)" [menu]="invoiceMenu">
    </app-button-menu>
  </div>

  <div style="display: flex; width: 100%">


    <dx-data-grid width="95%" #grid style="    font-size: 12px;
    margin-left: auto;
    margin-right: auto;
    height: calc(100vh - 260px);
    padding: 20px;
    width: 95%;
    background-color: #2a2a2a;
    border-radius: 10px;" id="gridContainer" [dataSource]="inoviceSrc.data" [hoverStateEnabled]="true"
      [columnHidingEnabled]="false" keyExpr="id" [allowColumnReordering]="true" [(selectedRowKeys)]="selectedRows"
      [allowColumnResizing]="true" columnResizingMode="widget" [columnMinWidth]="100"
      (onSelectionChanged)="selectionChangedHandler($event)" [columns]="['id', 'name', 'group']" [showBorders]="true">




      <dxo-export [enabled]="true" fileName="faturalar" [allowExportSelectedData]="true"></dxo-export>
      <dxo-selection mode="multiple"></dxo-selection>
      <dxo-scrolling columnRenderingMode="virtual" mode="virtual"></dxo-scrolling>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
      <dxo-search-panel [visible]="true" [width]="240" placeholder="Ara..."></dxo-search-panel>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
      <dxo-paging [pageSize]="15"></dxo-paging>
      <dxo-state-storing
      [enabled]="true"
      type="localStorage"
      storageKey="inbound-invoive-state"
    ></dxo-state-storing>


      <dxi-column dataField="readed" caption="{{'Readed' | translate}}" [width]="80" dataType="boolean">
      </dxi-column>
      <dxi-column dataType="boolean" [allowEditing]="false" dataField="printed" caption="{{ 'Printed' | translate }}"
        [width]="100">
      </dxi-column>
      <dxi-column dataField="stock" caption="{{'Stok' | translate}}" [width]="80" dataType="boolean"></dxi-column>

      <dxi-column dataField="createDate" caption="Geliş Tarihi" alignment="right" dataType="datetime"
        format="dd/MM/yyyy" [width]="80"></dxi-column>

      <dxi-column dataField="invoiceDate" caption="{{'Date' | translate}}" alignment="right" dataType="datetime"
        format="dd/MM/yyyy" [width]="80"></dxi-column>
      <dxi-column dataField="invoiceNumber" caption="{{'Invoice ID' | translate}}" [width]="140"></dxi-column>
      <dxi-column dataField="typeScenario" caption="{{'Scene' | translate}}" [width]="60">
        <dxo-lookup [dataSource]="this.inoviceSrc.scenarios" displayExpr="name" valueExpr="id">
        </dxo-lookup>
      </dxi-column>
      <dxi-column dataField="invoiceType" caption="{{'Type' | translate}}" [width]="60">
        <dxo-lookup [dataSource]="this.inoviceSrc.invoiceTypes" displayExpr="name" valueExpr="id">
        </dxo-lookup>
      </dxi-column>
      <dxi-column dataField="account.accountName" caption="{{'Acount Title' | translate}}" [width]="240"></dxi-column>
      <dxi-column dataField="account.taxNumber" caption="{{'VKN/TC Kimlik' | translate}}" [width]="120"></dxi-column>
      <dxi-column dataField="matrah" [calculateCellValue]="calculateCellValue" caption="{{ 'Matrah' | translate }}"
        [width]="80">
        <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
      </dxi-column>
      <dxi-column dataField="taxTotal" caption="{{ 'KDV Tutarı' | translate }}" [width]="80">
        <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
      </dxi-column>
      <dxi-column dataField="grandTotal" caption="{{'Tutar' | translate}}" [width]="80">
        <dxo-format type="fixedPoint" [precision]="2">
        </dxo-format>
      </dxi-column>
      <dxi-column dataField="currency" caption="Para Birimi" [width]="50">
        <dxo-lookup [dataSource]="this.currencies" displayExpr="name" valueExpr="id"></dxo-lookup>
      </dxi-column>
      <!-- <dxi-column dataField="gibCode" caption="{{'GIB Kodu' | translate}}" [width]="60"></dxi-column> -->
      <!-- <dxi-column dataField="sendDate" caption="{{'Gönderim Tarihi' | translate}}" alignment="right" dataType="datetime"
        format="dd/MM/yyyy hh:MM:ss" [width]="130"></dxi-column> -->
      <dxi-column dataField="editUser" caption="{{'Gönderen' | translate}}" [width]="80">,
        <dxo-lookup [dataSource]="this.appUserService.users" displayExpr="name" valueExpr="id">
        </dxo-lookup>
      </dxi-column>
      <!-- <dxi-column dataField="branchName" caption="{{'Şube' | translate}}" [width]="80"></dxi-column> -->

      <dxi-column dataField="description" caption="Fatura Açıklama"></dxi-column>
      <dxi-column dataField="autoConvertMsg" caption="Oto Irs-Fat"></dxi-column>

      <dxi-column dataField="dispatchNumber" caption="İrsaliye"></dxi-column>

      <dxi-column dataField="account.city" caption="İl"></dxi-column>
      <dxi-column dataField="account.town" caption="İlçe"></dxi-column>

      <!-- <dxi-column dataField="gibLast" format="dd/MM/yyyy" [width]="80" alignment="right" dataType="datetime" caption="Gib Tarihi"></dxi-column> -->
      <dxi-column dataType="number" [allowEditing]="true" dataField="accountingType" caption="{{ 'Muh. Türü' | translate }}"
        [width]="100">
        <dxo-lookup [dataSource]="typeList" displayExpr="name" valueExpr="id">
        </dxo-lookup>
      </dxi-column>
      <dxi-column dataField="account.address" caption="Adres"></dxi-column>

      <dxi-column [fixed]="false" fixedPosition="left" [allowEditing]="false" dataField="merchantStatus" caption="Durum"
        [allowFiltering]="true" [visible]="true" [hidingPriority]="4" [allowSorting]="false"
        cellTemplate="merchantStatus">
      </dxi-column>

      <dxi-column dataField="merchantDesc" caption="Açıklama"></dxi-column>

      <div *dxTemplate="let data of 'merchantStatus'">
        <span *ngIf="data.data.merchantStatus == ''" style="color:yellow">Bekliyor</span>
        <span *ngIf="data.data.merchantStatus == 'accepted'" style=" color:#29c11f;">KABUL</span>
        <span *ngIf="data.data.merchantStatus == 'rejected'" style=" color:#9c2207;">RED</span>
      </div>

      <dxi-column [fixed]="false" fixedPosition="left" [allowEditing]="false" dataField="status" caption="İptal Durumu"
        [allowFiltering]="true" [visible]="true" [hidingPriority]="4" [allowSorting]="false"
        cellTemplate="InvoiceStatus">
      </dxi-column>

      <div *dxTemplate="let data of 'InvoiceStatus'">
        <span *ngIf="data.data.status == 'cancelled'" style="color:#9c2207">IPTAL</span>
        <span *ngIf="data.data.status == 'standby'" style=" color:yellow;">BEKLİYOR</span>
      </div>

      <!-- <div *dxTemplate="let data of 'merchantStatus">
        <i *ngIf="data.data.erp == 'success'" class="far fa-check-circle" style="font-size: 18px"></i>
        <i *ngIf="data.data.erp == 'new'" class="far fa-times-circle" style="font-size: 18px"></i>
        <i *ngIf="data.data.erp == 'error'" class="fas fa-exclamation" style="font-size: 18px"></i>
      </div> -->


      <!-- <div *dxTemplate="let data of 'matrah'">
        <label>{{data.data.grandTotal - data.data.taxTotal | currency:'₺'}}</label>
     </div> -->

      <dxo-summary>
        <dxi-total-item column="grandTotal" summaryType="sum" valueFormat="#,###.00" displayFormat="{0}">
        </dxi-total-item>
        <dxi-total-item column="matrah" summaryType="sum" valueFormat="#,###.00" displayFormat="{0}">
        </dxi-total-item>
        <dxi-total-item column="taxTotal" summaryType="sum" valueFormat="#,###.00" displayFormat="{0}">
        </dxi-total-item>
        <dxi-total-item column="invoiceNumber" summaryType="count">
        </dxi-total-item>
      </dxo-summary>

    </dx-data-grid>

  </div>

</div>

<div style="height: 200px;">

</div>

<ng-template #modalHeader let-id="id" let-title="title" let-print="print">
  <div class="modal-header">
    <div class="modal-header-text">
      {{title | translate}}
    </div>
    <div style="display: flex; justify-content:flex-end; width: 100px;">
      <button tooltip="Faturayı İndir" [theme]="'light'" *ngIf="print=='on'"
        style="border: 0; background-color: transparent; color: white;" (click)="saveFatura(id)">
        <i class="fas fa-download"></i>
      </button>
      <button tooltip="Faturayı Yazdır" [theme]="'light'" *ngIf="print=='on'"
        style="border: 0; background-color: transparent; color: white;" (click)="printFatura(id)">
        <i class="fas fa-print"></i>
      </button>
      <i (click)="sendMailFromPreview()" *ngIf="print=='on'" tooltip="Mail Gönder" [theme]="'light'"
        class="far fa-paper-plane"></i>
      <div tooltip="Kapat" [theme]="'light'" class="html-invoice-header-close-icon" (click)="closeModal(id)">
        <i title="{{'Close' | translate}}" class="fas fa-times-circle cl-close-icon"></i>
      </div>
    </div>


  </div>
</ng-template>

<jw-modal id="inoviceDetailModal">
  <div *ngTemplateOutlet="modalHeader; context: { id: 'inoviceDetailModal', title: 'Ayrıntılar' }"></div>

  <div style="display: flex;
    background-color: var(--workspace-background);
    color: white;
    font-family: 'Montserrat';
    width: 900px;
    margin: auto;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;">
    <div style="width: 50%; padding: 10px; box-sizing: border-box;">
      <app-cl-text-box style="width: 100%" [(value)]="activeInvoice.invoiceNumber" label="Fatura No"></app-cl-text-box>
      <app-cl-text-box style="width: 100%" [(value)]="detail.CreateDate" label="Fatura Tarihi"></app-cl-text-box>
      <app-cl-text-box style="width: 100%" [(value)]="detail.DocumentType" label="Fatura Tipi"></app-cl-text-box>
      <app-cl-text-box style="width: 100%" [(value)]="detail.InvoiceState" label="Akış Durum"></app-cl-text-box>
    </div>

    <div style="width: 50%; padding: 10px; box-sizing: border-box;">
      <app-cl-text-box style="width: 100%" [(value)]="detail.ReceiverName" label="ETTN"></app-cl-text-box>
      <app-cl-text-box style="width: 100%" [(value)]="detail.InvoiceEnvelopeGUID" label="Zarf No"></app-cl-text-box>
      <app-cl-text-box style="width: 100%" [(value)]="activeInvoice.typeScenario" label="Senaryo"></app-cl-text-box>
      <app-cl-text-box style="width: 100%" [(value)]="detail.Code" label="Zarf Durumu"></app-cl-text-box>
    </div>
  </div>

  <div style="display: flex;
    background-color: var(--workspace-background);
    color: white;
    font-family: 'Montserrat';
    width: 900px;
    margin: auto;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    font-size: 12px;">
    <div style="width: 50%; padding: 10px;">
      <app-cl-text-box style="width: 100%" [(value)]="detail.SenderName" label="Gönderen"></app-cl-text-box>
      <app-cl-text-box style="width: 100%" [(value)]="detail.SenderTaxNumber" label="Gönderen VKN"></app-cl-text-box>
      <app-cl-text-box style="width: 100%" [(value)]="detail.SenderAlias" label="Gönderen Etiket"></app-cl-text-box>
    </div>

    <div style="width: 50%; padding: 10px">
      <app-cl-text-box style="width: 100%" [(value)]="detail.ReceiverName" label="Alıcı"></app-cl-text-box>
      <app-cl-text-box style="width: 100%" [(value)]="detail.ReceiverTaxNumber" label="Alıcı VKN"></app-cl-text-box>
      <app-cl-text-box style="width: 100%" [(value)]="detail.ReceiverAlias" label="Alıcı Etiket"></app-cl-text-box>

    </div>
  </div>

  <div style="display: flex;
    background-color: var(--workspace-background);
    color: white;
    font-family: 'Montserrat';
    width: 900px;
    margin: auto;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;">

    <table style="    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    font-size: 12px;
    empty-cells: show;">
      <tr *ngFor="let item of activeInvoice.logs">
        <td>
          {{item.type}}
        </td>
        <td>
          {{item.time | date:'medium'}}
        </td>
        <td>
          {{item.title}}
        </td>
        <td>
          {{item.message}}
        </td>
        <td>
          {{item.userId}}
        </td>
      </tr>
    </table>
  </div>

</jw-modal>

<jw-modal id="inoviceModal">
  <div *ngTemplateOutlet="modalHeader; context: { id: 'inoviceModal', title: 'Ön İzleme', print: 'on' }"></div>

  <div id="invHtml" class="modal-content html-invoice-content"></div>
</jw-modal>

<jw-modal id="invoiceEditModal">
  <div *ngTemplateOutlet="modalHeader; context: { id: 'invoiceEditModal', title: 'Düzenle' }"></div>
  <app-invoice-form (formClose)="closeModal('invoiceEditModal')" [invoice]="activeInvoice"></app-invoice-form>
</jw-modal>

<jw-modal id="inoviceError">
  <div *ngTemplateOutlet="modalHeader; context: { id: 'inoviceError', title: 'Hata' }"></div>
  <div class="modal-content error-content">{{inoviceError}}</div>>
</jw-modal>


<jw-modal id="convertInvoiceModal">
  <div *ngTemplateOutlet="modalHeader; context: { id: 'convertInvoiceModal', title: 'İrsaliye - Fatura Dönüştürme' }">
  </div>
  <app-convert-invoice (formClose)="closeModal('convertInvoiceModal')" [invoice]="activeInvoice"></app-convert-invoice>
</jw-modal>

<jw-modal id="invoiceEmail_old">
  <div *ngTemplateOutlet="modalHeader; context: { id: 'invoiceEmail', title: 'ePosta Gönder' }"></div>
  <div class="modal-content email-content">
    <app-cl-form>
      <app-cl-text-box required="true" type="email" #clMail label="E-Posta" [(value)]="invoiceSendEmailAddress">
      </app-cl-text-box>
      <button (click)="clMail.validate(clMail.value); sendMail(clMail.isValid, clMail.value)"
        class="cl-ok-button">Gönder</button>
    </app-cl-form>
  </div>>
</jw-modal>

<jw-modal id="invoiceEmail">
  <div *ngTemplateOutlet="modalHeader; context: { id: 'invoiceEmail', title: 'ePosta Gönder' }"></div>
  <div class="modal-content email-content">
    <app-cl-form>
      <app-cl-text-box required="true" #clMail label="E-Posta" [(value)]="invoiceSendEmailAddress">
      </app-cl-text-box>

      <button (click)="clMail.validate(clMail.value); sendMail(clMail.isValid, clMail.value); clMail.value = ''"
        class="cl-ok-button">Gönder</button>
    </app-cl-form>
  </div>>
</jw-modal>

<jw-modal id="invoiceLogsModal">
  <div *ngTemplateOutlet="modalHeader; context: { id: 'invoiceLogsModal', title: 'Log Kayıtları' }"></div>

  <div style="display: flex;
      background-color: var(--workspace-background);
      color: white;
      font-family: 'Montserrat';
      width: 900px;
      margin: auto;
      padding: 10px;
      box-sizing: border-box;
      margin-bottom: 10px;">

    <table style="    border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      font-size: 12px;
      empty-cells: show;">
      <tr *ngFor="let item of activeInvoice.logs">
        <td>
          {{item.type}}
        </td>
        <td>
          {{item.time | date:'medium'}}
        </td>
        <td>
          {{item.title}}
        </td>
        <td>
          {{item.message}}
        </td>
        <td>
          {{this.appUserService.getUserName(item.userId)}}
        </td>
      </tr>
    </table>
  </div>

</jw-modal>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingVisible" [showIndicator]="true"
  [showPane]="true" [message]="loadingMessage" [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>

<dx-popup class="popup" [fullScreen]="true" [showTitle]="true" title="Information" [dragEnabled]="false"
  [closeOnOutsideClick]="true" [(visible)]="popupVisible">

  <div *dxTemplate="let data of 'content'" style="max-width: 600px; margin: auto;">
    <dx-scroll-view>
      <app-invoice-form></app-invoice-form>
    </dx-scroll-view>
  </div>
</dx-popup>

<dx-popup [visible]="approveVisble" title="Faturayı Kabul Et" [width]="400" [height]="300">

  <div style="display: flex;
  flex-direction: column;
  justify-content: center;">
    <div style="margin-bottom: 10px; text-align: center;">Faturayı onaylamak için açıklama girin.</div>
    <dx-text-area style="margin-bottom: 10px;" [(value)]="arDescription"></dx-text-area>
    <dx-button (onClick)="approve()" text="Kaydet"></dx-button>
  </div>
</dx-popup>



<dx-popup [width]="400" [height]="300" [visible]="rejectVisible" title="Faturayı Redet">

  <div style="display: flex;
  flex-direction: column;
  justify-content: center;">
    <div style="margin-bottom: 10px; text-align: center;">Faturayı red etmek için açıklama girin.</div>
    <dx-text-area style="margin-bottom: 10px;" [(value)]="arDescription"></dx-text-area>
    <dx-button (onClick)="reject()" text="Kaydet"></dx-button>
  </div>
</dx-popup>

<jw-modal id="backOfficeModal" style="width: 100%;">
  <div style="width: 1200px;"></div>
  <app-backoffice-transfer *ngIf="backOfficeModalVisible" (formClose)="closeModal('backOfficeModal')"
    [invoice]="activeInvoice">
  </app-backoffice-transfer>
</jw-modal>

<!-- <dx-popup fullScreen=true [showTitle]="true" [showCloseButton]="true"
    [dragEnabled]="false" [closeOnOutsideClick]="false" [(visible)]="backofficeTransferPopupVisible"
    title="BackOffice Aktar">
    <div *dxTemplate="let data of 'content'">
        <dx-scroll-view width="100%" height="100%">
           <app-backoffice-transfer [myCallback]="theBoundCallback" [selectedInvoice]="this.selectedInovices[0]"  ></app-backoffice-transfer>
        </dx-scroll-view>
    </div>
</dx-popup> -->
