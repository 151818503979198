<div style="overflow:scroll; height:100vh;" id="campaign">
    <div class="section-title">
        {{"Program Definations" | translate}}
    </div>
    <div class="card-info">
        Damga Programı, 'n' Sayıda Damganın 1 Puana dönüşmesi ile çalışır, her ürünün kazandıracağı damga sayısı aşağıda ki listede tanımlanır ayrıcı her ürünü alabilmek için gerekli olan puan sayısı da bir sonraki listede tanımlanır.
    </div>
    <div class="card" style="min-height: 0 !important">

        <div class="card-line">
            <div class="card-title">{{"Stamp Nick" | translate}}</div>
            <div class="card-value">
                <dx-text-box [(value)]="this.stamp.stampNick" [showClearButton]="true">
                </dx-text-box>
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"Point Nick" | translate}}</div>
            <div class="card-value">
                <dx-text-box [(value)]="this.stamp.pointNick" [showClearButton]="true">
                </dx-text-box>
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"conversion rate" | translate}}</div>
            <div class="card-value">
                <dx-text-box [(value)]="this.stamp.conversionRate" [showClearButton]="true">
                </dx-text-box>
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"Banner Style" | translate}}</div>
            <div class="card-value">
                <dx-select-box [(value)]="this.stamp.bannerStyle" [items]="['1-Anime Ikon', '2-Dairesel Gauge', '3-Büyük Banner']"></dx-select-box>
            </div>
        </div>

        <dx-button [width]="90" type='default' text="{{'Save' | translate}}" (onClick)="saveCampaign()">
        </dx-button>

    </div>

    <div class="section-title">
        Damga Kazanım & Kazı Kazan Kontenjan Tablosu
    </div>

    <div class="card-info">
        Aşağıdaki listede her ürünün kazandıracağı {{stampNick}} miktarını tanımlayın.<br /> Değeri olmayan yada (0) sıfır olan ürünler dikkate alınmayacatır.
        <br/><br/>
        Kazı Kazan uygulaması için, KK alanına ücretsiz vermek istediğiniz ürün adedini giriniz.<br /> Sistem % dağılımını rasgele yapacaktır.
        KK.V alanı ücretsiz verilen miktarı göstermektedir.
    </div>

    <div class="card">
        <dx-data-grid [height]="500" style="width: 100%;" id="gridContainer" [dataSource]="this.ps.data" [showBorders]="true" keyExpr="id" (onRowUpdated)="updateRow($event)">
            <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-search-panel [visible]="true" [width]="240" placeholder="Ara..."></dxo-search-panel>
            <dxo-scrolling mode="infinite"></dxo-scrolling>

            <dxo-editing mode="cell" [allowUpdating]="true" [allowDeleting]="false" [allowAdding]="false">
            </dxo-editing>

            <dxi-column [allowEditing]="false" dataField="name"  caption="Product">
            </dxi-column>

            <dxi-column [allowEditing]="false" dataField="group" caption="{{'Group' | translate}}" [hidingPriority]="1">
                <dxo-lookup [dataSource]="this.pg.data" displayExpr="name" valueExpr="id">
                </dxo-lookup>
            </dxi-column>

            <dxi-column dataType="number" dataField="winStamp" [width]="100" caption="{{stampNick}}"></dxi-column>
            <dxi-column dataType="number" dataField="scratch" [width]="100" caption="KK"></dxi-column>
            <dxi-column dataType="number" [allowEditing]="false" dataField="scratchSpend" [width]="100" caption="KK V."></dxi-column>

        </dx-data-grid>
    </div>


    <div class="section-title">
        {{"Point Spending Table" | translate}}
    </div>

    <div class="card-info">
        Aşağıdaki listede o ürünü elde edebilmek için gerkeli {{pointNick}} miktarını tanımlayın.
        <br /> Değeri olmayan yada (0) sıfır olan ürünler dikkate alınmayacatır.


    </div>

    <div class="card">
        <dx-data-grid [height]="500" style="width: 100%;" id="gridContainer" [dataSource]="this.ps.data" [showBorders]="true" keyExpr="id" (onRowUpdated)="updateRow($event)">
            <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-search-panel [visible]="true" [width]="240" placeholder="Ara..."></dxo-search-panel>
            <dxo-scrolling mode="infinite"></dxo-scrolling>

            <dxo-editing mode="cell" [allowUpdating]="true" [allowDeleting]="false" [allowAdding]="false">
            </dxo-editing>

            <dxi-column [allowEditing]="false" dataField="name" [width]="300" caption="Product">
            </dxi-column>

            <dxi-column [allowEditing]="false" dataField="group" caption="{{'Group' | translate}}" [hidingPriority]="1" [width]="300">
                <dxo-lookup [dataSource]="this.pg.data" displayExpr="name" valueExpr="id">
                </dxo-lookup>
            </dxi-column>

            <dxi-column dataField="spendPoints" [width]="140" caption="{{pointNick}}"></dxi-column>

        </dx-data-grid>
    </div>


    <dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#campaign' }" [(visible)]="loadingVisible" [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false">

    </dx-load-panel>


    <div style="height: 300px;">

    </div>

</div>