<div class="app-table-cover">

    <!-- TABLE EDIT MENU -->
    <div class="table-menu" *ngIf="drag">

        <div class="menu-button" (click)="showAddGroup()">
            <i class="material-icons-outlined md-24">library_add</i> {{'Add Groups' | translate}}
        </div>
        <dx-select-box (onValueChanged)="groupSelected($event)" [dataSource]="ts.tableGroups" displayExpr="tableGroup" valueExpr="tableGroupID" [value]="tableGroupID"></dx-select-box>
        <div class="menu-button" (click)="deleteGroup()">
            <i class="material-icons-outlined md-24">library_add</i> {{'Delete Groups' | translate}}
        </div>
        <div class="menu-button" (click)="showAddTable()">
            <i class="material-icons-outlined md-24">note_add</i> {{'Add Table' | translate}}
        </div>
        <div class="menu-button" (click)="addPlant()">
            <i class="material-icons-outlined md-24">add_photo_alternate</i> {{'Add Plant' | translate}}
        </div>
        <div (click)="delete()" class="menu-button">
            <i class="material-icons-outlined md-24">delete_forever</i> {{'Delete Table' | translate}}
        </div>
        <div style="width:100px; padding:10px; text-align:center">
            {{'Rotation' | translate}}
            <dx-slider (onValueChanged)="sliderValueChanged($event)" [min]="0" [max]="360" [(value)]="sliderValue"></dx-slider>
        </div>
        <div style="width:100px; padding:10px; text-align:center">
            {{'Zoom' | translate}}
            <dx-slider (onValueChanged)="zoomValueChanged($event)" [min]="0" [max]="10" [(value)]="zoomValue"></dx-slider>
        </div>
        <div (click)="removeDrag()" class="menu-button">
            <i class="material-icons-outlined md-24">delete_forever</i> {{'Remove Drag' | translate}}
        </div>

    </div>

    <!-- TABLE GROUP SELECTION IF TABLE EDIT DISABLED -->
    <app-button-menu *ngIf="!drag" type="row" (clicked)="selectGroup($event)" [menu]="tables"></app-button-menu>


    <!-- TABLES CANVAS FOR DESIGN -->
    <div class="drag-cover" id="drag-cover">
        <div *ngIf="drag">
            <div *ngIf=" this.ts.selectedTableGroup">
                <dx-text-box stylingMode="filled" [value]="this.ts.selectedTableGroup.tableGroup" [showClearButton]="true" placeholder="Enter full name" valueChangeEvent="focusout" (onValueChanged)="groupNameChanged($event)"></dx-text-box>
                <div *ngFor="let item of ts.selectedTableGroup.tables" (click)="selectTable(item)" cdkDrag (cdkDragEnded)="dragEnd($event)" (cdkDragStarted)="dragStart($event)" (cdkDragMoved)="dragMove($event)" class="image" id="table-{{item.id}}">
                    <span *ngIf="!item.isDecorationObject" [ngStyle]="{'background-color':item.selected ? 'var(--secondary)': 'var(--primary)' }" class="table-text">{{item.name}}</span>
                    <img id="image-{{item.id}}" [ngClass]="{selectedTableImage: item.selected}" [ngClass]="{tableImage: item.selected == false}" class="table-image" src="{{item.icon}}" alt="" />
                    <img id="image-ok-{{item.id}}" class="person-1" src="/assets/myicons/ok.png" />
                </div>
            </div>
        </div>

        <!-- DESIGN TABLES CANVAS FOR POS-->
        <div *ngIf="!drag && ts.selectedTableGroup && false">
            <div *ngFor="let item of ts.selectedTableGroup.tables" (click)="selectTable(item)" (cdkDragEnded)="dragEnd($event)" (cdkDragStarted)="dragStart($event)" (cdkDragMoved)="dragMove($event)" class="image image-anim" id="table-{{item.id}}">
                <span *ngIf="!item.isDecorationObject" [ngStyle]="{'background-color':isOpen(item) ? 'var(--secondary)': 'var(--primary)' }" class="table-text">{{item.name}} {{isLocked(item)}}</span>
                <img id="image-{{item.id}}" [ngClass]="{openTableImage: isOpen(item)}" [ngClass]="{tableImage: isOpen(item) == false}" class="table-image" src="{{item.icon}}" alt="" />
                <img id="image-ok-{{item.id}}" class="person-1" src="/assets/myicons/ok.png" />
            </div>
        </div>

        <!-- GRID TABLES CANVAS FOR POS-->
        <div *ngIf="!drag && ts.selectedTableGroup" class="grid-table">
            <div *ngFor="let item of ts.selectedTableGroup.tables" (click)="selectTable(item)">
                <!-- <span *ngIf="!item.isDecorationObject" [ngStyle]="{'background-color':isOpen(item) ? 'var(--secondary)': 'var(--primary)' }" class="table-text">{{item.name}} {{isLocked(item)}}</span>
                <img id="image-{{item.id}}" [ngClass]="{openTableImage: isOpen(item)}" [ngClass]="{tableImage: isOpen(item) == false}" class="table-image" src="{{item.icon}}" alt="" />
                <img id="image-ok-{{item.id}}" class="person-1" src="/assets/myicons/ok.png" /> -->
                <div class="grid-table-item" [ngStyle]="{'background-color':isOpen(item) ? 'var(--secondary)': 'var(--product-group-background)' }">
                    {{item.name}} {{isLocked(item)}}
                    <dx-load-indicator *ngIf="isClosed(item)" id="large-indicator" height="64" width="64"></dx-load-indicator>
                </div>
            </div>
        </div>


    </div>


</div>




<dx-popover position="center" [width]="400" [(visible)]="popVisible">
    <div *dxTemplate="let data  of 'content'">


        <div *ngIf="editTemplate == 'table'" style="height:200px; align-items:center; width:350px;color:var(--primary-text); font-family: var(--product-font); background-color:var(--primary); z-index:1000; display:flex; flex-direction: column">
            <div style="width:100%; padding:10px; text-align:center">
                {{'Table Name' | translate}}
                <dx-text-box #tableName value="" [showClearButton]="true">
                </dx-text-box>
            </div>
            <div style="width:100%; padding:10px; text-align:center">
                {{'Table Type' | translate}}
                <dx-radio-group #tableType class="radio-group" [items]="tableTypes" [value]="tableTypes[0]" layout="horizontal">
                </dx-radio-group>
            </div>
            <dx-button (click)="addTable(tableName.value, tableType.value)" text="Kaydet">Oluştur</dx-button>
        </div>


        <div *ngIf="editTemplate == 'group'" style="height:200px; align-items:center; width:350px;color:var(--primary-text); font-family: var(--product-font); background-color:var(--primary); z-index:1000; display:flex; flex-direction: column">
            <div style="width:100%; padding:10px; text-align:center">
                {{'Group Name' | translate}}
                <dx-text-box #groupName value="" [showClearButton]="true">
                </dx-text-box>
            </div>
            <dx-button (click)="addTableGroup(groupName.value)" text="Kaydet">Oluştur</dx-button>
        </div>




    </div>
</dx-popover>