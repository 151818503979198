import { Component, OnInit } from '@angular/core';
import { PayTypesService, paymentType } from '../services/pay-types.service';
import { IdGeneratorService } from 'cloud-labs-core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-pay-types',
  templateUrl: './pay-types.component.html',
  styleUrls: ['./pay-types.component.scss']
})
export class PayTypesComponent implements OnInit {


  //https://exchangeratesapi.io/
  //https://api.exchangeratesapi.io/latest?base=USD
  //https://api.exchangeratesapi.io/history?start_at=2018-01-01&end_at=2018-09-01
  //https://restcountries.eu/rest/v2

  paytypes
  test: string
  baseCurrency: string
   
  
  payTypes: paymentType[] = []
  constructor(public pts: PayTypesService, private ig: IdGeneratorService, private http: HttpClient) {
   
    this.pts.load().then(o => {
      this.payTypes = this.pts.data
    })

  }
  

  add() {
    let pt = new paymentType()
    pt.id = this.ig.generate()
    this.payTypes.push(pt)
  }

  ngOnInit() {
    this.pts.load()
  }

  initRow(e) {

  }

  updatedRow(e) {
    this.pts.upsert(e.data)
  }

  insertedRow(e) {
    this.pts.upsert(e.data)
  }

  deleteRow(e) {
    this.pts.delete(e.data)
  }

  save() {
    this.payTypes.forEach(e => {
      this.pts.upsert(e)
    })
  }



}


declare module namespace {

  export interface Currency {
    code: string;
    name: string;
    symbol?: any;
  }

  export interface Language {
    iso639_1: string;
    iso639_2: string;
    name: string;
    nativeName: string;
  }

  export interface Translations {
    de: string;
    es: string;
    fr: string;
    ja: string;
    it: string;
    br: string;
    pt: string;
    nl: string;
    hr: string;
    fa: string;
  }

  export interface RootObject {
    name: string;
    topLevelDomain: string[];
    alpha2Code: string;
    alpha3Code: string;
    callingCodes: string[];
    capital: string;
    altSpellings: string[];
    region: string;
    subregion: string;
    population: number;
    latlng: number[];
    demonym: string;
    area: number;
    gini: number;
    timezones: string[];
    borders: string[];
    nativeName: string;
    numericCode: string;
    currencies: Currency[];
    languages: Language[];
    translations: Translations;
    flag: string;
    regionalBlocs: any[];
    cioc: string;
  }

}


