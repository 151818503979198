import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { CloudDbService, IdGeneratorService } from "cloud-labs-core";
import { DxDataGridComponent, DxFormComponent } from "devextreme-angular";
import notify from "devextreme/ui/notify";
var that;
import {
  Account,
  Invoice,
  invoiceRow,
  InvoiceService,
} from "../services/invoice.service";
import { GlobalsService } from "src/app/services/globals.service";
import { Branch } from "../models/global-model";

@Component({
  selector: "app-create-invoice",
  templateUrl: "./create-invoice.component.html",
  styleUrls: ["./create-invoice.component.scss"],
})
export class CreateInvoiceComponent implements OnInit {
  @ViewChild(DxFormComponent, { static: false }) dxForm: DxFormComponent;
  @ViewChild(DxDataGridComponent, { static: false })
  dxDataGrid: DxDataGridComponent;
  editRowKey: number;
  vknTc: 10;
  branchCode: string;
  branchName: string;
  rows: invoiceRow[] = [];
  documentTypes = [];
  documentType: string = "eInvoice";
  sgkPrim = 0;
  stopaj = 0;
  borsaTescilBedeli = 0;
  scenarios = [];
  stoppages = [];
  exemptions = [];
  isVisible = false;
  invoiceTypes = [];
  tevkifatOranlari = [];
  units = [];
  loadingVisible: boolean = false;
  loadingMessage: string = "Bekleyiniz...";
  constructor(
    public invoiceService: InvoiceService,
    private IdGeneratorService: IdGeneratorService,
    private db: CloudDbService,
    private glb: GlobalsService
  ) {
    that = this;
  }

  async ngOnInit(): Promise<void> {
    this.init();
    this.loadStoppages();
    this.calculateCellValueKDVTutar =
      this.calculateCellValueKDVTutar.bind(this);
    this.calculateCellValueNetTutar =
      this.calculateCellValueNetTutar.bind(this);
    this.calculateCellValueToplamTutar =
      this.calculateCellValueToplamTutar.bind(this);
    this.calculateCellValueTekifKDV =
      this.calculateCellValueTekifKDV.bind(this);
  }
  init() {
    this.initDocumentTypes();
    this.initScenarios("");
    this.initInvoceTypes("");
    this.initUnits();
    this.initTevkifatOranlari(10);
  }

  loadStoppages() {
    this.db
      .getCollectionToPromise({
        _db: "helpers",
        col: "digital-invoice-reasons",
      })
      .then((o) => {
        this.stoppages = o.filter((x) => x.invoiceType == "stoppage");
        this.exemptions = o.filter((x) => x.invoiceType == "exemptions");

        this.stoppages.forEach((o) => {
          o.displayValue = o["id"] + " " + o["rate"] + " " + o["name"];
          o.value = o["rate"].substring(0, 1) / 10;
        });

        this.exemptions.forEach((o) => {
          o.displayValue = o["id"] + " " + o["name"];
        });
      });
  }

  getBrancList() {
    this.invoiceService.getBranchList().subscribe((b: Branch[]) => {
      this.invoiceService.branchList = b;
    });
  }

  initUnits() {
    this.units = [
      // { id: "B32", name: "Kilogram-Metre Kare" },
      // { id: "C62", name: "Adet (Unit)" },
      // { id: "CCT", name: "Ton Başına Taşıma Kapasitesi" },
      // { id: "PR", name: "Çift" },
      // { id: "D30", name: "Brüt Kalori Değeri" },
      // { id: "D40", name: "Bin Litre" },
      // { id: "GFI", name: "Fissile Izotop Gramı" },
      // { id: "GRM", name: "Gram" },
      // { id: "GT", name: "Gross Ton" },
      // { id: "CEN", name: "Yüz Adet" },
      // { id: "KPO", name: "Kilogram Potasyum Oksit" },
      // { id: "MND", name: "Kurutulmuş Net Ağırlıklı Kilogramı" },
      // { id: "31", name: "Kilogram-Adet" },
      // { id: "KFO", name: "Difosfor Pentaoksit Kilogramı" },
      // { id: "KGM", name: "Kilogram" },
      // { id: "KHY", name: "Hidrojen Peroksit Kilogramı" },
      // { id: "KMA", name: "Metil Aminlerin Kilogramı" },
      // { id: "KNI", name: "Azotun Kilogramı" },
      // { id: "KPH", name: "Kilogram Potasyum Hidroksit" },
      // { id: "KSD", name: "%690 Kuru Ürün Kilogramı" },
      // { id: "KSH", name: "Sodyum Hidroksit Kilogramı" },
      // { id: "KUR", name: "Uranyum Kilogramı" },
      // { id: "D32", name: "Terawatt Saat" },
      // { id: "GWH", name: "Gigawatt Saat" },
      // { id: "MWH", name: "Megawatt Saat (1000 kW.h)" },
      // { id: "KWH", name: "Kilowatt Saat" },
      // { id: "KWT", name: "Kilowatt" },
      // { id: "LPA", name: "Saf Alkol Litresi" },
      // { id: "LTR", name: "Litre" },
      // { id: "MTK", name: "Metre Kare" },
      // { id: "DMK", name: "Desimetre Kare" },
      // { id: "MTQ", name: "Metre Küp" },
      // { id: "MTR", name: "Metre" },
      // { id: "NCL", name: "Hücre Adedi" },
      // { id: "CTM", name: "Karat" },
      // { id: "SM3", name: "Standart Metreküp" },
      // { id: "R9", name: "Bin Metre Kup" },
      // { id: "SET", name: "Set" },
      // { id: "T3", name: "Bin Adet" },
      { id: "KL", name: "Koli" },
      { id: "KT", name: "Kutu" },
      { id: "PK", name: "Paket" },
      { id: "KG", name: "Kilo" },
      { id: "GR", name: "Gram" },
      { id: "LT", name: "Litre" },
      { id: "ML", name: "Mililitre" },
      { id: "CL", name: "Santilitre" },
      { id: "MT", name: "Metre" },
      { id: "AD", name: "Adet" },
      { id: "PR", name: "Porsiyon" },
      { id: "TP", name: "Top" },
      { id: "CV", name: "Çuval" },
      { id: "TN", name: "Teneke" },
      { id: "KWH", name: "Kilowatt Saat" }
    ];
  }
  initTevkifatOranlari(max: number) {
    for (let i = 1; i < max; i++) {
      this.tevkifatOranlari.push({ id: i / max, name: `${i}/${max}` });
    }
  }
  initInvoceTypes(type: String) {
    this.invoiceTypes = [];
    if (type === "EProducerReceipt") {
      this.invoiceTypes.push({ id: "sale", name: "SATIŞ" });
    } else {
      this.invoiceTypes.push({ id: "sale", name: "SATIŞ" });
      this.invoiceTypes.push({ id: "return", name: "İADE" });
      this.invoiceTypes.push({ id: "stoppage", name: "TEVKİFAT" });
      this.invoiceTypes.push({ id: "exemptions", name: "İSTİSNA" });
    }

    // this.invoiceTypes.push({ id: "export", name: "İHRAÇ KAYITLI" });
    // this.invoiceTypes.push({ id: "special", name: "ÖZEL MATRAH" });
    // this.invoiceTypes.push({ id: "ssn", name: "SGK" });
    // this.invoiceTypes.push({ id: "dispatch", name: "SEVK" });
  }
  initScenarios(type: String) {
    this.scenarios = [];
    if (type === "EProducerReceipt") {
      this.scenarios.push({ id: "base", name: "TEMEL" });
    } else {
      this.scenarios.push({ id: "base", name: "TEMEL" });
      this.scenarios.push({ id: "merchant", name: "TİCARİ" });
    }
  }

  initDocumentTypes() {
    this.documentTypes.push({ id: "eInvoice", name: "E-FATURA" });
    this.documentTypes.push({ id: "eArchive", name: "E-ARŞİV" });
    this.documentTypes.push({ id: "EProducerReceipt", name: "E-MÜHTAHSİL" });
  }

  documentTypeChanged(e) {
    if (e.selectedItem.id === "EProducerReceipt") {
      that.isVisible = true;
      that.vknTc = 11;
    } else {
      that.isVisible = false;
      that.vknTc = 10;
    }
    that.initInvoceTypes(e.selectedItem.id);
    that.initScenarios(e.selectedItem.id);
  }

  ngAfterViewInit() {
    this.getBrancList();
  }

  changeSGK(e) {
    if (e.value == "") {
      return;
    } else {
      that.sgkPrim = e.value;
    }
  }

  chanceStopage(e) {
    if (e.value == "") {
      return;
    } else {
      that.stopaj = e.value;
    }
  }

  changeStopaj(e) {
    if (e.value == "") {
      return;
    } else {
      that.borsaTescilBedeli = e.value;
    }
  }

  taxno(e) {
    if (e.value == "") {
      that.dxForm.formData.account.accountName = "";
      return;
    }
    that.loadingMessage = "Yükleniyor...";
    that.loadingVisible = true;
    that.db
      .getCollection({
        col: "accounts",
        _db: "helpers",
        _filter: { taxNo: e.value, documentType: "eInvoice" },
      })
      .subscribe((o) => {
        if (o.length > 0) {
          that.dxForm.formData.account.accountName = o[0].accountName;
        }
      });
    that.loadingVisible = false;
  }

  roundNumber(amount: number) {
    return Math.round((amount + Number.EPSILON) * 100) / 100;
  }

  addHours(date, hours) {
    date.setHours(date.getHours() + hours);

    return date;
  }

  save() {
    debugger;
    console.log(this.tevkifatOranlari);
    console.log(this.stoppages);
    console.log("Form:", this.dxForm.formData);

    if (this.dxForm.instance.validate().isValid) {
      const invoice: Invoice = { ...new Invoice(), ...this.dxForm.formData };
      debugger;
      if (this.invoiceService.branchList.length === 0) {
        invoice.branchCode = "0";
        invoice.branchName = this.glb.activeBrand;
      } else {
        invoice.branchName = this.invoiceService.branchList.find(
          (x) => x.branchCode == invoice.branchCode
        ).branchName;
        invoice.branchCode = invoice.branchCode;
      }
      invoice.id = this.IdGeneratorService.generateMaxi();
      invoice.account = new Account();

      invoice.account.accountName = this.dxForm.formData.account.accountName;
      invoice.account.taxNumber = this.dxForm.formData.account.taxNumber;
      invoice.invoiceDate = this.addHours(invoice.invoiceDate, 3);
      if (invoice.invoiceType == "stoppage") {
        invoice.stoppageRate = this.stoppages.find(
          (x) => x.id == this.dxForm.formData.stoppageId
        ).rate;
        invoice.stoppageReason = this.dxForm.formData.stoppageId;
      }
      invoice.uuid = "";
      invoice.reference = this.IdGeneratorService.generate();
      console.log("invoice:", invoice);

      const invoiceRows: any[] = this.dxDataGrid.dataSource as any[];
      if (invoiceRows.length === 0) {
        notify("Fatura Satır Kaydı Yok !", "error");
        return;
      }

      // submit codes here
      invoice.source = "erp";
      invoice.status = "standby";
      invoice.direction = "outBound";
      invoice.stoppageAmount = 0;
      invoiceRows.forEach((el) => {
        debugger;
        el.stoppageAmount = this.roundNumber(
          this.calculateCellValueTekifKDV(el)
        );
        el.isHide = false;
        el.discount = this.calculateCellValueDiscountPrice(el);
        invoice.grandTotal = invoice.grandTotal + el.grandTotal;
        invoice.discountTotal = invoice.discountTotal + el.discount;
        invoice.taxTotal += el.taxTotal;
        invoice.stoppageAmount += el.stoppageAmount;
        invoice.invoiceRows.push(el);
      });

      debugger;
      if (invoice.documentType == "EProducerReceipt") {
        if (this.sgkPrim > 0) {
          let invRow = new invoiceRow();
          invRow.code = "SGK_PRIM";
          invRow.name = "SGK PRIMI";
          invRow.isHide = true;
          invRow.cityTaxTotal = 0;
          invRow.discount = 0;
          invRow.total = this.sgkPrim;
          invRow.quantity = 1;
          invRow.unitName = "AD";
          invRow.unitPrice = this.sgkPrim;
          invRow.taxRate = 0;
          invRow.grandTotal = this.sgkPrim;
          invRow.taxTotal = 0;
          // invoice.grandTotal -= this.sgkPrim
          invoice.invoiceRows.push(invRow);
        }

        if (this.stopaj > 0) {
          debugger;
          let invRow = new invoiceRow();
          invRow.code = "0003";
          invRow.name = "STOPAJ";
          invRow.isHide = true;
          invRow.cityTaxTotal = 0;
          invRow.discount = 0;
          invRow.total =
            invoice.invoiceRows
              .filter((x) => x.isHide === false)
              .reduce((sum, item) => sum + item.total, 0) * this.stopaj;
          invRow.quantity = 1;
          invRow.unitName = "AD";
          invRow.unitPrice =
            invoice.invoiceRows
              .filter((x) => x.isHide === false)
              .reduce((sum, item) => sum + item.total, 0) * this.stopaj;
          invRow.taxRate = 0;
          invRow.grandTotal =
            invoice.invoiceRows
              .filter((x) => x.isHide === false)
              .reduce((sum, item) => sum + item.total, 0) * this.stopaj;
          invRow.taxTotal = 0;
          // invoice.grandTotal -= invRow.grandTotal
          invoice.invoiceRows.push(invRow);
        }

        if (this.borsaTescilBedeli > 0) {
          let invRow = new invoiceRow();
          invRow.code = "8001";
          invRow.name = "BORSA TESCİL BEDELİ";
          invRow.isHide = true;
          invRow.quantity = 1;
          invRow.cityTaxTotal = 0;
          invRow.discount = 0;
          invRow.unitName = "AD";
          invRow.total = this.borsaTescilBedeli;
          invRow.unitPrice = this.borsaTescilBedeli;
          invRow.taxRate = 0;
          invRow.grandTotal = this.borsaTescilBedeli;
          // invoice.grandTotal -= this.borsaTescilBedeli
          invRow.taxTotal = 0;
          invoice.invoiceRows.push(invRow);
        }
        debugger;
        //  invoice.grandTotal -=  invoice.invoiceRows.filter(x => x.isHide === true).reduce((sum, item) => sum + item.total, 0)
      }

      delete invoice.borsaTescilBedeli;
      delete invoice.sgkPrim;
      delete invoice.stopaj;
      debugger;

      this.invoiceService.saveInvoice(invoice).subscribe(
        (o) => {
          console.log("Saved: ", o);
          this.rows = [];
          // this.dxForm.instance.resetValues();
          notify("Fatura kaydedildi.", "success", 3000);
        },
        (err: HttpErrorResponse) => {
          notify("İşlem Başarısız.", "error", 3000);
        },
        () => {
          this.dxForm.instance.resetValues();
          this.rows = [];
        }
      );
      console.log("submit Invoice:", invoice);
    }
  }

  onEditingStart(e) {
    this.editRowKey = e.key;
    console.log("onEditingStart:", e);
    if (e.column.dataField === "discountPrice" && e.data.discount > 0) {
      e.cancel = true;
    } else if (e.column.dataField === "discount" && e.data.discountPrice > 0) {
      e.cancel = true;
    }
  }
  getCellValue(key: string) {
    const editRowIndex = this.dxDataGrid.instance.getRowIndexByKey(
      this.editRowKey
    );
    if (editRowIndex >= 0) {
      return this.dxDataGrid.instance.cellValue(editRowIndex, key);
    }
    return null;
  }
  validationCallbackTCKN(e) {
    // console.log("validationCallbackTCKN:", e);
    if (e.value.length === 11) {
      return +e.value % 2 === 0;
    }
    return true;
  }
  validationCallbackInvoiceRows(e) {
    console.log("validationCallbackInvoiceRows:", e);
    const invoiceRows: any[] = this.dxDataGrid.dataSource as any[];
    return false;
  }
  onInitNewRow(e) {
    // console.log(editingChange"onInitNewRow:", e);
    e.data = new invoiceRow();
    e.data.discount = 0;
    e.data.discountPrice = 0;
  }
  onRowInserting(e) {
    console.log("onRowInserting:", e);
  }
  onRowValidating(e) {
    // console.log("onRowInserting:", e);
    if (e?.brokenRules.length) {
      notify(e.brokenRules[0]?.message, "error");
    }
  }
  onRowInserted(e) {
    // console.log("onRowInserted:", e);
    e.data.total = this.calculateCellValueNetTutar(e.data);
    e.data.taxTotal = this.roundNumber(this.calculateCellValueKDVTutar(e.data));
    e.data.grandTotal = this.calculateCellValueToplamTutar(e.data);
    e.data.tekifKDV = this.roundNumber(this.calculateCellValueTekifKDV(e.data));
  }
  calculateCellValueNetTutar(e) {
    // console.log("calculateCellValueNetTutar:", e);
    if (
      e.quantity === null ||
      e.quantity === undefined ||
      e.unitPrice === null ||
      e.unitPrice === undefined ||
      e.discount === null ||
      e.discount === undefined
    ) {
      return 0;
    }
    const total: number = +e.quantity * +e.unitPrice;
    return total - (total / 100) * this.calculateCellValueDiscount(e);
  }
  calculateCellValueKDVTutar(e): number {
    // console.log("calculateCellValueKDVTutar:", e);

    if (e.taxRate === null || e.taxRate === undefined) {
      return 0;
    }

    return (+e.taxRate / 100) * this.calculateCellValueNetTutar(e);
  }
  calculateCellValueToplamTutar(e) {
    // console.log("calculateCellValueToplamTutar:", e);

    if (
      e.tekifKDV === null ||
      e.tekifKDV === undefined ||
      this.dxForm?.formData?.invoiceType !== "stoppage"
    ) {
      return (
        this.calculateCellValueKDVTutar(e) +
        this.calculateCellValueNetTutar(e) -
        this.calculateCellValueTekifKDV(e)
      );
    }

    return this.calculateCellValueNetTutar(e) + +e.tekifKDV;
  }
  calculateCellValueTekifKDV(e): number {
    if (
      this.dxForm?.formData?.invoiceType !== "stoppage" ||
      this.dxForm?.formData?.stoppageId === null ||
      this.dxForm?.formData?.stoppageId === undefined
    ) {
      return 0;
    }

    return (
      +this.calculateCellValueKDVTutar(e) *
      +this.stoppages.find((x) => x.id == this.dxForm.formData.stoppageId).value
    );
  }
  calculateCellValueDiscountPrice(e): number {
    if (e.discount === null || e.discount === undefined || +e.discount <= 0) {
      return e.discountPrice;
    }
    if (e.unitPrice === null || e.unitPrice === undefined) {
      return 0;
    }
    return +(e.unitPrice * e.quantity) * (+e.discount / 100);
  }
  calculateCellValueDiscount(e): number {
    if (
      e.discountPrice === null ||
      e.discountPrice === undefined ||
      +e.discountPrice <= 0
    ) {
      return e.discount;
    }
    if (
      e.unitPrice === null ||
      e.unitPrice === undefined ||
      +e.unitPrice === 0
    ) {
      return 0;
    }
    return +e.discountPrice * ((100 / +e.unitPrice) * e.quantity);
  }
}
