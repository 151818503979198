import { Component, OnInit, Input, ElementRef, Renderer2, ViewChild, Output } from '@angular/core';
import { IdGeneratorService } from 'cloud-labs-core';
import { EventEmitter } from '@angular/core';


@Component({
  selector: 'app-cl-toggle',
  templateUrl: './cl-toggle.component.html',
  styleUrls: ['./cl-toggle.component.scss']
})

export class ClToggleComponent implements OnInit {

  @Input() value: string
  @Output() valueChange = new EventEmitter()

  @Input() type : type = "ios"
  @Input() label = ""
  @Input() on = "ON"
  @Input() off = "OFF"
 

  cb5
  constructor(private ig: IdGeneratorService, private elementRef: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.cb5 = this.ig.generate()
  }

  changed(e) {
    this.valueChange.emit(e.target.checked)
  }

}

type type = 'light' | 'ios' | 'flat' | 'skewed' | 'flip'