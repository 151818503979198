<div style="overflow:scroll; height:100vh; width: Calc(100% - 50px);">


  <div class="invoice-toolbar-inset">
    <!--E-INVOICE, POS SOURCE STATS-->
    <div style="display: flex; justify-content: space-around;">
      <div>
        <div class="stat-sub-cover">ENTEGRASYON DURUMU</div>
        <div style="display: flex; justify-content: space-around;width: 500px;">
          <div *ngFor="let itemA of this.stats">
            <div (click)="loadGrid(itemA)" class="stat-cover">
              <div class="stat-text">
                {{itemA.getName(itemA.id)}}
              </div>
              <div class="banner1 {{getCSS(itemA)}}" *ngIf="true">
                {{itemA.count}}
              </div>
              <div class="banner1" *ngIf="false">
                <img style="width: 30px; height:30px;" src="/assets/loading2.gif" />
              </div>
            </div>
          </div>
          <div style="display: flex;">
            <div (click)="integration()" class="cl-ok-button">Aktar</div>
            <div (click)="logs()" class="cl-cancel-button">Loglar</div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <dx-data-grid width="95%" #grid height="640px" style="font-size:12px;margin-left:auto; margin-right: auto;"
    id="gridContainer" [dataSource]="is.data" [hoverStateEnabled]="true" [columnHidingEnabled]="false" keyExpr="id"
    [allowColumnReordering]="true" [(selectedRowKeys)]="selectedRows"
    (onSelectionChanged)="selectionChangedHandler($event)" [columns]="['id', 'name', 'group']" [showBorders]="true">

    <dxo-selection mode="multiple">
    </dxo-selection>
    <dxo-paging [pageSize]="20"></dxo-paging>

    <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
    <dxo-filter-row [visible]="false" [applyFilter]=""></dxo-filter-row>

    <!-- <dxo-search-panel [visible]="true" [width]="240" placeholder="Ara..."></dxo-search-panel> -->


    <dxi-column cellTemplate="erpTemplate" dataField="erp" caption="{{'Erp' | translate}}" [width]="50">
    </dxi-column>

    <dxi-column dataField="invoiceDate" caption="{{'Date' | translate}}" alignment="right" dataType="datetime"
      format="dd/MM/yyyy" [width]="80"></dxi-column>
    <dxi-column dataField="invoiceNumber" caption="{{'Invoice ID' | translate}}" [width]="140"></dxi-column>
    <!-- <dxi-column dataField="uuid" caption="{{'uuid' | translate}}" [width]="80"></dxi-column> -->

    <dxi-column dataField="invoiceType" caption="{{'Type' | translate}}" [width]="60">
      <dxo-lookup [dataSource]="this.is.invoiceTypes" displayExpr="name" valueExpr="id">
      </dxo-lookup>
    </dxi-column>
    <dxi-column dataField="account.accountName" caption="{{'Acount Title' | translate}}" [width]="240"></dxi-column>
    <dxi-column dataField="account.taxNumber" caption="{{'VKN/TC Kimlik' | translate}}" [width]="120"></dxi-column>
    <dxi-column dataField="grandTotal" caption="{{'Tutar' | translate}}" [width]="80"></dxi-column>
    <dxi-column dataField="gibCode" caption="{{'GIB Kodu' | translate}}" [width]="60"></dxi-column>
    <dxi-column dataField="sendDate" caption="{{'Gönderim Tarihi' | translate}}" alignment="right" dataType="datetime"
      format="dd/MM/yyyy hh:MM:ss" [width]="130"></dxi-column>
    <dxi-column dataField="editUser" caption="{{'Gönderen' | translate}}" [width]="80">,
      <dxo-lookup [dataSource]="this.appUserService.users" displayExpr="name" valueExpr="id">
      </dxo-lookup>
    </dxi-column>
    <dxi-column dataField="branchName" caption="{{'Şube' | translate}}" [width]="80"></dxi-column>


    <div *dxTemplate="let data of 'erpTemplate'">
        <i *ngIf="data.data.erp == 'success'" class="far fa-check-circle" style="font-size: 18px"></i>
        <i *ngIf="data.data.erp == 'new'" class="far fa-times-circle" style="font-size: 18px"></i>
        <i *ngIf="data.data.erp == 'error'" class="fas fa-exclamation" style="font-size: 18px"></i>
      </div>

  </dx-data-grid>

</div>



<ng-template #modalHeader let-id="id" let-title="title" let-print="print">
  <div class="modal-header">
    <div class="modal-header-text">
      {{title | translate}}
    </div>
    <div style="display: flex; justify-content:flex-end; width: 100px;">
      <div tooltip="Kapat" [theme]="'light'" class="html-invoice-header-close-icon" (click)="closeModal(id)">
        <i title="{{'Close' | translate}}" class="fas fa-times-circle cl-close-icon"></i>
      </div>
    </div>
  </div>
</ng-template>


<jw-modal id="inoviceError">
  <div *ngTemplateOutlet="modalHeader; context: { id: 'inoviceError', title: 'Hata' }"></div>
  <div class="modal-content error-content">{{inoviceError}}</div>>
</jw-modal>


<jw-modal id="invoiceLogsModal">
  <div *ngTemplateOutlet="modalHeader; context: { id: 'invoiceLogsModal', title: 'Log Kayıtları' }"></div>

  <div style="display: flex;
      background-color: var(--workspace-background);
      color: white;
      font-family: 'Montserrat';
      width: 700px;
      margin: auto;
      padding: 10px;
      box-sizing: border-box;
      margin-bottom: 10px;">

    <table style="    border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      font-size: 12px;
      empty-cells: show;">
      <tr *ngFor="let item of activeInvoice.logs">        
        <td>
          {{item.type}}
        </td>
        <td>
          {{item.time | date:'medium'}}
        </td>
        <td>
          {{item.title}}
        </td>
        <td>
          {{item.message}}
        </td>
        <td>
          {{this.appUserService.getUserName(item.userId)}}
        </td>
      </tr>
    </table>
  </div>

</jw-modal>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingVisible" [showIndicator]="true"
  [showPane]="true" [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>