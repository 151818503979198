import { Component, OnInit } from "@angular/core";
import { InvoiceService, InvoiceTaxReport } from "../services/invoice.service";

@Component({
  selector: "app-invoice-tax-report",
  templateUrl: "./invoice-tax-report.component.html",
  styleUrls: ["./invoice-tax-report.component.scss"],
})
export class InvoiceTaxReportComponent implements OnInit {
  startDate: Date = new Date();
  endDate: Date = new Date();
  selectedRows: any[] = [];
  data: InvoiceTaxReport[] = [];
  constructor(public inoviceSrc: InvoiceService) {}

  ngOnInit(): void {}

  loadData() {
    // Implement the logic to load data based on startDate and endDate
    console.log("Loading data from", this.startDate, "to", this.endDate);
    this.inoviceSrc
      .getInvoiceTaxReports({
        startDate: this.startDate,
        endDate: this.endDate,
      })
      .subscribe((response) => {
        this.data = response;
        console.log("Data loaded:", this.data);
      });
  }
}
