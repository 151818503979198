<div
  style="
    width: 95%;
    padding: 20px;
    margin: auto;
    display: flex;
    justify-content: space-between;
  "
  class="form-container"
>
  <div style="width: 70%">
    <div style="display: flex">
      <div class="label">Vergi No/Daire</div>
      <div style="display: flex">
        <dx-text-box
          [(value)]="inv.account.taxNumber"
          valueChangeEvent="change"
          (onValueChanged)="taxno($event)"
          width="200px"
        >
          <dx-validator>
            <dxi-validation-rule
              type="required"
              message="Vergi No zorunlu bir alandır."
            >
            </dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
        <dx-text-box [(value)]="inv.account.taxOffice" style="margin-left: 5px;" width="200px">
          <dx-validator>
            <dxi-validation-rule
              type="required"
              message="Vergi Dairesi zorunlu bir alandır."
            >
            </dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>
    </div>

    <div style="display: flex">
      <div class="label">Firma Adı</div>
      <dx-text-box [(value)]="inv.account.accountName" width="300px">
        <dx-validator>
          <dxi-validation-rule
            type="required"
            message="Firma Adı zorunlu bir alandır."
          >
          </dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
    </div>

    <div style="display: flex">
      <div class="label">Şube</div>
      <dx-select-box
      item="item"
      style="width: 400px"
      (onSelectionChanged)="branchChanged($event)"
      [(value)]="this.branchCode"
      [searchEnabled]="true"
      searchMode="contains"
      [searchExpr]="['branchCode', 'branchName']"
      [dataSource]="this.is.branchList"
      valueExpr="branchCode"
      displayExpr="branchName"
    >
      <div *dxTemplate="let i of 'item'">
        <span>{{ i.branchCode }} - {{ i.branchName }}</span>
      </div>
    </dx-select-box>
    </div>
    <!-- <dxi-item *ngIf="this.is.branchList.length>0"  dataField="branchCode" editorType="dxSelectBox" [editorOptions]="{
      dataSource: this.invoiceService.branchList,
      valueExpr: 'branchCode',
      displayExpr: 'branchName',
      searchEnabled : true
    }">
    <dxo-label text="Şube Seçimi"></dxo-label>
    <dxi-validation-rule type="required" message="Şube Seçimi Zorunlu Alandır.">
    </dxi-validation-rule>   
  </dxi-item> -->

    <div style="display: flex">
      <div class="label">İrsaliye Tarihi</div>
      <dx-date-box [max]="today" type="datetime" [(value)]="inv.invoiceDate">
        <dx-validator>
          <dxi-validation-rule
            type="required"
            message="İrsaliye Tarihi zorunlu bir alandır."
          >
          </dxi-validation-rule>
        </dx-validator>
      </dx-date-box>
    </div>

    <div style="display: flex">
      <div class="label">Fiili Sevk Tarihi</div>
      <dx-date-box [max]="today" type="datetime" [(value)]="inv.sendDate">
        <dx-validator>
          <dxi-validation-rule
            type="required"
            message="Fiili Sevk Tarihi zorunlu bir alandır."
          >
          </dxi-validation-rule>
        </dx-validator>
      </dx-date-box>
    </div>
  </div>
  <div style="width: 20%">
    <dx-button
      stylingMode="contained"
      text="Kaydet"
      type="success"
      [width]="120"
      (onClick)="save($event)"
    >
    </dx-button>
    <dx-validation-summary></dx-validation-summary>
  </div>
</div>

<dx-data-grid
  width="95%"
  #grid
  style="
    font-size: 12px;
    margin-left: auto;
    margin-right: auto;

    padding: 20px;
    width: 95%;
    background-color: #2a2a2a;
    border-radius: 10px;
  "
  id="gridContainer"
  [dataSource]="inv.invoiceRows"
  [hoverStateEnabled]="true"
  [columnHidingEnabled]="false"
  keyE
  [showBorders]="true"xpr="code"
  [allowColumnReordering]="true"
  [(selectedRowKeys)]="selectedRows"
  [allowColumnResizing]="true"
  columnResizingMode="widget"
  (onSelectionChanged)="selectionChangedHandler($event)"
  (onInitNewRow)="initRow($event)"
  (onRowInserted)="insertedRow($event)"
  (onRowUpdated)="updatedRow($event)"
  (onRowValidating)="onRowInserting($event)"
  (onFocusedCellChanged)="onFocusedCellChanged($event)"
>
  <dxo-export
    [enabled]="true"
    fileName="faturalar"
    [allowExportSelectedData]="true"
  ></dxo-export>
  <dxo-selection mode="multiple"></dxo-selection>
  <dxo-scrolling columnRenderingMode="virtual" mode="virtual"></dxo-scrolling>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-filter-row [visible]="false" [applyFilter]=""></dxo-filter-row>
  <dxo-search-panel
    [visible]="true"
    [width]="240"
    placeholder="Ara..."
  ></dxo-search-panel>
  <dxo-header-filter [visible]="false"></dxo-header-filter>
  <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
  <dxo-paging [pageSize]="15"></dxo-paging>
  <dxo-editing
    mode="batch"
    [allowUpdating]="true"
    [allowDeleting]="true"
    [allowAdding]="true"
  >
  </dxo-editing>

  <dxi-column dataField="code" [width]="100" caption="Ürün Kodu" >
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="name" caption="Ürün Adı">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <!-- <dxi-column [width]="90" dataField="unitName" caption="Birimi"></dxi-column> -->

  <dxi-column dataField="unitName" [width]="90" caption="Birimi">
    <dxo-lookup [dataSource]="units" displayExpr="name" valueExpr="id">
    </dxo-lookup>
    <dxi-validation-rule type="required" message="Para birimi seçmelisiniz..">
    <dxi-validation-rule type="range" [min]="0.000000001" message="Para birimi 0 dan büyük olmalıdır."></dxi-validation-rule>

    </dxi-validation-rule>
  </dxi-column>

  <dxi-column
    [width]="100"
    dataType="number"
    dataField="quantity"
    caption="Miktarı"
  >

  <dxi-validation-rule type="required"></dxi-validation-rule>
  <dxi-validation-rule type="range" [min]="0.000000001" message="Miktar 0 dan büyük olmalıdır."></dxi-validation-rule>

</dxi-column>

  <dxi-column
    [width]="100"
    dataType="number"
    dataField="unitPrice"
    caption="Brm Fiyat"
  >
  <dxi-validation-rule type="required"></dxi-validation-rule>
  <dxi-validation-rule type="range" [min]="0.000000001" message="Brm Fiyat  0 dan büyük olmalıdır.."></dxi-validation-rule>

</dxi-column>

  <dxi-column
    [width]="100"
    dataType="number"
    [allowEditing]="false"
    dataField="total"
    caption="Toplam"
  >
  <dxi-validation-rule type="required"></dxi-validation-rule>

</dxi-column>

  <!-- <dxi-column dataField="branchName" caption="{{'Şube' | translate}}" [width]="80"></dxi-column> -->

  <!-- <div *dxTemplate="let data of 'erpTemplate'">
        <i *ngIf="data.data.erp == 'success'" class="far fa-check-circle" style="font-size: 18px"></i>
        <i *ngIf="data.data.erp == 'new'" class="far fa-times-circle" style="font-size: 18px"></i>
        <i *ngIf="data.data.erp == 'error'" class="fas fa-exclamation" style="font-size: 18px"></i>
    </div>

    <dxo-summary>
        <dxi-total-item column="grandTotal" summaryType="sum" valueFormat="#,###.00" displayFormat="{0}">
        </dxi-total-item>
        <dxi-total-item column="invoiceNumber" summaryType="count">
        </dxi-total-item>
    </dxo-summary> -->
</dx-data-grid>
