import { LoyaltyUsersService } from './../users/service/loyalty-users.service';
import { loyaltyUserObject } from './../users/service/loyalty-users';
import { ProductService } from './../../product/products/service/product.service';
import { BranchService, branch } from './../branches/branch.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as firebase from 'firebase'
import { UploadComponent } from '../../../../projects/cloud-components/src/lib/upload/upload.component';
import { CouponService, coupon, selectBox } from './coupon.service';
import { product } from 'src/app/product/products/service/product.service';
import { TranslateService } from '@ngx-translate/core';
import { SegmentsService } from '../segments/segments.service';
import DataSource from "devextreme/data/data_source";
@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss']
})
export class CouponsComponent implements OnInit {


  tagBoxData: DataSource 

  branches: branch[]
  coupons: coupon[]
  products: product[]
  loyaltyUsers: loyaltyUserObject[]
  popupVisible = false
  tempImage
  loadingVisible = false


  triggers: selectBox[] = []
  couponTypes: selectBox[] = []
  couponProgram: selectBox[] = []
  programs: selectBox[] = []

  coupon: coupon = new coupon()

  buttonOptions: any = {
    text: "Register",
    type: "success",
    useSubmitBehavior: true
  }
  
  constructor(
    public couponservice: CouponService,
    private transate: TranslateService,
    public ls: LoyaltyUsersService,
    public segmentSrvc: SegmentsService,
    public bs: BranchService,
    public ps: ProductService) {
    this.click1 = this.click1.bind(this)


    this.couponTypes.push({ id: "trigger", name: this.transate.instant("Trigger") })
    this.couponTypes.push({ id: "template", name: this.transate.instant("Template") })
    this.couponTypes.push({ id: "user-template", name: this.transate.instant("User Template") })

    this.programs.push({ id: "free-product", name: this.transate.instant("Free Product") })
    this.programs.push({ id: "discount-basket", name: this.transate.instant("Discount Basket") })

    this.triggers.push({ id: "new-membership", name: this.transate.instant("New Memebership") })
    this.triggers.push({ id: "complate-profile", name: this.transate.instant("Complate Profile") })
    this.triggers.push({ id: "offer-friend", name: this.transate.instant("Offer Friend") })


    this.ls.dxLoad()

    this.tagBoxData = new DataSource({
      store: this.ls.store,
      paginate: true,
      pageSize: 10
  });


  }

  @ViewChild('branchImage') upload: UploadComponent

  click1(e) {
    this.coupon = e.row.data
    this.popupVisible = true
  }

  ngOnInit() {
    this.segmentSrvc.load({}, true)
    this.bs.load({}, true)
    //this.ps.load({}, true)
    //this.ls.load({}, true)
    this.couponservice.pretty_load()
  }

  async onFormSubmit(e) {
    this.loadingVisible = true
    e.preventDefault();
    await this.couponservice.upsert(this.coupon)
    this.couponservice.pretty_load()
    this.popupVisible = false
    this.loadingVisible = false
  }

  deleteRow(e) {
    this.couponservice.delete({ id: e.data.id });
  }


  new() {
    this.popupVisible = true
    this.coupon = new coupon()
  }

  select(item) {
    this.coupon = item
    this.popupVisible = true
  }

  filter(field, value) {
    this.coupons = this.couponservice.data.filter(p => p.name.toLowerCase().indexOf(value) > -1).sort((a, b) => {
      var x = a.name.toLowerCase();
      var y = b.name.toLowerCase();
      if (x < y) { return -1; }
      if (x > y) { return 1; }
      return 0;
    })
  }


  //#region upload component

  startUpload(e) {
    this.tempImage = this.coupon.image
    this.coupon.image = "/assets/loading.gif"
  }

  setURL(u) {
    this.coupon.image = u
  }

  setError(e) {
    this.coupon.image = this.tempImage
  }

  setPercent(p) {
  }

  showUpload() {
    this.upload.showUpload()
  }

  //#endregion



}
