import { UploadComponent } from '../../../../projects/cloud-components/src/lib/upload/upload.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { loyaltySettings, SettingsService, links } from './service/settings.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { ClImageUploadComponent } from 'projects/cloud-components/src/lib/cl-image-upload/cl-image-upload.component';
import { ModalService } from 'projects/cloud-components/src/lib/modal/modal.service';
import { CloudLoginService } from 'cloud-labs-core';
import { AppPermissionsService } from 'src/app/settings/services/app-permissions.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']


})
export class SettingsComponent implements OnInit {


  settings: loyaltySettings = new loyaltySettings()

  constructor(
   
    private modalService: ModalService,
    private sso: CloudLoginService, private perm: AppPermissionsService,
    private st: SettingsService, private glb: GlobalsService) { }
  @ViewChild('clUpload', { static: true }) clUplod: ClImageUploadComponent
  @ViewChild('productImage', { static: true }) upload: UploadComponent
  loadingVisible: boolean = true

  ngAfterViewInit() {

    this.loadingVisible = true
    this.st.load({ id: 'settings' }).then(o => {
      this.loadingVisible = false
      if (this.st.data.length == 0) {
        this.settings = new loyaltySettings()
      } else {
        this.settings = this.st.data[0]
      }
    })
  }

  ngOnInit() {

  }

  activeData
  activeImage
  uploadImage(data) {
    this.activeData = data
    //this.upload.showUpload()
    this.showUpload(data)
  }

  removeImage(data) {

    this.activeData = data
    if (this.activeData == "header") {
      this.settings.images.header = ""
    } else if (this.activeData == "backgroundImage") {
      this.settings.images.backgroundImage = ""
    } else if (this.activeData == "scratchImage") {
      this.settings.images.scratchImage = ""
    } else if (this.activeData == "backgroundLogo") {
      this.settings.images.backgroundLogo = ""
    } else if (this.activeData == "bonusPage") {
      this.settings.icons.bonusPage = ""
    } else if (this.activeData == "newsPage") {
      this.settings.icons.newsPage = ""
    } else if (this.activeData == "productsPage") {
      this.settings.icons.productPage = ""
    } else if (this.activeData == "stampCircularIcon") {
      this.settings.images.stampCircularIcon = ""
    } else if (this.activeData == "stampCircularImage") {
      this.settings.images.stampCircularImage = ""
    } else if (this.activeData == "stampAnimeGift") {
      this.settings.images.stampAnimeGift = ""
    } else if (this.activeData == "stampAnimeItem") {
      this.settings.images.stampAnimeItem = ""
    } else if (this.activeData == "stampAnimeItemFilled") {
      this.settings.images.stampAnimeItemFilled = ""
    } else if (this.activeData == "stampBackground") {
      this.settings.images.stampBackground = ""
    }


    //stampBackground
    //stampAnimeGift

  }

  setPercent(e) {
    this.loadingVisible = true

  }
  setURL(e) {
    if (this.activeData == "header") {
      this.settings.images.header = e
    } else if (this.activeData == "backgroundImage") {
      this.settings.images.backgroundImage = e
    } else if (this.activeData == "scratchImage") {
      this.settings.images.scratchImage = e
    } else if (this.activeData == "backgroundLogo") {
      this.settings.images.backgroundLogo = e
    } else if (this.activeData == "bonusPage") {
      this.settings.icons.bonusPage = e
    } else if (this.activeData == "newsPage") {
      this.settings.icons.newsPage = e
    } else if (this.activeData == "productsPage") {
      this.settings.icons.productPage = e
    } else if (this.activeData == "stampCircularIcon") {
      this.settings.images.stampCircularIcon = e
    } else if (this.activeData == "stampCircularImage") {
      this.settings.images.stampCircularImage = e
    } else if (this.activeData == "stampAnimeGift") {
      this.settings.images.stampAnimeGift = e
    } else if (this.activeData == "stampAnimeItem") {
      this.settings.images.stampAnimeItem = e
    } else if (this.activeData == "stampAnimeItemFilled") {
      this.settings.images.stampAnimeItemFilled = e
    } else if (this.activeData == "stampBackground") {
      this.settings.images.stampBackground = e
    }
    this.loadingVisible = false
  }

  setError(e) {
    setTimeout(() => {
      this.loadingVisible = false
      this.loadingVisible = false
    }, 2000);

  }

  save() {
    this.loadingVisible = true
    this.st.upsert(this.settings).then(o => {

      setTimeout(() => {
        this.loadingVisible = false
        this.glb.notice("Kaydedildi..", "info")
      }, 1000);


    })
  }

  addLink() {
    let link = new links
    if (!this.settings.links) {
      this.settings["links"] = []
    }
    this.settings.links.push(link)
  }

  removeLink(index) {
    this.settings.links.splice(index, 1)
  }



  successImage(e) {
    console.log(e);

    if (this.activeData == "header") {
      this.settings.images.header = e
    } else if (this.activeData == "backgroundImage") {
      this.settings.images.backgroundImage = e
    } else if (this.activeData == "scratchImage") {
      this.settings.images.scratchImage = e
    } else if (this.activeData == "backgroundLogo") {
      this.settings.images.backgroundLogo = e
    } else if (this.activeData == "bonusPage") {
      this.settings.icons.bonusPage = e
    } else if (this.activeData == "newsPage") {
      this.settings.icons.newsPage = e
    } else if (this.activeData == "productsPage") {
      this.settings.icons.productPage = e
    } else if (this.activeData == "stampCircularIcon") {
      this.settings.images.stampCircularIcon = e
    } else if (this.activeData == "stampCircularImage") {
      this.settings.images.stampCircularImage = e
    } else if (this.activeData == "stampAnimeGift") {
      this.settings.images.stampAnimeGift = e
    } else if (this.activeData == "stampAnimeItem") {
      this.settings.images.stampAnimeItem = e
    } else if (this.activeData == "stampAnimeItemFilled") {
      this.settings.images.stampAnimeItemFilled = e
    } else if (this.activeData == "stampBackground") {
      this.settings.images.stampBackground = e
    }
    this.loadingVisible = false


    /*  let data = this.st.data.filter(p => p.id == this.activeData)[0]
     data.image = e
     this.pg.upsert(data) */
    //this.loadingVisible = false
  }

  errorImage(e) {

  }

  showUpload(e) {
    this.activeData = e

    if (this.activeData == "header") {
      this.clUplod.value = this.settings.images.header
    } else if (this.activeData == "backgroundImage") {
      this.clUplod.value = this.settings.images.backgroundImage
    } else if (this.activeData == "scratchImage") {
      this.clUplod.value = this.settings.images.scratchImage
    } else if (this.activeData == "backgroundLogo") {
      this.clUplod.value = this.settings.images.backgroundLogo
    } else if (this.activeData == "bonusPage") {
      this.clUplod.value = this.settings.icons.bonusPage
    } else if (this.activeData == "newsPage") {
      this.clUplod.value = this.settings.icons.newsPage
    } else if (this.activeData == "productsPage") {
      this.clUplod.value = this.settings.icons.productPage
    } else if (this.activeData == "stampCircularIcon") {
      this.clUplod.value = this.settings.images.stampCircularIcon
    } else if (this.activeData == "stampCircularImage") {
      this.clUplod.value = this.settings.images.stampCircularImage
    } else if (this.activeData == "stampAnimeGift") {
      this.clUplod.value = this.settings.images.stampAnimeGift
    } else if (this.activeData == "stampAnimeItem") {
      this.clUplod.value = this.settings.images.stampAnimeItem
    } else if (this.activeData == "stampAnimeItemFilled") {
      this.clUplod.value = this.settings.images.stampAnimeItemFilled
    } else if (this.activeData == "stampBackground") {
      this.clUplod.value =  this.settings.images.stampBackground 
    }




    //this.activeData = e.data.id

    this.clUplod.init()
    this.modalService.open("image-upload")
  }

}
