import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-e-invoice-outbound',
  templateUrl: './e-invoice-outbound.component.html',
  styleUrls: ['./e-invoice-outbound.component.scss']
})
export class EInvoiceOutboundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
