
<div style="overflow: scroll; height: 100vh; width: Calc(100% - 50px)">

  <div *ngIf="false" class="invoice-toolbar">
    <div>Tarih Aralığı</div>
    <dx-date-box applyValueMode="useButtons" [(value)]="startDate">
    </dx-date-box>
    <dx-date-box applyValueMode="useButtons" [(value)]="endDate"> </dx-date-box>
    <div>Fatura Türü</div>
    <cl-drop-box [(value)]="filterDocumentType" [list]="this.inoviceSrc.documentTypes" valueExpr="id"
      displayExpr="name"></cl-drop-box>
    <div>Durumu</div>
    <cl-drop-box [(value)]="filterStatus" [list]="this.inoviceSrc.statuses" valueExpr="id" displayExpr="name">
    </cl-drop-box>
    <button class="cl-ok-button" (click)="loadData()">Tamam</button>
  </div>

  <ng-template #tabButtons>
    <div style="display: flex">
      <div *ngIf="this.perm.perm.eInvoiceModule == true" [ngClass]="{
          'document-type-tab-button-selected': activeTab == 'eInvoice'
        }" class="document-type-tab-button" (click)="activeTab = 'eInvoice'; initGrid(activeTab)">
        E-FATURA
      </div>
      <div *ngIf="this.perm.perm.eArchiveModule == true" [ngClass]="{
          'document-type-tab-button-selected': activeTab == 'eArchive'
        }" class="document-type-tab-button" (click)="activeTab = 'eArchive'; initGrid(activeTab)">
        E-ARŞİV
      </div>
      <div *ngIf="this.perm.perm.eFolioModule == true" [ngClass]="{
        'document-type-tab-button-selected': activeTab == 'eFolio'
      }" class="document-type-tab-button" (click)="activeTab = 'eFolio'; initGrid(activeTab)">
        E-ADİSYON
      </div>
      <div *ngIf="this.perm.perm.eDispatchModule == true" [ngClass]="{
          'document-type-tab-button-selected': activeTab == 'eDispatch'
        }" class="document-type-tab-button" (click)="activeTab = 'eDispatch'; initGrid(activeTab)">
        E-İRSALİYE
      </div>
      <div *ngIf="this.perm.perm.eArchiveModule == true" [ngClass]="{
        'document-type-tab-button-selected': activeTab == 'eDispatchReply'
      }" class="document-type-tab-button" (click)="activeTab = 'eDispatchReply'; initGrid(activeTab)">
        İRSALİYE YANIT
      </div>
      <div *ngIf="this.perm.perm.eArchiveModule == true" [ngClass]="{
        'document-type-tab-button-selected': activeTab == 'EProducerReceipt'
      }" class="document-type-tab-button" (click)="activeTab = 'EProducerReceipt'; initGrid(activeTab)">
       E-MÜSTAHSİL
      </div>
    </div>
  </ng-template>

  <!--NAVIGATION FILTER TOOLBAR-->
  <div class="invoice-toolbar">
    <!--   <div style="padding: 10px; margin-left: 20px font-size 16px;">
      <div (click)="activeTab = 'eInvoice'">E-Fatura</div>
      <div (click)="activeTab = 'eArchive'">E-Arşiv</div>
    </div> -->

    <!--E-INVOICE BAR START-->
    <div class="invoice-toolbar-inset" *ngIf="activeTab == 'eInvoice'">
      <div style="display: flex; justify-content: space-between">
        <div *ngTemplateOutlet="tabButtons"></div>

        <div style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          ">
          Tarih Aralığı
          <dx-date-box *ngIf="dateFilterEnabled" [(value)]="startDate">
          </dx-date-box>
          <dx-date-box *ngIf="dateFilterEnabled" [(value)]="endDate">
          </dx-date-box>
          <dx-button [text]="'Tamam'" (onClick)="loadStatics()"></dx-button>
        </div>
      </div>

      <div style="display: flex; justify-content: space-around">
        <!--E-INVOICE, POS SOURCE STATS-->
        <div style="width: 50%; border-right: 1px solid #6e728a">
          <div class="stat-sub-cover">POS</div>
          <div style="display: flex; justify-content: space-around">
            <div *ngFor="let itemA of this.inoviceSrc.eInvoicePOS">
              <div (click)="loadGrid(itemA)" class="stat-cover" *ngIf="this.perm.perm.POSInvoices == true">
                <div class="stat-text">
                  {{ itemA.status }}
                </div>
                <div class="banner1 {{ getCss(itemA) }}" *ngIf="this.inoviceSrc.staticsLoadingStatus == 'loaded'">
                  {{ itemA.count }}
                </div>
                <div class="banner1" *ngIf="this.inoviceSrc.staticsLoadingStatus != 'loaded'">
                  <img style="width: 30px; height: 30px" src="/assets/loading2.gif" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--E-INVOICE, ERP SOURCE STATS-->
        <div style="width: 50%">
          <div class="stat-sub-cover">ERP</div>
          <div style="display: flex; justify-content: space-around">
            <div *ngFor="let itemA of this.inoviceSrc.eInvoiceERP">
              <div (click)="loadGrid(itemA)" class="stat-cover" *ngIf="this.perm.perm.ERPInvoices == true">
                <div class="stat-text">
                  {{ itemA.status }}
                </div>
                <div class="banner1 {{ getCss(itemA) }}" *ngIf="this.inoviceSrc.staticsLoadingStatus == 'loaded'">
                  {{ itemA.count }}
                </div>
                <div class="banner1" *ngIf="this.inoviceSrc.staticsLoadingStatus != 'loaded'">
                  <img style="width: 30px; height: 30px" src="/assets/loading2.gif" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--E-ARCHIVE BAR START-->
    <div class="invoice-toolbar-inset" *ngIf="activeTab == 'eArchive'">
      <div style="display: flex; justify-content: space-between">
        <div *ngTemplateOutlet="tabButtons"></div>

        <div style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          ">
          Tarih Aralığı
          <dx-date-box *ngIf="dateFilterEnabled" [(value)]="startDate">
          </dx-date-box>
          <dx-date-box *ngIf="dateFilterEnabled" [(value)]="endDate">
          </dx-date-box>
          <dx-button [text]="'Tamam'" (onClick)="loadStatics()"></dx-button>
        </div>
      </div>

      <div style="display: flex; justify-content: space-around">
        <!--E-ARCHIVE, POS SOURCE STATS-->
        <div style="width: 50%; border-right: 1px solid #6e728a">
          <div class="stat-sub-cover">POS</div>
          <div style="display: flex; justify-content: space-around">
            <div *ngFor="let itemA of this.inoviceSrc.eArchicePOS">
              <div (click)="loadGrid(itemA)" class="stat-cover" *ngIf="this.perm.perm.POSInvoices == true">
                <div class="stat-text">
                  {{ itemA.status }}
                </div>
                <div class="banner1 {{ getCss(itemA) }}" *ngIf="this.inoviceSrc.staticsLoadingStatus == 'loaded'">
                  {{ itemA.count }}
                </div>
                <div class="banner1" *ngIf="this.inoviceSrc.staticsLoadingStatus != 'loaded'">
                  <img style="width: 30px; height: 30px" src="/assets/loading2.gif" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--E-ARCHIVE, ERP SOURCE STATS-->
        <div style="width: 50%">
          <div class="stat-sub-cover">ERP</div>
          <div style="display: flex; justify-content: space-around">
            <div *ngFor="let itemA of this.inoviceSrc.eArchiveERP">
              <div (click)="loadGrid(itemA)" class="stat-cover" *ngIf="this.perm.perm.ERPInvoices == true">
                <div class="stat-text">
                  {{ itemA.status }}
                </div>
                <div class="banner1 {{ getCss(itemA) }}" *ngIf="this.inoviceSrc.staticsLoadingStatus == 'loaded'">
                  {{ itemA.count }}
                </div>
                <div class="banner1" *ngIf="this.inoviceSrc.staticsLoadingStatus != 'loaded'">
                  <img style="width: 30px; height: 30px" src="/assets/loading2.gif" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--E-FOLIO BAR START-->
    <div class="invoice-toolbar-inset" *ngIf="activeTab == 'eFolio'">
      <div style="display: flex; justify-content: space-between">
        <div *ngTemplateOutlet="tabButtons"></div>

        <div style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            ">
          Tarih Aralığı
          <dx-date-box *ngIf="dateFilterEnabled" [(value)]="startDate">
          </dx-date-box>
          <dx-date-box *ngIf="dateFilterEnabled" [(value)]="endDate">
          </dx-date-box>
          <dx-button [text]="'Tamam'" (onClick)="loadStatics()"></dx-button>
        </div>
      </div>

      <div style="display: flex; justify-content: space-around">
        <!--E-ARCHIVE, POS SOURCE STATS-->
        <div style="width: 50%; border-right: 1px solid #6e728a">
          <div class="stat-sub-cover">POS</div>
          <div style="display: flex; justify-content: space-around">
            <div *ngFor="let itemA of this.inoviceSrc.eFolioPOS">
              <div (click)="loadGrid(itemA)" class="stat-cover" *ngIf="this.perm.perm.POSInvoices == true">
                <div class="stat-text">
                  {{ itemA.status }}
                </div>
                <div class="banner1 {{ getCss(itemA) }}" *ngIf="this.inoviceSrc.staticsLoadingStatus == 'loaded'">
                  {{ itemA.count }}
                </div>
                <div class="banner1" *ngIf="this.inoviceSrc.staticsLoadingStatus != 'loaded'">
                  <img style="width: 30px; height: 30px" src="/assets/loading2.gif" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--E-ARCHIVE, ERP SOURCE STATS-->
        <div style="width: 50%">
          <div class="stat-sub-cover">ERP</div>
          <div style="display: flex; justify-content: space-around">
            <div *ngFor="let itemA of this.inoviceSrc.eFolioERP">
              <div (click)="loadGrid(itemA)" class="stat-cover" *ngIf="this.perm.perm.ERPInvoices == true">
                <div class="stat-text">
                  {{ itemA.status }}
                </div>
                <div class="banner1 {{ getCss(itemA) }}" *ngIf="this.inoviceSrc.staticsLoadingStatus == 'loaded'">
                  {{ itemA.count }}
                </div>
                <div class="banner1" *ngIf="this.inoviceSrc.staticsLoadingStatus != 'loaded'">
                  <img style="width: 30px; height: 30px" src="/assets/loading2.gif" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--E-DISPATCH BAR START-->
    <div class="invoice-toolbar-inset" *ngIf="activeTab == 'eDispatch'">
      <div style="display: flex; justify-content: space-between">
        <div *ngTemplateOutlet="tabButtons"></div>

        <div style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          ">
          Tarih Aralığı
          <dx-date-box *ngIf="dateFilterEnabled" [(value)]="startDate">
          </dx-date-box>
          <dx-date-box *ngIf="dateFilterEnabled" [(value)]="endDate">
          </dx-date-box>
          <dx-button [text]="'Tamam'" (onClick)="loadStatics()"></dx-button>
        </div>
      </div>

      <div style="display: flex; justify-content: space-around">
        <!--E-DISPATCH, POS SOURCE STATS-->
        <div style="width: 50%; border-right: 1px solid #6e728a">
          <div class="stat-sub-cover">POS</div>
          <div style="display: flex; justify-content: space-around">
            <div *ngFor="let itemA of this.inoviceSrc.eDispatchPOS">
              <div (click)="loadGrid(itemA)" class="stat-cover" *ngIf="this.perm.perm.POSInvoices == true">
                <div class="stat-text">
                  {{ itemA.status }}
                </div>
                <div class="banner1 {{ getCss(itemA) }}" *ngIf="this.inoviceSrc.staticsLoadingStatus == 'loaded'">
                  {{ itemA.count }}
                </div>
                <div class="banner1" *ngIf="this.inoviceSrc.staticsLoadingStatus != 'loaded'">
                  <img style="width: 30px; height: 30px" src="/assets/loading2.gif" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--E-DISPATCH, ERP SOURCE STATS-->
        <div style="width: 50%">
          <div class="stat-sub-cover">ERP</div>
          <div style="display: flex; justify-content: space-around">
            <div *ngFor="let itemA of this.inoviceSrc.eDispatchERP">
              <div (click)="loadGrid(itemA)" class="stat-cover" *ngIf="this.perm.perm.ERPInvoices == true">
                <div class="stat-text">
                  {{ itemA.status }}
                </div>
                <div class="banner1 {{ getCss(itemA) }}" *ngIf="this.inoviceSrc.staticsLoadingStatus == 'loaded'">
                  {{ itemA.count }}
                </div>
                <div class="banner1" *ngIf="this.inoviceSrc.staticsLoadingStatus != 'loaded'">
                  <img style="width: 30px; height: 30px" src="/assets/loading2.gif" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--E-DISPATCH REPLY BAR START-->
    <div class="invoice-toolbar-inset" *ngIf="activeTab == 'eDispatchReply'">
      <div style="display: flex; justify-content: space-between">
        <div *ngTemplateOutlet="tabButtons"></div>

        <div style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            ">
          Tarih Aralığı
          <dx-date-box *ngIf="dateFilterEnabled" [(value)]="startDate">
          </dx-date-box>
          <dx-date-box *ngIf="dateFilterEnabled" [(value)]="endDate">
          </dx-date-box>
          <dx-button [text]="'Tamam'" (onClick)="loadStatics()"></dx-button>
        </div>
      </div>

      <div style="display: flex; justify-content: space-around">
        <!--E-DISPATCH, POS SOURCE STATS-->
        <div style="width: 50%; border-right: 1px solid #6e728a">
          <div class="stat-sub-cover">POS</div>
          <div style="display: flex; justify-content: space-around">
            <div *ngFor="let itemA of this.inoviceSrc.eDispatchReplyPos">
              <div (click)="loadGrid(itemA)" class="stat-cover" *ngIf="this.perm.perm.POSInvoices == true">
                <div class="stat-text">
                  {{ itemA.status }}
                </div>
                <div class="banner1 {{ getCss(itemA) }}" *ngIf="this.inoviceSrc.staticsLoadingStatus == 'loaded'">
                  {{ itemA.count }}
                </div>
                <div class="banner1" *ngIf="this.inoviceSrc.staticsLoadingStatus != 'loaded'">
                  <img style="width: 30px; height: 30px" src="/assets/loading2.gif" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--E-DISPATCH REPLY, ERP SOURCE STATS-->
        <div style="width: 50%">
          <div class="stat-sub-cover">ERP</div>
          <div style="display: flex; justify-content: space-around">
            <div *ngFor="let itemA of this.inoviceSrc.eDispatchReplyERP">
              <div (click)="loadGrid(itemA)" class="stat-cover" *ngIf="this.perm.perm.ERPInvoices == true">
                <div class="stat-text">
                  {{ itemA.status }}
                </div>
                <div class="banner1 {{ getCss(itemA) }}" *ngIf="this.inoviceSrc.staticsLoadingStatus == 'loaded'">
                  {{ itemA.count }}
                </div>
                <div class="banner1" *ngIf="this.inoviceSrc.staticsLoadingStatus != 'loaded'">
                  <img style="width: 30px; height: 30px" src="/assets/loading2.gif" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

       <!--E-PRODUCES BAR START-->
       <div class="invoice-toolbar-inset" *ngIf="activeTab == 'EProducerReceipt'">
        <div style="display: flex; justify-content: space-between">
          <div *ngTemplateOutlet="tabButtons"></div>

          <div style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              ">
            Tarih Aralığı
            <dx-date-box *ngIf="dateFilterEnabled" [(value)]="startDate">
            </dx-date-box>
            <dx-date-box *ngIf="dateFilterEnabled" [(value)]="endDate">
            </dx-date-box>
            <dx-button [text]="'Tamam'" (onClick)="loadStatics()"></dx-button>
          </div>
        </div>

        <div style="display: flex; justify-content: space-around">
          <!--E-PRODUCES , POS SOURCE STATS-->
          <div style="width: 50%; border-right: 1px solid #6e728a">
            <div class="stat-sub-cover">POS</div>
            <div style="display: flex; justify-content: space-around">
              <div *ngFor="let itemA of this.inoviceSrc.eProducesPOS">
                <div (click)="loadGrid(itemA)" class="stat-cover" *ngIf="this.perm.perm.POSInvoices == true">
                  <div class="stat-text">
                    {{ itemA.status }}
                  </div>
                  <div class="banner1 {{ getCss(itemA) }}" *ngIf="this.inoviceSrc.staticsLoadingStatus == 'loaded'">
                    {{ itemA.count }}
                  </div>
                  <div class="banner1" *ngIf="this.inoviceSrc.staticsLoadingStatus != 'loaded'">
                    <img style="width: 30px; height: 30px" src="/assets/loading2.gif" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--E-PRODUCES , ERP SOURCE STATS-->
          <div style="width: 50%">
            <div class="stat-sub-cover">ERP</div>
            <div style="display: flex; justify-content: space-around">
              <div *ngFor="let itemA of this.inoviceSrc.eProducesERP">
                <div (click)="loadGrid(itemA)" class="stat-cover" *ngIf="this.perm.perm.ERPInvoices == true">
                  <div class="stat-text">
                    {{ itemA.status }}
                  </div>
                  <div class="banner1 {{ getCss(itemA) }}" *ngIf="this.inoviceSrc.staticsLoadingStatus == 'loaded'">
                    {{ itemA.count }}
                  </div>
                  <div class="banner1" *ngIf="this.inoviceSrc.staticsLoadingStatus != 'loaded'">
                    <img style="width: 30px; height: 30px" src="/assets/loading2.gif" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    <!--
      #9c2207 error
      #d44b2c yok sayıldı
      #800076 iptal edildi
      #8759e2 beklemede
      #29c11f gönderildi -->
  </div>

  <!--MENU BAR-->
  <div>
    <app-button-menu class="invoice-menu" type="row" (clicked)="inovice_menu_click($event)" [menu]="invoiceMenu">
    </app-button-menu>
  </div>

  <div style="display: flex; width: 100%">
    <dx-data-grid width="95%" #grid style="
        font-size: 12px;
        margin-left: auto;
        margin-right: auto;
        height: calc(100vh - 260px);
        padding: 20px;
        width: 95%;
        background-color: #2a2a2a;
        border-radius: 10px;
      " id="gridContainer" [dataSource]="inoviceSrc.data" [hoverStateEnabled]="true" [columnHidingEnabled]="false"
      keyExpr="id" [allowColumnReordering]="true"  (onCellPrepared)="onCellPrepared($event)" [(selectedRowKeys)]="selectedRows" [allowColumnResizing]="true"
      columnResizingMode="widget" (onSelectionChanged)="selectionChangedHandler($event)"
      [columns]="['id', 'name', 'group']" [showBorders]="true">
      <dxo-export [enabled]="true" fileName="faturalar" [allowExportSelectedData]="true"></dxo-export>
      <dxo-selection mode="multiple"></dxo-selection>
      <dxo-scrolling columnRenderingMode="virtual" mode="virtual"></dxo-scrolling>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <!-- <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 500, 1000]" [showInfo]="true"></dxo-pager> -->
      <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
      <dxo-search-panel [visible]="true" [width]="240" placeholder="Ara..."></dxo-search-panel>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
      <!-- <dxo-paging [pageSize]="15"></dxo-paging> -->

      <dxi-column dataType="boolean" dataField="mailReaded" caption="{{ 'Okundu' | translate }}" [width]="100">
      </dxi-column>

      <dxi-column dataType="boolean" [allowEditing]="false" dataField="printed" caption="{{ 'Yazdırıldı' | translate }}" [width]="100">
      </dxi-column>

      <dxi-column dataField="erp" caption="{{ 'Erp Status' | translate }}" [width]="100">
        <dxo-lookup [dataSource]="this.inoviceSrc.erpStatus" displayExpr="name" valueExpr="id">
        </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="status" caption="{{ 'Status' | translate }}"
        [width]="80">
        <dxo-lookup [dataSource]="this.inoviceSrc.statuses" displayExpr="name" valueExpr="id">
        </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="invoiceDate" caption="{{ 'Date' | translate }}" alignment="right" dataType="datetime"
        format="dd/MM/yyyy" dataType="date" [width]="150"></dxi-column>
      <dxi-column dataField="invoiceNumber" caption="{{ 'Invoice ID' | translate }}" [width]="160"></dxi-column>
      <!-- <dxi-column dataField="uuid" caption="{{'uuid' | translate}}" [width]="80"></dxi-column> -->
      <dxi-column dataField="typeScenario" caption="{{ 'Scene' | translate }}" [width]="100">
        <dxo-lookup [dataSource]="this.inoviceSrc.scenarios" displayExpr="name" valueExpr="id">
        </dxo-lookup>
      </dxi-column>
      <dxi-column dataField="invoiceType" caption="{{ 'Type' | translate }}" [width]="100">
        <dxo-lookup [dataSource]="this.inoviceSrc.invoiceTypes" displayExpr="name" valueExpr="id">
        </dxo-lookup>
      </dxi-column>
      <dxi-column dataField="account.accountName" caption="{{ 'Acount Title' | translate }}" [width]="240"></dxi-column>
      <dxi-column dataField="account.taxNumber" caption="{{ 'VKN/TC Kimlik' | translate }}" [width]="120"></dxi-column>
      <dxi-column dataField="matrah" [calculateCellValue]="calculateCellValue" caption="{{ 'Matrah' | translate }}" [width]="80" >
        <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
      </dxi-column>
      <dxi-column dataField="taxTotal" caption="{{ 'KDV Tutarı' | translate }}" [width]="80">
        <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
      </dxi-column>
      <dxi-column dataField="grandTotal" caption="{{ 'Tutar' | translate }}" [width]="80">
        <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
      </dxi-column>
      <dxi-column dataField="currency" caption="Para Birimi" [width]="50">
        <dxo-lookup [dataSource]="this.currencies" displayExpr="name" valueExpr="id"></dxo-lookup>
      </dxi-column>
      <dxi-column dataField="gibCode" caption="{{ 'GIB Kodu' | translate }}" [width]="60"></dxi-column>
      <dxi-column dataField="sendDate" caption="{{ 'Gönderim Tarihi' | translate }}" alignment="right"
        dataType="datetime" format="dd/MM/yyyy hh:MM:ss" [width]="130"></dxi-column>

      <dxi-column dataField="invoiceNumber" caption="{{ 'Son İşlem Yapan' | translate }}" [width]="150" cellTemplate="lastEditUser">,

      </dxi-column>

      <dxi-column dataField="payRows[0].payName" caption="{{ 'Ödeme' | translate }}" [width]="80">
        <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
      </dxi-column>

      <!-- <dxi-column dataField="senderUserId" caption="{{ 'Gönderen' | translate }}" [width]="150">,
        <dxo-lookup [dataSource]="this.appUserService.users" displayExpr="name" valueExpr="id">
        </dxo-lookup>
      </dxi-column> -->

      <dxi-column dataField="invoiceNumber" caption="{{ 'Gönderen' | translate }}" [width]="150" cellTemplate="senderUser">

      </dxi-column>

      <dxi-column dataField="reference" caption="{{ 'Referans' | translate }}" [width]="140"></dxi-column>
      <dxi-column dataField="uuid" caption="{{ 'UUID' | translate }}" [width]="140"></dxi-column>
      <!-- <dxi-column dataType="number" [allowEditing]="true" dataField="accountingType" caption="{{ 'Muh. Türü' | translate }}"
      [width]="100">
      <dxo-lookup [dataSource]="typeList" displayExpr="name" valueExpr="id">
      </dxo-lookup>
  </dxi-column> -->
      <dxi-column dataField="branchName" caption="{{ 'Şube' | translate }}" [width]="160"></dxi-column>

      <dxi-column [visible]="this.isColumnVisible()" dataField="customerNo" caption="{{ 'Müşteri No' | translate }}"
        [width]="160"></dxi-column>
      <dxi-column [visible]="this.isColumnVisible()" dataField="customerNote" caption="{{ 'Müşteri Not' | translate }}"
        [width]="260"></dxi-column>


      <!-- <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
      <div *dxTemplate="let employee of 'detail'">
          <div class="master-detail-caption">
            Ödeme Kaydı
          </div>
          <dx-data-grid id="gridContainer" width="65%" [dataSource]="employee.data.payRows" [showBorders]="true">
            <dxi-column dataField="payName" caption="{{ 'Pay Type' | translate }}" ></dxi-column>
            <dxi-column dataField="total" caption="{{ 'Tutar' | translate }}" [width]="80">
              <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
            </dxi-column>
          </dx-data-grid>
      </div> -->
      <div *dxTemplate="let data of 'erpTemplate'">
        <i *ngIf="data.data.erp == 'success'" class="far fa-check-circle" style="font-size: 18px"></i>
        <i *ngIf="data.data.erp == 'new'" class="far fa-times-circle" style="font-size: 18px"></i>
        <i *ngIf="data.data.erp == 'error'" class="fas fa-exclamation" style="font-size: 18px"></i>
      </div>

      <div *dxTemplate="let data of 'lastEditUser'">
         <label>{{this.appUserService.getUserName(data.data.editUser)}}</label>
      </div>

      <div *dxTemplate="let data of 'senderUser'">
        <label>{{getSenderUser(data.data.logs)}}</label>
     </div>

      <div *dxTemplate="let data of 'matrah'">
        <label>{{data.data.grandTotal - data.data.taxTotal | currency:'₺'}}</label>
     </div>

      <dxo-summary>
        <dxi-total-item column="grandTotal" summaryType="sum" valueFormat="#,###.00" displayFormat="{0}">
        </dxi-total-item>
        <dxi-total-item column="taxTotal" summaryType="sum" valueFormat="#,###.00" displayFormat="{0}">
        </dxi-total-item>
        <dxi-total-item column="matrah" summaryType="sum" valueFormat="#,###.00" displayFormat="{0}">
        </dxi-total-item>
        <dxi-total-item column="invoiceDate" summaryType="count">
        </dxi-total-item>
      </dxo-summary>
    </dx-data-grid>
  </div>
</div>

<div style="height: 200px"></div>

<ng-template #modalHeader let-id="id" let-title="title" let-print="print">
  <div class="modal-header">
    <div class="modal-header-text">
      {{ title | translate }}
    </div>
    <div style="display: flex; justify-content: flex-end; width: 100px">
      <button tooltip="Faturayı İndir" [theme]="'light'" *ngIf="print=='on'"
        style="border: 0; background-color: transparent; color: white;" (click)="saveFatura(id)">
        <i class="fas fa-download"></i>
      </button>
      <button (click)="printFatura('invHtml')" tooltip="Faturayı Yazdır" [theme]="'light'" *ngIf="print == 'on'"
        style="border: 0; background-color: transparent; color: white">
        <i class="fas fa-print"></i>
      </button>
      <i (click)="sendMailFromPreview()" *ngIf="print == 'on' && activeInvoice.status == 'success'"
        tooltip="Mail Gönder" [theme]="'light'" class="far fa-paper-plane"></i>
      <div tooltip="Kapat" [theme]="'light'" class="html-invoice-header-close-icon" (click)="closeModal(id)">
        <i title="{{ 'Close' | translate }}" class="fas fa-times-circle cl-close-icon"></i>
      </div>
    </div>
  </div>
</ng-template>

<jw-modal id="inoviceDetailModal">
  <div *ngTemplateOutlet="
      modalHeader;
      context: { id: 'inoviceDetailModal', title: 'Ayrıntılar' }
    "></div>

  <div style="
      display: flex;
      background-color: var(--workspace-background);
      color: white;
      font-family: 'Montserrat';
      width: 700px;
      margin: auto;
      padding: 10px;
      box-sizing: border-box;
      margin-bottom: 10px;
    ">
    <div style="width: 50%; padding: 10px; box-sizing: border-box">
      <app-cl-text-box style="width: 100%" [(value)]="activeInvoice.invoiceNumber" label="Fatura No"></app-cl-text-box>
      <app-cl-text-box style="width: 100%" [(value)]="detail.CreateDate" label="Fatura Tarihi"></app-cl-text-box>
      <app-cl-text-box style="width: 100%" [(value)]="detail.DocumentType" label="Fatura Tipi"></app-cl-text-box>
      <app-cl-text-box style="width: 100%" [(value)]="detail.InvoiceState" label="Akış Durum"></app-cl-text-box>
    </div>

    <div style="width: 50%; padding: 10px; box-sizing: border-box">
      <app-cl-text-box style="width: 100%" [(value)]="detail.ReceiverName" label="ETTN"></app-cl-text-box>
      <app-cl-text-box style="width: 100%" [(value)]="detail.InvoiceEnvelopeGUID" label="Zarf No"></app-cl-text-box>
      <app-cl-text-box style="width: 100%" [(value)]="activeInvoice.typeScenario" label="Senaryo"></app-cl-text-box>
      <app-cl-text-box style="width: 100%" [(value)]="detail.Code" label="Zarf Durumu"></app-cl-text-box>
    </div>
  </div>

  <div style="
      display: flex;
      background-color: var(--workspace-background);
      color: white;
      font-family: 'Montserrat';
      width: 700px;
      margin: auto;
      padding: 10px;
      box-sizing: border-box;
      margin-bottom: 10px;
      font-size: 12px;
    ">
    <div style="width: 50%; padding: 10px">
      <app-cl-text-box style="width: 100%" [(value)]="detail.SenderName" label="Gönderen"></app-cl-text-box>
      <app-cl-text-box style="width: 100%" [(value)]="detail.SenderTaxNumber" label="Gönderen VKN"></app-cl-text-box>
      <app-cl-text-box style="width: 100%" [(value)]="detail.SenderAlias" label="Gönderen Etiket"></app-cl-text-box>
    </div>

    <div style="width: 50%; padding: 10px">
      <app-cl-text-box style="width: 100%" [(value)]="detail.ReceiverName" label="Alıcı"></app-cl-text-box>
      <app-cl-text-box style="width: 100%" [(value)]="detail.ReceiverTaxNumber" label="Alıcı VKN"></app-cl-text-box>
      <app-cl-text-box style="width: 100%" [(value)]="detail.ReceiverAlias" label="Alıcı Etiket"></app-cl-text-box>
    </div>
  </div>
  <div style="
      display: flex;
      background-color: var(--workspace-background);
      color: white;
      font-family: 'Montserrat';
      width: 700px;
      margin: auto;
      padding: 10px;
      box-sizing: border-box;
      margin-bottom: 10px;
    ">
    <dx-data-grid class="invoice-history" [dataSource]="[]">
      <dxo-paging [pageSize]="10"> </dxo-paging>
      <dxo-pager displayMode="full" [allowedPageSizes]="[5, 10, 15]" [visible]="true" [showInfo]="true"
        [showPageSizeSelector]="true" [showNavigationButtons]="true">></dxo-pager>
      <dxi-column dataField="id" caption="#Id"> </dxi-column>
      <dxi-column dataField="date" caption="{{ 'Save Date' | translate }}">
      </dxi-column>
      <dxi-column dataField="saveType" caption="{{ 'Save Type' | translate }}">
      </dxi-column>
      <dxi-column dataField="ettn" caption="{{ 'ETTN' | translate }}">
      </dxi-column>
      <dxi-column dataField="zarfNo" caption="{{ 'Envelope No' | translate }}">
      </dxi-column>
      <dxi-column dataField="zarfDuruKodu" caption="{{ 'Envelope Status Code' | translate }}">
      </dxi-column>
      <dxi-column dataField="Description" caption="{{ 'Açıklama' | translate }}">
      </dxi-column>
    </dx-data-grid>
  </div>

  <div style="
      display: flex;
      background-color: var(--workspace-background);
      color: white;
      font-family: 'Montserrat';
      width: 700px;
      margin: auto;
      padding: 10px;
      box-sizing: border-box;
      margin-bottom: 10px;
    ">
    <table style="
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        font-size: 12px;
        empty-cells: show;
      ">
      <tr *ngFor="let item of activeInvoice.logs">
        <td>
          {{ item.type }}
        </td>
        <td>
          {{ item.time | date: "medium" }}
        </td>
        <td>
          {{ item.title }}
        </td>
        <td>
          {{ item.message }}
        </td>
        <td>
          {{ item.userId }}
        </td>
      </tr>
    </table>
  </div>
</jw-modal>

<jw-modal id="inoviceModal">
  <div *ngTemplateOutlet="
      modalHeader;
      context: { id: 'inoviceModal', title: 'Ön İzleme', print: 'on' }
    "></div>

  <div id="invHtml" class="modal-content html-invoice-content"></div>
</jw-modal>

<jw-modal id="invoiceEditModal">
  <div *ngTemplateOutlet="
      modalHeader;
      context: { id: 'invoiceEditModal', title: 'Düzenle' }
    "></div>
  <app-invoice-form (formClose)="closeModal('invoiceEditModal')" [activeDocument]="activeTab" [invoice]="activeInvoice"></app-invoice-form>
</jw-modal>

<jw-modal id="inoviceError">
  <div *ngTemplateOutlet="
      modalHeader;
      context: { id: 'inoviceError', title: 'Hata' }
    "></div>
  <div class="modal-content error-content">{{ inoviceError }}</div>
  >
</jw-modal>

<jw-modal id="invoiceEmail">
  <div *ngTemplateOutlet="
      modalHeader;
      context: { id: 'invoiceEmail', title: 'ePosta Gönder' }
    "></div>
  <div class="modal-content email-content">
    <app-cl-form>
      <app-cl-text-box required="true" #clMail label="E-Posta" [(value)]="invoiceSendEmailAddress">
      </app-cl-text-box>

      <button (click)="
          clMail.validate(clMail.value);
          sendMail(clMail.isValid, clMail.value);
          clMail.value = ''
        " class="cl-ok-button">
        Gönder
      </button>
    </app-cl-form>
  </div>
  >
</jw-modal>

<jw-modal id="invoiceLogsModal">
  <div *ngTemplateOutlet="
      modalHeader;
      context: { id: 'invoiceLogsModal', title: 'Log Kayıtları' }
    "></div>

  <div style="
      display: flex;
      background-color: white;
      color: black;
      font-family: 'Montserrat';
      width: 900px;
      margin: auto;
      padding: 20px;
      border-radius: 0px 0px 10px 10px;
      box-sizing: border-box;
      margin-bottom: 10px;
    ">
    <table style="
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        font-size: 16px;
        empty-cells: show;
      ">
      <tr *ngFor="let item of activeInvoice.logs">
        <td>
          {{ item.type }}
        </td>
        <td>
          {{ item.time | date: "medium" }}
        </td>
        <td>
          {{ item.title }}
        </td>
        <td>
          {{ item.message }}
        </td>
        <td>
          {{ this.appUserService.getUserName(item.userId) }}
        </td>
      </tr>
    </table>
  </div>
</jw-modal>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingVisible" [showIndicator]="true"
  [showPane]="true" [message]="loadingMessage" [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>

<dx-popup class="popup" [fullScreen]="true" [showTitle]="true" title="Information" [dragEnabled]="false"
  [closeOnOutsideClick]="true" [(visible)]="popupVisible">
  <div *dxTemplate="let data of 'content'" style="max-width: 600px; margin: auto">
    <dx-scroll-view>
      <app-invoice-form></app-invoice-form>
    </dx-scroll-view>
  </div>
</dx-popup>

<dx-popup [(visible)]="showExpiredInvoicesModal" [showTitle]="true" title="Fatura Gönder" maxWidth="30vw"
  maxHeight="20vh">
  <div style="
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    ">
    <div>
      Yasal fatura gönderim süresinin dışında bir gönderim yapıyorsunuz işleme
      devam etmek ister misiniz?
    </div>
    <div style="
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
      ">
      <button class="cl-ok-button" (click)="sendInvoice();showExpiredInvoicesModal = false">Evet</button>
      <button class="cl-cancel-button" (click)="showExpiredInvoicesModal = false">
        Hayır
      </button>
    </div>
  </div>
</dx-popup>

<div id="invHtml2" class="modal-content html-invoice-content"></div>
