import { FirestoreService } from './../../services/firestore.service';
import { BranchService } from './../branches/branch.service';
import { Component, OnInit } from '@angular/core';
import { SegmentsService } from '../segments/segments.service';
import { PushNotificationsService, notification } from './push-notifications.service';

@Component({
  selector: 'app-push-notifications',
  templateUrl: './push-notifications.component.html',
  styleUrls: ['./push-notifications.component.scss']
})
export class PushNotificationsComponent implements OnInit {




  fields: Array<any> = []
  timeFields: Array<any> = []


  filter = ""

  notif: notification = new notification()
  popupVisible = false
  loadingVisible = false
  constructor(
    public ns: PushNotificationsService,
    private sg: SegmentsService, private bs: BranchService, private fs: FirestoreService) { }

  ngOnInit() {
    this.fields.push({
      dataField: "Segment",
      filterOperations: ["=", "anyof"],
      lookup: {
        dataSource: this.sg.data,
        displayExpr: "name",
        valueExpr: "id"
      }
    })

    this.fields.push({
      dataField: "Şube",
      filterOperations: ["=", "anyof"],
      lookup: {
        dataSource: this.bs.data,
        displayExpr: "name",
        valueExpr: "id"
      }
    })

    this.fields.push({
      dataField: "Son Giriş (Gün)",
      dataType: "number"
    })

    this.fields.push({
      dataField: "Üye Olma (Gün)",
      dataType: "number"
    })

    this.fields.push({
      dataField: "Profil Tam",
      dataType: "boolean"
    })

    this.fields.push({
      dataField: "Register",
      dataType: "boolean"
    })

    this.fields.push({
      dataField: "Login",
      dataType: "boolean"
    })

    this.fields.push({
      dataField: "Toplam Puan",
      dataType: "number"
    })

    this.fields.push({
      dataField: "Harcanabilir Puan",
      dataType: "number"
    })

    this.fields.push({
      dataField: "Harcanmış Puan",
      dataType: "number"
    })


    this.timeFilter()





    
  }


  timeFilter() {

    


    let time = [
      {id: "gun", value : "Her Gün"},
      {id: "hafta-basi", value : "Her Hafta Başı"},
      {id: "ay-basi", value : "Her Ay Başı"}
    ]

   


    this.timeFields.push({
      dataField: "Hangi Gün",
      filterOperations: ["=", "anyof"],
      lookup: {
        dataSource: time,
        displayExpr: "value",
        valueExpr: "id"
      }
    })

    this.timeFields.push({
      dataField: "Saat",
      filterOperations: ["=", "anyof"],
      formatValue: "##:##"
    })


    this.timeFields.push({
      dataField: "Frekans Gün",
      filterOperations: ["=", "anyof"],
      formatValue: "##:##"
    })


  }



  updateTexts(e) {
  }

  initRow(e) {
    e.data.id = this.fs.getID()
  }

  updatedRow(e) {
    this.ns.upsert(e.data)
  }

  insertedRow(e) {
    this.ns.upsert(e.data)
    this.ns.data.push(e.data)
  }

  deleteRow(e) {
    //this.segmentService.delete(e.data)
    this.ns.delete({ id: e.data.id })
  }



  new() {
    this.popupVisible = true
    this.notif = new notification
  }


  formatValue(value, spaces = 0) {
    if (value && Array.isArray(value[0])) {
      var TAB_SIZE = 4;
      spaces = spaces || TAB_SIZE;
      return "[" + this.getLineBreak(spaces) + value.map(function (item) {
        return Array.isArray(item[0]) ? this.formatValue(item, spaces + TAB_SIZE) : JSON.stringify(item);
      }).join("," + this.getLineBreak(spaces)) + this.getLineBreak(spaces - TAB_SIZE) + "]";
    }
    return JSON.stringify(value);
  }

  getLineBreak(spaces) {
    return "\r\n" + new Array(spaces + 1).join(" ");
  }

  detay(data) {
    this.notif = data.data;
    this.popupVisible = true
  }


  async save() {
    this.loadingVisible = true
    await this.ns.upsert(this.notif)
    this.popupVisible = false
    this.loadingVisible = false
  }

  delete(data) {
    this.ns.delete({ id: data.data.id })
    this.ns.load()
  }

}
