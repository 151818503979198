<div style="
display: flex;
width: 100%;
margin-bottom: 20px;
width: 95%;
margin-left: 20px;
margin-right: 7px;
line-height: 40px;
">
    <div style="margin-left: 10px;">VKN No :</div>
    <dx-select-box item="item" style="width: 400px;height: 40px;" (onSelectionChanged)="accountChanged($event)"
        [(value)]="this.taxNo" [searchEnabled]="true" searchMode="contains"
        [searchExpr]="['taxNo', 'accountName']" [minSearchLength]="3" [dataSource]="this.dxAccount"
        valueExpr="taxNo" displayExpr="accountName">
        <div *dxTemplate="let i of 'item'">
            <span>{{ i.taxNo }} - {{ i.accountName }}</span>
        </div>
    </dx-select-box>
    <div style="margin-left: 10px;">Muhtelif. Türü :</div>
<dx-select-box
  item="item"
  style="width: 400px;height: 40px;"
  (onSelectionChanged)="accountTypeChanged($event)"
  [(value)]="this.accountType"
  [searchEnabled]="true"
  searchMode="contains"
  [searchExpr]="['branchCode', 'branchName']"
  [dataSource]="typeList"
  valueExpr="id"
  displayExpr="name"
>
  <div *dxTemplate="let i of 'item'">
    <span>{{i?.name }}</span>
  </div>
</dx-select-box>
    <button  style="margin-top:0px!important;" class="cl-cancel-button" (click)="add()">Ekle</button>
</div>


<div style="
display: flex;
width: 100%;
margin-bottom: 10px;
width: 95%;
margin-left: 20px;
margin-right: 7px;
line-height: 40px;
">




<div style="overflow: scroll; height: 100vh; width: Calc(100% - 50px)">

    <dx-data-grid width="95%" #grid style="
    font-size: 12px;
    margin-left: auto;
    margin-right: auto;
    height: calc(100vh - 260px);
    padding: 20px;
    width: 95%;
    background-color: #2a2a2a;
    border-radius: 10px;
  " id="gridContainer"  (onRowUpdated)="onRowUpdated($event)"  [dataSource]="accountInvoiceList" [hoverStateEnabled]="true" [columnHidingEnabled]="false"
        keyExpr="taxNumber" [allowColumnReordering]="true" [allowColumnResizing]="true" columnResizingMode="widget"
        [showBorders]="true">
        <dxo-editing [allowAdding]="false" [allowUpdating]="true" mode="cell"></dxo-editing>
        <dxi-column dataField="taxNumber" [allowEditing]="false" caption="{{ 'Vergi Kimlik No' | translate }}"></dxi-column>
        <dxi-column dataField="name" [allowEditing]="false" caption="{{ 'Cari Adı' | translate }}"></dxi-column>
         <dxi-column dataType="type" [allowEditing]="true" dataField="type" caption="{{ 'Tip' | translate }}"
            [width]="100">
            <dxo-lookup [dataSource]="typeList" displayExpr="name" valueExpr="id">
            </dxo-lookup>
        </dxi-column>
        <dxi-column dataType="boolean" [allowEditing]="true" dataField="isActive" caption="{{ 'Durum' | translate }}"
            [width]="100">

        </dxi-column>

        <dxo-filter-row [visible]="true"></dxo-filter-row>
    </dx-data-grid>
</div>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingVisible" [showIndicator]="true"
    [showPane]="true" [message]="loadingMessage" [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>
