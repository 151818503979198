<div style="overflow:scroll; height:100vh;">
    <div class="section-title">
        {{"Pay Types" | translate}}
    </div>

    <div class="card">

        <dx-button (onClick)="add()" text="EKLE"></dx-button>
        <dx-button (onClick)="save()" text="KAYDET"></dx-button>

        <div class="pay-detail">
            <span style="width: 150px;">{{'Base Currency' | translate}}</span>
            <dx-select-box [(value)]="baseCurrency" [dataSource]="pts.currencies" displayExpr="name" valueExpr="id"></dx-select-box>
        </div>

        <div>
            <div class="pay-cover">

                <div style="padding: 5px; width: 200px">
                    {{'Payment Type' | translate}}
                </div>

                <div style="padding: 5px; width: 250px">
                    {{'Description' | translate}}
                </div>

                <div style="padding: 5px; width: 250px">
                    {{'Detail' | translate}}
                </div>
            </div>

            <div *ngFor="let item of payTypes">
                <div class="pay-cover">

                    <div style="padding: 5px; width: 200px">
                        <dx-select-box [(value)]="item.paytype" [dataSource]="pts.collections" displayExpr="name" valueExpr="id"></dx-select-box>
                    </div>

                    <div style="padding: 5px; width: 70px">
                        <dx-text-box   [(value)]="item.order" [showClearButton]="true"></dx-text-box>
                    </div>

                    <div style="padding: 5px; width: 250px">
                        <dx-number-box [(value)]="item.name" [showClearButton]="true"></dx-number-box>
                    </div>

                    <div class="pay-detail" *ngIf="item.paytype == 1">
                        <span style="width: 100px;">{{'Currency' | translate}}</span>
                        <dx-select-box [(value)]="item.currency" [dataSource]="pts.currencies" displayExpr="name" valueExpr="id"></dx-select-box>
                    </div>

                    <div class="pay-detail" *ngIf="item.paytype == 2">
                        <span style="width: 100px;">{{'Bank' | translate}}</span>
                        <dx-text-box [(value)]="item.bank" [showClearButton]="true"></dx-text-box>
                    </div>


                    <div class="pay-detail" *ngIf="item.paytype == 3">
                        <span style="width: 100px;">{{'Company' | translate}}</span>
                        <dx-text-box [(value)]="item.foodCheck" [showClearButton]="true"></dx-text-box>
                    </div>

                    <div class="pay-detail" *ngIf="item.paytype == 4">
                        <span style="width: 100px;">{{'Acc Type' | translate}}</span>
                        <dx-text-box [(value)]="item.account" [showClearButton]="true"></dx-text-box>
                    </div>
                </div>
            </div>

            <dx-data-grid *ngIf="false" id="gridContainer" [dataSource]="pts.data" [showBorders]="true" style="margin:auto;" (onInitNewRow)="initRow($event)" (onRowInserted)="insertedRow($event)" (onRowUpdated)="updatedRow($event)">
                <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
                <dxo-header-filter [visible]="true"></dxo-header-filter>
                <dxo-search-panel [visible]="true" [width]="240" placeholder="Ara..."></dxo-search-panel>
                <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
                </dxo-editing>
                <dxi-column dataField="name" [width]="240" caption="{{'Name' | translate}}"></dxi-column>
                <dxi-column dataField="description" [width]="240" caption="{{'Name' | translate}}"></dxi-column>
            </dx-data-grid>

        </div>

        <div style="height: 200px;">

        </div>
    </div>