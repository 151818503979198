import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, style, animate, transition,stagger, keyframes,query } from '@angular/animations';

@Component({
  selector: 'app-must-auto-selection',
  templateUrl: './must-auto-selection.component.html',
  styleUrls: ['./must-auto-selection.component.css'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [

        query(':enter', style({ opacity: 0 }), { optional: true }),

        query(':enter', stagger('500ms', [
          animate('500ms ease-in', keyframes([
            style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
            style({ opacity: .5, transform: 'translateY(35px)', offset: 0.3 }),
            style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
          ]))]), { optional: true })
        ,
        query(':leave', stagger('500ms', [
          animate('500ms ease-in', keyframes([
            style({ opacity: 1, transform: 'translateX(0)', offset: 0 }),
            style({ opacity: .5, transform: 'translateX(0px)', offset: 0 }),
            style({ opacity: 0, transform: 'translateX(100%)', offset: 0.1 }),
          ]))]), { optional: true })
      ])
    ]),
    trigger('explainerAnim', [
      transition('* => *', [
        query('.col', style({ opacity: 0, transform: 'translateX(-40px)' })),

        query('.col', stagger('500ms', [
          animate('800ms 1.2s ease-out', style({ opacity: 1, transform: 'translateX(0)' })),
        ])),

        query('.col', [
          animate(1000, style('*'))
        ])
      ])
    ]),
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(100%)', opacity: 0}),
          animate('300ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(100%)', opacity: 1}),
          animate('300ms', style({transform: 'translateX(-100%)', opacity: 0}))
        ])
      ]
    )
  ]
})
export class MustAutoSelectionComponent  {

  @Input() showPrice: boolean = true;
  @Input() type: string = "must"
  @Input() item
  @Output() itemChanged = new EventEmitter()

change() {
  this.itemChanged.emit(this.item)
}


mustChange(e) {
  if (!e.value) {
    this.item.maxSelect = 0
    this.item.minSelect = 0
  }
}

}
