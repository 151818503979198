<div style="width: 1000px; margin: auto; display: flex; justify-content: space-evenly; margin-bottom: 10px;">

    <dx-button stylingMode="contained" text="Hepsini Kabul Et" type="success" [width]="120" (onClick)="acceptAll()">
    </dx-button>
    <dx-button stylingMode="contained" text="Kısmen Kabul Et" type="danger" [width]="120" (onClick)="acceptPartial()">
    </dx-button>
</div>


<dx-data-grid width="95%" #grid style="font-size: 12px;
      margin-left: auto;
      margin-right: auto;
     
      padding: 20px;
      width: 95%;
      background-color: #2a2a2a;
      border-radius: 10px;" id="gridContainer" [dataSource]="invSrc.activeDispatch.invoiceRows"
    [hoverStateEnabled]="true" [columnHidingEnabled]="false" keyExpr="id" [allowColumnReordering]="true"
    [(selectedRowKeys)]="selectedRows" (onEditorPrepared)="onEditorPreparing($event)"   [allowColumnResizing]="true" columnResizingMode="widget"
    (onSelectionChanged)="selectionChangedHandler($event)" [showBorders]="true">


    <dxo-export [enabled]="true" fileName="faturalar" [allowExportSelectedData]="true"></dxo-export>
    <dxo-selection mode="multiple"></dxo-selection>
    <dxo-scrolling columnRenderingMode="virtual" mode="virtual"></dxo-scrolling>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
    <dxo-search-panel [visible]="true" [width]="240" placeholder="Ara..."></dxo-search-panel>
    <dxo-header-filter [visible]="false"></dxo-header-filter>
    <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
    <dxo-paging [pageSize]="15"></dxo-paging>
    <dxo-editing mode="batch" [allowUpdating]="true" [allowDeleting]="false" [allowAdding]="false">

    </dxo-editing>



    <dxi-column dataField="name" [width]="300" caption="Ürün Adı"></dxi-column>

    <dxi-column [width]="45" dataField="unitName" caption="Birimi"></dxi-column>
    <dxi-column [width]="60" dataField="quantity" caption="Miktarı"></dxi-column>
   
    <dxi-column [width]="60" dataField="approvedQty" caption="Kabul"></dxi-column>
    <dxi-column [width]="45" [visible]="false" dataField="approvedUnit" caption="Kabul Brm"></dxi-column>

    <dxi-column [width]="60" dataField="rejectedQty" caption="Ret"></dxi-column>
    <dxi-column [width]="45"[visible]="false" dataField="rejectedUnit" caption="Ret Brm"></dxi-column>

    <dxi-column [width]="60" dataField="missingQty" caption="Eksk"></dxi-column>
    <dxi-column [width]="45" [visible]="false" dataField="missingUnit" caption="Eksk Brm"></dxi-column>

    <dxi-column [width]="60" dataField="exceesQty" caption="Fazla"></dxi-column>
    <dxi-column [width]="45" [visible]="false" dataField="exceesUnit" caption="Fazla Brm"></dxi-column>

    <dxi-column dataField="rejectReason" caption="Ret Nedeni"></dxi-column>
    <dxi-column dataField="lateDeliveryDesc" caption="Geç Teslim Açk."></dxi-column>


    <!-- <dxi-column dataField="branchName" caption="{{'Şube' | translate}}" [width]="80"></dxi-column> -->


    <!-- <div *dxTemplate="let data of 'erpTemplate'">
        <i *ngIf="data.data.erp == 'success'" class="far fa-check-circle" style="font-size: 18px"></i>
        <i *ngIf="data.data.erp == 'new'" class="far fa-times-circle" style="font-size: 18px"></i>
        <i *ngIf="data.data.erp == 'error'" class="fas fa-exclamation" style="font-size: 18px"></i>
    </div>

    <dxo-summary>
        <dxi-total-item column="grandTotal" summaryType="sum" valueFormat="#,###.00" displayFormat="{0}">
        </dxi-total-item>
        <dxi-total-item column="invoiceNumber" summaryType="count">
        </dxi-total-item>
    </dxo-summary> -->

</dx-data-grid>