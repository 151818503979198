import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  Input,
  OnChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  InvoiceService,
  Invoice,
  typeInvoice,
  typeDocument,
  typeScenario,
  invoiceLog,
  logType,
  InvoiceDeliveryInofo,
} from "../services/invoice.service";
import { fromEvent } from "rxjs";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter,
  merge,
} from "rxjs/operators";
import { isString, isNumber } from "util";
import { CloudDataStoreService } from "projects/cloud-components/src/lib/database/cloud-data-store.service";
import { ClTextBoxComponent } from "projects/cloud-components/src/lib/cl-text-box/cl-text-box.component";
import { CityService } from "src/app/tools/services/city.service";
import { TownService } from "src/app/tools/services/town.service";
import { ClStore } from "projects/cloud-components/src/lib/database/cl-store";
import { CloudDbService, CloudLoginService } from "cloud-labs-core";
import { DxFormComponent } from "devextreme-angular";

@Component({
  selector: "app-invoice-form",
  templateUrl: "./invoice-form.component.html",
  styleUrls: ["./invoice-form.component.scss"],
})
export class InvoiceFormComponent implements OnInit, OnChanges {
  xps = "";
  cps = "";
  typeOfDispatch = 0;
  typesOfDispatch: any[] = [
    {
      title: "Nakliye Firması",
      value: true,
    },
    {
      title: "Sürücü",
      value: false,
    },
  ];

  @Input()
  activeDocument: any;
  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (changes.invoice) {
      this.invoice.label = "";

      if (this.invoice.account.mail == undefined) {
        this.invoice.account.mail = "";
      }

      // let accountStore2 = new ClStore(
      //   this.invoiceSrvc.cs,
      //   "accounts",
      //   "helpers"
      // );
      // accountStore2.clDataOptions.filter.field = "taxNo";
      // accountStore2.clDataOptions.filter.condition = "equal";
      // accountStore2.clDataOptions.filter.value = this.invoice.account.taxNumber;
      // // debugger
      // accountStore2.pageLoad().then((o) => {

      //   if (o.totalCount > 0) {
      //     this.labels = o.data[0].postBoxes;
      //   }
      // });
    }
  }
  now: Date = new Date();
  accountStore: ClStore;
  account;

  documentTypes = [];
  invoiceTypes = [];
  scenarios = [];
  labels = [];
  exemptions = [];
  stoppages = [];

  lval = true;
  @Input("invoice") set setInvoice(value: Invoice) {
    // console.log("Edited invoice:",value);
    this.invoice = value;
    console.log("invoiceType", this.invoice.typeScenario);
    this.invoice.label = "";

    if (this.invoice.account.mail == undefined) {
      this.invoice.account.mail = "";
    }
    let activeDocument;
    // debugger
    if (["eInvoice", "eDispatch"].includes(this.activeDocument)) {
      activeDocument = this.activeDocument;
    }
    let accountStore2 = new ClStore(
      this.invoiceSrvc.cs,
      "accounts",
      "helpers",
      activeDocument
    );
    accountStore2.clDataOptions.filter.field = "taxNo";
    accountStore2.clDataOptions.filter.condition = "equal";
    accountStore2.clDataOptions.filter.value = this.invoice.account.taxNumber;
    // debugger
    accountStore2.pageLoad().then((o) => {
      if (o.totalCount > 0) {
        this.labels = o.data[0].postBoxes;
      }
    });
    if (!this.invoice) {
      this.invoice.invoiceType = "dispatch";
      this.invoice.documentType = "eArchive";
    } else {
      if (this.invoice.account.mail == undefined) {
        this.invoice.account.mail = "";
      }

      this.invoice.deliveryInfo = this.invoice.deliveryInfo ?? {
        carrierName: "",
        vehiclePlateNo: "",
        isShippingCompany: true,
        carrierTaxNumber: "",
      };
    }
  }
  invoice: Invoice;
  @Input() invoiceEntry = false;
  @Output() formClose = new EventEmitter<string>();

  constructor(
    public cityService: CityService,
    public townService: TownService,
    public db: CloudDbService,
    private ls: CloudLoginService,
    public invoiceSrvc: InvoiceService,
    private cds: CloudDataStoreService
  ) {}

  @ViewChildren(ClTextBoxComponent) cltexts: QueryList<ClTextBoxComponent>;
  errors: string[] = [];
  ngOnInit() {
    this.cityService.createClDataSource();
    this.townService.createClDataSource();
    this.accountStore = new ClStore(this.invoiceSrvc.cs, "accounts", "helpers");

    this.db
      .getCollectionToPromise({
        _db: "helpers",
        col: "digital-invoice-reasons",
      })
      .then((o) => {
        this.stoppages = o.filter((x) => x.invoiceType == "stoppage");
        this.exemptions = o.filter((x) => x.invoiceType == "exemptions");

        this.stoppages.forEach((o) => {
          o.displayValue = o["id"] + " " + o["rate"] + " " + o["name"];
        });

        this.exemptions.forEach((o) => {
          o.displayValue = o["id"] + " " + o["name"];
        });
      });

    if (!this.invoice) {
      this.invoice.invoiceType = "dispatch";
      this.invoice.documentType = "eArchive";
    } else {
      if (this.invoice.account.mail == undefined) {
        this.invoice.account.mail = "";
      }

      this.invoice.deliveryInfo = this.invoice.deliveryInfo ?? {
        carrierName: "",
        vehiclePlateNo: "",
        isShippingCompany: true,
        carrierTaxNumber: "",
      };
    }

    this.documentTypes.push({ id: "eInvoice", name: "E-FATURA" });
    this.documentTypes.push({ id: "eArchive", name: "E-ARŞİV" });
    this.documentTypes.push({ id: "eDispatch", name: "E-İRSALİYE" });
    this.documentTypes.push({ id: "eDispatchReply", name: "E-İRSALİYE YANIT" });

    this.invoiceTypes.push({ id: "sale", name: "SATIŞ" });
    this.invoiceTypes.push({ id: "return", name: "İADE" });
    this.invoiceTypes.push({ id: "stoppage", name: "TEVKİFAT" });
    this.invoiceTypes.push({ id: "exemptions", name: "İSTİSNA" });
    this.invoiceTypes.push({ id: "export", name: "İHRAÇ KAYITLI" });
    this.invoiceTypes.push({ id: "special", name: "ÖZEL MATRAH" });
    this.invoiceTypes.push({ id: "ssn", name: "SGK" });
    this.invoiceTypes.push({ id: "dispatch", name: "SEVK" });

    this.scenarios.push({ id: "base", name: "TEMEL" });
    this.scenarios.push({ id: "merchant", name: "TİCARİ" });
    this.scenarios.push({ id: "export", name: "İHTACAT" });
    this.scenarios.push({ id: "baseDispatch", name: "İRSALİYE" });

    /*  this.exemptions.push({ id: "eArchive", name: 'NEDEN 1' })
    this.exemptions.push({ id: "eInvoice", name: 'NEDEN 2' })

    this.stoppages.push({ id: "eArchive", name: 'NEDEN 1' })
    this.stoppages.push({ id: "eInvoice", name: 'NEDEN 2' }) */
  }

  sel(e) {
    this.account = e;
    this.cltexts.forEach((es) => {});
  }

  save() {
    if (!this.validate()) {
      return;
    }
    this.formClose.emit("close");
    this.invoiceSrvc.upsert(this.invoice);
    this.addLog("log", this.invoice, "fatura-düzenleme", "başarılı");
  }

  addLog(lType: logType, inv: Invoice, title, message) {
    let log: invoiceLog = new invoiceLog();
    log.type = lType;
    log.title = title;
    log.message = message;
    log.userId = this.ls.userInfo.id;
    log.uuid = inv.uuid;
    if (!inv.logs) {
      inv["logs"] = [];
    }
    inv.logs.push(log);
    this.invoiceSrvc.addInvoiceLog(log).subscribe((x: any) => {
      console.log("addlog", x);
    });
  }

  validate(): boolean {
    this.errors = [];

    if (this.invoice.typeScenario === "baseDispatch") {
      if (this.invoice.deliveryInfo.isShippingCompany) {
        if (
          this.invoice.deliveryInfo.carrierTaxNumber?.trim().length === 0 ||
          this.invoice.deliveryInfo.carrierTaxNumber === undefined ||
          this.invoice.deliveryInfo.carrierTaxNumber === null
        ) {
          this.errors.push("Taşıyıcı Firmanın VKN  zorunludur.");
        }

        this.invoice.deliveryInfo = {
          ...new InvoiceDeliveryInofo(),
          isShippingCompany: this.invoice.deliveryInfo.isShippingCompany,
          carrierTaxNumber: this.invoice.deliveryInfo.carrierTaxNumber,
        };
      } else {
        if (
          this.invoice.deliveryInfo.carrierName?.trim().length === 0 ||
          this.invoice.deliveryInfo.carrierName === undefined ||
          this.invoice.deliveryInfo.carrierName === null
        ) {
          this.errors.push("Adı Soyad  zorunludur.");
        }
        if (
          this.invoice.deliveryInfo.vehiclePlateNo?.trim().length === 0 ||
          this.invoice.deliveryInfo.vehiclePlateNo === undefined ||
          this.invoice.deliveryInfo.vehiclePlateNo === null
        ) {
          this.errors.push("Araç Plaka  zorunludur.");
        }
        if (
          this.invoice.deliveryInfo.carrierTaxNumber?.trim().length === 0 ||
          this.invoice.deliveryInfo.carrierTaxNumber === undefined ||
          this.invoice.deliveryInfo.carrierTaxNumber === null
        ) {
          this.errors.push("TCKN  zorunludur.");
        }
      }
    }

    return this.errors.length === 0;
  }

  close() {
    this.formClose.emit("close");
  }

  townSelected(e) {
    console.log(e);
  }

  taxChange(e) {
    console.log(e);
  }
}
