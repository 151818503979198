import { CloudDbService } from 'cloud-labs-core';
import { GenericDataService } from '../../../../../projects/cloud-components/src/lib/database/generic-data.service';
import { Injectable, EventEmitter, Output } from '@angular/core';
import { StartupService } from 'src/app/services/startup.service';

@Injectable({
  providedIn: 'root'
})
export class ProductGroupService extends GenericDataService {

  collection = "menu-product_groups"

  public data: productGroup[]
  @Output() eventService = new EventEmitter<any>()
  public loaded: boolean = false

  constructor(public cs: CloudDbService) {
    super(cs)
  }

  getSubGroups(id): productGroup[] {
    return this.data.filter(p => p.parentID == id)
  }

}

export class productGroup {
  id: string = ""
  name: string = ""
  parentID: string = ""
  order: number = 0
  kerzzID: string = ""
  image: string = ""
  base64: string = ""
  hide: boolean = false
}