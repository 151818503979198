import { ProductGroupService } from 'src/app/product/product-groups/service/product-group.service';
import { CampaignsService } from '../../services/campaigns/campaigns.service';
import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../product/products/service/product.service';
import { stampCampaign } from 'src/app/services/campaigns/campaign-model';
import { GlobalsService } from 'src/app/services/globals.service';

@Component({
  selector: 'app-campaign-selector',
  templateUrl: './campaign-selector.component.html',
  styleUrls: ['./campaign-selector.component.scss']
})
export class CampaignSelectorComponent implements OnInit {

  stampItems = [
    {
      id: 1,
      name: "1-Linear Gauge"
    },
    {
      id: 2,
      name: "2-Circular Gauge"
    }
  ]

  loadingVisible = true
  stamp: stampCampaign = new stampCampaign()
  stampConversionRate = 0;
  stampNick = "Damga"
  pointNick = "Puan"
  value = "";
  constructor(public ps: ProductService, private glb: GlobalsService, private campaignService: CampaignsService, public pg: ProductGroupService) {
    this.stamp.id = "stampCampaign"
    this.stamp.active = true
    this.stamp.name = "stampCampaign"
  }

  saveCampaign() {
    this.loadingVisible = true
    this.campaignService.upsertStamp(this.stamp).then(o => {

      setTimeout(() => {
        this.loadingVisible = false
        this.glb.notice("Kayedildi", "info");
      }, 1000);

    })
  }


  ngOnInit() {
    this.campaignService.loadStampCampaignFromMongo().then(o => {
      this.stamp = this.campaignService.data
      this.ps.load().then(p => {
        this.loadingVisible = false
      })
    })

  }

  updateRow(e) {
    this.ps.upsert(e.data)
  }

}

export class starProduct {
  id: string = ""
  productId: string = ""
  group: string = ""
  name: string = ""
  star: number = 0
}
