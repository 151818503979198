<app-cl-select-box [bottomLine]="true" label="{{'Company Name' | translate}}" [displayExpr]="'accountName'"
  (selectionChanged)="sel($event)" [showRecordStatus]="true" [(value)]="activeInvoice.account.accountName"
  [searchExpr]="'accountName'" [clStore]="accountStore">

</app-cl-select-box>


<div>
  <table class="cl-grid" style="table-layout: fixed" role="presentation">
    <colgroup>
      <col [ngStyle]="{'width': colitem.width + 'px'}" *ngFor="let colitem of columns; index as cindex; last as last">
    </colgroup>
    <thead>
      <tr>
        <td>
          Ürün / Hizmet
        </td>
        <td>
          Miktar
        </td>
        <td>
          Br. Fiyat
        </td>
        <td>
          Vergi
        </td>
        <td>
          Toplam
        </td>
        <td>

        </td>
      </tr>
    </thead>
  </table>

  <div style="width: 100%; height: 400px; overflow: auto;  will-change: transform">
    <table class="cl-grid" style="table-layout: fixed" role="presentation">
      <colgroup>
        <col [ngStyle]="{'width': colitem.width + 'px'}" *ngFor="let colitem of columns; index as cindex; last as last">
      </colgroup>
      <tbody role="presentation">
        <tr *ngFor="let item of this.activeInvoice.invoiceRows; index as row_index">
          <td>
            <app-cl-select-box [bottomLine]="true" [displayExpr]="'name'" (selectionChanged)="sel($event)"
              [showRecordStatus]="true" [(value)]="item.name" [searchExpr]="'name'" [clStore]="productStore">
            </app-cl-select-box>
          </td>
          <td>
            <app-cl-text-box pointer="kg" (valueChange)="qtyChange($event, row_index)" type="number"
              [(value)]="item.quantity"></app-cl-text-box>
          </td>
          <td>
            <app-cl-text-box pointer="₺" (valueChange)="unitPriceChange($event, row_index)" type="number"
              [(value)]="item.unitPrice"></app-cl-text-box>
          </td>
          <td>
            <cl-drop-box [list]="vats" [displayExpr]="'name'" [valueExpr]="'id'" [(value)]="item.taxRate"></cl-drop-box>
            <!-- <app-cl-text-box pointer="%" type="number" [(value)]="item.taxRate"></app-cl-text-box> -->
          </td>
          <td>
            <app-cl-text-box pointer="₺" appMyCurrency (valueChange)="totalChange($event, row_index)" type="number"
              [(value)]="item.Total"></app-cl-text-box>
          </td>
          <td>
            <button style="border:0; color:white; background-color:transparent" (click)="add_row()">
              <i class="fas fa-plus"></i>
            </button>
            <button style="border:0; color: white; background-color:transparent" (click)="remove_row(row_index)">
              <i class="fas fa-minus"></i>
            </button>
          </td>
        </tr>
      </tbody>

    </table>
  </div>

  <div style="width: 300px; right: 0; position: absolute; padding: 10px;">


    <div style="display: flex; justify-content: space-between;">
      <div>
        Ara Toplam
      </div>
      <div style="text-align: right;">
        980,00₺
      </div>
    </div>

    <div style="display: flex; justify-content: space-between;">
      <div>
        Toplam KDV
      </div>
      <div style="text-align: right;">
        120,00₺
      </div>
    </div>


    <div style="display: flex; justify-content: space-between;">
      <div>
        Genel Toplam
      </div>
      <div style="text-align: right;">
        1.100,00₺
      </div>
    </div>
  </div>

</div>

<input appMycurrency>