import { Component, OnInit } from '@angular/core';
import { CentralOrderService } from '../services/central-order.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  constructor(public centralOrderService: CentralOrderService) { }

  ngOnInit(): void {


  }

  selectionChangedHandler(e) {
    if (e.selectedRowsData.length == 0) return;
    console.log(e.selectedRowsData);

    this.centralOrderService.selectedOrder = e.selectedRowsData[0];
  }


}
