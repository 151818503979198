import { Injectable } from '@angular/core';
import { GenericDataService } from 'projects/cloud-components/src/lib/database/generic-data.service';
import { CloudDbService } from 'cloud-labs-core';
import { contact } from 'src/app/services/tools/contacts.service';
import { StartupService } from 'src/app/services/startup.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends GenericDataService {

  collection = "company-accounts"

  constructor(public cs: CloudDbService) {
    super(cs)
  }
}


export class account {
  id = ""
  name = ""
  brandName = ""
  taxNo = ""
  taxHome = ""
  address = ""
  country: _def = new _def()
  city: _def = new _def()
  town: _def = new _def()
  phone: string = ""
  mail: string = ""
  contact: string = ""
  contacts: contact[] = []
}

export class _def {
  id: string = ""
  name: string = ""
}

