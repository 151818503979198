import { GlobalsService } from './../../services/globals.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GenericDataService } from '../../../../projects/cloud-components/src/lib/database/generic-data.service';
import { Injectable } from '@angular/core';
import { CloudDbService, CloudLoginService } from 'cloud-labs-core';
import { PublicApiService } from 'src/app/services/public-service/public-api.service';


const getUsers = "https://sso-service.kerzz.com:4500/api/user/getusers"
const updateRoles = "https://sso-service.kerzz.com:4500/api/user/updateroles"
const updateUser = "https://sso-service.kerzz.com:4500/api/user/updateuser"
const removeFormLicance = "https://sso-service.kerzz.com:4500/api/user/removefromlicance"
//const updateUser = "http://192.168.2.196:4500/api/user/updateuser"


@Injectable({
  providedIn: 'root'
})
export class AppUsersService {
  apiKey: string = "e5788b40-ec7e-0fe0-daf8-80c17445ab9d!?@f8a4!e347**11d3"
  collection = "users"
  users: temp_user[] = []
  roles
  appUsers : AppUsers[]  = []
  temp_users: temp_user[] = []
  minLevel: number

  constructor(public cs: CloudDbService, private ls: CloudLoginService, private http: HttpClient, private glb: GlobalsService,private publicApi : PublicApiService) {
    this.loadUsers()
    
  }

  getHeaders() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-api-key': this.apiKey ,'x-user-token' : this.ls.userInfo.accessToken })
    }
    return httpOptions
  }

  loadRoles(): Promise<any> {

    return new Promise((resolve, reject) => {
      let filter = { $and: [{ app_id: '21aa-9637' }] }
      this.cs.getCollectionToPromise({ _db: "sso-db", col: "roles", _filter: filter }).then(o => {
        console.log(o)
        this.roles = o
        let al = this.ls.getActiveLicance()
        al.roles.forEach(e => {
          e['level'] = this.roles.find(frl => frl.id == e.id).level
        })
        resolve(this.roles)
      })

    })
  }

  loadUsers() {

    this.loadRoles().then(async () => {
     await this.getAppUsers()
      this.http.post(getUsers, {
        licance_id: this.ls.getActiveLicance().licanceId
      },
        this.getHeaders()
      ).subscribe(o => {
        console.log("users", o)
        console.log("roles", this.ls.getActiveLicance().roles)
        console.log("isDeveloper", this.ls.getActiveLicance().roles.filter(o => o['developer'] == true).length)
        this.temp_users = Object.assign(o)

        //if (this.ls.getActiveLicance().roles.filter(o => o['developer'] == true).length == 0) {
        //  this.temp_users = this.temp_users.filter(o => o.rls.filter(tr => tr.developer == true).length == 0)
       // }
        this.users = this.temp_users
        console.log("USERS",this.users)

      })

    })
  }

  getAppUsers()
  {
    this.publicApi.getCollection({
      _db: 'sso-db',
      col: 'app-licances',
      _filter: { app_name: 'Cloudie',licance_id : this.ls.getActiveLicance().licanceId }
    })
    .toPromise()
    .then(async (o: any) => {
      this.appUsers = o
    })
  }

  updateRoles() {
    this.http.post(updateRoles, {
      licance_id: this.ls.getActiveLicance().licanceId,
      user_id: "",
      roles: []
    },
      this.getHeaders()
    ).subscribe(o => {
      console.log("users", o)
      //this.data = Object.assign(o)
    })
  }

  updateUser(data: temp_user): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(updateUser, {
        licance_id: this.ls.getActiveLicance().licanceId,
        name: data.name,
        email: data.email,
        phone: data.phone,
        roles: data.roles,
        isSuspend: data.isSuspend
      },
        this.getHeaders()
      ).subscribe(o => {
        resolve(o)
      }, e => {
        reject(e)
      })
    })
  }

  removeFromLicance(id) {
    this.http.post(removeFormLicance, {
      licance_id: this.ls.getActiveLicance().licanceId,
      user_id: id
    },
      this.getHeaders()
    ).subscribe(o => {
      console.log("users", o)
      //this.data = Object.assign(o)
    })
  }


  getUser(id): temp_user {
    return this.users.find(u => u.id == id)
  }

  getUserName(id): string {
    debugger
    let tu = this.appUsers.find(u => u.user_id == id)
    if (tu) {
      return tu.user_name
    } else  {
      return "<" + id + ">"
    }
  }


}

interface temp_user {
  id: string
  email: string
  name: string
  phone: string
  roles: string[]
  rls?: temp_roles[]
  active: boolean,
  isSuspend: boolean
}

interface AppUsers {
  id : string
  app_name : string
  app_id : string
  brand : string
  licance_id : string
  user_id : string
  user_name : string
}

interface temp_roles {
  id: string
  level: number
  name: string
  developer: boolean
}
