<div (click)="showUpload()" class="upload-div">
    <div class="progress flash progress-fade upload-progress">
        <progress max="100" [value]="(uploadProgress | async)" data-displayval="0%"></progress>
    </div>

    <label for="file-upload" [ngClass]="{'custom-file-upload':!uploading,'custom-file-progress':uploading}" class="custom-file-upload"
        style="width:80%">
        <div class="upload1" *ngIf="!uploading">
            {{'Upload' | translate}}
        </div>
        <div *ngIf="uploading" style="text-align: center">
            <span class="spinner spinner-inline-inverse spinner-sm">
            </span>
            <span>
                {{'Uploading' | translate}}
            </span>
        </div>
    </label>
    <div style="display:none;">
        <input #fileUpload style="visibility:hidden" id="fileUpload" (change)="upload($event)" accept=".png,.jpg,.gif,.svg" type="file"
        />
    </div>
</div>