import { CompanySettingsService } from './../services/company-settings.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.scss']
})
export class CompanySettingsComponent implements OnInit {

  loadIndicatorVisible = true
  constructor(public cSettings: CompanySettingsService) { }

  ngOnInit() {
  }

  save() {
    
  }

  countryChange(e)  {

    console.log("cityChange",e)
    this.cSettings.load_cities(e.alpha2Code)

  }
}
