import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NumericButtonsService {

  value: number = 0
  constructor() { }

  getValue(): number {
    let tempValue: number
    this.value = this.value == 0 ? 1 : this.value
    tempValue = this.value
    this.value = 0
    return tempValue
  }

}
