import { ProductService, product } from './service/product.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductGroupService } from '../product-groups/service/product-group.service';
import { UploadComponent } from '../../../../projects/cloud-components/src/lib/upload/upload.component';
import CustomStore from 'devextreme/data/custom_store';
import { CloudDbService } from 'cloud-labs-core';
import { ModalService } from 'projects/cloud-components/src/lib/modal/modal.service';
import { Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ClImageUploadComponent } from 'projects/cloud-components/src/lib/cl-image-upload/cl-image-upload.component';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  @ViewChild('clUpload', { static: true }) clUplod: ClImageUploadComponent
  @ViewChild('productImage', { static: true }) upload: UploadComponent
  loadingVisible = true;

  showFilterRow: boolean = true;
  showHeaderFilter: boolean = true;

  dataSource: any = {};

  columns

  select(o) {
  }

  constructor(
    private modalService: ModalService,
    private http: HttpClient,
    public ps: ProductService, public pg: ProductGroupService, private cdb: CloudDbService) {
  }

  ngOnInit() {

    this.ps.observerGenericDataService.subscribe(o => {
      if (o) {
        if (o.action == "loading") {
          this.loadingVisible = true
        } else {
          this.loadingVisible = false
        }
      }
    })

    this.ps.load()
    this.pg.load().then(o => {
      this.columns = [
        { name: 'name', caption: 'İsim', width: 200, type: 'text', filter : null },
        // { name: 'description', caption: 'Açıklama', width: 250, type: 'text', filter : null },
        { name: 'group', caption: 'Grup', width: 200, type: 'combo', filter : null, data: { source: this.pg.data, displayExpr: 'name', valueExpr: 'id' } },
        { name: 'price', caption: 'Fiyat', width: 80, type: "currency", filter : null },
        { name: 'kerzzID', caption: 'Kerzz ID', width: 100, filter : null },
        { name: 'base64', caption: 'Resim', width: 100, type: "image", filter : null },
      ]
    })
  }

  ngAfterViewInit() {
    this.ps.refresh()
    this.pg.refresh()
  }

  initRow(e) {
    e.data.vat = 8
  }

  updatedRow(e) {
    this.ps.upsert(e.data)
  }

  insertedRow(e) {
    this.ps.upsert(e.data)
  }

  deleteRow(e) {
    this.ps.delete(e.data)
  }

  editorPreparing(e) {
    if (e.parentType == "dataRow" && e.dataField == "group") {
      e.editorOptions.grouped = true;
      e.editorOptions.dataSource.group = "parentName";
    } else if (e.dataField == "color") {
      e.editorName = "dxColorBox";
    }
  }

  activeData
  uploadImage(data) {
    this.activeData = data.data.id
    this.upload.showUpload()
  }
  setPercent(e) {
    this.loadingVisible = true
  }
  setURL(e) {
    let data = this.ps.data.filter(p => p.id == this.activeData)[0]
    data.image = e
    this.ps.upsert(data)
    this.loadingVisible = false
  }

  setError(e) {
    setTimeout(() => {
      this.loadingVisible = false
    }, 2000);

  }



  activeImage
  successImage(e) {
    console.log(e);
    let data = this.ps.data.filter(p => p.id == this.activeData)[0]
    data.image = e
    this.ps.upsert(data)
    this.loadingVisible = false
  }

  errorImage(e) {

  }

  getBase64Image(img: HTMLImageElement) {
    // We create a HTML canvas object that will create a 2d image
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    // This will draw image    
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    var dataURL = canvas.toDataURL("image/png");
 return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
 }


  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      // create an image object
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      
      img.src = url;
      if (!img.complete) {
          // This will call another method that will create image from url
          img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
           observer.error(err);
        };
      } else {
          observer.next(this.getBase64Image(img));
          observer.complete();
      }
    });
 }

  showUpload(e) {
    this.activeData = e.data.id
    this.clUplod.value = e.data.image
    this.clUplod.init()
    this.modalService.open("image-upload")

  }
  
  
 

}
