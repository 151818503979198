<!-- <div class="sub-header">
    <span>
        Kullanıcı Segmentleri
    </span>
</div> -->

<div style="overflow:scroll; height:100vh;">
    <div class="section-title">
        <dx-button icon="plus" (click)="new()"></dx-button>
        Notifications
    </div>

    <div class="card">


        <dx-data-grid id="gridContainer" [dataSource]="ns.data" [showBorders]="true" style="margin:auto;" (onInitNewRow)="initRow($event)" (onRowInserted)="insertedRow($event)" (onRowUpdated)="updatedRow($event)">
            <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-search-panel [visible]="true" [width]="240" placeholder="Ara..."></dxo-search-panel>
            
            <dxi-column dataField="name"  caption="{{'Name' | translate}}"></dxi-column>
            <dxi-column dataField="title"  caption="{{'Title' | translate}}"></dxi-column>

            <dxi-column type="buttons"  caption="{{'Apps' | translate}}" cellTemplate="cellTemplate">
            </dxi-column>
    
            <div *dxTemplate="let data of 'cellTemplate'">
                <button class="upload-button" (click)="detay(data)">İncele</button>
                <button class="upload-button" (click)="delete(data)">Sil</button>
            </div>

        </dx-data-grid>

    </div>

    <div style="height: 200px;">

    </div>



</div>


<dx-popup class="popup" [width]="900" [height]="950" [showTitle]="true" title="{{'New Notification' | translate }}" [dragEnabled]="false" [closeOnOutsideClick]="true" [(visible)]="popupVisible">
    <div *dxTemplate="let data of 'content'">
        <div>
            <div class="dx-fieldset" style="height: 80%;">
                <div class="dx-field">
                    <div class="dx-field-label">{{'Name' | translate}}</div>
                    <div class="dx-field-value">
                        <dx-text-box [(value)]="notif.name" [disabled]="false" [readOnly]="false">
                            <dx-validator>
                                <dxi-validation-rule type="required" message="name"></dxi-validation-rule>
                            </dx-validator>
                        </dx-text-box>
                    </div>
                </div>

                <div class="dx-field">
                    <div class="dx-field-label">{{'Title' | translate}}</div>
                    <div class="dx-field-value">
                        <dx-text-box [(value)]="notif.title" [disabled]="false" [readOnly]="false">
                            <dx-validator>
                                <dxi-validation-rule type="required" message="title"></dxi-validation-rule>
                            </dx-validator>
                        </dx-text-box>
                    </div>
                </div>

                <div class="dx-field">
                    <div class="dx-field-label">{{'Body' | translate}}</div>
                    <div class="dx-field-value">
                        <dx-text-box [(value)]="notif.body" [disabled]="false" [readOnly]="false">
                            <dx-validator>
                                <dxi-validation-rule type="required" message="body"></dxi-validation-rule>
                            </dx-validator>
                        </dx-text-box>
                    </div>
                </div>

                  <div class="dx-field">
                    <div class="dx-field-label">{{'Filter' | translate}}</div>
                    <div class="dx-field-value">
                        <dx-text-area [(value)]="notif.filter" [disabled]="false" [readOnly]="true">
                        </dx-text-area>
                    </div>
                </div>
 
                <div class="dx-field">
                    <div class="dx-field-label">{{'Koşul' | translate}}</div>
                    <div class="dx-field-value">
                        <dx-filter-builder [fields]="fields" [(value)]="notif.filter" (onInitialized)="updateTexts($event)" (onValueChanged)="updateTexts($event)">
                            <!--     <dx-tag-box *dxTemplate="let condition of 'tagBoxTemplate'" [value]="condition.value" [items]="categories" (onValueChanged)="condition.setValue($event.value)" width="auto">
                            </dx-tag-box> -->
                        </dx-filter-builder>
                    </div>
                </div>


                <div class="dx-field">
                    <div class="dx-field-label">{{'Zaman' | translate}}</div>
                    <div class="dx-field-value">
                        <dx-filter-builder [fields]="timeFields" [(value)]="notif.timeFilter" (onInitialized)="updateTexts($event)" (onValueChanged)="updateTexts($event)">
                            <!--     <dx-tag-box *dxTemplate="let condition of 'tagBoxTemplate'" [value]="condition.value" [items]="categories" (onValueChanged)="condition.setValue($event.value)" width="auto">
                            </dx-tag-box> -->
                        </dx-filter-builder>
                    </div>
                </div>


            </div>
        </div>

        <!-- Edit Panel Footer -->
        <div class="edit-panel-footer">
            <div style="display: flex; flex-direction: row; justify-content: space-evenly;">
                <dx-button text="İptal" (click)="popupVisible = false;" type="cancel">
                </dx-button>
                <dx-button icon="check" text="Kaydet" type="success" (onClick)="save()">
                    <div *dxTemplate="let data of 'content'">
                        <dx-load-indicator class='button-indicator' [visible]="loadingVisible">
                        </dx-load-indicator>
                        <span class='dx-button-text'>{{'Save' | translate}}</span>
                    </div>
                </dx-button>
            </div>
        </div>
    </div>
</dx-popup>