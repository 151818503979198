import { CloudDbService } from 'cloud-labs-core';
import { GenericDataService } from 'projects/cloud-components/src/lib/database/generic-data.service';
import { Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FaqService extends GenericDataService {

  collection = "loyalty-faq"
  public data: faqs[]

  constructor(public cs: CloudDbService) {
    super(cs)
  }

  getSubFaqs(id): faqs[] {
    return this.data.filter(p => p.parentID == id)//this.afs.collection(this.glb.getPath("menu/product_groups"), ref => ref.where("parentID", "==", id)).valueChanges()
  }
 
}

export class faqs {
  id: string
  question: string
  answert: string
  hide: boolean
  image: string
  parentID: string
  order: number
}