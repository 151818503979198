import { CloudLoginService } from 'cloud-labs-core';
import { GlobalsService } from './../services/globals.service';
import { Component, OnInit, ElementRef } from '@angular/core';
import { StartupService } from '../services/startup.service';
import dx from 'devextreme/ui/themes';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  theme: string = "generic.darky"
  name: string
  showMenu: boolean = false
  constructor(
    public ls: CloudLoginService,
    private elementref: ElementRef,
    public glb: GlobalsService, private ss: StartupService) {
     
     }

  ngOnInit() {
    if (this.glb.ssoUsers)
      this.name = this.glb.ssoUsers.userInfo.userName
  }

  logOut() {
   this.ls.logout()
  }

  logo() {
    this.showMenu = this.showMenu ? false : true
    this.ss.subject_veritcal_nav.next(this.showMenu)
  }

  //notifiy to vertical-nav-component
  changeCompany() {
    this.ss.subject_global_events.next({ id: "change-company", data: "" })
  }

  changeTheme() {

    this.theme = this.theme == "generic.darky" ? "generic.lighty" : "generic.darky"

    this.ss.subject_global_events.next({ id: "changeTheme", data: this.theme })

    dx.current(this.theme);

  }

  changeBaseTheme(theme: string) {

    let menu_background = "white"
    let menu_selected = "#7ab8eb"
    let menu_sub_selected = "#7ab8eb"
    let menu_text = "black"
    let menu_logo_text = "#7E72F2"
    let workspace_background = "#ffffff"
    let workspace_text = "#000000"
    let header_background = "#ffffff"
    let header_text = "black"



    if (theme.indexOf("darky") > -1) {

      menu_background = "#0F1642"
      menu_selected = "#9E94FF"
      menu_sub_selected = "#978CFC"
      menu_text = "white"
      menu_logo_text = "#7E72F2"
      workspace_background = "#2B3253"
      workspace_text = "white"
      header_background = "#0F1642"
      header_text = "white"

    }

    this.elementref.nativeElement.style.setProperty('--menu-background', menu_background)
    this.elementref.nativeElement.style.setProperty('--menu-selected', menu_selected)
    this.elementref.nativeElement.style.setProperty('--menu-sub-selected', menu_sub_selected)
    this.elementref.nativeElement.style.setProperty('--menu-text', menu_text)
    this.elementref.nativeElement.style.setProperty('--menu-logo-text', menu_logo_text)
    this.elementref.nativeElement.style.setProperty('--workspace-background', workspace_background)
    this.elementref.nativeElement.style.setProperty('--workspace-text', workspace_text)
    this.elementref.nativeElement.style.setProperty('--header-background', header_background)
    this.elementref.nativeElement.style.setProperty('--menu-background', menu_background)
    this.elementref.nativeElement.style.setProperty('--header-text', header_text)

  }

 


}
