<div style="overflow:scroll; height:100vh;" id="settings">
    <div class="section-title">
        Loyalty Settings
    </div>

    <div class="card">
        <div class="card-line">
            <div class="card-title">{{"Brand" | translate}}</div>
            <div class="card-value">
                <dx-text-box [(value)]="settings.brand" [showClearButton]="true">
                </dx-text-box>

            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"Products Menu Text" | translate}}</div>
            <div class="card-value on-off-card">
                <dx-text-box [disabled]="!settings.productsMenuEnabled" #productsMenu [(value)]="settings.productsMenu"
                    [showClearButton]="true">
                </dx-text-box>
                <dx-switch [(value)]="settings.productsMenuEnabled"></dx-switch>
            </div>
        </div>


        <div class="card-line">
            <div class="card-title">{{"Bonus Menu Text" | translate}}</div>
            <div class="card-value on-off-card">
                <dx-text-box [disabled]="!settings.bonusMenuEnabled" [(value)]="settings.bonusMenu"
                    [showClearButton]="true">
                </dx-text-box>
                <dx-switch [(value)]="settings.bonusMenuEnabled"></dx-switch>
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"News Menu Text" | translate}}</div>
            <div class="card-value on-off-card">
                <dx-text-box [disabled]="!settings.newsMenuEnabled" [(value)]="settings.newsMenu"
                    [showClearButton]="true">
                </dx-text-box>
                <dx-switch [(value)]="settings.newsMenuEnabled"></dx-switch>
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"More Menu Text" | translate}}</div>
            <div class="card-value on-off-card">
                <dx-text-box [disabled]="!settings.moreMenuEnabled" [(value)]="settings.moreMenu"
                    [showClearButton]="true">
                </dx-text-box>
                <dx-switch [(value)]="settings.moreMenuEnabled"></dx-switch>
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"FCM Access Token" | translate}}</div>
            <div class="card-value on-off-card">
                <dx-text-box [(value)]="settings.fcmToken" [showClearButton]="true">
                </dx-text-box>
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"Şubelerimiz Menü Metni" | translate}}</div>
            <div class="card-value on-off-card">
                <dx-text-box [(value)]="settings.branchesMenu" [showClearButton]="true">
                </dx-text-box>
            </div>
        </div>


        <div class="card-line">
            <div class="card-title">{{"Haberler Yan Menüde" | translate}}</div>
            <div class="card-value">
                <dx-switch [(value)]="settings.newsSideMenu"></dx-switch>
            </div>
        </div>


        <div class="card-line">
            <div class="card-title">{{"Dizayn Kodu" | translate}}</div>
            <div class="card-value on-off-card">
                <dx-text-box [(value)]="settings.designCode" [showClearButton]="true">
                </dx-text-box>
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"Logged Out Text" | translate}}</div>
            <div class="card-value" style="width: 400px; height: 150px;">
               <dx-text-area [height]="150" [(value)]="settings.loggedOutText">
                </dx-text-area> 
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"SMS Kodu" | translate}}</div>
            <div class="card-value on-off-card">
                <dx-text-box [(value)]="settings.smsUrl" [showClearButton]="true">
                </dx-text-box>
            </div>
        </div>

        <dx-button text="Kaydet" (onClick)="save()">
        </dx-button>
    </div>




    <!-- CAPABILITIES -->
    <div class="section-title">
        {{'Capabilities' | translate}}
    </div>

    <div class="card">

        <div class="card-line">
            <div class="card-title">{{"Bonus Program" | translate}}</div>
            <div class="card-value">
                <dx-switch [(value)]="settings.capabilities.bonusProgram"></dx-switch>
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"Stamp Program" | translate}}</div>
            <div class="card-value">
                <dx-switch [(value)]="settings.capabilities.stampProgram"></dx-switch>
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"Table Service" | translate}}</div>
            <div class="card-value">
                <dx-switch [(value)]="settings.capabilities.tableService"></dx-switch>
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"Take Away Service" | translate}}</div>
            <div class="card-value">
                <dx-switch [(value)]="settings.capabilities.takeAWayService"></dx-switch>
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"Delivery Service" | translate}}</div>
            <div class="card-value">
                <dx-switch [(value)]="settings.capabilities.deliveryService"></dx-switch>
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"Online Reservation" | translate}}</div>
            <div class="card-value">
                <dx-switch [(value)]="settings.capabilities.onlineReservation"></dx-switch>
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"Reservation Form" | translate}}</div>
            <div class="card-value">
                <dx-switch [(value)]="settings.capabilities.reservationForm"></dx-switch>
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"Guest Login" | translate}}</div>
            <div class="card-value">
                <dx-switch [(value)]="settings.capabilities.guestLogin"></dx-switch>
            </div>
        </div>



        <dx-button text="Kaydet" (onClick)="save()">
        </dx-button>

    </div>


    <!-- ICONS -->
    <div class="section-title">
        {{'Icons' | translate}}
    </div>

    <div class="card">

        <div class="card-line">
            <div class="card-title">{{"Bonus Page" | translate}}</div>
            <div class="card-value" style="display: flex; flex-direction: row">
                <img class="header-logo" src="{{this.settings.icons.bonusPage}}" />
                <dx-button text="Dosya Seç" (onClick)="uploadImage('bonusPage')">
                </dx-button>
            </div>
        </div>


        <div class="card-line">
            <div class="card-title">{{"News Page" | translate}}</div>
            <div class="card-value" style="display: flex; flex-direction: row">
                <img class="background-logo" src="{{this.settings.icons.newsPage}}" />
                <dx-button text="Dosya Seç" (onClick)="uploadImage('newsPage')">
                </dx-button>
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"Products Page" | translate}}</div>
            <div class="card-value" style="display: flex; flex-direction: row">
                <img class="background-image" src="{{settings.icons.productPage}}" />
                <dx-button text="Dosya Seç" (onClick)="uploadImage('productsPage')">
                </dx-button>
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"More Page" | translate}}</div>
            <div class="card-value" style="display: flex; flex-direction: row">
                <img class="background-image" src="{{settings.icons.productPage}}" />
                <dx-button text="Dosya Seç" (onClick)="uploadImage('productsPage')">
                </dx-button>
            </div>
        </div>
        <dx-button text="Kaydet" (onClick)="save()">
        </dx-button>

    </div>



    <!-- IMAGES -->
    <div class="section-title">
        {{'Images' | translate}}
    </div>

    <div class="card">

        <div class="card-line">
            <div class="card-title">
                <div>{{"Header" | translate}}</div>
                <dx-button text="Dosya Seç" (onClick)="uploadImage('header')">
                </dx-button>
                <dx-button text="Sil" (onClick)="removeImage('header')">
                </dx-button>
            </div>

            <div class="card-value" style="display: flex; flex-direction: row">
                <img class="header-logo" src="{{settings.images.header}}" />

            </div>
        </div>


        <div class="card-line">
            <div class="card-title">
                <div>
                    {{"Background Logo" | translate}}
                </div>
                <dx-button text="Dosya Seç" (onClick)="uploadImage('backgroundLogo')">
                </dx-button>
                <dx-button text="Sil" (onClick)="removeImage('backgroundLogo')">
                </dx-button>
            </div>
            <div class="card-value" style="display: flex; flex-direction: row">
                <img class="background-logo" src="{{settings.images.backgroundLogo}}" />

            </div>
        </div>

        <div class="card-line">
            <div class="card-title">
                <div>
                    {{"Background Image" | translate}}
                </div>
                <div>
                    <dx-button text="Dosya Seç" (onClick)="uploadImage('backgroundImage')">
                    </dx-button>
                    <dx-button text="Sil" (onClick)="removeImage('backgroundImage')">
                    </dx-button>
                </div>
            </div>
            <div class="card-value" style="display: flex; flex-direction: row">
                <img class="background-image" src="{{settings.images.backgroundImage}}" />

            </div>
        </div>

        <div class="card-line">
            <div class="card-title">
                <div>
                    {{"Scratch Cover Image" | translate}}
                </div>
                <div>
                    <dx-button text="Dosya Seç" (onClick)="uploadImage('scratchImage')">
                    </dx-button>

                    <dx-button text="Sil" (onClick)="removeImage('scratchImage')">
                    </dx-button>
                </div>

            </div>
            <div class="card-value" style="display: flex; flex-direction: row">
                <img class="background-image" src="{{settings.images.scratchImage}}" />

            </div>
        </div>


        <div class="card-line">
            <div class="card-title">
                <div>
                    {{"Stamp Circular Icon" | translate}}
                </div>
                <div>
                    <dx-button text="Dosya Seç" (onClick)="uploadImage('stampCircularIcon')">
                    </dx-button>
                    <dx-button text="Sil" (onClick)="removeImage('stampCircularIcon')">
                    </dx-button>
                </div>
            </div>
            <div class="card-value" style="display: flex; flex-direction: row">
                <img class="background-image" src="{{settings.images.stampCircularIcon}}" />
            </div>
        </div>


        <div class="card-line">
            <div class="card-title">
                <div>
                    {{"Stamp Circular Image" | translate}}
                </div>
                <div>
                    <dx-button text="Dosya Seç" (onClick)="uploadImage('stampCircularImage')">
                    </dx-button>
                    <dx-button text="Sil" (onClick)="removeImage('stampCircularImage')">
                    </dx-button>
                </div>
            </div>
            <div class="card-value" style="display: flex; flex-direction: row">
                <img class="background-image" src="{{settings.images.stampCircularImage}}" />
            </div>
        </div>




        <div class="card-line">
            <div class="card-title">
                <div>
                    {{"Stamp Anime Item Filled" | translate}}
                </div>
                <div>
                    <dx-button text="Dosya Seç" (onClick)="uploadImage('stampAnimeItemFilled')">
                    </dx-button>
                    <dx-button text="Sil" (onClick)="removeImage('stampAnimeItemFilled')">
                    </dx-button>
                </div>
            </div>
            <div class="card-value" style="display: flex; flex-direction: row">
                <img class="background-image" src="{{settings.images.stampAnimeItemFilled}}" />
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">
                <div>
                    {{"Stamp Anime Item" | translate}}
                </div>
                <div>
                    <dx-button text="Dosya Seç" (onClick)="uploadImage('stampAnimeItem')">
                    </dx-button>
                    <dx-button text="Sil" (onClick)="removeImage('stampAnimeItem')">
                    </dx-button>
                </div>
            </div>
            <div class="card-value" style="display: flex; flex-direction: row">
                <img class="background-image" src="{{settings.images.stampAnimeItem}}" />
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">
                <div>
                    {{"Stamp Anime Gift" | translate}}
                </div>
                <div>
                    <dx-button text="Dosya Seç" (onClick)="uploadImage('stampAnimeGift')">
                    </dx-button>
                    <dx-button text="Sil" (onClick)="removeImage('stampAnimeGift')">
                    </dx-button>
                </div>
            </div>
            <div class="card-value" style="display: flex; flex-direction: row">
                <img class="background-image" src="{{settings.images.stampAnimeGift}}" />
            </div>
        </div>



        <div class="card-line">
            <div class="card-title">
                <div>
                    {{"Stamp Background" | translate}}
                </div>
                <div>
                    <dx-button text="Dosya Seç" (onClick)="uploadImage('stampBackground')">
                    </dx-button>
                    <dx-button text="Sil" (onClick)="removeImage('stampBackground')">
                    </dx-button>
                </div>
            </div>
            <div class="card-value" style="display: flex; flex-direction: row">
                <img class="background-image" src="{{settings.images.stampBackground}}" />
            </div>
        </div>





        <dx-button text="Kaydet" (onClick)="save()">
        </dx-button>

    </div>


    <!-- COLORS -->
    <div class="section-title">
        {{'Colors' | translate}}
    </div>

    <div class="card">

        <div class="card-line">
            <div class="card-title">{{"Background" | translate}}</div>
            <div class="card-value">
                <dx-color-box [(value)]="settings.colors.background" [editAlphaChannel]=true></dx-color-box>
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"Froreground" | translate}}</div>
            <div class="card-value">
                <dx-color-box [(value)]="settings.colors.foreground" [editAlphaChannel]=true></dx-color-box>
            </div>
        </div>


        <div class="card-line">
            <div class="card-title">{{"Header Background" | translate}}</div>
            <div class="card-value">
                <dx-color-box [(value)]="settings.colors.headerBackground" [editAlphaChannel]=true></dx-color-box>
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"Header Foreground" | translate}}</div>
            <div class="card-value">
                <dx-color-box [(value)]="settings.colors.headerForeground" [editAlphaChannel]=true></dx-color-box>
            </div>
        </div>


        <div class="card-line">
            <div class="card-title">{{"Footer Background" | translate}}</div>
            <div class="card-value">
                <dx-color-box [(value)]="settings.colors.footerBackground" [editAlphaChannel]=true></dx-color-box>
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"Footer Foreground" | translate}}</div>
            <div class="card-value">
                <dx-color-box [(value)]="settings.colors.footerForeground" [editAlphaChannel]=true></dx-color-box>
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"Success" | translate}}</div>
            <div class="card-value">
                <dx-color-box [(value)]="settings.colors.success" [editAlphaChannel]=true></dx-color-box>
            </div>
        </div>


        <div class="card-line">
            <div class="card-title">{{"Warning" | translate}}</div>
            <div class="card-value">
                <dx-color-box [(value)]="settings.colors.warning" [editAlphaChannel]=true></dx-color-box>
            </div>
        </div>




        <div class="card-line">
            <div class="card-title">{{"Danger" | translate}}</div>
            <div class="card-value">
                <dx-color-box [(value)]="settings.colors.danger" [editAlphaChannel]=true></dx-color-box>
            </div>
        </div>



        <div class="card-line">
            <div class="card-title">{{"Stamp Circular Circle" | translate}}</div>
            <div class="card-value">
                <dx-color-box [(value)]="settings.colors.stampCircleColor" [editAlphaChannel]=true></dx-color-box>
            </div>
        </div>


        <div class="card-line">
            <div class="card-title">{{"Stamp Circular Base" | translate}}</div>
            <div class="card-value">
                <dx-color-box [(value)]="settings.colors.stampCircularBaseColor" [editAlphaChannel]=true></dx-color-box>
            </div>
        </div>


        <div class="card-line">
            <div class="card-title">{{"Stamp Circular Circle" | translate}}</div>
            <div class="card-value">
                <dx-color-box [(value)]="settings.colors.stampCircleColor" [editAlphaChannel]=true></dx-color-box>
            </div>
        </div>


        <div class="card-line">
            <div class="card-title">{{"Stamp Circular Base" | translate}}</div>
            <div class="card-value">
                <dx-color-box [(value)]="settings.colors.stampCircularBaseColor" [editAlphaChannel]=true></dx-color-box>
            </div>
        </div>



        <div class="card-line">
            <div class="card-title">{{"Stamp Circular Text" | translate}}</div>
            <div class="card-value">
                <dx-color-box [(value)]="settings.colors.stampCircularTextColor" [editAlphaChannel]=true></dx-color-box>
            </div>
        </div>

        <dx-button text="Kaydet" (onClick)="save()">
        </dx-button>


    </div>


    <!-- LINKS -->
    <div class="section-title">
        {{'Links' | translate}}
    </div>




    <div class="card">
        <dx-button text="Ekle" (onClick)="addLink()"></dx-button>
        <div class="card-line" *ngFor="let item of settings.links; let i = index; ">
            <div class="card-title">#{{i}} {{"Link/Url" | translate}}</div>
            <div class="card-value" style="width: 100% !important;">
                <div style="display: flex;">
                    <dx-text-box style="width: 35%;" [(value)]="item.caption" placeholder="Caption"
                        [showClearButton]="true">
                    </dx-text-box>
                    <dx-text-box style="width: 55%;" [(value)]="item.url" placeholder="Url" [showClearButton]="true">
                    </dx-text-box>
                    <dx-button style="width: 10%;" text="Sil" (onClick)="removeLink(i)">
                    </dx-button>
                </div>


            </div>
        </div>

        <dx-button text="Kaydet" (onClick)="save()">
        </dx-button>
    </div>


    <dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#settings' }"
        [(visible)]="loadingVisible" [showIndicator]="true" [showPane]="true" [shading]="true"
        [closeOnOutsideClick]="false">

    </dx-load-panel>

    <div>
        <app-upload #productImage (uploadError)="setError($event)" (url)="setURL($event)" (percent)="setPercent($event)"
            folder="product"></app-upload>
    </div>

    <div style="height: 200px;">

    </div>
</div>

<jw-modal id="image-upload">
    <app-cl-image-upload [(value)]="activeImage" #clUpload 
        [maxSize]="500*1024" (error)="errorImage($event)" (success)="successImage($event)"></app-cl-image-upload>
</jw-modal>