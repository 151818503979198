import { UploadComponent } from '../../../../projects/cloud-components/src/lib/upload/upload.component';
import { FaqService, faqs } from './faq.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BranchesComponent } from '../branches/branches.component';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent  implements OnInit {

  @ViewChild('productImage') upload: UploadComponent

  showFilterRow: boolean = true;
  showHeaderFilter: boolean = true;
  loadingVisible = false;
  activeImage = "";
  showImage: boolean = true;

  constructor(public faqService: FaqService ) { }

  ngOnInit() {
    this.faqService.load()
  }


  initRow(e) {
    //e.data.id = this.faqService.createId();
    if (e.data.parentID == 0) {
      e.data.parentID = "0"
    }
  }

  updateRow(e) {
    this.faqService.upsert(e.data)
  }

  insertRow(e) {
    this.faqService.upsert(e.data)
  }

  imageClick(data) {
    this.activeImage = data.data.image
    this.showImage = true
  }

  async deleteRow(e) {
    this.faqService.delete( {id : e.data.id} )
  }


  activeData
  uploadImage(data) {
    this.activeData = data.data.id
    this.upload.showUpload()
  }

  setPercent(e) {
    this.loadingVisible = true
  }
  setURL(e) {
    let data = this.faqService.data.filter(p => p.id == this.activeData)[0]
    data.image = e
    this.faqService.upsert(data)
    this.loadingVisible = false
  }

  setError(e) {
    setTimeout(() => {
      this.loadingVisible = false
      this.loadingVisible = false
    }, 2000);
  }

}
