
export class loyaltyUserObject {
    id: string
    status: string = "loggedout" // loggedin, expires
    userName: string
    displayName: string
    name: string
    surename: string
    phone: string
    licances: _sso_licances_object[]
    profile: userProfileDetailsObject = new userProfileDetailsObject()
    pushToken: string
    totalSpend: number
    totaStamp: number
    totalWin: number
    segment: string
    isActive = true
  }
  
  export class userProfileDetailsObject {
    gender: string = ""
    birthdate: Date = new Date()
    photo: string = ""
    notifyEmail: boolean = true
    notifySms: boolean = true
    notifyPhone: boolean = true
  }
  
  export class _sso_licances_object {
    id: string
    name: string
  }
  
  export class ssoObject {
    name: string = ""
    surname: string = ""
    mail: string = ""
    phone: string = ""
    password: string = ""
    userType: string = ""
    authGroup: string = ""
    authDetails: string = ""
    lang: string = ""
    licances: string = ""
  }
  
  export class _sso_login_response_object {
    expiresIn: string
    iat: number
    issuer: string
    userInfo: _sso_user_info_object
  
  }
  
  export class _sso_user_info_object {
    autDetails: string
    authGroup: string
    id: string
    isVerified: boolean
    lang: string
    licances: _sso_licances_object[]
    mail: string
    name: string
    phone: string
    surname: string
    userName: string
    userType: string
  }
  
  export class userAddressObject {
    id: string
    name: string
    country: { id: string, name: string }
    county: { id: string, name: string }
    street: string
    reciepe: string
  }
  
  
  export enum State {
    nothing = 'nothing', initializing = 'initializing', loggedin = 'loggedin', loggedout = 'loggedout'
  }