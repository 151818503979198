export class Branch {
    branchCode = ""
    branchName = ""
}

export class WareHouse {
    branchCode: string = ""
    warehouseCode: number= 0
    warehouseName: string = ""
}

export class PosAccount {
    accountCode: string = ""
    accountName: string = ""
    taxNumber: string = ""
}

export class Inventory {
    groupName: string = ""
    inventoryName: string = ""
    inventroyCode: string = ""
}

export class Unit {
    unitCode: string = ""
    unitName: string = ""
    unitMultiplier: 0
    isBaseUnit: true
    isPonderable: true
    isActive: true
}

export class MappingItem {    
    branchCode: any = 0 //sube kodu zorunlu
    itemCode = "" //stok ise stok kodu, cari ise vergi no
    warehouseCode = 0 //stok ise kullanilacak
    unitCode ="" //stok ise kullanilacak
    itemType: "inventory" | "account" = "account"
    externalCode = "" // kerzzdeki stok kodu veya cari kodu
}