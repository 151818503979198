import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CloudLoginService } from "cloud-labs-core";
import { IdGeneratorService } from "src/app/invoice/services/id-generator.service";
import { InvoiceSettingsService } from "src/app/invoice/services/invoice-settings.service";
import {
  mongo_delete_params,
  mongo_get_params,
  mongo_upsert_params,
} from "./dto";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PublicApiService {
  apiKey = "MzR5cjgyMsSxdXJmZmcyamdydWZncmV1amRmbmJodWQ";
  postMongoUrl = "https://public.kerzz.com:50502/api/database/dataAction";
  constructor(
    private http: HttpClient,
    private ig: IdGeneratorService,
    private ls: CloudLoginService
  ) {}

  getCollection(prm: mongo_get_params): Observable<any> {
    let filter = prm._filter ? prm._filter : {};
    return this.http.post(
      this.postMongoUrl,
      {
        job: "get",
        database: prm._db,
        collection: prm.col,
        filter: filter,
        projection: prm._project,
        limit: prm._limit,
        skip: prm._skip,
        sort: prm._sort,
        withTotalCount: prm._withTotalCount,
      },
      this.getHeaders()
    );
  }

  upsert(prm: mongo_upsert_params): Promise<any> {
    if (!prm.data.id) {
      prm.data.id = this.ig.generate();
    }
    let filter = prm.filter;
    if (!prm.filter) {
      filter = { id: prm.data.id };
    }
    return this.http
      .post(
        this.postMongoUrl,
        {
          job: "upsert",
          database: prm._db,
          collection: prm.col,
          filter: filter,
          data: prm.data,
        },
        this.getHeaders()
      )
      .toPromise();
  }

  upsertv2(prm: mongo_upsert_params): Promise<any> {
    let filter = { taxNumber: prm.data.taxNumber };
    return this.http
      .post(
        this.postMongoUrl,
        {
          job: "upsert",
          database: prm._db,
          collection: prm.col,
          filter: filter,
          data: prm.data,
        },
        this.getHeaders()
      )
      .toPromise();
  }

  delete(prm: mongo_delete_params): Promise<any> {
    let filter = prm.filter;
    return this.http
      .post(
        this.postMongoUrl,
        {
          job: "delete",
          database: prm._db,
          collection: prm.col,
          filter: filter,
        },
        this.getHeaders()
      )
      .toPromise();
  }

  getHeaders() {
    let httpOptions = {
      headers: new HttpHeaders({
        "x-user-token": this.ls.acessToken,
        "x-api-key": this.apiKey,
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    };
    return httpOptions;
  }
}
