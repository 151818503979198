import { Component, OnInit } from "@angular/core";
import {
  Invoice,
  invoiceRow,
  InvoiceService,
} from "src/app/invoice/services/invoice.service";
import { CloudDbService, IdGeneratorService } from "cloud-labs-core";
import notify from "devextreme/ui/notify";
import moment from "moment";
import { Branch } from "src/app/invoice/models/global-model";
import { GlobalsService } from "src/app/services/globals.service";

@Component({
  selector: "app-outbound-dispatch-form",
  templateUrl: "./outbound-dispatch-form.component.html",
  styleUrls: ["./outbound-dispatch-form.component.scss"],
})
export class OutboundDispatchFormComponent implements OnInit {
  selectedRows = [];
  _taxno = "";
  _accountName = "";
  inv: Invoice = new Invoice();
  branchCode : string
  branchName : string
  date = moment()
  today = this.date.endOf('day').toString();

  /* 1   KL  0   Koli
2   KT  0   Kutu
3   PK  0   Paket
4   KG  1   Kilo
5   GR  1   Gram
6   LT  1   Litre
7   ML  1   Mililitre
8   CL  1   Santilitre
9   MT  1   Metre
10  AD  0   Adet
11  PR  1   Porsiyon
12  TP  0   Top
13  CV  0   Çuval
14  TN  0   Teneke
 */
  units = [
    { id: "KL", name: "Koli" },
    { id: "KT", name: "Kutu" },
    { id: "PK", name: "Paket" },
    { id: "KG", name: "Kilo" },
    { id: "GR", name: "Gram" },
    { id: "LT", name: "Litre" },
    { id: "ML", name: "Mililitre" },
    { id: "CL", name: "Santilitre" },
    { id: "MT", name: "Metre" },
    { id: "AD", name: "Adet" },
    { id: "PR", name: "Porsiyon" },
    { id: "TP", name: "Top" },
    { id: "CV", name: "Çuval" },
    { id: "TN", name: "Teneke" },
  ];

  constructor(
    private db: CloudDbService,
    private ig: IdGeneratorService,
    public is: InvoiceService,
    private glb : GlobalsService
  ) {
    this.getBrancList()
  }

  ngOnInit(): void {}

  taxno(e) {
    this.db
      .getCollection({
        col: "accounts",
        _db: "helpers",
        _filter: { taxNo: e.value, documentType: "eDispatch"  },
      })
      .subscribe((o) => {
        if (o.length > 0) {
          this.inv.account.accountName = o[0].accountName;
        }
      });
  }

  selectionChangedHandler(e) {}

  save(e) {
    let result = e.validationGroup.validate();
    if (!result.isValid) {
      return;
    }

    if (this.is.branchList.length === 0)
    {
      this.inv.branchCode = "0"
      this.inv.branchName = this.glb.activeBrand
    }
    else
  {
    this.inv.branchName = this.is.branchList.find(x=>x.branchCode == this.inv.branchCode).branchName
  }

    this.inv.reference = this.ig.generateMaxi();
    this.inv.documentType = "eDispatch";
    this.inv.invoiceType = "dispatch";
    this.inv.typeScenario = "baseDispatch";
    this.inv.direction = "outBound";
    this.inv.status = "standby";
    this.inv.source = 'erp'
    this.inv.grandTotal = this.inv.invoiceRows.reduce((sum, item) => sum + item.total, 0)

    if (this.inv.invoiceRows.length == 0) {
      notify("İrsaliye Satır Kaydı Yok !", "error");
      return;
    }

    this.is.saveInvoice(this.inv).subscribe(
      (o) => {
        notify("İrsaliye Kayıt Edildi", "info");
      },
      (e) => {
        notify("İrsaliye Kayıt Edilemedi", "error");
      }
    );
  }

  initRow(e) {
    // console.log("initRow:", e);

    e.data = new invoiceRow();
  }

  insertedRow(e) {
    // console.log("insertedRow:", e);

    e.data.total = parseFloat((e.data.unitPrice * e.data.quantity).toFixed(2));
  }

  async branchChanged(e) {
    this.branchCode = e.selectedItem.branchCode;
    this.inv.branchCode = e.selectedItem.branchCode;   
  }
  

  updatedRow(e) {
    // console.log("Row updated:", e);
    e.data.total = parseFloat((e.data.unitPrice * e.data.quantity).toFixed(2));
  }
  onRowInserting(e) {
    // console.log("onRowInserting:", e);
    if(e?.brokenRules.length){
      notify(e.brokenRules[0]?.message, "error");
    }
  }


  getBrancList() {   
    this.is.getBranchList().subscribe((b: Branch[]) => {
      this.is.branchList = b
    })
  }

  onFocusedCellChanged(e){
    // console.log("onFocusedCellChanged:", e);

  }
}
