import { Injectable } from '@angular/core';
import { Branch } from 'src/app/invoice/models/global-model';
import { InvoiceService } from 'src/app/invoice/services/invoice.service';
import { enOrderStatus, enRowStatus, GenericList, Order, OrderRow } from '../models/CentralOrder.model'

@Injectable({
  providedIn: 'root'
})
export class CentralOrderService {
  branchList: Branch[] = []
  OrderList: Order[] = []
  orderStatusList: GenericList[] = []
  selectedOrder: Order = new Order()
  constructor(private inoviceSrc: InvoiceService) {

  }

  getBrancList() {
    this.inoviceSrc.getBranchList().subscribe((b: Branch[]) => {
      console.log("coBranch", b)
      this.branchList = b
    })
  }

  getOrderStatus() {
    this.orderStatusList.push({ "id": 0, "name": 'Finans Onayı Bekleyenler' })
    this.orderStatusList.push({ "id": 1, "name": 'Gelen Siparişler' })
    this.orderStatusList.push({ "id": 2, "name": 'Toplanacak Siparişler' })
    this.orderStatusList.push({ "id": 3, "name": 'Sevk Edilenler' })
    this.orderStatusList.push({ "id": 4, "name": 'İptal Edilenler' })
    this.orderStatusList.push({ "id": 5, "name": 'Teslim Edilenler' })
    this.orderStatusList.push({ "id": 6, "name": 'Tedarikçi Tarafından Onaylananlar' })
    this.orderStatusList.push({ "id": 7, "name": 'Tedarikçi Onay Bekleyenler' })
    this.orderStatusList.push({ "id": 8, "name": 'Tedarikçi Tarafından Gönderilenler' })
    this.orderStatusList.push({ "id": 9, "name": 'Üretilenler : Sevk Bekliyor' })
    this.orderStatusList.push({ "id": 10, "name": 'Silinenler' })
    this.orderStatusList.push({ "id": 11, "name": 'None' })
    this.orderStatusList.push({ "id": 12, "name": 'Tedarikçi Tarafından Reddedilenler' })
    this.orderStatusList.push({ "id": 13, "name": 'İşletme Tarafından Reddedilenler' })
  }

  loadOrders() {
    this.OrderList.push(
      {
        ArrivalDate: new Date(),
        BranchInvoiceNumber: "ABC112",
        CustomerName: "SEMİH ALTINTAS",
        DeliveredAgent: "",
        DeliveredDate: new Date(),
        DiscountRate: 0,
        DiscountTotal: 0,
        DriverName: "ALTINTAS",
        InvoiceNumber: "ABC 123",
        IsWebService: false,
        LocationCode: "0",
        Note: "DNEME",
        NewOrderNumber: "KSK123",
        OrderDate: new Date(),
        OrderGrandTotal: 100,
        OrderNumber: "ORD-Q123123132",
        OrderRows: [
          {
            ActiveUnit: "KL",
            BatchSerialNo: '1',
            Deleted: false,
            DeletedEvent: 0,
            DeletedUser: '',
            Discount1Total: 0,
            Discount2Total: 0,
            Discount3Total: 0,
            DiscountRate1: 0,
            DiscountRate2: 0,
            DiscountRate3: 0,
            Image: '',
            LineCode: "1",
            NewOrderNumber: '',
            Note: 'Deneme Sipariş',
            OrderDate: new Date(),
            PreviousQuantitiy: 0,
            PreviousRowGuid: 'asda',
            PreviousUnitPrice: 0,
            ProductionNumber: "0",
            Quantity: 1,
            RowGuid: 'asdas',
            RowNumber: 1,
            Status: enRowStatus.NewOrder,
            StockCode: "ABC123",
            StockName: 'Patetes',
            SupplierAccountCode: "",
            Total: 100,
            UnitName: "KG",
            UnitPrice: 10,
            VatRate: 8,
            VatTotal: 18,
            WareHouseCode: '10',
            WareHouseName: 'DENEME'
          }
        ],
        OrderStatus: enOrderStatus.AccumulateOrder,
        OrderTotal: 100,
        OwnerBranchCode: "100",
        OwnerWarehouseCode: 100,
        PlateNumber: "35APZ123",
        ProductionNumber: "",
        RowGuid: "asda",
        RowNumber: "1",
        RowsDiscountTotal: 0,
        SupplierAccountCode: "asda",
        SupplierMailAddress: "semih.altintas@gmail.com",
        SupplierSendDate: new Date(),
        TotalVat: 0
      },
      {
        ArrivalDate: new Date(),
        BranchInvoiceNumber: "ABC112",
        CustomerName: "SEMİH ALTINTAS",
        DeliveredAgent: "",
        DeliveredDate: new Date(),
        DiscountRate: 0,
        DiscountTotal: 0,
        DriverName: "ALTINTAS",
        InvoiceNumber: "ABC 123",
        IsWebService: false,
        LocationCode: "0",
        Note: "DNEME",
        NewOrderNumber: "KSK123",
        OrderDate: new Date(),
        OrderGrandTotal: 100,
        OrderNumber: "ORD-1233",
        OrderRows: [
          {
            ActiveUnit: "KL",
            BatchSerialNo: '1',
            Deleted: false,
            DeletedEvent: 0,
            DeletedUser: '',
            Discount1Total: 0,
            Discount2Total: 0,
            Discount3Total: 0,
            DiscountRate1: 0,
            DiscountRate2: 0,
            DiscountRate3: 0,
            Image: '',
            LineCode: "1",
            NewOrderNumber: '',
            Note: 'Deneme Sipariş',
            OrderDate: new Date(),
            PreviousQuantitiy: 0,
            PreviousRowGuid: 'asda',
            PreviousUnitPrice: 0,
            ProductionNumber: "0",
            Quantity: 3,
            RowGuid: 'asdas',
            RowNumber: 1,
            Status: enRowStatus.NewOrder,
            StockCode: "ABC555",
            StockName: 'Elma',
            SupplierAccountCode: "",
            Total: 100,
            UnitName: "KG",
            UnitPrice: 10,
            VatRate: 8,
            VatTotal: 18,
            WareHouseCode: '10',
            WareHouseName: 'DENEME'
          },
          {
            ActiveUnit: "KL",
            BatchSerialNo: '1',
            Deleted: false,
            DeletedEvent: 0,
            DeletedUser: '',
            Discount1Total: 0,
            Discount2Total: 0,
            Discount3Total: 0,
            DiscountRate1: 0,
            DiscountRate2: 0,
            DiscountRate3: 0,
            Image: '',
            LineCode: "1",
            NewOrderNumber: '',
            Note: 'Deneme Sipariş',
            OrderDate: new Date(),
            PreviousQuantitiy: 0,
            PreviousRowGuid: 'asda',
            PreviousUnitPrice: 0,
            ProductionNumber: "0",
            Quantity: 1,
            RowGuid: 'asdas',
            RowNumber: 2,
            Status: enRowStatus.NewOrder,
            StockCode: "ABC123",
            StockName: 'Patetes',
            SupplierAccountCode: "",
            Total: 100,
            UnitName: "KG",
            UnitPrice: 10,
            VatRate: 8,
            VatTotal: 18,
            WareHouseCode: '10',
            WareHouseName: 'DENEME'
          }
        ],
        OrderStatus: enOrderStatus.AccumulateOrder,
        OrderTotal: 100,
        OwnerBranchCode: "107",
        OwnerWarehouseCode: 107,
        PlateNumber: "35APZ123",
        ProductionNumber: "",
        RowGuid: "asda",
        RowNumber: "2",
        RowsDiscountTotal: 0,
        SupplierAccountCode: "asda",
        SupplierMailAddress: "semih.altintas@gmail.com",
        SupplierSendDate: new Date(),
        TotalVat: 0
      }
    )
  }

}




