import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css']
})
export class MainPageComponent implements OnInit {


  showItem: showItemObject[] = []

  constructor(private translate: TranslateService, private router: Router) { }

  ngOnInit() {
    this.showItem.push({ id: "ecommerce", 
    name: this.translate.instant("Online Ordering"), icon: "web" })
    this.showItem.push({ id: "POS", route: "menu",
    name: this.translate.instant("Point of Sale"), icon: "shopping_basket" })
    this.showItem.push({ id: "Delivery", 
    name: this.translate.instant("Delivery"), icon: "motorcycle" })
    this.showItem.push({ id: "Production", 
    name: this.translate.instant("Production"), icon: "receipt" })
    this.showItem.push({ id: "Loyalty", 
    name: this.translate.instant("Loyalty"), icon: "loyalty" })
    this.showItem.push({ id: "MenuBoard",  route: "menu-board",
    name: this.translate.instant("Menu Board"), icon: "dashboard" })
    this.showItem.push({ id: "Survey", 
    name: this.translate.instant("Survey"), icon: "question_answer" })
    this.showItem.push({ id: "Kiosk", 
    name: this.translate.instant("Kiosk"), icon: "branding_watermark" })
    this.showItem.push({ id: "Speedy", 
    name: this.translate.instant("Speedy Foody"), icon: "fastfood" })
    this.showItem.push({ id: "emp", 
    name: this.translate.instant("Employee Management"), icon: "face" })
    this.showItem.push({ id: "tmenu", 
    name: this.translate.instant("Tablet Menu"), icon: "tablet_mac" })
    this.showItem.push({ id: "notification", 
    name: this.translate.instant("Push Notifications"), icon: "notification_important" })
    
  }

  route(item: showItemObject) {
    if (item.route) {
      this.router.navigate([item.route])
    }
  }

}

export class showItemObject {
  id: string
  name: string
  icon: string
  route?: string
}