import { GuardService } from "./services/auth/guard.service";
import { HammertimeDirective } from "./directives/hammertime.directive";
import { GlobalsService } from "./services/globals.service";
import { ProductService } from "./product/products/service/product.service";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, Injector, APP_INITIALIZER, Injectable } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppComponent } from "./app.component";
import { MainPageComponent } from "./main-page/main-page.component";
import { VeritcalNavComponent } from "./veritcal-nav/veritcal-nav.component";
import { AppRoutingModule } from "./app-routing.module";
import { HeaderComponent } from "./header/header.component";
import { LoginComponent } from "./login/login.component";
import { ProductsComponent } from "./product/products/products.component";

import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { ProductGroupService } from "./product/product-groups/service/product-group.service";

import {
  DxDataGridModule,
  DxTreeListModule,
  DxLookupModule,
  DxLoadPanelModule,
  DxButtonModule,
  DxTextBoxModule,
  DxNumberBoxModule,
  DxSwitchModule,
  DxCheckBoxModule,
  DxTabsModule,
  DxPopupModule,
  DxTextAreaModule,
  DxPopoverModule,
  DxSliderModule,
  DxRadioGroupModule,
  DxSelectBoxModule,
  DxFormModule,
  DxScrollViewModule,
  DxTagBoxModule,
  DxDateBoxModule,
  DxValidatorModule,
  DxLoadIndicatorModule,
  DxDropDownBoxModule,
  DxHtmlEditorModule,
  DxColorBoxModule,
  DxToastModule,
  DxTooltipModule,
  DxTemplateModule,
  DxFilterBuilderModule,
  DxAutocompleteModule,
  DxValidationSummaryModule,
} from "devextreme-angular";
import { FirestoreService } from "./services/firestore.service";
import { ProductGroupsComponent } from "./product/product-groups/product-groups.component";
import { ModifiersComponent } from "./product/modifiers/modifiers.component";
import { FolioComponent } from "./pos/folio/folio.component";
import { MenuComponent } from "./pos/menu/menu.component";

import { DragDropModule } from "@angular/cdk/drag-drop";

import { registerLocaleData } from "@angular/common";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { KerzzCurrencyPipe } from "./pipes/kerzz-currency.pipe";
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}
import localeTr from "@angular/common/locales/tr";
registerLocaleData(localeTr, "tr");

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SelectMustGroupComponent } from "./pos/menu/select-must-group/select-must-group.component";
import { MustAutoSelectionComponent } from "./tools/modifiers/must-auto-selection/must-auto-selection.component";
import { SubGroupsComponent } from "./pos/menu/sub-groups/sub-groups.component";

import { CloudLabsLoginModule } from "cloud-labs-login";
import { NgDragDropModule } from "ng-drag-drop";

export const cenvironment = {
  production: false,
  cloud: {
    apiKey: "AIzaSyDCvxCYjW-SNUdyW0BIYZUJ4zNDWwiVZdU",
    authDomain: "kerzz-cloud-8c590.firebaseapp.com",
    databaseURL: "https://kerzz-cloud-8c590.firebaseio.com",
    projectId: "kerzz-cloud-8c590",
    storageBucket: "kerzz-cloud-8c590.appspot.com",
    messagingSenderId: "857745108319",
  },
  boss: {
    apiKey: "AIzaSyCjYYgcvP-C6oeS48xZIDIR3ywvXqDklUE",
    authDomain: "kerzz-boss-d03fc.firebaseapp.com",
    databaseURL: "https://kerzz-boss-d03fc.firebaseio.com",
    projectId: "kerzz-boss-d03fc",
    storageBucket: "kerzz-boss-d03fc.appspot.com",
    messagingSenderId: "550294287370",
  },
};

const appRoutes: Routes = [
  { path: "", component: MainPageComponent, canActivate: [GuardService] },
  { path: "main", component: MainPageComponent, canActivate: [GuardService] },
  { path: "login", component: LoginComponent },
  {
    path: "products",
    component: ProductsComponent,
    canActivate: [GuardService],
  },
  {
    path: "product-groups",
    component: ProductGroupsComponent,
    canActivate: [GuardService],
  },
  {
    path: "modifiers",
    component: ModifiersComponent,
    canActivate: [GuardService],
  },
  { path: "pos", component: FolioComponent, canActivate: [GuardService] },
  { path: "menu", component: MenuComponent, canActivate: [GuardService] },
  {
    path: "delivery",
    component: DeliveryComponent,
    canActivate: [GuardService],
  },
  {
    path: "designed-table",
    component: DesignedTableComponent,
    canActivate: [GuardService],
  },
  {
    path: "branches",
    component: BranchesComponent,
    canActivate: [GuardService],
  },
  { path: "news", component: NewsComponent, canActivate: [GuardService] },
  {
    path: "campaign",
    component: CampaignSelectorComponent,
    canActivate: [GuardService],
  },
  { path: "faq", component: FaqComponent, canActivate: [GuardService] },
  {
    path: "loyaltyUsers",
    component: UsersComponent,
    canActivate: [GuardService],
  },
  {
    path: "agreements",
    component: AgreementsComponent,
    canActivate: [GuardService],
  },
  {
    path: "segments",
    component: SegmentsComponent,
    canActivate: [GuardService],
  },
  {
    path: "loyaltySettings",
    component: SettingsComponent,
    canActivate: [GuardService],
  },
  { path: "payment", component: PaymentComponent, canActivate: [GuardService] },
  {
    path: "menu-board",
    component: MbWelcomeComponent,
    canActivate: [GuardService],
  },
  { path: "coupons", component: CouponsComponent, canActivate: [GuardService] },
  {
    path: "push",
    component: PushNotificationsComponent,
    canActivate: [GuardService],
  },
  {
    path: "app-users",
    component: AppUsersComponent,
    canActivate: [GuardService],
  },
  {
    path: "settings-tables",
    component: TablesComponent,
    canActivate: [GuardService],
  },
  {
    path: "settings-pay-types",
    component: PayTypesComponent,
    canActivate: [GuardService],
  },
  {
    path: "settings-printers",
    component: PrintersComponent,
    canActivate: [GuardService],
  },
  {
    path: "settings-company",
    component: CompanySettingsComponent,
    canActivate: [GuardService],
  },
  {
    path: "invoice-list",
    component: InvoiceListComponent,
    canActivate: [GuardService],
  },
  {
    path: "create-invoice",
    component: CreateInvoiceComponent,
    canActivate: [GuardService],
  },
  {
    path: "invoice-form",
    component: InvoiceFormComponent,
    canActivate: [GuardService],
  },
  {
    path: "account-form",
    component: AccountFormComponent,
    canActivate: [GuardService],
  },
  {
    path: "account-list",
    component: AccountListComponent,
    canActivate: [GuardService],
  },
  {
    path: "invoice-settings",
    component: InvoiceSettingsComponent,
    canActivate: [GuardService],
  },
  {
    path: "eaccounts",
    component: EaccountsComponent,
    canActivate: [GuardService],
  },
  {
    path: "erp-integration",
    component: ErpFormComponent,
    canActivate: [GuardService],
  },
  {
    path: "invoice-entry",
    component: InoviceEntryComponent,
    canActivate: [GuardService],
  },
  {
    path: "e-invoices-inbound",
    component: EInvoiceInboundComponent,
    canActivate: [GuardService],
  },
  {
    path: "e-invoices-outbound",
    component: EInvoiceOutboundComponent,
    canActivate: [GuardService],
  },
  {
    path: "e-dispatches-inbound",
    component: InboundDispatchComponent,
    canActivate: [GuardService],
  },
  {
    path: "e-dispatches-outbound",
    component: OutboundDispatchComponent,
    canActivate: [GuardService],
  },
  {
    path: "e-dispatches-outbound-form",
    component: OutboundDispatchFormComponent,
    canActivate: [GuardService],
  },
  {
    path: "central-order",
    component: CentralOrderMainComponent,
    canActivate: [GuardService],
  },
  { path: "advices", component: AdvicesComponent, canActivate: [GuardService] },
  {
    path: "manual-e-producer",
    component: ManualEProducerComponent,
    canActivate: [GuardService],
  },
  {
    path: "e-producer",
    component: EProducerComponent,
    canActivate: [GuardService],
  },
  {
    path: "invoice-convert-accounts",
    component: InvoiceConvertAccountsComponent,
    canActivate: [GuardService],
  },
  {
    path: "invoice-account-type",
    component: InvoiceAccountTypesComponent,
    canActivate: [GuardService],
  },
];

import * as Hammer from "hammerjs";
import {
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from "@angular/platform-browser";
import { InfoBoxComponent } from "./tools/info-box/info-box.component";
import { NumericButtonsComponent } from "./tools/numeric-buttons/numeric-buttons.component";
import { ScrollingModule } from "@angular/cdk/scrolling";

import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";
import { DesignedTableComponent } from "./pos/table/designed-table.component";
import { MenuButtonComponent } from "./tools/menu-button/menu-button.component";
import { FooterButtonComponent } from "./tools/footer-button/footer-button.component";
import { ButtonMenuComponent } from "./tools/button-menu/button-menu.component";
import { ModifiersRowComponent } from "./pos/folio/tools/modifiers-row/modifiers-row.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { BranchesComponent } from "./loyalty/branches/branches.component";
import { NewsComponent } from "./company/news/news.component";
import { CampaignSelectorComponent } from "./loyalty/campaign-selector/campaign-selector.component";

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    // override hammerjs default configuration
    swipe: { direction: Hammer.DIRECTION_ALL },
  };

  buildHammer(element: HTMLElement) {
    let mc = new Hammer(element, {
      touchAction: "auto",
    });
    return mc;
  }
}

import { LOCATION_INITIALIZED } from "@angular/common";
import { UsersComponent } from "./loyalty/users/users.component";
import { FaqComponent } from "./loyalty/faq/faq.component";
import { SegmentsComponent } from "./loyalty/segments/segments.component";
import { AgreementsComponent } from "./loyalty/agreements/agreements.component";
import { SettingsComponent } from "./loyalty/settings/settings.component";
import { PaymentComponent } from "./pos/folio/payment/payment.component";
import { MbWelcomeComponent } from "./menu-board/mb-welcome/mb-welcome.component";

export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector
) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );
      locationInitialized.then(() => {
        /*       const langToSet = 'en'
            translate.setDefaultLang('en');
            translate.use(langToSet).subscribe(() => {
              console.info(`Successfully initialized '${langToSet}' language.'`);
            }, err => {
              console.error(`Problem with '${langToSet}' language initialization.'`);
            }, () => {
              resolve(null);
            }) */

        resolve(null);
      });
    });
}

import { CouponsComponent } from "./loyalty/coupons/coupons.component";
import { PushNotificationsComponent } from "./loyalty/push-notifications/push-notifications.component";
import { AppUsersComponent } from "./settings/auth/app-users/app-users.component";
import { GenericGridComponent } from "./generic/generic-grid/generic-grid.component";
import { DeliveryComponent } from "./pos/delivery/delivery/delivery.component";
import { PrintersComponent } from "./settings/printers/printers.component";
import { PayTypesComponent } from "./settings/pay-types/pay-types.component";
import { TablesComponent } from "./settings/tables/tables.component";
import { CompanySettingsComponent } from "./settings/company-settings/company-settings.component";
import { InvoiceListComponent } from "./invoice/invoice-list/invoice-list.component";
import { InvoiceFormComponent } from "./invoice/invoice-form/invoice-form.component";
import { AccountFormComponent } from "./accounts/account-form/account-form.component";
import { AccountListComponent } from "./accounts/account-list/account-list.component";
import { InvoiceSettingsComponent } from "./invoice/invoice-settings/invoice-settings.component";
import { EaccountsComponent } from "./invoice/eaccounts/eaccounts.component";
import { ErpFormComponent } from "./integrations/erp/erp-form/erp-form.component";
import { ClModalDialogComponent } from "projects/cloud-components/src/lib/cl-modal-dialog/cl-modal-dialog.component";

import { TooltipModule } from "ng2-tooltip-directive";
import { InoviceEntryComponent } from "./invoice/inovice-entry/inovice-entry.component";
import { MycurrencyPipe } from "projects/cloud-components/src/lib/pipe/mycurrency.pipe";
import { MypercentPipe } from "projects/cloud-components/src/lib/pipe/mypercent.pipe";
import { EInvoiceInboundComponent } from "./invoice/e-invoice-inbound/e-invoice-inbound.component";
import { EInvoiceOutboundComponent } from "./invoice/e-invoice-outbound/e-invoice-outbound.component";
import { CloudComponentsModule } from "projects/cloud-components/src/public-api";
import { AdvicesComponent } from "./product/advices/advices.component";
import { InboundDispatchComponent } from "./dispatch/inbound-dispatch/inbound-dispatch.component";
import { OutboundDispatchComponent } from "./dispatch/outbound-dispatch/outbound-dispatch.component";
import { DispatchFormComponent } from "./dispatch/dispatch-form/dispatch-form.component";
import { OutboundDispatchFormComponent } from "./dispatch/outbound-dispatch-form/outbound-dispatch-form.component";
import { StorageModule } from "@ngx-pwa/local-storage";
import dxValidationSummary from "devextreme/ui/validation_summary";
import { CreateInvoiceComponent } from "./invoice/create-invoice/create-invoice.component";
import { ConvertInvoiceComponent } from "./invoice/convert-invoice/convert-invoice.component";
import { BackofficeTransferComponent } from "./invoice/backoffice-transfer/backoffice-transfer.component";
import { CentralOrderMainComponent } from "./central-order/central-order-main/central-order-main.component";
import { OrderDetailsComponent } from "./central-order/order-details/order-details.component";
import { OrdersComponent } from "./central-order/orders/orders.component";
import { EProducerComponent } from "./invoice/e-producer/e-producer.component";
import { ManualEProducerComponent } from "./invoice/manual-e-producer/manual-e-producer.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { InvoiceConvertAccountsComponent } from './invoice/invoice-convert-accounts/invoice-convert-accounts.component';
import { InvoiceAccountTypesComponent } from './invoice/invoice-account-types/invoice-account-types.component';

@NgModule({
  entryComponents: [ClModalDialogComponent],
  declarations: [
    AppComponent,
    MainPageComponent,
    VeritcalNavComponent,
    HeaderComponent,
    LoginComponent,
    ProductsComponent,
    ProductGroupsComponent,

    ModifiersComponent,
    FolioComponent,
    MenuComponent,
    KerzzCurrencyPipe,
    SelectMustGroupComponent,
    MustAutoSelectionComponent,
    SubGroupsComponent,
    HammertimeDirective,
    InfoBoxComponent,
    NumericButtonsComponent,
    DesignedTableComponent,
    MenuButtonComponent,
    FooterButtonComponent,
    ButtonMenuComponent,
    ModifiersRowComponent,
    BranchesComponent,
    NewsComponent,
    CampaignSelectorComponent,
    UsersComponent,
    FaqComponent,
    SegmentsComponent,
    AgreementsComponent,
    SettingsComponent,
    PaymentComponent,
    MbWelcomeComponent,
    CouponsComponent,
    PushNotificationsComponent,
    AppUsersComponent,
    GenericGridComponent,
    DeliveryComponent,
    PrintersComponent,
    PayTypesComponent,
    TablesComponent,
    CompanySettingsComponent,
    InvoiceListComponent,

    InvoiceFormComponent,

    AccountFormComponent,

    AccountListComponent,

    InvoiceSettingsComponent,
    EaccountsComponent,
    ErpFormComponent,

    InoviceEntryComponent,

    EInvoiceInboundComponent,
    EInvoiceOutboundComponent,
    AdvicesComponent,
    InboundDispatchComponent,
    OutboundDispatchComponent,
    DispatchFormComponent,
    OutboundDispatchFormComponent,
    CreateInvoiceComponent,
    ConvertInvoiceComponent,
    BackofficeTransferComponent,
    CentralOrderMainComponent,
    OrdersComponent,
    OrderDetailsComponent,
    EProducerComponent,
    ManualEProducerComponent,
    InvoiceConvertAccountsComponent,
    InvoiceAccountTypesComponent,

    /*
 UploadComponent,
    MycurrencyDirective,
    MycurrencyPipe,
    MypercentDirective,
    MypercentPipe,
    ClCollapsableTitleComponent,
    ClModalDialogComponent,
    ClSelectBoxComponent,
    ClTextBoxComponent,
    ClTextAreaComponent,
    ClDropBoxComponent,
    ClToggleComponent,
    ClContactFormComponent,
    ClBaseComponent,
    ClGridComponent,
    ModalComponent,
    ClFormComponent,
    ClImageUploadComponent, */
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      relativeLinkResolution: "legacy",
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    CloudComponentsModule,

    AngularFireModule.initializeApp(cenvironment.cloud), // imports firebase/app needed for everything
    AngularFirestoreModule.enablePersistence(),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features

    DxDataGridModule,
    DxTreeListModule,
    DxLookupModule,
    DxLoadPanelModule,
    DxButtonModule,
    DxTextBoxModule,
    DxNumberBoxModule,
    DxSwitchModule,
    DxCheckBoxModule,
    DxTabsModule,
    DxPopupModule,
    DxTextAreaModule,
    DxPopoverModule,
    DxSliderModule,
    DxRadioGroupModule,
    DxSelectBoxModule,
    DxFormModule,
    DxScrollViewModule,
    DxTagBoxModule,
    DxDateBoxModule,
    DxValidatorModule,
    DxLoadIndicatorModule,
    DxSwitchModule,
    DxDropDownBoxModule,
    DxHtmlEditorModule,
    DxColorBoxModule,
    DxToastModule,
    DxTooltipModule,
    DxTemplateModule,
    DxFilterBuilderModule,
    DxAutocompleteModule,
    DxValidationSummaryModule,

    CloudLabsLoginModule,
    MatSnackBarModule,
    TooltipModule,

    DragDropModule,
    ScrollingModule,

    ScrollToModule.forRoot(),
    NgDragDropModule.forRoot(),

    //translate module

    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register("/ngsw-worker.js", {
      enabled: environment.production,
    }),
    StorageModule.forRoot({ IDBNoWrap: false }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    ProductService,
    TranslateService,
    ProductGroupService,
    FirestoreService,
    GlobalsService,
    KerzzCurrencyPipe,
    MycurrencyPipe,
    MypercentPipe,

    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
