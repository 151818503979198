import { GlobalsService } from './../../../services/globals.service';
import { AppPermissionsService } from './../../services/app-permissions.service';
import { CloudLoginService } from 'cloud-labs-core';
import { DxDataGridModule, DxDateBoxComponent, DxDataGridComponent } from 'devextreme-angular';
import { FirestoreService } from './../../../services/firestore.service';
import { AppUsersService } from './../../services/app-users.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { isArray } from 'util';

@Component({
  selector: 'app-app-users',
  templateUrl: './app-users.component.html',
  styleUrls: ['./app-users.component.scss']
})
export class AppUsersComponent implements OnInit {

  constructor(public auser: AppUsersService, public ls: CloudLoginService, private aps: AppPermissionsService, private glb: GlobalsService) {

    this.nos = this.nos.bind(this)

  }


  @ViewChild("gridContainer") grid: DxDataGridComponent

  ngOnInit() {
    this.auser.loadUsers()
    //console.log(this.auser.roles)
  }


  nos(a) {
    console.log(a)
    a.component.editRow(a.rowIndex)
  }

  suspend(status, a) {
    a.data.isSuspend = status
    this.auser.updateUser(a.data).then(o => {
    }).catch(er => {
      console.log(er)
    })
  }

  delete(a) {
    a.component.removeRow(a.rowIndex)
  }
  initRow(e) {

  }

  updatedRow(e) {

    this.auser.updateUser(e.key).then(o => {

    }).catch(er => {
      e.cancel = true
    })
  }

  insertedRow(e) {
    this.auser.updateUser(e.data)
  }

  deleteRow(e) {
    this.auser.removeFromLicance(e.data.id)
  }

  getRolesDisplay(e) {
    let p = ""
    try {
      if (isArray(e.roles)) {
        e.roles.forEach(element => {
          p += "[" + this.auser.roles.find(o => o.id == element).level + "-" + this.auser.roles.find(o => o.id == element).name + "],"
        })
        p = p.substring(0, p.length - 1)
      }
    } catch (error) {
      console.log(error)
    }
    return p
  }


  onTagBoxValueChanged(e, d) {
    d.setValue(e.value);
  }

  checkLevel() {

  }
}


//franchise müdürü
//süper yönetici
//yönetici
//işletme müdürü
//müdür yardımcısı
//hostes
//servis personeli