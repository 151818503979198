export class Order {
    RowNumber: string = ""
    OrderNumber: string = ""
    OwnerBranchCode: string = ""
    OwnerWarehouseCode: number
    OrderDate: Date = new Date()
    OrderStatus: enOrderStatus = enOrderStatus.WaitForFinanceApprovalOrder
    DeliveredDate: Date = new Date()
    CustomerName: string = ""
    RowGuid: string = ""
    DriverName: string = ""
    PlateNumber: string = ""
    DeliveredAgent: string = ""
    Note: string = ""
    LocationCode: string = ""
    OrderRows: OrderRow[] = []
    SupplierAccountCode: string = ""
    SupplierMailAddress: string = ""
    SupplierSendDate: Date = new Date()
    ProductionNumber: string = ""
    InvoiceNumber: string = ""
    BranchInvoiceNumber: string = ""
    NewOrderNumber: string = ""
    ArrivalDate: Date = new Date()
    IsWebService: Boolean = false    
    DiscountTotal : number = 0
    DiscountRate : number = 0
    TotalVat : number = 0
    OrderGrandTotal : number = 0
    OrderTotal : number = 0
    RowsDiscountTotal : number = 0
}


export enum enOrderStatus {
    WaitForFinanceApprovalOrder = 0,
    WaitOrder = 1,
    AccumulateOrder = 2,
    ShippedOrder = 3,
    CancelledOrder = 4,
    DeliveredOrder = 5,
    obsolete_SupplyAndCompanyAccepted,
    obsolete_SupplyConfirmationPending,
    SentSupplier = 8,
    produced = 9,
    Deleted = 10,
    none = 11,
    obsolete_rejectedFromSuppliers,
    obsolete_rejectedFromCompany,
}

export class OrderRow {

             RowNumber : number = 0
             StockName : string = ""
             StockCode : string = ""
             Quantity : number = 0 
             PreviousQuantitiy : number = 0
             PreviousUnitPrice : number = 0
             UnitPrice : number = 0
             VatRate : number = 0
             OrderDate : Date = new Date()
             Status : enRowStatus = enRowStatus.NewOrder
             LineCode : string = ""
             UnitName : string = "" 
             RowGuid : string
             PreviousRowGuid : string
             WareHouseCode : string = ""
             WareHouseName : string = ""
             NewOrderNumber : string = ""
             Image : string = ""
             Deleted : Boolean = false
             DeletedEvent : number = 0
             ProductionNumber : string = ""
             DeletedUser : string = ""
             SupplierAccountCode : string = ""
             Note : string = ""
             BatchSerialNo : string = ""
             DiscountRate1 : number = 0
             DiscountRate2 : number = 0
             DiscountRate3 : number = 0
             ActiveUnit  : string = ""
             Total : number = 0
             Discount1Total : number = 0
             Discount2Total: number = 0
             Discount3Total: number = 0
             VatTotal : number = 0
}

export enum enRowStatus {
    NewOrder,
    ConvertedNewStock = 10
}

export class GenericList {
    id : number = 0
    name : string = ""
}