import { Component, OnInit } from '@angular/core';
import { SegmentsService } from './segments.service';

@Component({
  selector: 'app-segments',
  templateUrl: './segments.component.html',
  styleUrls: ['./segments.component.scss']
})
export class SegmentsComponent implements OnInit {



  constructor(public segmentService: SegmentsService) { }

  ngOnInit() {
    this.segmentService.load()
  }

  initRow(e) {

  }

  updatedRow(e) {
    this.segmentService.upsert(e.data)
  }

  insertedRow(e) {
    this.segmentService.upsert(e.data)
  }

  deleteRow(e) {
    this.segmentService.delete(e.data)
  }
}
