import { Component, OnInit } from '@angular/core';
import { AccountService } from '../services/account.service';
import { CityService } from 'src/app/tools/services/city.service';
import { TownService } from 'src/app/tools/services/town.service';
import { ModalService } from 'projects/cloud-components/src/lib/modal/modal.service';

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss']
})
export class AccountListComponent implements OnInit {

  activeItem
  columns

  constructor(public accountService: AccountService,
    private modalService: ModalService,
    private cityService: CityService, private townService: TownService) {

      this.columns = [
        { name: 'name', caption: 'Ünvan', width: 200 },
        { name: 'brandName', caption: 'Marka', width: 200 },
        { name: 'taxNo', caption: 'Vergi No', width: 100 },
        { name: 'taxHome', caption: 'Vergi Da', width: 100 },
        { name: 'phone', caption: 'Telefon', width: 100 },
      ]

     }

  ngOnInit() {
    this.accountService.load()
    this.accountService.createClDataSource()
    this.accountService.pageLoad()
  }

  select(item) {
    this.activeItem = item
    this.openModal("custom-modal-1");
  }


  openModal(id: string) {
   
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
    this.accountService.pageLoad()
  }

  ngAfterViewInit() {

  


  }

}
