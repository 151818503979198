import { bonusCampaign } from './campaign-model';
import { stampCampaign } from 'src/app/services/campaigns/campaign-model';
import { Injectable } from '@angular/core';
import { CloudDbService } from 'cloud-labs-core';

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {

  data: stampCampaign = new stampCampaign()
  bonusData: bonusCampaign = new bonusCampaign()


  constructor(private cs: CloudDbService) {

  }

  loadBonusCampaignFromMongo(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.cs.getCollection({ col: "bonusCampaign" }).subscribe(d => {
        if (d.length == 0) {
          this.createNewBonusCampaign()
        } else {
          this.bonusData = d[0]
        }
        resolve(this.bonusData)
      }, e => {
        reject(e)
      })
    })

  }

  loadStampCampaignFromMongo(): Promise<any>  {
    return new Promise((resolve, reject) => { 
      this.cs.getCollection({ col: "stampCampaign" }).subscribe(d => {
        if (d.length == 0) {
          this.createNewStampCampaign()
        } else {
          this.data = d[0]
        }
        resolve(this.data)
      }, e => {
        reject(e)
      })
    })
 
  }


  createNewBonusCampaign() {
    this.bonusData.active = false
    this.bonusData.id = "bonusCampaign"
    this.bonusData.name = "Bonus Kampanyası"
    this.bonusData.bonusNick = "Bonus"
    this.upsertBonus(this.bonusData)
  }

  upsertBonus(bdata: bonusCampaign) {
   return this.cs.upsert({ col: "bonusCampaign", data: bdata })
  }

  upsertStamp(data: stampCampaign) {
   return this.cs.upsert({ col: "stampCampaign", data: data })
  }

  createNewStampCampaign() {
    this.data.active = false
    this.data.conversionRate = 5
    this.data.id = "stampCampaign"
    this.data.name = "Damga Kampanyası"
    this.data.pointNick = "Puan"
    this.data.stampNick = "Damga"
    this.data.bannerStyle = "1."
    this.upsertStamp(this.data)
  }



}


