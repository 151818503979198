import { CloudDbService, CloudLoginService } from 'cloud-labs-core';
import { GenericDataService } from 'projects/cloud-components/src/lib/database/generic-data.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends GenericDataService {
  

  collection = "settings"
  xdata: loyaltySettings = new loyaltySettings()
  constructor(public cs: CloudDbService) {
    super(cs)
   }


  /* getSettings() {
    this.cs.readCollectionFromMongo("settings", { id: "settings" }).subscribe(o => {
      if (o.length > 0)  {
        if (o[0].id) {
          delete o[0]._id
          this.data = o[0]
        }
      } else {
        this.data = new loyaltySettings()
      }
    })
  } */


}


export class loyaltySettings {

  id: string = "settings"
  brand: string = ""

  productsMenu: string = ""
  bonusMenu: string = ""
  newsMenu: string = ""
  moreMenu: string = ""
  branchesMenu = "Şubelerimiz"
  designCode = ""

  productsMenuEnabled: boolean = true
  bonusMenuEnabled: boolean = true
  newsMenuEnabled: boolean = true
  moreMenuEnabled: boolean = true
  newsSideMenu = false

  colors: colors = new colors()
  images: images = new images()
  capabilities: capabilities = new capabilities()
  icons: icons = new icons()
  fcmToken: string
  links : links[] = []

  loggedOutText = ""
  smsUrl = ""

}

export class capabilities {
  stampProgram: boolean = false
  bonusProgram: boolean = false

  guestLogin: boolean = false

  tableService: boolean = false
  deliveryService: boolean = false
  takeAWayService: boolean = false
  transferPoints: boolean = false
  reservationForm: boolean = false
  onlineReservation: boolean = false
  showBranches: boolean = false
  showRadio: boolean = false

}

export class colors {
  background: string = "white"
  foreground: string = "black"
  
  headerBackground: string = "white"
  footerBackground: string = "white"
  
  headerForeground: string = "white"
  footerForeground: string = "white"

  success: string = "green"
  warning: string = "yellow"
  danger: string = "red"

  stampCircularBaseColor: "#613713";
  stampCircularTextColor: "white";
  stampCircleColor: "#613713";


}

export class icons {
  bonusPage: string = ""
  newsPage: string = ""
  productPage: string = ""
}

export class images {
  header: string = ""
  backgroundLogo: string = ""
  backgroundImage: string = ""
  scratchImage: string = ""
  stampCircularIcon: string = ""
  stampCircularImage: string = ""
  stampAnimeItem: string = ""
  stampAnimeItemFilled = ""
  stampAnimeGift: string = ""
  stampBackground = ""

}

export class links {
  name = ""
  url = ""
  caption = ""
  active = true
}