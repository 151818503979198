export class loyaltyFolio {
    qrCode: string = ""
    basket: loyaltyBasket = new loyaltyBasket()
  }
  
  export class loyaltyBasket {
    folioNo: string = ""
    discountTotal: number = 0
    grandTotal: number = 0
    waiter: string = ""
    folioId: number = 0
    branchcode: string = ""
    basketItems: loyaltyBasketItems[] = []
  }
  
  export class loyaltyBasketItems {
    itemCode: string
    itemName: string
    quantity: number
    unitPrice: number
    totalPrice: number
    spendPoint: number
    isUse: boolean
  }

  export class loyalty_service_result {
    basketWinStamp: number
    benefitProducts: loyalty_service_result_products[]
    objectType: string
    totalPoint: number
    userId: string
    userName: string
  }
  
  export class loyalty_service_result_products {
    itemCode: string
    itemName: string
    quantity: number
    unitPrice: number
    totalPrice : number
    spendPoint: number
  
  }
  