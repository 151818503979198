import { Component, OnInit, ElementRef } from '@angular/core';
import { Invoice, invoiceDetail, InvoiceService, logType, invoiceLog } from 'src/app/invoice/services/invoice.service';
import { menu } from 'src/app/tools/button-menu/button-menu.component';
import { ClModalDialogService } from 'projects/cloud-components/src/lib/cl-modal-dialog/cl-modal-dialog.service';
import { ModalService } from 'projects/cloud-components/src/lib/modal/modal.service';
import { CloudLoginService } from 'cloud-labs-core';
import { AppUsersService } from 'src/app/settings/services/app-users.service';
import { AppPermissionsService } from 'src/app/settings/services/app-permissions.service';
import { confirm } from 'devextreme/ui/dialog';
import { GlobalsService } from 'src/app/services/globals.service';

@Component({
  selector: 'app-inbound-dispatch',
  templateUrl: './inbound-dispatch.component.html',
  styleUrls: ['./inbound-dispatch.component.scss']
})
export class InboundDispatchComponent implements OnInit {

  dispatchAnswerVisible = false

  now: Date = new Date()

  startDate: Date = new Date()
  endDate: Date = new Date()

  activeInvoice: Invoice = new Invoice()
  invoiceMenu: menu[] = []
  inoviceError = ""
  popupVisible = false
  selectedRows: number[];
  loadingVisible = false
  loadIndicatorVisible = false
  invoiceSendEmailAddress: string = ""
  filterStatus = "standby"
  filterDocumentType = "eInvoice"
  animationState = 'in';
  detail: invoiceDetail = new invoiceDetail()
  loadingMessage = "Yükleniyor..."
  activeFilter = {}

  selectedInovices: Invoice[] = []

  approveVisble = false
  rejectVisible = false
  arDescription = ""

  constructor(public inoviceSrc: InvoiceService,
    private dialog: ClModalDialogService,
    private modalService: ModalService,
    private ls: CloudLoginService,
    public appUserService: AppUsersService,
    public perm: AppPermissionsService,
    private glb : GlobalsService,
    private elementref: ElementRef) { }

  ngOnInit() {

    this.invoiceMenu.push({ title: "Okundu?", faicon: "fa-file-invoice", icon: "money", tag: "readed", selectable: false, disabled: false })
    this.invoiceMenu.push({ title: "Stok?", faicon: "fa-file-invoice", icon: "money", tag: "stock", selectable: false, disabled: false })

    this.invoiceMenu.push({ title: "Önizleme", faicon: "fa-file-invoice", icon: "money", tag: "preview", selectable: false, disabled: true })
    this.invoiceMenu.push({ title: "EPosta Gönder", faicon: "fa-envelope-open-text", icon: "card_giftcard", tag: "sendmail", selectable: false, disabled: true })
    // this.invoiceMenu.push({ title: "Fatura Ayrıntısı", faicon: "fa-list-alt", icon: "card_giftcard", tag: "detail", selectable: false, disabled: true })
    // this.invoiceMenu.push({ title: "Excele Aktar", faicon: "fa-file-excel", icon: "card_giftcard", tag: "emp", selectable: false, disabled: false })

   /*  this.invoiceMenu.push({ title: "Onayla", faicon: "fa-sitemap", icon: "card_giftcard", tag: "approve", selectable: false, disabled: false })
    this.invoiceMenu.push({ title: "Reddet", faicon: "fa-sitemap", icon: "card_giftcard", tag: "reject", selectable: false, disabled: false })

  */   this.invoiceMenu.push({ title: "Yanıt Oluştur", faicon: "fa-sitemap", icon: "card_giftcard", tag: "answer", selectable: false, disabled: false })


    //this.invoiceMenu.push({ title: "ERP Aktar", faicon: "fa-sitemap", icon: "card_giftcard", tag: "erp", selectable: false, disabled: true })
    this.invoiceMenu.push({ title: "Loglar", faicon: "fa-clipboard-list", icon: "card_giftcard", tag: "logs", selectable: false, disabled: false })
    this.invoiceMenu.push({ title: "Yenile", faicon: "fa-clipboard-list", icon: "card_giftcard", tag: "refresh", selectable: false, disabled: false })

    this.elementref.nativeElement.style.setProperty('--button-menu-width', "50px")
    this.elementref.nativeElement.style.setProperty('--button-menu-height', "60px")

    this.startDate = new Date(this.now.getFullYear(), this.now.getMonth(), 1)
    this.endDate = new Date(this.now.getFullYear(), this.now.getMonth() + 1, 0)

  }

  ngAfterViewInit() {
    this.inoviceSrc.data = []
    this.loadData()
  }


  loadData() {
    this.activeFilter = {
      $and: [
        { documentType: 'eDispatch' },
        { direction: 'inBound' },
        { $and: [{ invoiceDate: { $gte: this.startDate.toISOString() } }, { invoiceDate: { $lte: this.endDate.toISOString() } }] },
      ]

    }

    this.refreshWithFilter()
  }

  calculateCellValue(data) {
    return data.grandTotal - data.taxTotal
  }

  selectionChangedHandler(e) {
    if (e.selectedRowsData.length == 0) return
    console.log(e)
    this.selectedInovices = e.selectedRowsData
    this.activeInvoice = e.selectedRowsData[0]

    this.invoiceMenu.find(f => f.tag == "preview").disabled = (this.selectedInovices[0].status != 'success' && this.selectedInovices[0].status != 'standby')
    this.invoiceMenu.find(f => f.tag == "detail").disabled = (this.selectedRows.length > 1 || this.selectedInovices[0].status != 'success')
    this.invoiceMenu.find(f => f.tag == "sendmail").disabled = (this.selectedInovices[0].status != 'success')


    // this.invoiceMenu.find(f => f.tag == "reject").disabled = false
    // this.invoiceMenu.find(f => f.tag == "approve").disabled = false

    if (this.activeInvoice.typeScenario != 'merchant') {
      // this.invoiceMenu.find(f => f.tag == "reject").disabled = true
      // this.invoiceMenu.find(f => f.tag == "approve").disabled = true
    }

  }

  inovice_menu_click(e) {
    console.log(e)

    if (e.tag == "preview") {
      if (this.selectedInovices.length > 20)
      {
        this.glb.notice('Bir defa da en çok 20 faturada işlem yapılabilir.','warning')
        return 
      }
      this.previewInvoice();
    }


    if (e.tag == "answer") {
      this.inoviceSrc.activeDispatch = this.selectedInovices[0]
      for (let index = 0; index < this.inoviceSrc.activeDispatch.invoiceRows.length; index++) {
        const element = this.inoviceSrc.activeDispatch.invoiceRows[index];
        element.approvedQty = element.quantity        
      }
      this.dispatchAnswerVisible = true
    }


    if (e.tag == "erp") {
      // if (this.selectedInovices.length > 20)
      // {
      //   this.glb.notice('Bir defa da en çok 20 faturada işlem yapılabilir.','warning')
      //   return 
      // }
      this.sendErp();
    }

    if (e.tag == "sendmail") {
      if (this.selectedInovices.length > 20)
      {
        this.glb.notice('Bir defa da en çok 20 faturada işlem yapılabilir.','warning')
        return 
      }
      this.senMailInvoice();
    }

    if (e.tag == "detail") {
      this.detailInvoice();
    }

    if (e.tag == "logs") {

      this.modalService.open('invoiceLogsModal')
    }

    if (e.tag == "refresh") {      
      this.inoviceSrc.runCalculateStatics()
      this.refreshWithFilter()
    }
 
    if (e.tag == "readed") {
      if (this.selectedInovices.length > 1) {       
        const result = confirm(this.selectedInovices.length + " adet kayıt seçtiniz.İşleme devam etmek istiyor musunuz?", "Okundu");
        result.then((dialogResult: any) => {
          if (dialogResult) {
            this.selectedRows = []
            this.selectedInovices.forEach((el) => {
              el.readed = el.readed ? false : true;
              this.inoviceSrc.upsert(el)
              this.addLog(
                "log",
                el,
                "okundu",
                el.readed ? "işaretledi" : "kaldırıldı"
              );
            });
          }
          else {
            //this.glb.pushToast("İptal Edildi.", "error", 2000)
          }
        });
      }
      else {

        this.selectedInovices.forEach(el => {
          if (el.readed == true) {
            const result = confirm(this.selectedInovices[0].invoiceNumber + " numaralı faturanın okundu bilgisini kaldırmak istediğinize emin misiniz?", "Okundu");
            result.then((dialogResult: any) => {
              if (dialogResult) {
                this.selectedRows = []
                this.selectedInovices.forEach((el) => {
                  el.readed = el.readed ? false : true;
                  this.inoviceSrc.upsert(el)
                  this.addLog(
                    "log",
                    el,
                    "okundu",
                    el.readed ? "işaretledi" : "kaldırıldı"
                  );
                });
              }
              else {
                //this.glb.pushToast("İptal Edildi.", "error", 2000)
              }
            });
          }
          else {
            this.selectedInovices.forEach((el) => {
              el.readed = el.readed ? false : true;
              this.inoviceSrc.upsert(el)
              this.addLog(
                "log",
                el,
                "okundu",
                el.readed ? "işaretledi" : "kaldırıldı"
              );
            });
            this.selectedRows = []
          }


        })
      }



    }



    if (e.tag == "stock") {
      if (this.selectedInovices.length > 1) {
        const result = confirm(this.selectedInovices.length + " adet kayıt seçtiniz.İşleme devam etmek istiyor musunuz?", "Stok");
        result.then((dialogResult: any) => {
          if (dialogResult) {
            this.selectedRows = []
            this.selectedInovices.forEach((el) => {             
              el.stock = el.stock ? false : true
              this.inoviceSrc.upsert(el)
              this.addLog("log", el, "stok", el.stock ? "işaretlendi" : "kaldırıldı")
            });
          }
          else {

          }
        });
      }
      else {

        this.selectedInovices.forEach(el => {
          if (el.stock == true) {
            const result = confirm(this.selectedInovices[0].invoiceNumber + " numaralı faturanın stok bilgisini kaldırmak istediğinize emin misiniz?", "Stok");
            result.then((dialogResult: any) => {
              if (dialogResult) {
                this.selectedRows = []
                this.selectedInovices.forEach((el) => {                 
                  el.stock = el.stock ? false : true
                  this.inoviceSrc.upsert(el)
                  this.addLog("log", el, "stok", el.stock ? "işaretledi" : "kaldırıldı")
                });
              }
              else {
                //this.glb.pushToast("İptal Edildi.", "error", 2000)
              }
            });
          }
          else {
            this.selectedInovices.forEach((el) => {
              el.stock = el.stock ? false : true
              this.inoviceSrc.upsert(el)
              this.addLog("log", el, "stok", el.stock ? "işaretledi" : "kaldırıldı")
            });
            this.selectedRows = []
          }


        })
      }

    }



    if (e.tag == "approve") {
      this.approveVisble = true

    }

    if (e.tag == "reject") {
      this.rejectVisible = true
    }


  }

  sendMail(isValid, mail) {
    if (!isValid) return
    this.modalService.close('invoiceEmail')
    this.selectedInovices.forEach(el => {
      this.loadingVisible = true
      this.inoviceSrc.mail(el.uuid, mail).subscribe(o => {
        this.addLog("log", el, "invoice-mail-send", mail)
        this.loadingVisible = false
        let resInvoice: Invoice = Object.assign(o);
        let pobj = this.inoviceSrc.data.findIndex(X => X.uuid == resInvoice.uuid);
        el = Object.assign(o);
        this.inoviceSrc.data[pobj] = Object.assign(o);
      }, e => {
        this.loadingVisible = false
        this.ShowError(e.error.message)
      })
    })
  }

  sendMailFromPreview() {
    this.modalService.close("inoviceModal");
    setTimeout(() => {
      this.modalService.open("invoiceEmail");
    }, 500);


  }

  previewDX = false
  private previewInvoice() {

    this.loadingVisible = true
    let uuids = []
    this.activeInvoice = this.selectedInovices[0];

    for (const iterator of this.selectedInovices) {
      uuids.push(iterator.uuid)
    }

    this.inoviceSrc.requestPdfInvoices(uuids).subscribe(o => {
      this.loadingVisible = false
      this.modalService.open("inoviceModal");
      document.getElementById('invHtml').innerHTML = `<embed src='data:application/pdf;base64,${Object.assign(o).pdf}' type="application/pdf" width="100%" height="600px" />`
      this.addLog('log', this.selectedInovices[0], "fatura-ön-izleme", "başarılı")
    }, e => {

      this.ShowError(e.error.message)
    });

  }

  private senMailInvoice() {
    this.modalService.open('invoiceEmail')
  }

  private detailInvoice() {

    this.loadingVisible = true
    this.activeInvoice = this.selectedInovices[0];
    let uuid = this.selectedInovices[0].uuid;
    this.inoviceSrc.detail(uuid).subscribe(o => {
      this.loadingVisible = false
      this.detail = Object.assign(o);
      this.modalService.open("inoviceDetailModal");
    }, e => {
      this.ShowError(e.error.message)
    });
  }

  private sendErp() {
    this.loadingVisible = true
    let erpsender = new Promise((resolve, reject) => {
      this.selectedRows = []

      this.selectedInovices.forEach((el, index, array) => {


        this.inoviceSrc.sendInvoiceToErp(el.uuid).subscribe(o => {
          this.loadingMessage = "Erp'ye gönderiliyor (" + index + ")"
          let ps = Object.assign(o)

          if (ps.message == "success") {
            if (el.erp) el["erp"] = "new"
            el.erp = "success"
            this.addLog("erp", el, "integration", "success")
          } else {
            this.addLog("erp", el, "integration-error", ps.message)
          }
          if (index === array.length - 1) resolve("");


        }, e => {
          if (index === array.length - 1) reject(e.error.message);
          //this.ShowError(e.error.message)
          this.addLog("erp", el, "integration-error", e.error.message)
        })

      })


    })

    erpsender.then(o => {
      this.inoviceSrc.runCalculateStatics()
      this.loadingVisible = false
      this.loadingMessage = "Yükleniyor..."
    }).catch(p => {
      this.loadingVisible = false
      this.ShowError(p)
      this.loadingMessage = "Yükleniyor..."
    })



  }

  private ShowError(e) {
    console.log(e)
    this.loadingVisible = false
    this.inoviceError = e
    this.modalService.open('inoviceError');
  }

  updateLocalInvoiceObjects(respInvObj: Invoice, visible: boolean = false) {
    this.loadingVisible = visible
    this.activeInvoice = respInvObj
    let dataIndex = this.inoviceSrc.data.findIndex(X => X.uuid == this.activeInvoice.uuid);
    this.inoviceSrc.data[dataIndex] = respInvObj
  }

  closeModal(i) {    
    this.modalService.close(i)
    document.getElementById('invHtml').innerHTML = ""
  }

  refresh() {
    this.inoviceSrc.refresh()
  }

  eInvoicePos = true
  eInvoiceErp = false
  eArchivePos = false
  eArchiveErp = false
  showStatusMenu = false
  activeStat = { docType: "", status: "", source: "" }

  statMenu() {
    this.showStatusMenu = this.showStatusMenu ? false : true
    this.inoviceSrc.runCalculateStatics()
  }

  loadGrid(item) {
    if (item.count == 0) return
    this.activeFilter = {
      $and: [
        { direction: 'inBound' },
        { typeScenario: item.id }
      ]
    }
    console.log(this.activeFilter)

    this.activeStat = { docType: item.docType, status: item.id, source: item.source }

    this.refreshWithFilter()


  }

  refreshWithFilter() {
    this.loadingVisible = true
    this.inoviceSrc.load(this.activeFilter).then(o => {      
      this.loadingVisible = false
    })
  }

  // addLog(lType: logType, inv: Invoice, title, message) {
  //   let log: invoiceLog = new invoiceLog
  //   log.type = lType
  //   log.title = title
  //   log.message = message
  //   log.userId = this.ls.userInfo.id
  //   if (!inv.logs) {
  //     inv["logs"] = []
  //   }
  //   inv.logs.push(log)
  //   this.inoviceSrc.upsert(inv)
  // }

  addLog(lType: logType, inv: Invoice, title, message) {
    let log: invoiceLog = new invoiceLog();
    log.type = lType;
    log.title = title;
    log.message = message;
    log.userId = this.ls.userInfo.id;
    log.uuid = inv.uuid
    if (!inv.logs) {
      inv["logs"] = [];
    }
    inv.logs.push(log);
    this.inoviceSrc.addInvoiceLog(log).subscribe((x: any) => {

    })
  }

  getCss(item) {

    if (item.count == 0) {
      return "gray-stat"
    } else {
      if (this.activeStat.docType == item.docType && this.activeStat.status == item.id && this.activeStat.source == item.source) {
        return item.id + " active-stat"
      } else {
        return item.id
      }

    }
  }

  approve() {
    this.loadingVisible = true
    this.loadingMessage = "İşleniyor..."
    this.activeInvoice = this.selectedInovices[0];
    this.inoviceSrc.approveInbond(this.activeInvoice.uuid, this.arDescription).toPromise().then(o => {
      this.loadingVisible = false
    })
  }

  reject() {
    this.loadingVisible = true
    this.loadingMessage = "İşleniyor..."
    this.activeInvoice = this.selectedInovices[0];
    this.inoviceSrc.rejectInbound(this.activeInvoice.uuid, this.arDescription).toPromise().then(o => {
      this.loadingVisible = false
    })
  }

}
