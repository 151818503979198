import { Observable } from 'rxjs/internal/Observable';
import { ProductService, product } from '../products/service/product.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { productGroup, ProductGroupService } from '../product-groups/service/product-group.service';
import { UploadComponent } from '../../../../projects/cloud-components/src/lib/upload/upload.component';
import { ModifiersService, modifiers } from './service/modifiers.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { trigger, style, animate, transition, state, query, stagger, keyframes } from '@angular/animations';
import { IdGeneratorService } from 'cloud-labs-core';

@Component({
  selector: 'app-modifiers',
  templateUrl: './modifiers.component.html',
  styleUrls: ['./modifiers.component.css'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [

        query(':enter', style({ opacity: 0 }), { optional: true }),

        query(':enter', stagger('500ms', [
          animate('500ms ease-in', keyframes([
            style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
            style({ opacity: .5, transform: 'translateY(35px)', offset: 0.3 }),
            style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
          ]))]), { optional: true })
      ])
    ]),
    trigger('explainerAnim', [
      transition('* => *', [
        query('.col', style({ opacity: 0, transform: 'translateX(-40px)' })),

        query('.col', stagger('500ms', [
          animate('800ms 1.2s ease-out', style({ opacity: 1, transform: 'translateX(0)' })),
        ])),

        query('.col', [
          animate(1000, style('*'))
        ])

      ])
    ])
  ]
})
export class ModifiersComponent implements OnInit {

  showAll: boolean = false
  showSimpleModifiers: boolean = false
  showGroupModifiers: boolean = false
  selectedType
  selectedProductOrGroupID

  selectedRowKeys: any[] = [];
  recursiveSelectionEnabled = false;
  loadingVisible = false;

  showFilterRow: boolean = true;
  showHeaderFilter: boolean = true;

  lookupProductGroup
  lookupProduct

  products: product[]
  modifiers: modifiers
  productGroups: Observable<productGroup>
  constructor(private ms: ModifiersService,
    private ig: IdGeneratorService,
    public pg: ProductGroupService, public ps: ProductService) { }

  ngOnInit() {

    this.ms.getProductGroupModifiersFromMongo()
    this.ms.getProductModifiersFromMongo()

  }


  productOrGroupSelected(e, type) {
    console.log(e)
    this.selectedType = type
    this.selectedProductOrGroupID = e.value.id

    if (this.selectedType == "productGroup") {

      this.lookupProduct = undefined
      this.modifiers = this.ms.productGroupModifiers.find(p => p.productGroupID == e.value.id)
      if (!this.modifiers) {
        this.modifiers = new modifiers()
        this.modifiers.productGroupID = this.selectedProductOrGroupID
      }
    } else {
      this.lookupProductGroup = undefined
      this.modifiers = this.ms.productModifiers.find(p => p.productID == e.value.id)
      if (!this.modifiers) {
        this.modifiers = new modifiers()
        this.modifiers.productID = this.selectedProductOrGroupID
      }
    }


    this.showAll = true
    this.showGroupModifiers = true
  }

  addProduct(item) {
    item.push({ productID: "", productName: "", price: 0, priceless: false, detachable: false, canBeAdded: true })
  }

  removeModifier(j) {
    this.modifiers.modif.splice(j, 1)
  }

  removeProduct(prod, j) {
    if (prod.length > 1) {
      prod.splice(j, 1)
    }
  }

  blank(e) {
    console.log(e)
  }

  saveModifiers() {

    if (this.selectedType == "productGroup") {

      for (let index = 0; index < this.modifiers.modif.length; index++) {
        const element = this.modifiers.modif[index];
        if (this.modifiers.modif[index].type == 'group') {
          this.modifiers.modif[index].products = []
  
          this.ps.data.filter(x => x.group == this.modifiers.modif[index].productGroup).forEach(element => {
  
            this.modifiers.modif[index].products.push({
              productName: element.name,
              productID: element.id,
              price: element.price,
              priceless: this.modifiers.modif[index].priceless,
              detachable: this.modifiers.modif[index].detachable,
              canBeAdded: this.modifiers.modif[index].canBeAdded,
            })
          });
        }
      }
     
      //  


      console.log('LAST MODIFIER: ', this.modifiers)
      this.ms.saveProductGroupModifier(this.modifiers, this.selectedProductOrGroupID)
    } else {
      for (let index = 0; index < this.modifiers.modif.length; index++) {
        const element = this.modifiers.modif[index];
        if (this.modifiers.modif[index].type == 'group') {
          this.modifiers.modif[index].products = []
  
          this.ps.data.filter(x => x.group == this.modifiers.modif[index].productGroup).forEach(element => {
  
            this.modifiers.modif[index].products.push({
              productName: element.name,
              productID: element.id,
              price: element.price,
              priceless: this.modifiers.modif[index].priceless,
              detachable: this.modifiers.modif[index].detachable,
              canBeAdded: this.modifiers.modif[index].canBeAdded,
            })
          });
        }
      }
      this.ms.saveProductModifier(this.modifiers, this.selectedProductOrGroupID)
    }
  }

  deleteModifiers() {
    if (this.selectedType == "productGroup") {
      this.ms.deleteProductGroupModifier(this.selectedProductOrGroupID)
    } else {
      this.ms.deleteProductModifier(this.selectedProductOrGroupID)
    }
  }

  addGroupModifier() {

    this.addModifier("group")

  }

  addProductModifier() {

    this.addModifier("product")
  }

  addCustomModifier() {
    this.addModifier("custom")
  }

  addModifier(type: string) {
    let modifier = {
      index: 0,
      group: "",
      id: this.ig.generate(),
      productGroup: "",
      products: [{ productID: "", productName: "", price: 0, priceless: false, detachable: false, canBeAdded: true }],
      type: type,
      maxSelect: 0,
      minSelect: 0,
      remain: 0,
      must: false,
      auto: false,
      detachable: false,
      canBeAdded: true,
      priceless: false,
      filteredProducts: [],
      isMustModifier: false
    }
    this.modifiers.modif.push(modifier)
  }


}

