<div style="overflow:scroll; height:100vh;">
    <div class="section-title">
        {{'Group List' | translate}}
        <div class="card">

            <dx-tree-list id="employees" [dataSource]="pg.data" keyExpr="id" parentIdExpr="parentID"
                [showRowLines]="true" [showBorders]="true" [columnAutoWidth]="true" [expandedRowKeys]="[1]"
                (onRowInserting)="initRow($event)" (onRowRemoving)="deleteRow($event)"
                (onRowUpdated)="updateRow($event)" (onRowInserted)="insertRow($event)">
                <dxo-selection mode="single"></dxo-selection>


                <dxo-editing [useIcons]="true" mode="row" [allowUpdating]="true" [allowDeleting]="true"
                    [allowAdding]="true">

                    <dxo-popup title="{{'Product Group' | translate}}" [showTitle]="true" [width]="500" [height]="325"
                        [position]="{ my: 'center', at: 'center', of: 'window' }">
                    </dxo-popup>

                    <!-- <dxo-form>
                        <dxi-item itemType="group" cssClass="first-group" [colCount]="1">
                            <dxi-item [editorOptions]="{ width: '200px'}" dataField="name"></dxi-item>
                            <dxi-item [editorOptions]="{ width: '100%'}" dataField="order"></dxi-item>
                            <dxi-item dataField="hide"></dxi-item>
                        </dxi-item>
                    </dxo-form> -->

                </dxo-editing>

                <dxi-column type="buttons" [width]="110">
                    <dxi-button name="edit"></dxi-button>
                    <dxi-button name="delete"></dxi-button>
                    <dxi-button name="add"></dxi-button>
                </dxi-column>

                <dxi-column dataField="id" caption="ID" [formItem]="{visible: false}" [visible]="false">
                </dxi-column>

                <dxi-column dataField="name" caption="{{'Name' | translate}}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dxo-form-item [colSpan]="2">
                    </dxo-form-item>
                </dxi-column>

                <dxi-column dataField="order" dataType="number" caption="{{'Order' | translate}}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>

                <dxi-column dataField="hide" dataType="boolean" caption="{{'Hide' | translate}}">

                </dxi-column>

                <dxi-column dataField="parentID" [formItem]="{visible: false}" caption="parent ID" [visible]="false">
                </dxi-column>

                <dxi-column dataField="image" [width]="60" caption="{{'Image' | translate}}" [allowFiltering]="false"
                    [visible]="true" [formItem]="{visible: false}" [hidingPriority]="4" [allowSorting]="false"
                    cellTemplate="cellTemplate"></dxi-column>

                <div *dxTemplate="let data of 'cellTemplate'">
                    <img class="avatar" [src]="data.data.image" (click)="imageClick(data)" />
                    <!-- <button class="upload-button" (click)="uploadImage(data)">Y</button> -->
                    <button class="upload-button" (click)="showUpload(data)">Y</button>
                </div>

            </dx-tree-list>

        </div>

        <dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#grid' }"
            [(visible)]="loadingVisible" [showIndicator]="true" [showPane]="true" [shading]="true"
            [closeOnOutsideClick]="false">
        </dx-load-panel>

        <div>
            <app-upload #productImage (uploadError)="setError($event)" (url)="setURL($event)"
                (percent)="setPercent($event)" folder="product"></app-upload>
        </div>
        <div class="image-shower" *ngIf="showImage" (click)="showImage = false">
            <img src="{{activeImage}}" />
        </div>
        <div style="height: 200px">

        </div>

    </div>

    <dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#grid' }" [(visible)]="loadingVisible"
        [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false">
    </dx-load-panel>


    <jw-modal id="image-upload">
        <app-cl-image-upload [(value)]="activeImage" #clUpload initalWidth="400px" initalHeight="300px"
            [maxSize]="500*1024" (error)="errorImage($event)" (success)="successImage($event)"></app-cl-image-upload>
    </jw-modal>