<jw-modal id="cl-modal-dialog">
  <div *ngTemplateOutlet="modalHeader; context: { id: 'cl-modal-dialog', title: this.ms.title }"></div>
  <div class="modal-content">
    <app-cl-form #clForm>

      <div *ngIf="this.ms.prompts">
        <div class="modal-message-content">
          {{this.ms.message}}
        </div>
        <div *ngFor="let item of this.ms.prompts">
          <app-cl-text-box theme="light" [(value)]="item.value" [required]="item.required" [label]=item.title></app-cl-text-box>
        </div>
      </div>

      <div *ngIf="this.ms.prompts == undefined" class="modal-message-content">
        {{this.ms.message}}
      </div>

      <div *ngIf="this.ms.prompts == undefined" style="display: flex; justify-content: space-between;">
        <button (click)="cancel()" class="cl-ok-button">{{this.ms.cancelButton}}</button>
        <button (click)="ok()" class="cl-cancel-button">{{this.ms.okButton}}</button>
      </div>

      <div *ngIf="this.ms.prompts" style="display: flex; justify-content: space-between;">
          <button (click)="cancelPrompt()" class="cl-ok-button">{{this.ms.cancelButton}}</button>
          <button [disabled]="!clForm.validate()" (click)="clForm.validate(); okPormpt(clForm.isValid, this.ms.prompts)" class="cl-cancel-button">{{this.ms.okButton}}</button>
        </div>
    </app-cl-form>
  </div>>
</jw-modal>





<ng-template #modalHeader let-id="id" let-title="title">
  <div class="modal-header">
    <div class="modal-header-text">
      {{this.ms.title | translate}}
    </div>
    <div class="html-invoice-header-close-icon" (click)="closeModal(id)">
      <i title="{{'Close' | translate}}" class="fas fa-times-circle cl-close-icon"></i>
    </div>
  </div>
</ng-template>