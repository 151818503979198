<div style="overflow:scroll; height:100vh;" id="campaign">
    <div class="section-title">
        {{"Company Settings" | translate}}
    </div>
    <div class="card-info">
        İşletmeniz ile ilgili genel tanımlamalar..
    </div>
    <div class="card" style="min-height: 0 !important">
        <div class="card-line">
            <div class="card-title">{{"Legal Name" | translate}}</div>
            <div class="card-value">
                <dx-text-box [(value)]="cSettings.data_object.legalName" [showClearButton]="true">
                </dx-text-box>
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"Brand" | translate}}</div>
            <div class="card-value">
                <dx-text-box [(value)]="cSettings.data_object.brand" [showClearButton]="true">
                </dx-text-box>
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"Country" | translate}}</div>
            <div class="card-value" style="display: flex; flex-direction: row">
                <dx-select-box [searchMode]="'contains'" [searchExpr]="'name'" (selectedItemChange)="countryChange($event)" [searchEnabled]="true" [(value)]="cSettings.data_object.country" [dataSource]="this.cSettings.countries_list" valueExpr="alpha2Code" displayExpr="name"></dx-select-box>
                <dx-text-box *ngIf="false" [(value)]="cSettings.data_object.country" [showClearButton]="true">
                </dx-text-box>
                <dx-load-indicator class='button-indicator' [visible]="cSettings.countries_loading">
                </dx-load-indicator>
            </div>
        </div>

        <div class="card-line">
            <div class="card-title">{{"City" | translate}}</div>
            <div class="card-value" style="display: flex; flex-direction: row">
                <dx-select-box [searchMode]="'contains'" [searchExpr]="'name'" [searchEnabled]="true" [(value)]="cSettings.data_object.city" [dataSource]="this.cSettings.cities_list" valueExpr="city" displayExpr="city">
                </dx-select-box>
                <dx-load-indicator class='button-indicator' [visible]="cSettings.cities_loading">
                </dx-load-indicator>
            </div>
        </div>

        <dx-button [width]="90" type='default' text="{{'Save' | translate}}" (onClick)="save()">

        </dx-button>

    </div>
</div>