import { CloudFolioService } from './../../../../../../projects/cloud-folio/src/lib/cloud-folio.service';
import { Output, EventEmitter } from '@angular/core';
import { Component, OnInit, Input } from '@angular/core';
import { trigger, style, animate, transition, query, stagger, keyframes } from '@angular/animations';
import { folioRow } from 'projects/cloud-folio/src/lib/models/folio';

@Component({
  selector: 'app-modifiers-row',
  templateUrl: './modifiers-row.component.html',
  styleUrls: ['./modifiers-row.component.css'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({ transform: 'translateX(100%)', opacity: 0 }),
          animate('200ms', style({ transform: 'translateX(0)', opacity: 1 }))
        ]),
        transition(':leave', [
          style({ transform: 'translateX(0)', opacity: 1 }),
          animate('200ms', style({ transform: 'translateX(100%)', opacity: 0 }))
        ])
      ]
    ),
    trigger('listAnimation', [
      transition('* <=> *', [

        query(':enter', style({ opacity: 0 }), { optional: true }),

        query(':enter', stagger('100ms', [
          animate('500ms ease-in', keyframes([
            style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
            style({ opacity: .5, transform: 'translateY(35px)', offset: 0.3 }),
            style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
          ]))]), { optional: true })
        ,
        query(':leave', stagger('100ms', [
          animate('800ms ease-in', keyframes([
            style({ opacity: 1, transform: 'translateX(-50px)', offset: 0.2 }),
            style({ opacity: .7, transform: 'translateX(-60px)', offset: 0.7 }),
            style({ opacity: 0, transform: 'translateX(100%)', offset: 1 }),
          ]))]), { optional: true })
      ])
    ]),
  ]
})
export class ModifiersRowComponent implements OnInit {

  @Input() item: folioRow
  @Output() changeModifier = new EventEmitter

  constructor(private fls: CloudFolioService) { }

  ngOnInit() {
  }

  isParentSelected() {

  }


  //! get selected rows modifiers from service
  getModifRows(folioRowID) {
   return this.fls.getModifierRows(folioRowID)
  }

  //! change selected rows modifiers
  _changeModifier(item: folioRow, modItem) {
    let p = {
      modifierRows: this.getModifRows(item.id),
      folioRow: item,
      parentID: modItem.parentID
    }
    this.changeModifier.emit(p)
  }


}
