<dx-data-grid #grid style="    font-size: 12px;
margin-left: auto;
margin-bottom: 30px;
margin-right: auto;
min-height: calc(100vh - 360px);
background-color: #2a2a2a;
border-radius: 10px;" id="gridContainer" [columnWidth]="100" [dataSource]="this.centralOrderService.OrderList"
    [hoverStateEnabled]="true" [columnHidingEnabled]="false" (onSelectionChanged)="selectionChangedHandler($event)" [allowColumnResizing]="true" columnResizingMode="widget"
    keyExpr="RowNumber" [allowColumnReordering]="true" [showBorders]="true">
    <dxo-selection mode="multiple"></dxo-selection>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 500, 1000]" [showInfo]="true"></dxo-pager>
    <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
    <dxo-search-panel [visible]="true" [width]="240" placeholder="Ara..."></dxo-search-panel>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxi-column dataField="OwnerBranchCode" caption="Sipariş Veren" [width]="140">
        <dxo-lookup [dataSource]="this.centralOrderService.branchList" displayExpr="branchName" valueExpr="branchCode">
        </dxo-lookup>
    </dxi-column>
    <dxi-column dataField="OwnerWarehouseCode" caption="Sipariş Veren Depo" [width]="140">
        <dxo-lookup [dataSource]="this.centralOrderService.branchList" displayExpr="branchName" valueExpr="branchCode">
        </dxo-lookup>
    </dxi-column>
    <dxi-column dataField="OrderNumber" caption="Sipariş No" [width]="80"></dxi-column>
    <dxi-column dataField="OrderStatus" caption="Sipariş Durumu" [width]="100">
        <dxo-lookup [dataSource]="this.centralOrderService.orderStatusList" displayExpr="name" valueExpr="id">
        </dxo-lookup>
    </dxi-column>
    <dxi-column dataField="OrderDate" caption="Sipariş Tarihi" alignment="right" dataType="datetime"
        format="dd/MM/yyyy" dataType="date" [width]="80"></dxi-column>
        <dxi-column dataField="DeliveredDate" caption="Tahmini Teslim Tar." alignment="right" dataType="datetime"
        format="dd/MM/yyyy" dataType="date" [width]="80"></dxi-column>

        <dxi-column dataField="TotalVat" caption="KDV Tutarı" [width]="80">
            <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
        </dxi-column>
        <dxi-column dataField="OrderGrandTotal" caption="Sipariş Tutarı" [width]="80">
            <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
        </dxi-column>
    <dxi-column dataField="OrderTotal" caption="Genel Toplam" [width]="80">
        <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
    </dxi-column>
    <!-- <dxo-lookup [dataSource]="this.inoviceSrc.erpStatus" displayExpr="name" valueExpr="id">
        </dxo-lookup> -->
</dx-data-grid>