import { AppPermissionsService } from './../../settings/services/app-permissions.service';
import { CloudFolioService } from './../../../../projects/cloud-folio/src/lib/cloud-folio.service';
import { menu } from '../../tools/button-menu/button-menu.component';
import { NumericButtonsService } from '../../services/tools/numeric-buttons.service';
import { GlobalsService } from '../../services/globals.service';
import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { trigger, style, animate, transition, query, stagger, keyframes } from '@angular/animations';
import { StartupService } from 'src/app/services/startup.service';
import { DxTextBoxComponent } from 'devextreme-angular';
import { folioRow } from 'projects/cloud-folio/src/lib/models/folio';

@Component({
  selector: 'app-folio',
  templateUrl: './folio.component.html',
  styleUrls: ['./folio.component.css'],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('200ms', style({ transform: 'translateX(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('200ms', style({ transform: 'translateX(100%)', opacity: 0 }))
      ])
    ]
    ),
    trigger('listAnimation', [
      transition('* <=> *', [

        query(':enter', style({ opacity: 0 }), { optional: true }),

        query(':enter', stagger('100ms', [
          animate('500ms ease-in', keyframes([
            style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
            style({ opacity: .5, transform: 'translateY(35px)', offset: 0.3 }),
            style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
          ]))]), { optional: true })
        ,
        query(':leave', stagger('100ms', [
          animate('800ms ease-in', keyframes([
            style({ opacity: 1, transform: 'translateX(-50px)', offset: 0.2 }),
            style({ opacity: .7, transform: 'translateX(-60px)', offset: 0.7 }),
            style({ opacity: 0, transform: 'translateX(100%)', offset: 1 }),
          ]))]), { optional: true })
      ])
    ]),
  ]
})
export class FolioComponent implements OnInit {

  ngOnInit() {
    this.fls.loadOpenFolios()
  }

  ngOnDestroy() {
  }

  @Output() showModifiers = new EventEmitter();
  @Output() changeModifiers = new EventEmitter();
  @Output() changeSubModifiers = new EventEmitter();

  @ViewChild("qrcodetext", { static: true }) qrCodeText: DxTextBoxComponent

  constructor(private glb: GlobalsService,
    public fls: CloudFolioService,
    private ss: StartupService,
    private ps: AppPermissionsService,
    public ns: NumericButtonsService) {

    this.folioMenu.push({ title: "Miktar", faicon: "fa-calculator", color: "yellowgreen", icon: "keyboard", tag: "qty" })
    this.folioMenu.push({ title: "Fiyat", faicon: "fa-money-bill", icon: "money", tag: "price", disabled: !this.ps.perm.ChangePrice })
    this.folioMenu.push({ title: "İkram", icon: "card_giftcard", tag: "gift", disabled: !this.ps.perm.MakeGift })
    this.folioMenu.push({ title: "QRCode", faicon: "fa-qrcode", icon: "keyboard", tag: "qrCode" })
    this.folioMenu.push({ title: "Varyasyon", faicon: "fa-pepper-hot", color: "purple", icon: "restaurant_menu", tag: "modifier" })
    this.folioMenu.push({ title: "Not", faicon: "fa-sticky-note", color: "yellow", icon: "money", tag: "note" })
    this.folioMenu.push({ title: "Sil", faicon: "fa-trash", color: "purple", icon: "card_giftcard", tag: "delete" })
    this.folioMenu.push({ title: "Kişi S.", faicon: "fa-users", icon: "event_seat", tag: "pax" })
    this.folioMenu.push({ title: "Sandalye", faicon: "fa-chair", icon: "event_seat", tag: "seat" })

    this.footerMenu.push({ title: "Gönder", faicon: "fa-receipt", icon: "restaurant_menu", tag: "send", selectable: true })
    this.footerMenu.push({ title: "İptal", faicon: "fa-window-close", icon: "money", tag: "cancel", selectable: true })
    this.footerMenu.push({ title: "Ödeme", faicon: "fa-cash-register", icon: "card_giftcard", tag: "payment", selectable: true })


    // Folio loyalty service objserver. when: qr successfully read and received data from the service.
    this.fls.observer_folio_loyalty.subscribe(o => {
      if (!o) {
        return
      }

      if (o.status == "error") {
        this.glb.notice(o.data, "error")
        this.qrLoading = false
        this.qrCodeText.value = ""
        return
      }

      this.qrLoading = false
      this.qrVisible = false
      this.expand()
      setTimeout(() => {
        this.showToolTip = true
        setTimeout(() => {
          this.showToolTip = false
        }, 4000);
      }, 1000);
    })


    this.ss.observer_pos_screen.subscribe(o => {
      if (o.id == 'modifier-selection-active') {
        this.sideMenuDisabled = this.footerMenuDisabled = o.data
      }
    })


  }

  // qr code loading and popup visibile
  qrLoading = false
  qrVisible = false
  qrCode: string = ""

  // folio expanded when loyalty qr scanned expanded = true
  expanded = false

  // benefist tool tip eg: point and free goodies
  showToolTip = false

  // parent component -> menu or payment
  @Input() mode: string = "folio" //? or payment

  // row selection mode
  @Input() selectionMode: string = "single"

  //?rowselect: boolean = false

  //popup / popOver / tooltip show-hide variables
  noteVisible = false;
  qtyVisible = false;
  paxVisible = false;
  seatVisible = false;

  folioMenu: menu[] = []
  footerMenu: menu[] = []

  numPadTarget = "#qty-button"

  footerMenuDisabled: boolean = false
  sideMenuDisabled: boolean = false

  footer_button_clicked(item: menu) {
    this.showToolTip = false
    switch (item.tag) {
      case "send":
        this.fls.doneFolio()
        this.ss.subject_pos_screen.next({ id: "change-view", data: "table" })
        break;
      case "cancel":
        this.fls.cancelFolio()
        this.ss.subject_pos_screen.next({ id: "change-view", data: "table" })
        break;
      case "payment":
        this.ss.subject_pos_screen.next({ id: "change-view", data: "payment" })
        break;
      default:
        break;
    }
  }

  folio_button_clicked(item: menu) {

    switch (item.tag) {
      case "delete":
        if (!this.fls.selectedFolioRow) {
          this.glb.notice("Seçili adisyon satırı yok!", "info")
          return
        }
        this.removeFolioRow(this.fls.selectedFolioRow)
        break;
      case "modifier":
        if (!this.fls.selectedFolioRow) {
          this.glb.notice("Seçili adisyon satırı yok!", "info")
          return
        }
        this.showModifiers.emit(this.fls.selectedFolioRow)
        break;
      case "qrCode":
        this.qrCodeText.value = ""
        this.numPadTarget = "#qrCode"
        this.qrVisible = true
        break;
      case "seat":
        if (!this.fls.selectedFolioRow) {
          this.glb.notice("Seçili adisyon satırı yok!", "info")
          return
        }
        this.seatVisible = true
        break;
      case "gift":
        if (!this.fls.selectedFolioRow) {
          this.glb.notice("Seçili adisyon satırı yok!", "info")
          return
        }
        this.fls.selectedFolioRow.isGift = this.fls.selectedFolioRow.isGift ? false : true;
        break;
      case "qty":
        this.numPadTarget = "#qty"
        this.qtyVisible = this.qtyVisible ? false : true
        break;
      case "pax":
        this.numPadTarget = "#pax"
        this.paxVisible = this.paxVisible ? false : true
        break;
      case "note":
        this.noteVisible = true;
        break;
      default:
        break;
    }
  }

  //select folio row
  selectRow(item: folioRow) {
    this.glb.clickSound()
    this.fls.selectRow(item, this.selectionMode)
    this.folioMenu.find(p => p.tag === "modifier").disabled = (item.recordStatus != 'new') || (this.fls.folio.rows.filter(o => o.selected).length > 1)

  }

  //remove row from folio
  removeFolioRow(item) {
    if (item.qty > 1) {
      item.qty = item.qty - 1
    } else {
      this.glb.deleteSound()
      this.fls.removeRow(item.id)
    }
  }

  //emmit the MenuComponent
  changeModfier(e) {

    if (this.fls.selectedFolioRow.id != e.parentID) {
      let pRow: folioRow = this.fls.folio.rows.find(p => p.id == e.parentID)
      if (pRow.parentID != "0") {
        let pRow: folioRow = this.fls.folio.rows.find(p => p.id == pRow.id)
      }
      this.selectRow(pRow)
    } else {
      this.changeModifiers.emit(e)
    }


  }

  // Create array ngfor
  createArray(n: number): any[] {
    let p: number[] = []
    for (let index = 1; index <= n; index++) {
      p.push(index)
    }
    return p
  }

  // Expand folio div
  expand() {
    this.expanded = this.expanded ? false : true
    this.ss.subject_pos_screen.next({ id: "expand-folio", data: "" })
  }

  //after qrCodeScreen sohowed
  shown() {
    this.qrCodeText.instance.focus();
    this.qrCodeText.instance.focus();
  }

  paymentClick(paymentItem: folioRow, jPayment) {
    document.getElementById("folio-payment-" + paymentItem.id).style.position = "fixed"
    let s : string = (document.getElementById("folio-row-" + paymentItem.id).getBoundingClientRect().top)  + "px"
    let y : string = (document.getElementById("folio-row-" + paymentItem.id).getBoundingClientRect().left-50)  + "px"
    document.getElementById("folio-payment-" + paymentItem.id).style.top = s
    document.getElementById("folio-payment-" + paymentItem.id).style.left = y
  }
}
