import { AgreementsService } from './agreements.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agreements',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.scss']
})
export class AgreementsComponent implements OnInit {

  constructor(public agreementService: AgreementsService) { }

  ngOnInit() {
    this.agreementService.load()
  }

  initRow(e) {
  }

  updatedRow(e) {
    this.agreementService.upsert(e.data)
  }

  insertedRow(e) {
    this.agreementService.upsert(e.data)
  }

  deleteRow(e) {
    this.agreementService.delete(e.data)
  }

}
