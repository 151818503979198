import { PrintersService } from './../services/printers.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-printers',
  templateUrl: './printers.component.html',
  styleUrls: ['./printers.component.scss']
})
export class PrintersComponent implements OnInit {

  constructor(public prnService: PrintersService) { }

  ngOnInit() {
    this.prnService.load()
  }

  initRow(e) {

  }

  updatedRow(e) {
    this.prnService.upsert(e.data)
  }

  insertedRow(e) {
    this.prnService.upsert(e.data)
  }

  deleteRow(e) {
    this.prnService.delete(e.data)
  }

}
