<div style="overflow: scroll; height: 100vh; width: Calc(100% - 50px)">
  <!--NAVIGATION FILTER TOOLBAR-->
  <div class="invoice-toolbar">
    <div class="invoice-toolbar-inset" style="padding: 20px">
      <div style="display: flex; justify-content: space-between">
        <div style="padding: 10px">{{ "Invoice Tax Report" | translate }}</div>

        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 550px;
          "
        >
          Tarih Aralığı
          <dx-date-box [(value)]="startDate" [(max)]="endDate"> </dx-date-box>
          <dx-date-box [(value)]="endDate" [(min)]="startDate"> </dx-date-box>
          <dx-button [text]="'Tamam'" (onClick)="loadData()"></dx-button>
        </div>
      </div>
    </div>
  </div>

  <div style="display: flex; width: 100%">
    <dx-data-grid
      width="95%"
      #grid
      style="
        font-size: 12px;
        margin-left: auto;
        margin-right: auto;
        height: calc(100vh - 260px);
        padding: 20px;
        width: 95%;
        background-color: #2a2a2a;
        border-radius: 10px;
      "
      id="gridContainer"
      [dataSource]="data"
      [hoverStateEnabled]="true"
      [columnHidingEnabled]="false"
      invoiceNumber="id"
      [allowColumnReordering]="true"
      [(selectedRowKeys)]="selectedRows"
      [allowColumnResizing]="true"
      columnResizingMode="widget"
      [showBorders]="true"
      columnAutoWidth="true"
    >
      <dxo-state-storing
        [enabled]="true"
        type="localStorage"
        storageKey="tax-report-state"
      ></dxo-state-storing>
      <dxo-export
        [enabled]="true"
        fileName="fatura-vergi-raporu"
        [allowExportSelectedData]="true"
      ></dxo-export>
      <dxo-selection mode="multiple"></dxo-selection>
      <dxo-scrolling
        columnRenderingMode="virtual"
        mode="virtual"
        showScrollbar="always"
      ></dxo-scrolling>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
      <dxo-search-panel
        [visible]="true"
        [width]="240"
        placeholder="Ara..."
      ></dxo-search-panel>
      <dxo-header-filter [visible]="true"></dxo-header-filter>
      <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
      <dxo-paging [pageSize]="15"></dxo-paging>

      <dxi-column
        dataField="readed"
        caption="{{ 'Readed' | translate }}"
        [width]="80"
        dataType="boolean"
      >
      </dxi-column>
      <dxi-column
        dataField="stock"
        caption="{{ 'Stok' | translate }}"
        [width]="80"
        dataType="boolean"
      ></dxi-column>

      <dxi-column
        dataField="invoiceDate"
        caption="{{ 'Date' | translate }}"
        alignment="right"
        dataType="datetime"
        format="dd/MM/yyyy"
        [width]="80"
      ></dxi-column>
      <dxi-column
        dataField="invoiceNumber"
        caption="{{ 'Invoice ID' | translate }}"
        [width]="140"
      ></dxi-column>
      <dxi-column
        dataField="reference"
        caption="{{ 'Reference' | translate }}"
        [width]="140"
      ></dxi-column>
      <dxi-column
        dataField="invoiceType"
        caption="{{ 'Type' | translate }}"
        [width]="60"
      >
        <dxo-lookup
          [dataSource]="inoviceSrc.invoiceTypes"
          displayExpr="name"
          valueExpr="id"
        >
        </dxo-lookup>
      </dxi-column>
      <dxi-column
        dataField="direction"
        caption="{{ 'Direction' | translate }}"
        [width]="80"
      >
        <dxo-lookup
          [dataSource]="[
            { id: 'inBound', name: 'Gelen' },
            { id: 'outBound', name: 'Giden' }
          ]"
          displayExpr="name"
          valueExpr="id"
        >
        </dxo-lookup>
      </dxi-column>
      <dxi-column
        dataField="accountName"
        caption="{{ 'Acount Title' | translate }}"
        [width]="240"
      ></dxi-column>
      <dxi-column
        dataField="taxNumber"
        caption="{{ 'VKN/TC Kimlik' | translate }}"
        [width]="120"
      ></dxi-column>
      <dxi-column dataField="description" caption="Açıklama" [width]="200">
      </dxi-column>
      <dxi-column dataField="address" caption="Adres" [width]="200">
      </dxi-column>
      <dxi-column
        dataField="grandTotal"
        caption="{{ 'Tutar' | translate }}"
        [width]="80"
      >
        <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
      </dxi-column>
      <dxi-column
        dataField="taxTotal"
        caption="{{ 'KDV Tutarı' | translate }}"
        [width]="100"
      >
        <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
      </dxi-column>

      <dxi-column dataField="taxTotal_1" caption="%1 KDV Tutarı">
        <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
      </dxi-column>
      <dxi-column dataField="taxTotal_10" caption="%10 KDV Tutarı">
        <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
      </dxi-column>
      <dxi-column dataField="taxTotal_20" caption="%20 KDV Tutarı">
        <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
      </dxi-column>
      <dxi-column dataField="total_1" caption="%1 KDV Toplamı">
        <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
      </dxi-column>
      <dxi-column dataField="total_10" caption="%10 KDV Toplamı">
        <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
      </dxi-column>
      <dxi-column dataField="total_20" caption="%20 KDV Toplamı">
        <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
      </dxi-column>

      <dxi-column dataField="stoppageAmount" caption="Stopaj Tutarı">
        <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
      </dxi-column>
      <dxi-column dataField="stoppageRate" caption="Stopaj Oranı">
        <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
      </dxi-column>
      <dxi-column dataField="stoppageReason" caption="Stopaj Sebebi">
      </dxi-column>

      <dxo-summary>
        <dxi-total-item
          column="grandTotal"
          summaryType="sum"
          valueFormat="#,###.00"
          displayFormat="{0}"
        >
        </dxi-total-item>
        <dxi-total-item
          column="taxTotal"
          summaryType="sum"
          valueFormat="#,###.00"
          displayFormat="{0}"
        >
        </dxi-total-item>
        <dxi-total-item
          column="taxTotal_1"
          summaryType="sum"
          valueFormat="#,###.00"
          displayFormat="{0}"
        >
        </dxi-total-item>
        <dxi-total-item
          column="taxTotal_10"
          summaryType="sum"
          valueFormat="#,###.00"
          displayFormat="{0}"
        >
        </dxi-total-item>
        <dxi-total-item
          column="taxTotal_20"
          summaryType="sum"
          valueFormat="#,###.00"
          displayFormat="{0}"
        >
        </dxi-total-item>
        <dxi-total-item
          column="total_1"
          summaryType="sum"
          valueFormat="#,###.00"
          displayFormat="{0}"
        >
        </dxi-total-item>
        <dxi-total-item
          column="total_10"
          summaryType="sum"
          valueFormat="#,###.00"
          displayFormat="{0}"
        >
        </dxi-total-item>
        <dxi-total-item
          column="total_20"
          summaryType="sum"
          valueFormat="#,###.00"
          displayFormat="{0}"
        >
        </dxi-total-item>
        <dxi-total-item
          column="stoppageAmount"
          summaryType="sum"
          valueFormat="#,###.00"
          displayFormat="{0}"
        >
        </dxi-total-item>
        <dxi-total-item
          column="stoppageRate"
          summaryType="avg"
          valueFormat="#,###.00"
          displayFormat="{0}"
        >
        </dxi-total-item>
        <dxi-total-item column="invoiceNumber" summaryType="count">
        </dxi-total-item>
      </dxo-summary>
    </dx-data-grid>
  </div>
</div>
