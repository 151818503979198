import { CloudFolioService } from './../../../../projects/cloud-folio/src/lib/cloud-folio.service';
import { menu } from './../../tools/button-menu/button-menu.component';
import { TablesService, Table } from '../../services/tables.service';
import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Renderer2 } from '@angular/core';
//import * as moment from 'moment';
import notify from 'devextreme/ui/notify';
import { StartupService } from 'src/app/services/startup.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-table',
  templateUrl: './designed-table.component.html',
  styleUrls: ['./designed-table.component.css']
})
export class DesignedTableComponent implements OnInit, OnDestroy {


  tables: menu[] = []


  @Input() drag: boolean = true
  @Output() tableSelected = new EventEmitter

  editTemplate
  popVisible: boolean
  selectedTable: Table

  tableGroupID

  sliderValue = 0
  zoomValue = 0
  tempTables
  tableTypes = [
    "2 Kişi",
    "4 Kişi",
    "6 Kişi",
    "8 Kişi",
  ]

  objectTypes = [
    "Bitki",
    "Lamba",
    "Speratuar"
  ]

  oTables: Table[]
  constructor(private renderer: Renderer2, private ss: StartupService, public ts: TablesService, private fls: CloudFolioService, private router: Router) { }

  ngOnInit() {


    this.ts.load().then(o => {
      this.ts.data.forEach(tg => {
        let grp: menu = new menu()
        grp.title = tg.tableGroup
        grp.tag = tg.tableGroupID
        grp.selectable = true;
        grp.icon = "restaurant"
        this.tables.push(grp)
      })

      let self = new menu()
      self.title = "Self Servis"
      self.tag = "self-servis"
      self.selectable = true;
      self.icon = "restaurant"

      let delivery = new menu()
      delivery.title = "Paket Servis"
      delivery.tag = "delivery"
      delivery.selectable = true;
      delivery.icon = "motorcycle"

      this.tables.push(self)
      this.tables.push(delivery)

    })


  }

  ngAfterViewInit() {
    if (this.ts.selectedTableGroup) {
      setTimeout(() => {
        this.loadTable(this.ts.selectedTableGroup.tableGroupID)
      }, 2);
    }
  }

  ngOnDestroy() {
  }

  groupNameChanged(e) {
    this.ts.selectedTableGroup.tableGroup = e.value
    this.ts.setTables(this.ts.selectedTableGroup)
  }

  groupSelected(e) {
    this.ts.selectedTableGroup = this.ts.data.find(p => p.tableGroupID == e.value)
    this.loadTable(e.value)
  }

  selectGroup(grp) {
    if (grp.tag == "self-servis") {
      this.fls.createOrOpenFolio("self-service", "self", "Self Service")
      this.ss.subject_pos_screen.next({ id: "change-view", data: "menu" })
    } else if (grp.tag == "delivery") {
      this.router.navigate(['delivery'])
    } else {
      this.ts.selectedTableGroup = this.ts.data.find(o => o.tableGroupID == grp.tag)
      this.loadTable(this.ts.selectedTableGroup)
    }

  }

  addTableGroup(name) {
    this.popVisible = false
    this.ts.addTableGroup(name)
  }

  addTable(name, type) {
    type = type.replace(" Kişi", "")
    this.popVisible = false
    this.ts.addTable(name, type)
    this.loadTable(this.ts.selectedTableGroup.tableGroupID)
  }

  dragEnd(e) {
    console.log(e);
    let id = e.source.element.nativeElement.id
    let ex: HTMLElement = document.getElementById(id) as HTMLElement
    this.renderer.setStyle(ex, "transition", "none")
    this.renderer.setStyle(ex, 'z-index', "0")
    id = id.replace("table-", "")
    let tbl = this.ts.selectedTableGroup.tables.find(t => t.id == id)
    tbl.x = (tbl.x_start + e.source._dragRef._passiveTransform.x)
    tbl.y = (tbl.y_start + e.source._dragRef._passiveTransform.y)
    this.ts.saveActiveGroup()

  }

  dragMove(e) {
    console.log(e)
  }

  isOpen(item: Table) {
    return (this.fls.openFolios.find(fl => fl.table.id == item.id && fl.status == "open") != undefined)
  }


  isClosed(item: Table) {
    return (this.fls.openFolios.find(fl => fl.table.id == item.id && fl.status == "closed") != undefined)
  }


  isLocked(item: Table) {
    let fl = this.fls.openFolios.find(fl => fl.table.id == item.id)
    if (fl) {
      return fl.lock.status == 'locked' ? "!" : ""
    } else {
      return ""
    }
  }

  loadTable(tableGroupID) {
    this.ts.selectedTableGroup.tables.map(p => p.selected = false)
    setTimeout(() => {
      this.ts.selectedTableGroup.tables.forEach(element => {
        setTimeout(() => {
          let ex: HTMLElement = document.getElementById('table-' + element.id) as HTMLElement
          if (ex) {
            try {
              let tr = "translate3d(" + element.x + "px" + ", " + element.y + "px" + ", 0px)"
              this.renderer.setStyle(ex, 'transform', tr)
              this.renderer.setStyle(ex, 'z-index', "0")
              element.x_start = element.x
              element.y_start = element.y
              this.transformImage(element.id)
              this.renderer.setStyle(ex, "display", "block")
            } catch (error) {
            }
          }
        }, 0);
      });
    }, 0);
  }

  aloadTable(tableGroupID) {
    this.oTables = this.ts.data.find(tg => tg.tableGroupID == tableGroupID).tables
    setTimeout(() => {
      this.oTables.forEach(element => {
        let tbl = this.ts.selectedTableGroup.tables.find(t => t.id == element.id)
        if (!tbl) {
          this.ts.selectedTableGroup.tables.push(element)
        } else {
          tbl = element
        }
        setTimeout(() => {
          this.ts.selectedTableGroup.tables.map(p => p.selected = false)
          let ex: HTMLElement = document.getElementById('table-' + element.id) as HTMLElement
          let tr = "translate3d(" + element.x + "px" + ", " + element.y + "px" + ", 0px)"
          this.renderer.setStyle(ex, 'transform', tr)
          this.renderer.setStyle(ex, 'z-index', "0")
          element.x_start = element.x
          element.y_start = element.y
          this.transformImage(element.id)
          this.renderer.setStyle(ex, "display", "block")
        }, 0);
      });
    }, 0);
  }

  save() {

  }

  removeDrag() {
    this.drag = this.drag ? false : true
    this.loadTable(this.ts.selectedTableGroup.tableGroupID)
  }

  dragStart(e) {
    let id = e.source.element.nativeElement.id
    let ex: HTMLElement = document.getElementById(id) as HTMLElement
    this.renderer.setStyle(ex, 'z-index', "1000")
  }

  selectTable(item: Table) {
    if (item.isDecorationObject && !this.drag)
      return

    this.ts.selectedTableGroup.tables.map(p => p.selected = false)
    this.selectedTable = item
    item.selected = true
    this.sliderValue = item.rotate
    this.zoomValue = item.zoom

    if (!this.drag) {

      let fl = this.fls.openFolios.find(fl => fl.table.id == item.id)
      if (fl && fl.lock.status == 'locked') {
        let it = 0
        let toastSettings = {
          message: "Adisyon " + fl.lock.userName + " tarafından " + it + " dakikadır kilitli",
          type: "info",
          displayTime: 2000,
          position: {
            at: 'center',
          }
        }
        notify(toastSettings)
      }
      this.tableSelected.emit(this.selectedTable)
    }
  }

  sliderValueChanged(e) {
    this.selectedTable.rotate = e.value;
    this.transformImage(this.selectedTable.id)
  }

  zoomValueChanged(e) {
    this.selectedTable.zoom = e.value;
    this.transformImage(this.selectedTable.id)
  }

  transformImage(id) {
    let ex: HTMLElement = document.getElementById('image-' + id) as HTMLElement
    let table = this.ts.selectedTableGroup.tables.find(tbl => tbl.id == id)
    let tr = `rotate(${table.rotate}deg) scale(1.${table.zoom})`
    this.renderer.setStyle(ex, 'transform', tr)
  }

  showAddTable() {
    this.editTemplate = "table"
    this.popVisible = true;
  }

  addPlant() {
    this.popVisible = false
    this.ts.addDecorationObject("plant")
  }

  showAddGroup() {
    this.editTemplate = "group"
    this.popVisible = true;
  }

  delete() {
    this.ts.deleteTable(this.selectedTable.id)
  }

  deleteGroup() {
    
  }

}
