import { GenericDataService } from 'projects/cloud-components/src/lib/database/generic-data.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirestoreService } from './firestore.service';
import { Injectable } from '@angular/core';
import { GlobalsService } from './globals.service';
import { CloudDbService, IdGeneratorService } from 'cloud-labs-core';

@Injectable({
  providedIn: 'root'
})
export class TablesService extends GenericDataService {

  collection = "pos-tables"
  data: TableGroup[] = []

  selectedTableGroup: TableGroup
  constructor(public cs: CloudDbService, private gid: IdGeneratorService) {
    super(cs)
  }


  setTables(tg: TableGroup) {
    this.cs.upsert({ col: this.collection, data: tg })
  }

  addTableGroup(groupName: string) {
    let tg: TableGroup = new TableGroup()
    tg.tableGroupID = this.gid.generateMaxi()
    tg.branch = "master"
    tg.branchID = this.gid.generateMaxi()
    tg.tableGroup = groupName
    tg.tables = []
    this.setTables(tg)
    this.selectedTableGroup = tg
  }

  addTable(tableName: string, type: string) {
    const tbl: Table = new Table
    tbl.name = tableName
    tbl.icon = "/assets/tables/table" + type + ".png"
    tbl.id = this.gid.generateMaxi()
    tbl.x = tbl.y = tbl.x_start = tbl.y_start = tbl.zoom = tbl.rotate = 0
    tbl.selected = false
    tbl.type = type
    tbl.isDecorationObject = false
    this.selectedTableGroup.tables.push(tbl)
    this.saveActiveGroup()
  }

  addDecorationObject(type) {
    const tbl: Table = new Table
    tbl.name = "plant"
    tbl.icon = "/assets/tables/tableplant.png"
    tbl.id = this.gid.generateMaxi()
    tbl.x = tbl.y = tbl.x_start = tbl.y_start = tbl.zoom = tbl.rotate = 0
    tbl.selected = false
    tbl.type = "object"
    tbl.isDecorationObject = true
    this.selectedTableGroup.tables.push(tbl)
    this.saveActiveGroup()
  }

  saveActiveGroup() {
    this.setTables(this.selectedTableGroup)
  }

  deleteTable(id) {
    this.selectedTableGroup.tables.splice(this.selectedTableGroup.tables.findIndex(tbl => tbl.id === id), 1)
    this.saveActiveGroup()
  }

}


export class TableGroup {

  id: string
  branchID: string
  branch: string
  tableGroupID: string
  tableGroup: string
  tables: Table[]

}

export class Table {

  id: string = ""
  name: string = ""
  icon: string = ""
  type: string = ""
  x_start: number = 0
  y_start: number = 0
  x: number = 0
  y: number = 0
  rotate: number = 0
  zoom: number = 0
  selected: boolean = false
  isDecorationObject: boolean = false
  detail: TableDetail = new TableDetail()

}

export class TableDetail {
  isDirty: boolean = false
  isReserved: boolean = false
  reservationID: string = ""
}