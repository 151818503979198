

<div style="width:1000px; margin: auto; ">
    <dx-data-grid [width]="1000" id="gridContainer" [dataSource]="data" [showBorders]="true" [remoteOperations]="true">
        <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>                                
        <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
        <dxi-column caption="Vergi No" [width]="150" dataField="taxNo" dataType="string">
        </dxi-column>
        <dxi-column caption="Firma Adı" dataField="accountName" dataType="string">
        </dxi-column>
        <dxi-column caption="PK" dataField="postBoxes[0].alias" dataType="string">
        </dxi-column>
    
        <dxo-paging [pageSize]="25"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[25, 50, 100]"></dxo-pager>
    </dx-data-grid>
</div>
