import { Observable } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { productGroup, ProductGroupService } from './service/product-group.service';
import { UploadComponent } from '../../../../projects/cloud-components/src/lib/upload/upload.component';
import { IdGeneratorService } from 'cloud-labs-core';
import { dataServiceSubject } from 'projects/cloud-components/src/lib/database/generic-data.service';
import { ClImageUploadComponent } from 'projects/cloud-components/src/lib/cl-image-upload/cl-image-upload.component';
import { ModalService } from 'projects/cloud-components/src/lib/modal/modal.service';

@Component({
  selector: 'app-product-groups',
  templateUrl: './product-groups.component.html',
  styleUrls: ['./product-groups.component.css']
})
export class ProductGroupsComponent implements OnInit {
  @ViewChild('clUpload', { static: true }) clUplod: ClImageUploadComponent
  @ViewChild('productImage', { static: true }) upload: UploadComponent

  showFilterRow: boolean = true;
  showHeaderFilter: boolean = true;
  loadingVisible = false;
  activeImage = "";
  showImage: boolean = true;

  constructor(
    private modalService: ModalService,
    public pg: ProductGroupService, private igs: IdGeneratorService) { }

  ngOnInit() {

    this.pg.observerGenericDataService.subscribe(o => {
      if (o) {
        if (o.action == "loading") {
          this.loadingVisible = true
        } else {
          this.loadingVisible = false
        }
      }
    })

    this.pg.load()
  }

  ngAfterViewInit() {
    this.pg.refresh()
  }

  initRow(e) {
    console.log(e)
    e.data.id = this.igs.generateMaxi()
    e.data.hide = false
    if (e.data.parentID == 0) {
      e.data.parentID = "0"
    }
  }

  updateRow(e) {
    console.log(e)
    this.pg.upsert(e.data)
  }

  insertRow(e) {
    console.log(e)
    this.pg.upsert(e.data)
  }

  deleteRow(e) {
    this.pg.delete({ id: e.data.id })
  }

  imageClick(data) {
    this.activeImage = data.data.image
    this.showImage = true
  }

  activeData
  uploadImage(data) {
    this.activeData = data.data.id
    this.upload.showUpload()
  }

  setPercent(e) {
    this.loadingVisible = true
  }

  setURL(e) {
    let data = this.pg.data.filter(p => p.id == this.activeData)[0]
    data.image = e
    this.pg.upsert(data)
    this.loadingVisible = false
  }

  setError(e) {
    setTimeout(() => {
      this.loadingVisible = false
      this.loadingVisible = false
    }, 2000);
  }


  successImage(e) {
    console.log(e);
    let data = this.pg.data.filter(p => p.id == this.activeData)[0]
    data.image = e
    this.pg.upsert(data)
    this.loadingVisible = false
  }

  errorImage(e) {

  }

  showUpload(e) {
    this.activeData = e.data.id
    this.clUplod.value = e.data.image
    this.clUplod.init()
    this.modalService.open("image-upload")

  }
}
