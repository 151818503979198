import { Component, OnInit, ViewChild } from '@angular/core';
import { Invoice, invoiceRow, InvoiceService } from '../services/invoice.service';
import { ClStore, ClTextBoxComponent } from 'projects/cloud-components/src/public-api';
import { ProductService } from 'src/app/product/products/service/product.service';
import { MycurrencyPipe } from 'projects/cloud-components/src/lib/pipe/mycurrency.pipe';

@Component({
  selector: 'app-inovice-entry',
  templateUrl: './inovice-entry.component.html',
  styleUrls: ['./inovice-entry.component.scss']
})


export class InoviceEntryComponent implements OnInit {


vats = []
  activeInvoice: Invoice = new Invoice()
  accountStore: ClStore
  productStore: ClStore

  columns = []

  constructor(public invoiceService: InvoiceService, public productsService: ProductService, private mcp: MycurrencyPipe) { }

  @ViewChild('clTotal', { static: true }) clTotal: ClTextBoxComponent

  ngOnInit() {
    this.accountStore = new ClStore(this.invoiceService.cs, "accounts", "helpers")
    this.productStore = new ClStore(this.productsService.cs, "menu-products")
    this.productStore.clDataOptions.filter.condition = "contains"
    this.accountStore.clDataOptions.filter.condition = "contains"

    let ps = new invoiceRow()
    ps.taxRate = 18
    this.activeInvoice.invoiceRows.push(ps)

    this.vats.push({id:0, name: "0%"})
    this.vats.push({id:1, name: "1%"})
    this.vats.push({id:8, name: "8%"})
    this.vats.push({id:18, name: "18%"})

    /*  name = ""
     quantity = 0
     unitPrice = 0
     taxRate = 0
     taxTotal = 0
     Total = 0 
     
     data: { source: this.pg.data, displayExpr: 'name', valueExpr: 'id' }
     */

    this.columns = [
      { name: 'name', caption: 'Ürün / Hizmet', width: 250, type: 'text', filter: null },
      { name: 'qty', caption: 'Grup', width: 100, type: 'number', filter: null, },
      { name: 'unitPrice', caption: 'Br. Fiyat', width: 80, type: "currency", filter: null },
      { name: 'taxRate', caption: 'Vergi', width: 80, type: 'number', filter: null },
      { name: 'taxTotal', caption: 'Vergi ₺', width: 100, type: "currency", filter: null },
      { name: 'Total', caption: 'Toplam', width: 100, type: "currency", filter: null },
    ]
  }

  add_row() {
    let ps = new invoiceRow()
    this.activeInvoice.invoiceRows.push(ps)
  }

  remove_row(index) {
    this.activeInvoice.invoiceRows.splice(index, 1)
  }

  sel(e) {
  }

  qtyChange(e, index) {
    let ir: invoiceRow = this.activeInvoice.invoiceRows[index]
    ir.total = this.roundTwo(e * ir.unitPrice)

  }

  unitPriceChange(e, index) {
    let ir: invoiceRow = this.activeInvoice.invoiceRows[index]
    ir.total = (ir.quantity * e)
  }

  totalChange(e, index) {
    let ir: invoiceRow = this.activeInvoice.invoiceRows[index]
    ir.unitPrice = this.roundTwo(ir.total / ir.quantity)

    let str = ""
      str.replace(new RegExp(this.stringToRegExp("."), 'g'), '')
      str.replace(new RegExp(this.stringToRegExp("₺"), 'g'), '')
      str.replace(new RegExp(this.stringToRegExp(","), 'g'), ',');
  }

  roundTwo(num: number) {
    var str = num.toFixed(2); 
    var number = Number(str); 
    return number
  }

  stringToRegExp(str) {
    return str.replace(/\./g,'\\.')
         .replace(/\[/g, '\\[')
         .replace(/\]/g, '\\]')
         .replace(/\,/g, '\\,')
         .replace(/\|/g, '\\|')
         .replace(/\)/g, '\\)')
         .replace(/\(/g, '\\(')
         .replace(/\^/g, '\\^')
         .replace(/\$/g, '\\$')
         .replace(/\_/g, '\\_')
         .replace(/\?/g, '\\?')
         .replace(/\-/g, '\\-');
  }
}
