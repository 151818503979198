import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { CloudDbService } from 'cloud-labs-core';
import { Observable, timer, AsyncSubject, Subject } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { ClStore } from './cl-store';

@Injectable({
  providedIn: 'root'
})
export class GenericDataService {


  clStore: ClStore

  dataSource: any = {};
  store: CustomStore

  sort
  id_key: string = "LicanceID"
  loadRefresh: number = 0
  data: any[] = []
  pagedData: any[] = []
  db = null
  collection = ""
  subject: dataServiceSubject = new dataServiceSubject() //= { db: this.db, collection: this.collection, action: subjectActionStateMap.none  }

  subjectGenericDataService = new BehaviorSubject<dataServiceSubject>(undefined)
  observerGenericDataService = this.subjectGenericDataService.asObservable()



  filterBackup

  constructor(public cs: CloudDbService) {

    if (this.loadRefresh > 0 && this.subject.action == "loaded") {
      this.setRefreshTimer()
    }

    //!error buradan kaynaklanıyor..
   
      this.clStore = new ClStore(cs, this.collection, this.db)
   

   

  }

  refresh() {
    if (this.subject.action == "loading") { return }
    this.load(this.filterBackup, true).then(o => {
      this.data = o
    })
  }

  setRefreshTimer() {
    timer(0, 1000).subscribe(() => {
      if (this.subject.action == "loading") { return }
      this.load(this.filterBackup, true).then(o => {
        this.data = o
      })
    })
  }

  load(_filter?, _loadForce?: boolean, _sort?): Promise<any> {
    this.filterBackup = _filter
    this.publishSubject("loading")
    return new Promise((resolve, reject) => {
      debugger
      if (this.collection == "") {
        this.publishSubject("error")
        reject("collection not specified")
      }
      if (this.subject.action == "loaded" && !_loadForce) {
        this.publishSubject("loaded")
        this.subject.test = "Gryffindor"
        resolve(this.data)
      } else {
      return this.getDataFromServer(_filter, _sort, resolve, reject);
      }
    })
  }


  changeDatabase() {
    console.log("databse changed", this.collection)
    this.data = []
    this.pagedData = []

  }

  private getDataFromServer(_filter: any, _sort: any, resolve: (value?: any) => void, reject: (reason?: any) => void) {
    //this.data = [];
    if (this.sort && !_sort) {
      _sort = this.sort
    }

    let param = { col: this.collection, _filter: _filter, _sort: _sort, _db: this.db }
    if (!this.db) delete param._db


    this.cs.getCollection({ col: this.collection, _filter: _filter, _sort: _sort }).subscribe(d => {
      this.publishSubject("loaded");
      this.subject.collection = this.collection;
      this.subjectGenericDataService.next(this.subject);
      this.data = Object.assign(d);
      resolve(this.data);
    }, e => {
      this.data = [];
      this.publishSubject("error");
      reject(e);
    });
  }

  upsert(item: any): Promise<any> {
    this.subject.action = "upsert"
    this.subjectGenericDataService.next(this.subject)

    let param = { col: this.collection, data: item, _db: this.db }
    if (!this.db) delete param._db

    return this.cs.upsert(param)
  }

  delete(filter): Promise<any> {
    this.subject.action = "delete"
    this.subjectGenericDataService.next(this.subject)

    let param = { col: this.collection, filter: filter, _db: this.db }
    if (!this.db) delete param._db

    return this.cs.delete(param)
  }


  publishSubject(s: subjectActions) {
    this.subject.action = s
    this.subjectGenericDataService.next(this.subject)
    this.subject.action = "none"
  }


  createClDataSource() {
    this.clStore = new ClStore(this.cs, this.collection, this.db)

    /* this.dt.filter.condition = "none"
    this.dt.filter.field = "name"
    this.dt.filter.value = "B"

    this.dt.sort.field = "name"
    this.dt.sort.direction = -1
    this.dt.page.limit = 2
    this.dt.page.start = 0 */


  }

  clDataOptions: clData = new clData()
  clDatasource: { totalCount: 0, data: [] }
  totalCount = 0

  pageLoad(): Promise<any> {


    return new Promise((resolve, reject) => {
      let filter = this.createFilter(this.clDataOptions.filter)
      let sort = this.createSort(this.clDataOptions.sort)

      let getParam = {
        _db: this.db,
        col: this.collection,
        _filter: filter, _sort: sort,
        _limit: this.clDataOptions.page.limit, _skip: this.clDataOptions.page.start,
        _withTotalCount: true
      }

      if (!this.db) delete getParam._db

      this.cs.getCollection(getParam).subscribe(d => {

        this.pagedData = d.data
        this.totalCount = d.totalCount
        resolve(d)
      }, e => {
        this.clDatasource = { data: null, totalCount: 0 }
        this.publishSubject("error");
        reject(e);
      });

    })


  }

  private createSort(loadOptions: clSort) {
    let sort = {};

    if (loadOptions.field != '') {
      sort[loadOptions.field] = loadOptions.direction;
    }

    return sort;
  }

  private createFilter(loadOptions: clFilter) {
    let filter = {};
    if (loadOptions.condition != 'none') {
      let search = {};
      let field = loadOptions.field
      let condition = loadOptions.condition
      let value = loadOptions.value

      if (condition == "contains") {
        search = { $regex: '.*' + value + ".*", '$options': 'i' };

      }
      else if (condition == "startswith") {
        search = { $regex: '^' + value, '$options': 'i' };
      }
      else if (condition == "endswith") {
        search = { $regex: value + "$", '$options': 'i' };
      }
      else if (condition == "equal") {
        search = { $regex: '^' + value + "$", '$options': 'i' };
      }
      else if (condition == "notcontains") {
        search = { $regex: "^((?!" + value + ").)*$", '$options': 'i' };
      }
      filter[field] = search;
    }

    return filter;
  }



}


export class clData {
  page: clPage = new clPage()
  filter: clFilter = new clFilter()
  sort: clSort = new clSort()
}

export class clPage {
  start: number = 0
  limit: number = 10
}

export class clFilter {

  condition: condition = "none"
  field: string = ""
  value: any = undefined

}

export class clSort {
  direction: number = 1//1 -1
  field: string = ""
}


export class dataServiceSubject {
  db: string
  collection: string
  action: subjectActions = "none"
  test: House
}

export type condition = 'contains' | 'startswith' | 'endswith' | 'equal' | 'notcontains' | "none"

export declare type subjectActions = keyof typeof subjectActionStateMap;
declare const subjectActionStateMap: {
  "none": string
  'upsert': string
  'delete': string
  "loading": string
  "loaded": string
  "error": string

};

type House =
  | "Gryffindor" | 'Hufflepuff' | 'Ravenclaw' | 'Slytherin';