import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalService } from '../modal/modal.service';
import { ClModalDialogService, clPrompt } from './cl-modal-dialog.service';

@Component({
  selector: 'lib-cl-modal-dialog',
  templateUrl: './cl-modal-dialog.component.html',
  styleUrls: ['./cl-modal-dialog.component.scss']
})
export class ClModalDialogComponent implements OnInit {

  @Input() title = ""

  constructor(private modalService: ModalService, public ms: ClModalDialogService) { }

  ngOnInit() {
  }

  openModal() {
    this.modalService.open("cl-modal-dialog")
  }

  closeModal(i) {
    this.modalService.close(i)
  }

  ok() {
    this.modalService.close("cl-modal-dialog")
    this.ms.confirm(true)
  }

  cancel() {
    this.modalService.close("cl-modal-dialog")
    this.ms.confirm(false)
  }

  cancelPrompt() {
    this.modalService.close("cl-modal-dialog")
    this.ms.confirmPromt(false, [])
  }

  okPormpt(isValid, prompts: clPrompt[]) {
    console.log(prompts)
    if (isValid) {
      this.modalService.close("cl-modal-dialog")
      this.ms.confirmPromt(true, prompts)
    }
  }
}
