import { Component, OnInit, ElementRef, ViewChild, Input, Output, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { AccountService, account } from '../services/account.service';
import { CloudDataStoreService } from 'projects/cloud-components/src/lib/database/cloud-data-store.service';
import { fromEvent } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { contact } from 'src/app/services/tools/contacts.service';
import { IdGeneratorService } from 'cloud-labs-core';
import { EventEmitter } from '@angular/core';
import { ClFormComponent } from 'projects/cloud-components/src/lib/cl-form/cl-form.component';
import { CityService } from 'src/app/tools/services/city.service';
import { TownService } from 'src/app/tools/services/town.service';
import { ClStore } from 'projects/cloud-components/src/lib/database/cl-store';
import { changeEvent } from 'projects/cloud-components/src/lib/cl-base/cl-base.component';

@Component({
  selector: 'app-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.scss']
})
export class AccountFormComponent implements OnInit, OnChanges {

  @ViewChild(ClFormComponent, { static: true }) clform: ClFormComponent


  @Input() accounID

  @Output() statusChanged = new EventEmitter();

  account: account = new account()

  /* cityStore
  townStore*/
  accountStore: ClStore
  eInoviceStore: ClStore
  real: false
  constructor(public as: CloudDataStoreService,
    private cd: ChangeDetectorRef,
    private ig: IdGeneratorService,
    public cityService: CityService,
    public townService: TownService,
    public accountService: AccountService,
    private elementRef: ElementRef) { }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.accounID && !changes.accounID.firstChange) {
      this.account = changes.accounID.currentValue
    }
  }

  ngOnInit() {
this.cityService.createClDataSource()
this.townService.createClDataSource()
    this.accountStore = new ClStore(this.accountService.cs, this.accountService.collection)
    this.eInoviceStore = new ClStore(this.accountService.cs, 'accounts', 'helpers')
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.style.setProperty('--field-direction', "row")
  }

  townSelected(e) {
  
  }

  save() {
    if (!this.clform.validate()) return
    if (this.account.id == "") {
      this.account.id = this.ig.generateMaxi()
    }
    this.accountService.upsert(this.account)
    this.statusChanged.emit("saved")

  }

  new() {
    this.account = new account()
  }

  taxChange(e: changeEvent) {
    e.loading = true

    this.eInoviceStore.clDataOptions.filter.condition = "equal"
    this.eInoviceStore.clDataOptions.filter.field = "taxNo"
    this.eInoviceStore.clDataOptions.filter.value = e.value

    this.eInoviceStore.pageLoad().then(o => {
      if (o.totalCount > 0) {
        e.info = "EFATURA"
      } else {
        e.info = ""
      }
      e.loading = false
    }).catch(er => {
      e.loading = false
    })


  }

  contactsChange(e) {
  }

  nameSelected(e) {
    if (e) {
      this.account = e
    }

  }
}
