<div class="cloud-form">
  <div class="cloud-header">CARI HESAP FORMU</div>
  <app-cl-form>



    <app-cl-select-box style="width:100%" [clStore]="eInoviceStore" [(value)]="account.name" (selectionChanged)="nameSelected($event)"
      [ItemTemplate]="accountName" searchExpr="name" [clStore]="this.accountStore">
      <ng-template #accountName let-data="data">
        <div style="display: flex; justify-content: space-between">
          <div>
            {{data.name}}
          </div>
          <div>
            {{data.taxNo}}
          </div>
        </div>
      </ng-template>
    </app-cl-select-box>

    <app-cl-text-box label="Firma Adı" characterCase="upper" [required]="true" [(value)]="account.name">
    </app-cl-text-box>

    <app-cl-text-box label="Tablea Adı" characterCase="upper" [required]="true" [(value)]="account.brandName">
    </app-cl-text-box>

    <div class="cloud-group"></div>

    <div class="1cloud-group animated" [ngClass]="{'flipInX': !real, 'flipOutX': real }" *ngIf="!real">

      <app-cl-text-box #taxnox [eventsEnabled]="true" (objChanged)="taxChange($event)" label="Vergi No" [(value)]="account.taxNo">
      </app-cl-text-box>

      <app-cl-text-box label='Vergi Dairesi' characterCase="upper" [(value)]="account.taxHome"></app-cl-text-box>

    </div>

    <div class="1cloud-group animated" [ngClass]="{'flipInX': real, 'flipOutX': !real }" *ngIf="real">
      <app-cl-text-box label="TCKimlik No" [(value)]="account.taxNo"></app-cl-text-box>
    </div>

    <div class="cloud-group"></div>

    <cl-text-area style="width: 100%" label='Adres' [required]="true" rows="4" cols="50" [(value)]="account.address">
    </cl-text-area>


    <div class="cloud-field">

      <app-cl-select-box [required]="true" ilabel="İLÇE" style="width:100%" [(value)]="account.country.name"
        (selectionChanged)="townSelected($event)" [ItemTemplate]="pls2" searchExpr="name"
        [clStore]="this.townService.clStore">
        <ng-template #pls2 let-data="data">
          <div style="display: flex; justify-content: space-between">
            <div>
              {{data.name}}
            </div>
            <div style="font-size: 10px; color: gray">
              {{data.cityName}}
            </div>
          </div>
        </ng-template>
      </app-cl-select-box>


      <app-cl-select-box [required]="true" ilabel="İL" style="width:100%" [(value)]="account.city.name"
        [ItemTemplate]="pls" searchExpr="name" [clStore]="this.cityService.clStore">
        <ng-template #pls let-data="data">
          <div style="display: flex; justify-content: space-between">
            <div>
              {{data.name}}
            </div>
          </div>
        </ng-template>
      </app-cl-select-box>

    </div>




    <div class="cloud-field">
      <app-cl-text-box ilabel="TEL" style="width: 50%" [(value)]="account.phone"></app-cl-text-box>
      <app-cl-text-box characterCase="lower" ilabel="EMAİL" [type]="'email'" style="width: 50%"
        [(value)]="account.mail"></app-cl-text-box>
    </div>

    <div class="cloud-group"></div>

    <!-- <app-cl-contact-form [(valueArray)]="account.contacts" (valueChange)="contactsChange($event)"></app-cl-contact-form> -->


    <div>
      <button (click)="save()" class="ok-button">Kaydet</button>
      <button (click)="new()" class="cancel-button">Yeni</button>
    </div>

  </app-cl-form>
</div>