<div class="header-main">
    <div style="display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 10px;
    align-items: center;">
        <i class="fas fa-bars" style="padding-right: 4px;" (click)="logo()"></i>
        <i class="fas fa-palette" style="padding-right: 4px;" (click)="changeTheme()"></i>
        <span class="licance">
            {{this.glb.activeBrand}}
        </span>
        <i class="fas fa-store-alt" style="padding-left: 4px; line-height: 24px; font-size:14px"
            (click)="changeCompany()"></i>
            <div *ngIf="this.glb.exs.length > 0" class="header-currency" style="display: flex;
                flex-direction: row;
                justify-content: flex-start;
                margin-left: 10px;
                align-items: center;" >
                {{this.glb.exs[0].rate| currency:'$'}} - {{this.glb.exs[1].rate | currency:'€'}}
            </div>
    </div>
   
    <div class="user" *ngIf="this.ls.userInfo" (click)="logOut()">
        <i class="fas fa-sign-out-alt"
            style="padding-right: 4px;     padding-right: 4px;font-size: 20px; line-height: 34px;" (click)="logo()"></i>
        <div style="display: flex; flex-direction: column;">
            {{this.ls.userInfo.name}}
            <span (click)="logOut()">{{'Logout' | translate}}</span>
        </div>
    </div>
</div>