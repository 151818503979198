import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-generic-grid',
  templateUrl: './generic-grid.component.html',
  styleUrls: ['./generic-grid.component.scss']
})
export class GenericGridComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

  initNewRow(e) {

  }

  updateRow(e) {

  }

  deleteRow(e) {

  }

}
