
import { Component, OnInit, ViewChild } from '@angular/core';
import * as firebase from 'firebase'
import { UploadComponent } from '../../../../projects/cloud-components/src/lib/upload/upload.component';
import { BranchService, branch } from './branch.service';

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.scss']
})
export class BranchesComponent implements OnInit {

  countries = []
  cities = []
  countyies = []

  branches: branch[]
  popupVisible = false
  tempImage
  loadingVisible = false

  branch: branch = new branch()

  buttonOptions: any = {
    text: "Register",
    type: "success",
    useSubmitBehavior: true
  }

  constructor(private bs: BranchService) {
    this.click1 = this.click1.bind(this)
  }

  @ViewChild('branchImage') upload: UploadComponent

  click1(e) {
    console.log(e)
    this.branch = e.row.data
    this.popupVisible = true
  }

  ngOnInit() {

    this.bs.load().then(o => {
      this.initData()
    })

    this.countries = [{ id: '90', name: 'Turkiye' }, { id: '1', name: 'Germany' }, { id: '44', name: 'Netherlands' }]
    this.cities = [{ id: '34', counrtyId: '90', name: 'İstanbul' }, { id: '35', counrtyId: '90', name: 'İzmir' }]
    this.countyies = [{ id: '34', counrtyId: '90', name: 'İstanbul' }, { id: '35', counrtyId: '90', name: 'İzmir' }]
  }


  initData() {
    this.branches = this.bs.data.sort((a, b) => {
      var x = a.name.toLowerCase();
      var y = b.name.toLowerCase();
      if (x < y) { return -1; }
      if (x > y) { return 1; }
      return 0;
    })
  }

  async onFormSubmit(e) {
    this.loadingVisible = true
    console.log(e)
    console.log(this.branch)
    e.preventDefault();
    await this.bs.upsert(this.branch)
    this.bs.load({}, true).then(() => {
      this.initData();
    })
    console.log(this.branches)
    this.popupVisible = false
    this.initData()
    this.loadingVisible = false
  }

  deleteRow(e) {
    this.bs.delete({ id: e.data.id });
  }

  new() {
    this.popupVisible = true
    this.branch = new branch()
  
    this.branch.geopoint = new firebase.default.firestore.GeoPoint(0, 0)
  }

  select(item) {
    this.branch = item
    this.popupVisible = true
  }

  filter(field, value) {
    console.log(field, value)
    this.branches = this.bs.data.filter(p => p.name.toLowerCase().indexOf(value) > -1).sort((a, b) => {
      var x = a.name.toLowerCase();
      var y = b.name.toLowerCase();
      if (x < y) { return -1; }
      if (x > y) { return 1; }
      return 0;
    })
  }




  //#region upload component

  startUpload(e) {
    this.tempImage = this.branch.image
    this.branch.image = "/assets/loading.gif"
  }

  setURL(u) {
    this.branch.image = u
  }

  setError(e) {
    this.branch.image = this.tempImage
  }

  setPercent(p) {
    console.log(p)
  }

  showUpload() {
    this.upload.showUpload()
  }

  //#endregion



}



