<div [ngClass]="{'button-menu-row':type == 'row', 'button-menu-column':type == 'column'}">
    <div *ngFor="let item of menu" [id]="item.tag" (click)="_clicked(item)" [ngClass]="{
        'button': !item.disabled || !disabledAll, 
        'button-disabled': item.disabled || disabledAll,  
        'selected': item.selected
    }">
        <i *ngIf="item.faicon" class="fa {{item.faicon}}" style="font-size: 24px;" [ngStyle]="{'color': item.color}" [ngClass]="{
            'icon-disabled': item.disabled || disabledAll, 
            'icon-selected': item.selected
            }"></i>
        <i *ngIf="!item.faicon" class="material-icons md-24" [ngStyle]="{'color': item.color}" [ngClass]="{
            'icon-disabled': item.disabled || disabledAll, 
            'icon-selected': item.selected, 
            'icon-disabled': disabledAll}">{{item.icon}}</i> {{item.title}}
    </div>
</div>