<div
  [ngClass]="{ 'cloud-form': !invoiceEntry }"
  style="max-width: 1200px !important"
>
  <div class="tab-bar-header">
    <div
      class="tab-bar-item"
      *ngFor="let item of tabBarItems"
      (click)="selectTabItem(item)"
      [ngClass]="{ 'active-tab': selectedTab == item.id }"
    >
      {{ item.title }}
    </div>
  </div>

  <div *ngIf="selectedTab == 'invoice'">
    <div class="cloud-header">
      CARİ : {{ this.invoice.account.accountName }}
    </div>
    <div class="cloud-header">FATURA NO : {{ this.invoice.invoiceNumber }}</div>
    <!-- <button class="cl-cancel-button" style="background-color: #d95910;" (click)="previewInvoice()">Önizle</button> -->
    <!-- <div class="cloud-header">FATURA TARIHI : {{this.invoice.invoiceDate}}</div> -->
    <div
      style="
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
      "
    >
      <div style="width: 20%">
        <div>Fatura No :</div>
      </div>

      <dx-text-box
        [(value)]="this.invoice.invoiceNumber"
        style="width: 400px; margin-left: 4px"
        [disabled]="true"
      ></dx-text-box>

      <div>Şube :</div>
      <dx-select-box
        item="item"
        style="width: 400px"
        (onSelectionChanged)="branchChanged($event)"
        [(value)]="this.branchCode"
        [searchEnabled]="true"
        searchMode="contains"
        [searchExpr]="['branchCode', 'branchName']"
        [dataSource]="branchList"
        valueExpr="branchCode"
        displayExpr="branchName"
      >
        <div *dxTemplate="let i of 'item'">
          <span>{{ i?.branchCode }} - {{ i?.branchName }}</span>
        </div>
      </dx-select-box>
    </div>

    <div
      style="
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
      "
    >
      <div style="width: 20%">
        <div>Fatura Tarihi :</div>
      </div>
      <!-- <dx-text-box [(value)]="this.invoice.invoiceDate"  [disabled]="true" >

        </dx-text-box> -->
      <!-- {{this.invoice.invoiceDate}} -->
      <!-- <dx-text-box style="width: 250px;" mask = "dd.MM.yyyy" [(value)]="this.invoice.invoiceDate" [disabled]="true"></dx-text-box> -->
      <dx-date-box
        style="width: 400px"
        [disabled]="true"
        [useMaskBehavior]="true"
        type="datetime"
        [value]="this.invoice.invoiceDate"
        displayFormat="dd.MM.yyyy"
      >
      </dx-date-box>
      <div>
        <div>UUID :</div>
      </div>
      <dx-text-box
        style="width: 400px"
        [(value)]="this.invoice.uuid"
        [disabled]="true"
      ></dx-text-box>
    </div>

    <div
      style="
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
      "
    >
      <div style="width: 20%">
        <div>VKN/TC :</div>
      </div>
      <dx-text-box
        style="width: 400px"
        [(value)]="this.invoice.account.taxNumber"
        [disabled]="true"
      ></dx-text-box>

      <div>Cari :</div>
      <dx-select-box
        item="item"
        style="width: 400px"
        (onSelectionChanged)="accountChanged($event)"
        [(value)]="this.accountCode"
        [searchEnabled]="true"
        searchMode="contains"
        [searchExpr]="['accountCode', 'accountName']"
        [minSearchLength]="3"
        [dataSource]="this.dxAccount"
        valueExpr="accountCode"
        displayExpr="accountName"
      >
        <div *dxTemplate="let i of 'item'">
          <span>{{ i.accountCode }} - {{ i.accountName }}</span>
        </div>
      </dx-select-box>
    </div>
<div  style="
display: flex;
width: 100%;
margin-bottom: 20px;
">
    <div>Aktarım Tipi :</div>
    <dx-select-box
      item="item"
      style="width: 400px;margin-left: 14%;"
      (onSelectionChanged)="accountingTypeChanced($event)"
      [(value)]="this.defaultAccounting"
      [dataSource]="this.accountingTypes"
      valueExpr="id"
      displayExpr="name"
    >
      <!-- <div *dxTemplate="let i of 'item'">
        <span>{{ i.accountCode }} - {{ i.accountName }}</span>
      </div> -->
    </dx-select-box>

    </div>

    <div style="width: 100%">
      <dx-data-grid
        #dataGrid
        style="
          font-size: 12px;
          margin-left: auto;
          margin-bottom: 30px;
          margin-right: auto;
          height: calc(100vh - 460px);
          background-color: #2a2a2a;
          border-radius: 10px;
        "
        id="gridContainer"
        [dataSource]="this.invoice.invoiceRows"
        [columnWidth]="100"
        [hoverStateEnabled]="true"
        [columnHidingEnabled]="false"
        [allowColumnResizing]="true"
        columnResizingMode="widget"
        keyExpr="id"
        [allowColumnReordering]="true"
        [showBorders]="true"
        (onRowUpdated)="rowUpdated($event)"
        (onEditorPreparing)="onEditorPreparing($event)"
      >
        <!-- <dxo-selection mode="multiple"></dxo-selection> -->
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-pager
          [showPageSizeSelector]="true"
          [allowedPageSizes]="[50, 100, 500, 1000]"
          [showInfo]="true"
        >
        </dxo-pager>
        <dxo-editing mode="cell" [allowUpdating]="true" [allowAdding]="false">
        </dxo-editing>
        <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
        <dxo-search-panel
          [visible]="true"
          [width]="240"
          placeholder="Ara..."
        ></dxo-search-panel>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <!-- <dxi-column dataField="dispatchDate" caption="{{ 'Date' | translate }}" alignment="right"
                dataType="datetime" format="dd/MM/yyyy" dataType="date" [width]="80"></dxi-column> -->
        <dxi-column
          dataField="code"
          [allowEditing]="false"
          caption="Stok Kodu"
          [width]="40"
        ></dxi-column>
        <dxi-column
          dataField="name"
          [allowEditing]="false"
          caption="Ürün Adı"
          [width]="240"
        ></dxi-column>
        <dxi-column
          dataField="externalCode"
          caption="Kerzz Stok Kodu"
          [width]="340"
          [editorOptions]="kerzzStockCodeOptions"
        >
          <dxo-lookup
            [dataSource]="inventoryList"
            displayExpr="inventoryName"
            valueExpr="inventroyCode"
          >
          </dxo-lookup>
          <dxi-validation-rule
            message="Stok Seçim Alanı Zorunludur."
            type="required"
          ></dxi-validation-rule>
        </dxi-column>
        <dxi-column dataField="warehouseCode" caption="Depo Kodu" [width]="240">
          <dxo-lookup
            [dataSource]="this.wareHouseList"
            displayExpr="warehouseName"
            valueExpr="warehouseCode"
          >
          </dxo-lookup>
          <dxi-validation-rule
            message="Depo Seçim Alanı Zorunludur."
            type="required"
          ></dxi-validation-rule>
        </dxi-column>

        <dxi-column
          dataField="quantity"
          [allowEditing]="true"
          caption="Miktar"
          [width]="240"
          [editorOptions]="{ min: 0 }"
        >
        <dxi-validation-rule
      type="range"
      [min]="0.000000001"
      message="Miktar 0 dan büyük olmalıdır."
    ></dxi-validation-rule>
      </dxi-column>
        <!-- <dxi-column dataField="unitName" [allowEditing]="true" caption="Birim" [width]="240"></dxi-column> -->
        <dxi-column
          dataField="unitName"
          [width]="90"
          caption="Birimi"
          cellTemplate="unitCellTemplate"
          [allowEditing]="false"
        >
          <!-- <dxo-lookup [dataSource]="units" displayExpr="name" valueExpr="id">
          </dxo-lookup> -->
          <div *dxTemplate="let data of 'unitCellTemplate'">
            <dx-select-box
              [dataSource]="units[data.rowIndex]"
              [(value)]="data.data.unitName"
              displayExpr="name"
              [searchEnabled]="true"
              valueExpr="id"
            ></dx-select-box>
          </div>
          <dxi-validation-rule type="required"> </dxi-validation-rule>
        </dxi-column>
        <dxi-column
          dataField="unitPrice"
          [allowEditing]="false"
          caption="{{ 'Brm. Fiyat' | translate }}"
          [width]="80"
        >
          <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
        </dxi-column>
        <dxi-column
          dataField="taxRate"
          [allowEditing]="true"
          caption="{{ 'Kdv Oranı' | translate }}"
          [width]="80"
          [editorOptions]="{ min: 0 }"
        >
        </dxi-column>
        <dxi-column
          dataField="taxTotal"
          [allowEditing]="false"
          caption="{{ 'Top. Kdv' | translate }}"
          [width]="80"
        >
          <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
        </dxi-column>

        <dxi-column
        dataField="discount"
        [allowEditing]="false"
        caption="{{ 'İndirim' | translate }}"
        [width]="80"
      >
        <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
      </dxi-column>

        <dxi-column
          dataField="total"
          [allowEditing]="false"
          caption="{{ 'Toplam' | translate }}"
          [width]="80"
        >
          <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
        </dxi-column>
      </dx-data-grid>
    </div>

    <div
      style="
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
      "
    >
      <button class="cl-cancel-button" (click)="close()">Kapat</button>
      <!--  <div style="display: flex; gap: 10px; align-items: center">
         <button
          class="cl-cancel-button"
          style="background-color: #d95910"
          (click)="previewInvoice()"
        >
          Önizle
        </button>
      </div> -->
      <button class="cl-ok-button" (click)="sendBackOffice()">Aktar</button>
    </div>
  </div>

  <div *ngIf="selectedTab == 'preview'">
    <div id="pdf-preview-div"></div>
    <div
      style="
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
      "
    >
      <button class="cl-cancel-button" (click)="close()">Kapat</button>
      <!--  <div style="display: flex; gap: 10px; align-items: center">
       <button
        class="cl-cancel-button"
        style="background-color: #d95910"
        (click)="previewInvoice()"
      >
        Önizle
      </button>
    </div> -->
    </div>
  </div>
</div>

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [(visible)]="loadingVisible"
  [showIndicator]="true"
  [showPane]="true"
  [message]="loadingMessage"
  [shading]="true"
  [closeOnOutsideClick]="false"
>
</dx-load-panel>
