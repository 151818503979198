import { Component, OnInit, ViewChild, ElementRef, Output, Input, TemplateRef, ContentChild, HostListener } from '@angular/core';
import { fromEvent } from 'rxjs';
import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter,
} from "rxjs/operators";
import { EventEmitter } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { ClBaseComponent } from '../cl-base/cl-base.component';
import { GenericDataService } from 'projects/cloud-components/src/lib/database/generic-data.service';
import { ClStore } from 'projects/cloud-components/src/lib/database/cl-store';

@Component({
  selector: 'app-cl-select-box',
  templateUrl: './cl-select-box.component.html',
  styleUrls: ['./cl-select-box.component.scss']
})




export class ClSelectBoxComponent extends ClBaseComponent implements OnInit {

  @Input() searchExpr: string
  @Input() displayExpr: string
  // @Input() store: CustomStore

  @Input() showRecordStatus = false
  @Input() showClearButton = false
  @Input() minSearchLength = 2

  @Output() selectionChanged = new EventEmitter();

  // @Input() dataService: GenericDataService

  @Input() clStore: ClStore

  searchVal
  trackList = -1
  selected = ""
  loading = false
  new: estatus = "none"
  list: any[] = []
  constructor(private _elementRef: ElementRef) {
    super()
  }


  @Output('clickOutside') clickOutside: EventEmitter<any> = new EventEmitter();

  @HostListener('document:click', ['$event.target']) onMouseEnter(targetElement) {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.clickOutside.emit(null);
      this.list = []
    }
  }

  @ViewChild('clSelectBoxInput', { static: true }) clSelectBoxInput: ElementRef;
  clDropDownInput

  ngOnInit() {

    //this.clStore.clDataOptions.filter.condition = "startswith"
    this.clStore.clDataOptions.filter.field = this.searchExpr

    this.clStore.clDataOptions.page.limit = 15
    this.clStore.clDataOptions.page.start = 0
    this.clStore.clDataOptions.sort.field = this.searchExpr

  }

  ngAfterViewInit() {

    const keyDowns = fromEvent(this.clSelectBoxInput.nativeElement, 'keydown');
    const keyUps = fromEvent(this.clSelectBoxInput.nativeElement, 'keyup');

    const searchName = keyUps.pipe(
      filter((e: KeyboardEvent) => e.keyCode != 13 && e.keyCode != 40 && e.keyCode != 38 && e.keyCode != 9)
    );


    searchName.pipe(
      map((event: any) => {
        return event.target.value;
      })
      /* , filter(res => {
        return res.length >= this.minSearchLength && isNaN(res)
      }) */
      , debounceTime(300)
    ).subscribe((text: string) => {

      this.new = "none"
      if (text.length < 2) {
        this.list = []
        return
      }
      this.searchVal = text
      if (this.clStore) {
        this.searchStore(text)
        // } else {
        //   this.search(text);
      }
    })


    const keyPresses = keyDowns.pipe(

      filter((e: KeyboardEvent) => e.keyCode != 17)

    );

    keyPresses.subscribe((ctrlpress) => {

      console.log(ctrlpress.keyCode)
      if (ctrlpress.keyCode == 40) {
        this.traking("down")
      }
      else if (ctrlpress.keyCode == 38) {
        this.traking("up")
      }
      else if (ctrlpress.keyCode == 13) {
        this.select(this.list[this.trackList])
      }
    });
  }

  traking(path) {
    this.list.map(o => {
      o.selected = false
    })
    if (path == 'up' && this.trackList > 0) {
      this.trackList--
    }
    else if (path == 'down' && this.trackList < this.list.length) {
      this.trackList++
    }
    try {
      this.list[this.trackList].selected = true

    } catch (error) {

    }

  }

  isScrolledIntoView(el) {
    var rect = el.getBoundingClientRect();
    return (rect.top >= 0) && (rect.bottom <= window.innerHeight);
  }

  select(item) {
    console.log(item)
    this.clSelectBoxInput.nativeElement.value = item[this.searchExpr]
    this.list = []
    this.selected = item[this.searchExpr]
    this.new = 'old'
    this.value = item[this.searchExpr]
    this.valueChange.emit(item[this.searchExpr])
    this.selectionChanged.emit(item)
  }

  clear() {
    this.clSelectBoxInput.nativeElement.value = ""
    this.selectionChanged.emit(undefined)
    this.clSelectBoxInput.nativeElement.focus()
    this.new = "none"
    this.list = []
  }

  blur(e) {
    this.value = e.target.value
    this.valueChange.emit(e.target.value)
    if (this.value != "" && this.new != "old") {
      this.new = "new"
    }
  }

  searchStore(val) {
    this.new = "none"
    console.log("store", val);


    this.selectionChanged.emit(undefined)
    this.loading = true

    this.selected = undefined

    this.clStore.clDataOptions.filter.value = val

    this.clStore.filter(val).then(o => {
      console.log("loaded data", o);

      this.list = o.data;
      this.loading = false
      this.trackList = -1

      this.list.map(o => {
        o["selected"] = false
      })

    })
  }

  /*  search(val) {
 
 
     this.selectionChanged.emit(undefined)
     this.loading = true
     this.list = [];
     this.selected = undefined
     let that = this
     console.log(val);
 
     //this.clStore.clDataOptions.filter.condition = "startswith"
     this.clStore.clDataOptions.filter.field = this.searchExpr
     this.clStore.clDataOptions.filter.value = val
     this.clStore.clDataOptions.page.limit = 15
     this.clStore.clDataOptions.page.start = 0
     this.clStore.clDataOptions.sort.field = this.searchExpr
 
     this.clStore.pageLoad().then(o => {
 
      
       console.log(o);
       this.loading = false
       this.trackList = -1
 
       this.list = o.data;
       this.list.map(o => {
         o["selected"] = false
       })
 
       if (o.totalCount == 0) {
         this.new = 'new'
       }
     
     })
   } */

  higlight(itemValue) {

    const re = new RegExp(this.searchVal, 'gi');

    itemValue = itemValue.replace(re, '<span class="cl-highlight">' + this.searchVal + '</span>')
    return itemValue
  }



}


type estatus =
  | "none" | 'new' | 'old'


