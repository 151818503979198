import { take } from 'rxjs/operators';
import { AngularFireStorageModule, AngularFireStorageReference, AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
//import { AngularFireStorage, AngularFireUploadTask, AngularFireStorageReference } from 
import { finalize } from 'rxjs/internal/operators/finalize';
import { ElementRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})

export class UploadComponent implements OnInit {

  @Input() folder: string
  @Input() saveFirebase = true

  @Output() url = new EventEmitter();
  @Output() percent = new EventEmitter();
  @Output() uploadError = new EventEmitter();
  @Output() start = new EventEmitter();
  @Output() base64 = new EventEmitter();
  @Output() fileInfo = new EventEmitter()

  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;
  downloadURL: Observable<string>;
  uploading: boolean = false


  @ViewChild('fileUpload', { static: true }) nameInput;

  constructor(private afStorage: AngularFireStorage) {

  }

  ngOnInit() {

  }

  showUpload() {
    if (this.uploading)
      return

    this.nameInput.nativeElement.click();
  }

  base64textString
  handleFile(event) {
    var binaryString = event.target.result;
    let p = Image    
    this.base64textString = btoa(binaryString);
    this.base64.emit(btoa(binaryString))
  }


  upload(event) {
    var files = event.target.files;
    var file = files[0];

    if (files && file) {
      this.fileInfo.emit(file)
      var reader = new FileReader();
      reader.onload = this.handleFile.bind(this);
      reader.readAsBinaryString(file);
      
    }


    if (this.saveFirebase) {

      try {
        this.start.emit("starting")
        this.uploading = true
        const id = this.folder + "/" + Math.random().toString(24).substring(2);
        this.ref = this.afStorage.ref(id);
        this.task = this.ref.put(event.target.files[0]);

        this.task.snapshotChanges().subscribe(p => {
          if (p.totalBytes > 1000000) {
            this.uploading = false
            p.task.cancel()
            this.uploadError.emit("file size bigger")
          }
        })
        this.uploadProgress = this.task.percentageChanges();

        this.task.snapshotChanges().pipe(
          finalize(() => {
            this.downloadURL = this.afStorage.ref(id).getDownloadURL()
            this.afStorage.ref(id).getDownloadURL().subscribe(ref => {
              this.url.emit(ref)
              this.uploading = false
            })
          }
          )
        ).subscribe()

        this.task.percentageChanges().subscribe(ref => {
          this.percent.emit(ref)
        })

        this.uploadProgress.subscribe(ref => {
          this.percent.emit(ref)
        })

      } catch (error) {
        console.log(error)
        this.uploading = false
        this.uploadError.emit(error)
      }

    }
  }
}
