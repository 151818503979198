import { PushNotificationsService } from './../push-notifications/push-notifications.service';
import { Component, OnInit } from '@angular/core';
import { LoyaltyUsersService } from './service/loyalty-users.service';
import { TransactionsService } from 'src/app/services/loyalty/transactions.service';
import { loyaltyUserObject } from './service/loyalty-users';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  activeUser: loyaltyUserObject = new loyaltyUserObject()

  popupVisible = false
  profilePopupVisible = false
  transactionData = []

  constructor(public lus: LoyaltyUsersService,

    private push: PushNotificationsService, private ut: TransactionsService) { }

  ngOnInit() {
    this.lus.dxLoad()
  }

  refresh() {
    this.lus.dxLoad()
  }

  send(e) {
    console.log(e)
    this.push.sendNotificationToUser(e)
  }

  profile(user: loyaltyUserObject) {

    this.activeUser = user;
    this.profilePopupVisible = true;


  }

  block(user: loyaltyUserObject) {
    this.lus.block(user.id, false)


  }

  transactions(user: loyaltyUserObject) {
    this.ut.loadByParam(user.id).then(o => {
      this.transactionData = o
      this.popupVisible = true
    })

  }
}
