import { CloudDbService } from 'cloud-labs-core';
import { GenericDataService } from 'projects/cloud-components/src/lib/database/generic-data.service';
import { Injectable } from '@angular/core';
import { StartupService } from 'src/app/services/startup.service';

@Injectable({
  providedIn: 'root'
})
export class NewsService extends GenericDataService {

  public data: news[]
  collection  = "company-news"

  constructor(public cs: CloudDbService) {
    super(cs)
  }

  getBranch(id) {
    return this.data.find(p => p.id == id)
  }

}



export class news {

  id: string
  title: string
  subtitle: string
  message: string
  image: string
  creation: Date
  deadline: Date
  segments: string
  branches: string
  order: number = 0
  loginStatus: 'member' | 'guest' | 'both'

}