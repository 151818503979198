import { CloudLoginService, IdGeneratorService, CloudDbService } from 'cloud-labs-core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ScrollToConfigOptions } from "@nicky-lenaers/ngx-scroll-to";
import { Observable } from "rxjs/internal/Observable";
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { ScrollToService } from "@nicky-lenaers/ngx-scroll-to";
import { folioRow, folio } from './models/folio';
import { LoyaltyFolioService } from './loyalty/loyalty-folio.service';
import { loyalty_service_result } from './models/folio-loyalty';

@Injectable({
  providedIn: 'root'
})
export class CloudFolioService {


  loyaltyServiceResult: loyalty_service_result
  collection =  "pos-folio"

  totalPoint: 0
  loyaltyActive: boolean = false

  activeQrCode
  activeQrUser


  public subject_folio_loyalty = new BehaviorSubject<any>(false);
  observer_folio_loyalty = this.subject_folio_loyalty.asObservable();

  outstanding: number = 0

  public folioButtonsEnabled = true;
  public footerButtonsEnabled = true;

  public selectedFolioRow: folioRow

  public $folio: Observable<any>;
  public folio: folio = new folio();

  public openFolios: folio[] = [];
  constructor(
    private loyFls: LoyaltyFolioService,
    private http: HttpClient,
    private afs: AngularFirestore,
    private _scrollToService: ScrollToService,
    private cls: CloudLoginService,
    private ig: IdGeneratorService,
    private dbs: CloudDbService
  ) {
    this.loyFls.init(this)
    this.loadOpenFolios()
  }

  loadOpenFolios() {
    //TODO database name ?
      this.dbs.getCollectionToPromise({ col: this.collection, _filter: { status: 'open' } }).then(o => {
      this.openFolios = o
    })
  }

  getFolioID(id: string): string {

    try {
      return this.openFolios.find(fl => fl.table.id == id).id;
    } catch (error) {
      return undefined;
    }
  }

  //create new or open folio
  createOrOpenFolio(type: string, tableID: string, tableName: string) {
    let openFolioID = this.getFolioID(tableID);


    // is old folio, load it ->
    if (openFolioID != undefined) {
      this.folio = Object.assign(this.openFolios.find(fl => fl.table.id == tableID))
      this.folio.rows.filter(r => r.recordStatus == "new").map(fr => {
        fr.recordStatus = "old"
      })
      this.lockFolio()
      // is new folio, create new one ->
    } else {
      this.folio = new folio();
      this.folio.id = this.afs.createId();
      this.folio.type = type

      this.folio.creation = new Date();
      this.folio.lastChange = new Date();
      this.folio.creator.userID = this.cls.userInfo.id
      this.folio.creator.userName = this.cls.userInfo.name
      this.folio.table.id = tableID;
      this.folio.table.name = tableName;
      this.folio.type = "table";
      this.lockFolio()
      this.saveFolio()
    }
  }

  lockFolio() {
    this.folio.lastChange = new Date();
    this.folio.lock.userID = this.cls.userInfo.id
    this.folio.lock.userName = this.cls.userInfo.name
    this.folio.lock.status = "locked";
    this.folio.lock.time = new Date()
  }

  // Relase folio lock, convert new flagged rows to old and save folio then load open folios
  doneFolio() {
    this.folio.lock.status = "unlocked";
    this.folio.updaters.push({ userID: this.cls.userInfo.id, userName: this.cls.userInfo.name, time: new Date() })
    this.folio.rows.filter(p => p.recordStatus == 'new').map(m => m.recordStatus = "old")
    this.saveFolio()
  }

  // Relase folio lock, delete new flagged rows and save folio then load open folios
  cancelFolio() {
    this.folio.lock.status = "unlocked";
    this.folio.rows = this.folio.rows.filter(o => o.recordStatus != "new")
    this.loadOpenFolios()
  }

  createRow(rowID, product_id, product_name, qty, parent_id, price, image?, isGift?: boolean) {
    let row: folioRow = new folioRow()
    row.id = rowID
    row.name = product_name
    row.qty = qty
    row.unitPrice = price
    row.productID = product_id
    row.itemImage = image
    row.parentID = parent_id
    row.recordStatus = "new"
    row.isGift = isGift
    this.pushRow(row)

  }

  pushRow(row: folioRow) {
    //TODO refresh NS
    row.selected = false
    this.folio.rows.push(row);
  }

  saveFolio() {
    //! clear folio selection and loyalty items
    this.folio.rows.map(o => {
      o.isFree = false
      o.selected = false
    })
    //this.subject_folio_loyalty.next(undefined)
    this.dbs.upsert({ col: this.collection, data: this.folio }).then(o => {
      this.loadOpenFolios()
    }).catch(e => {
    })
  }

  closeFolio() {
    this.folio.lock.status = "unlocked";
    this.folio.status = "closed";
    this.saveFolio()
    this.loyFls.useLoyalty()
  }

  selectRow(row: folioRow, selectionMode: string) {


    if (!this.folioButtonsEnabled)
      return

    if (row.isPaidFull)
      return

    if (selectionMode != "multi") {
      if (row.selected) {
        row.selected = false
        return
      }
      this.folio.rows.map(o => o.selected = false)
    }


    this.folio.rows.filter(p => p.id == row.id).map(c => {
      if (!c.selected) {
        row.selected = true
      } else {
        row.selected = false
      }
    })

    this.selectedFolioRow = row.selected ? row : undefined
  }

  removeRow(folioRowID: string) {
    let subRows = this.folio.rows.filter(p => p.parentID == folioRowID);
    subRows.forEach(element => {
      let subFoliIndex = this.folio.rows.findIndex(sf => sf.id == element.id);
      this._removeRow(subFoliIndex)
    })
    let folioIndex = this.folio.rows.findIndex(p => p.id == folioRowID);
    this._removeRow(folioIndex)
  }

  _removeRow(index: number) {
    if (this.folio.rows[index].recordStatus == "new") {
      this.folio.rows.splice(index, 1);
    } else {
      this.folio.rows[index].recordStatus = "deleted"
      this.folio.rows[index].deleters.push({ userID: this.cls.userInfo.id, userName: this.cls.userInfo.name, time: new Date(), qty: 1, reasonID: "1", reason: "Garson Hatası" })
    }
  }

  scrollTo(rowID) {
    const config: ScrollToConfigOptions = {
      target: "folio-row-" + rowID,
      duration: 200,
      easing: "easeInOutQuad",
      offset: -20
    };
    setTimeout(() => {
      this._scrollToService.scrollTo(config);
    }, 0);
  }

  getRowTotal(row: folioRow) {
    row.price = row.qty * row.unitPrice;
    return row.price;
  }

  getFolioTotal() {
    return this.folio.rows.filter(o => !o.isGift && !o.isPayment && !o.isLoyalty && o.recordStatus != 'deleted').reduce((sum, item) => sum + (item.unitPrice * item.qty), 0);
  }

  getUnPaidTotal() {
    return this.getFolioTotal() + this.getPaidTotal()
  }

  getPaidTotal() {
    return this.folio.rows.filter(o => !o.isGift && o.isPayment).reduce((sum, item) => sum + (item.unitPrice * item.qty), 0);
  }

  getProductCountOnFolio(productID, parentID, qtyID?) {

    let findedModifiersInFolio: folioRow[]

    if (qtyID) {
      findedModifiersInFolio = this.folio.rows.filter(
        p =>
          p.parentID == parentID &&
          p.productID == productID &&
          p.qtyID == qtyID
      );
    } else {
      findedModifiersInFolio = this.folio.rows.filter(
        p =>
          p.parentID == parentID &&
          p.productID == productID
      );
    }

    let count = findedModifiersInFolio.reduce((sum, item) => sum + item.qty, 0);

    if (count < 0)
      count = 0

    return count;
  }

  addDiscount(id, discount, amount) {
    //this.glb.clickSound()
    let row = new folioRow()
    row.id = this.afs.createId()
    row.name = discount
    row.qty = 1
    row.unitPrice = amount - (amount * 2)
    row.productID = id
    row.parentID = "0"
    row.isDiscount = true
    this.pushRow(row)

    setTimeout(() => {
      this.scrollTo(row.id)
      this.saveFolio()
    }, 100);
  }

  addPayment(id, payment, amount) {
    //this.glb.clickSound()
    let row = new folioRow()
    row.id = this.afs.createId()
    row.name = payment
    row.qty = 1
    row.unitPrice = amount - (amount * 2)
    row.productID = id
    row.parentID = "0"
    row.isPayment = true
    this.pushRow(row)

    setTimeout(() => {
      this.scrollTo(row.id)
      this.saveFolio()
    }, 100);
  }

  getModifierRows(folioRowID) {
    let cs = this.folio.rows.filter(p => p.parentID === folioRowID && p.recordStatus != 'deleted').sort((a, b) => a.qtyID - b.qtyID)
    return cs
  }

  findModifierOnFolio(parentID, productID, activeQty) {
    return this.folio.rows.find(
      row =>
        row.parentID == parentID &&
        row.productID == productID &&
        row.qtyID == activeQty &&
        row.recordStatus != 'deleted'
    );
  }

  getModifiersOnFolio(parentID, productID, activeQty) {
    return this.folio.rows.filter(
      row =>
        row.parentID == parentID &&
        row.productID == productID &&
        row.qtyID == activeQty &&
        row.recordStatus != 'deleted'
    );
  }

  //get active rows
  getRows() {
    return this.folio.rows.filter(p => p.parentID === "0" && p.recordStatus != 'deleted' && !p.isPayment && !p.isDiscount)
  }

  //get discount rows in HTML
  getDiscountRows() {
    return this.folio.rows.filter(p => p.parentID === "0" && p.recordStatus != 'deleted' && p.isDiscount)
  }

  //get folio rows, only parentID == 0
  getPaymentRows() {
    return this.folio.rows.filter(p => p.parentID === "0" && p.recordStatus != 'deleted' && p.isPayment && !p.isDiscount)
  }



  createLoyaltyBasket() {
    this.loyFls.getLoyalty()
  }

   // Spended Point
   getSpended() {
    return this.folio.rows.filter(o => o.isLoyalty).reduce((sum, item) => sum + item.spendPoint, 0);
  }

  // Balance Point
  getBalanceStamp() {
    return this.totalPoint - this.getSpended()
  }

  // total point not enough false maybe free
  setLoyaltyFree(item: folioRow) {
    if ((this.totalPoint - this.getSpended()) < 0) {
      item.isLoyalty = false;
    }
  }


}



