import { product } from '../../../product/products/service/product.service';
import { Observable } from 'rxjs/internal/Observable';
import { Component, OnInit, Output, EventEmitter,Input } from '@angular/core';
import { ProductGroupService, productGroup } from '../../../product/product-groups/service/product-group.service';

@Component({
  selector: 'app-sub-groups',
  templateUrl: './sub-groups.component.html',
  styleUrls: ['./sub-groups.component.css']
})
export class SubGroupsComponent implements OnInit {


  subGroupsLevel: productGroup[]

  @Input() subGroups: Observable<product>
  @Output() subGroupClicked = new EventEmitter()

  constructor(private pg: ProductGroupService) { }

  ngOnInit() {
  }

  subGroupClick(grp: product) {
     this.subGroupClicked.emit(grp)
     this.subGroupsLevel = this.pg.getSubGroups(grp.id)
  }

  subGroupLevelClick(grp: product) {
    this.subGroupClicked.emit(grp)
 }

}
