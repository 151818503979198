import { product } from 'src/app/product/products/service/product.service';
import { ProductGroupService } from 'src/app/product/product-groups/service/product-group.service';
import { ProductService } from './../../product/products/service/product.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mb-welcome',
  templateUrl: './mb-welcome.component.html',
  styleUrls: ['./mb-welcome.component.scss']
})
export class MbWelcomeComponent implements OnInit {

  filteredProduct :product[]
  constructor(private ps: ProductService, private pg: ProductGroupService) { }

  ngOnInit() {
    let gid = this.pg.data.find(p => p.name == 'Kahveler').id
    this.filteredProduct = this.ps.data.filter(p => p.group == gid)
  }

}
