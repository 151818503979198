import { ThrowStmt } from '@angular/compiler';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CloudLoginService } from 'cloud-labs-core';
import { DxDataGridComponent } from 'devextreme-angular';
import moment from 'moment';
import { GlobalsService } from 'src/app/services/globals.service';
import { Branch } from '../models/global-model';
import { Invoice, invoiceLog, InvoiceService, logType } from '../services/invoice.service';

@Component({
  selector: 'app-convert-invoice',
  templateUrl: './convert-invoice.component.html',
  styleUrls: ['./convert-invoice.component.scss']
})
export class ConvertInvoiceComponent implements OnInit, OnChanges {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  dispatchList: Invoice[] = []
  branchList: Branch[] = []
  loadingMessage: string = "Yükleniyor...";
  loadingVisible: boolean = false;
  recordType = "0"
  selectedRows: string[]
  selectedInovices: Invoice[] = []
  selectedTotal: number = 0

  branchCode: string = ""
  constructor(public invoiceService: InvoiceService, private glb: GlobalsService, private ls: CloudLoginService,) { }
  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (changes.invoice) {
      console.log('convert', this.invoice)
    }
  }

  @Input("invoice") set setInvoice(value: Invoice) {
    this.invoice = value;
    this.getPosDispatchs(this.invoice.account.taxNumber, "0")
    this.branchCode = ""

  }
  invoice: Invoice;
  @Input() invoiceEntry = false;
  @Output() formClose = new EventEmitter<string>();

  ngOnInit(): void {
   

  }
  recordTypeChanged(e) {
    console.log(e.selectedItem.id)
    this.getPosDispatchs(this.invoice.account.taxNumber, e.selectedItem.id)
  }


  refreshDataGrid(e) {
    this.getPosDispatchs(this.invoice.account.taxNumber, this.recordType)
  }
  deSelectSingleRow(keys: any[]) {
    keys.forEach(key => {
      if (this.dataGrid.instance.isRowSelected(key)) {
        this.dataGrid.instance.deselectRows([key]);
      }
    })
  }
  getRow(key) {
    this.dataGrid.instance.getDataSource()[this.dataGrid.instance.getRowIndexByKey(key)];
  }
  isValidDispatchDate(invoiceDate, dispatchDate): boolean {
    const invoiceDateMoment = moment(invoiceDate);
    const dispatchDateMoment = moment(dispatchDate);
    const diff: number = invoiceDateMoment.diff(dispatchDateMoment, 'days')   // =1
    // Eğer irsaliye tarihi fatura tarihinden büyükse kontrol gereçekleştir.
    if (diff < 0) {
      if (diff < -7) { // fark 7 günnden fazla ise uyarı verip işleme izin ver
        // yarı ver
        this.glb.notice('İrsaliye tarihi ile fatura tarihi arasında 7 günden falza fark var.', "warning")
        return true;
      } // fark 7 gün ve altında ise işlemi iptal et.
      this.glb.notice('İrsaliye Tarihi Fatura Tarihinden Büyük Olamaz', "warning");
      return false;
    }

    return true;
  }

  selectionChangedHandler(e) {
    console.log(e)  
    if (e.currentSelectedRowKeys.length > 0) {
      let selectedInvoice = e.selectedRowsData.find(x => x.dispatchNumber == e.currentSelectedRowKeys[0])
      console.log(selectedInvoice)
      let invoiceDate: any = new Date(this.invoice.invoiceDate);
      let dispatchDate: any = new Date(selectedInvoice.dispatchDate);
      if (!this.isValidDispatchDate(invoiceDate, dispatchDate)) {
        this.deSelectSingleRow(e.currentSelectedRowKeys);
      }
    }
    if (e.selectedRowsData.length == 0) {
      this.selectedTotal = 0
    } else {
      this.selectedInovices = e.selectedRowsData;
      this.selectedTotal = this.selectedInovices.reduce((sum, item) => sum + (item.grandTotal), 0);
    }

  }



  close() { this.formClose.emit("close"); }
  convertToInvoice() {
    if (this.selectedRows.length == 0) {
      this.glb.notice('Faturaya Dönüştürülecek Belgeleri Seçiniz!', 'warning')
      return
    }
    if (this.branchCode == '') {
      this.glb.notice('Şube Seçimi Yapmadınız!', 'warning')
      return
    }
    let diff = Math.abs(this.invoice.grandTotal - this.selectedTotal)
    console.log(diff)
    if (0.03 >= diff || diff <= 0.03) { // +- 0.03 kontrolü
      this.loadingMessage = "İşleniyor..."
      this.loadingVisible = true;
      this.invoiceService.convertDispatchToInvoice(this.invoice.uuid, this.selectedRows, this.branchCode, this.recordType).subscribe(
        (o: any) => {
          this.loadingVisible = false;
          if (o.statusCode = 200) {
            this.addLog('log', this.invoice, 'faturalaştırma', 'başarılı.')
            this.invoice.stock = true;
            this.invoiceService.upsert(this.invoice)
            this.invoiceService.refresh()
            this.glb.notice('İşlem Tamamlandı', 'success')
            this.close()
          }
          else {
            this.glb.notice(o.message, 'danger')
            this.close()
          }
        })
    }
    else {
      this.glb.notice('Seçili irsaliye ve fatura genel toplamı aynı olmalı!', 'warning')
    }




  }

  onSelectionChanged(e) {
    this.recordType = e.selectedItem.id

  }

  addLog(lType: logType, inv: Invoice, title, message) {
    let log: invoiceLog = new invoiceLog();
    log.type = lType;
    log.title = title;
    log.message = message;
    log.userId = this.ls.userInfo.id;
    log.uuid = inv.uuid
    if (!inv.logs) {
      inv["logs"] = [];
    }
    inv.logs.push(log);
    this.invoiceService.addInvoiceLog(log).subscribe((x: any) => {

    })
  }

  // getBranchList() {
  //   this.loadingMessage = "Yükleniyor..."
  //   this.loadingVisible = true;
  //   this.invoiceService.getBranchList().subscribe(
  //     (o: any) => {
  //       this.loadingVisible = false;
  //       console.log('return data', o)
  //       this.branchList = o
  //     })
  // }

  getPosDispatchs(taxNo: string, recordType: string) {
    this.loadingMessage = "Yükleniyor..."
    this.loadingVisible = true;
    this.invoiceService.getPosDispatchs(taxNo, recordType).subscribe(
      (o: any) => {
        this.loadingVisible = false;
        console.log('return data', o)
        this.dispatchList = o
      })
  }

}
