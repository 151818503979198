import { branch, BranchService } from './../../loyalty/branches/branch.service';
import { UploadComponent } from '../../../../projects/cloud-components/src/lib/upload/upload.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { news, NewsService } from './services/news.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  loadIndicatorVisible = false;
  buttonText = "Kaydet";


  loginStatuses = [
    {id: "member", name: "Üye"},
    {id: "guest", name: "Misafir"},
    {id: "both", name: "Farketmez"},
  ]


  @ViewChild('newsImage') upload: UploadComponent

  type
  branches: branch[]
  newses: news[]
  popupVisible = false
  tempImage: string
  loadingVisible = false
  segments
  news: news = new news()

  buttonOptions: any = {
    text: "Register",
    type: "success",
    useSubmitBehavior: true
  }

  constructor(public ns: NewsService, private bs: BranchService) {
    this.click1 = this.click1.bind(this)
  }


  ngOnInit() {
    this.segments = [{ id: "1", name: "bronze" }, { id: "2", name: "silver" }, { id: "3", name: "gold" }, { id: "4", name: "platin" }]
    this.newses = this.ns.data
    this.branches = this.bs.data
  }

  ngAfterViewInit() {
    this.ns.load()
    this.bs.load()
  }


  //#region upload component

  startUpload(e) {
    this.tempImage = this.news.image
    this.news.image = "/assets/loading.gif"
  }

  setURL(u) {
    this.news.image = u
  }

  setError(e) {
    this.news.image = this.tempImage
  }

  setPercent(p) {
    console.log(p)
  }

  showUpload() {
    this.upload.showUpload()
  }

  //#endregion


  //#region new / edit /save

  new() {
    this.popupVisible = true
    this.news = new news()
  }

  select(item) {
    this.news = item
    this.popupVisible = true
  }

  async save(e) {
    this.loadIndicatorVisible = this.loadingVisible = true
    this.buttonText = "Kayıt Ediliyor"
    e.preventDefault();
    await this.ns.upsert(this.news)
    this.ns.load({},true);
    this.popupVisible = false
    this.loadIndicatorVisible = this.loadingVisible = false
    this.buttonText = "Kaydet"


  }

  click1(e) {
    this.news = e.row.data
    this.popupVisible = true
  }


  deleteRow(e) {
    this.ns.delete({id : e.data.id});
  }

  delete1(e) {
    //this.news = e.row.data
    //this.popupVisible = true

  
  }
  //#endregion

  filter(field, value) {
    this.newses = this.ns.data.filter(p => p.title.toLowerCase().indexOf(value) > -1).sort((a, b) => {
      var x = a.title.toLowerCase();
      var y = b.title.toLowerCase();
      if (x < y) { return -1; }
      if (x > y) { return 1; }
      return 0;
    })
  }


  setbase64(e) {
    //this.value = "data:" + this.type  +";base64," +  e
    this.news.image = "data:" + this.type  +";base64," +  e
  }

 

  fileInfo(e) {

    this.tempImage = this.news.image
    this.news.image = "/assets/loading.gif"

    this.type = e.type
    /*
    this.image_ok = true
    if (e.size > this.maxSize) {
      this.image_ok = false
      this.error_message = "file size is big.."
      this.error.emit(this.error_message)
    } else {
      this.error_message = ""
    } */

  }

}

