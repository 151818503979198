import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrintLayoutComponent } from 'projects/cloud-components/src/lib/printing/print-layout/print-layout.component';


const routes: Routes = [
  { path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      // { path: 'invoice/:invoiceIds', component: InvoiceComponent }
    ]
  }
];

@NgModule({
  exports: [RouterModule]
})
export class AppRoutingModule { }