<div style="overflow:scroll; height:100vh;">
    <div class="section-title">
        <dx-button icon="plus" (click)="new()"></dx-button>
        {{"Branch list" | translate}}
    </div>

    <div class="card">
        <dx-data-grid id="gridContainer" [dataSource]="branches" [showBorders]="true" style="margin:auto;"
        (onRowRemoved)="deleteRow($event)">
        <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"></dxo-editing>

        <dxi-column type="buttons" [width]="110">
            <dxi-button name="delete"></dxi-button>
            <dxi-button hint="Edit" icon="edit"  [onClick]="click1">
            </dxi-button>
        </dxi-column>



            <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-search-panel [visible]="true" [width]="240" placeholder="Ara..."></dxo-search-panel>
            
            <dxi-column dataField="name" [width]="240" caption="{{'Name' | translate}}"></dxi-column>
            <dxi-column dataField="country" [width]="140" caption="{{'Country' | translate}}"></dxi-column>
            <dxi-column dataField="city" [width]="140" caption="{{'City' | translate}}"></dxi-column>

        </dx-data-grid>
    </div>

    <div style="height: 200px;">

    </div>

</div>







<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#grid' }" [(visible)]="loadingVisible" [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>

<dx-popup class="popup" [showTitle]="true" title="Information" [fullScreen]="true" [dragEnabled]="false" [closeOnOutsideClick]="true" [(visible)]="popupVisible">

    <div *dxTemplate="let data of 'content'" style="max-width: 600px; margin: auto;">
        <dx-scroll-view>

            <div>
                <app-upload #branchImage (start)="startUpload($event)" (uploadError)="setError($event)" (url)="setURL($event)" (percent)="setPercent($event)" folder="product"></app-upload>
            </div>


            <div id="content">

                <form #f (submit)="onFormSubmit($event)">

                    <!--  
                        id: string
                        *name: string
                        *geopoint: firebase.firestore.GeoPoint
                        *phone: string
                        *address: string
                        *country: string
                        *city: string
                        *county: string
                        wifi: boolean
                        park: boolean
                        reservation: boolean  
                        image: string 
                    
                    -->

                    <div class="dx-fieldset" style="height: 80%;">


                        <div class="dx-field">
                            <div class="dx-field-label">{{'Name' | translate}}</div>
                            <div class="dx-field-value">
                                <dx-text-box [(value)]="branch.name" [disabled]="false" [readOnly]="false">
                                    <dx-validator>
                                        <dxi-validation-rule type="required" message="name"></dxi-validation-rule>
                                    </dx-validator>
                                </dx-text-box>
                            </div>
                        </div>


                        <div class="dx-fieldset-header">{{'Location' | translate}}</div>

                        <div class="dx-field">
                            <div class="dx-field-label">{{'Address' | translate}}</div>
                            <div class="dx-field-value">
                                <dx-text-area [height]="90" [maxLength]="500" [(value)]="branch.address" [readOnly]="false"></dx-text-area>
                            </div>
                        </div>

                        <div class="dx-field">
                            <div class="dx-field-label">{{'Country' | translate}}</div>
                            <div class="dx-field-value">
                               

                                <dx-text-box [(value)]="branch.country" [disabled]="false" [readOnly]="false">
                                </dx-text-box>

                            </div>
                        </div>

                        <div class="dx-field">
                            <div class="dx-field-label">{{'City' | translate}}</div>
                            <div class="dx-field-value">
                               

                                <dx-text-box [(value)]="branch.city" [disabled]="false" [readOnly]="false">
                                </dx-text-box>

                            </div>

                        </div>

                        <div class="dx-field">
                            <div class="dx-field-label">{{'Town' | translate}}</div>
                            <div class="dx-field-value">
                                <!-- <dx-select-box [dataSource]="countyies" displayExpr="name" valueExpr="id" placeholder="Choose County" [showClearButton]="true"
                                    [(value)]="branch.county"></dx-select-box> -->

                                <dx-text-box [(value)]="branch.county" [disabled]="false" [readOnly]="false">
                                </dx-text-box>
                            </div>
                        </div>



                        <div class="dx-field">
                            <div class="dx-field-label">{{'Phone' | translate}}</div>
                            <div class="dx-field-value">
                                <dx-text-box [(value)]="branch.phone" [disabled]="false" [readOnly]="false">
                                </dx-text-box>
                            </div>
                        </div>

                        <div class="dx-field" style="display: flex; flex-direction: row">
                            <div class="dx-field-label">{{'GeoPoint' | translate}}</div>
                            <div class="dx-field-value" style="display: flex; flex-direction: row">
                                <dx-text-box [(value)]="branch.geopoint.longitude" [disabled]="false" [readOnly]="false" placeholder="{{'Longitude' | translate}}">
                                </dx-text-box>
                                <dx-text-box [(value)]="branch.geopoint.latitude" [disabled]="false" [readOnly]="false" placeholder="{{'Latitude' | translate}}">
                                </dx-text-box>
                            </div>
                        </div>


                        <div class="dx-fieldset-header">{{'Properties' | translate}}</div>


                        <div class="dx-field" style="display: flex; flex-direction: row">
                            <div class="dx-field-label">{{'Properties' | translate}}</div>
                            <div class="dx-field-value">
                                <dx-check-box [(value)]="branch.wifi" text="wifi" style="margin-left:15px;">
                                </dx-check-box>

                                <dx-check-box [(value)]="branch.park" text="parking" style="margin-left:15px;">
                                </dx-check-box>

                                <dx-check-box [(value)]="branch.reservation" text="reserv" style="margin-left:15px;">
                                </dx-check-box>

                            </div>
                        </div>


                        <div class="dx-fieldset-header">{{'Image' | translate}}</div>

                        <div class="dx-field">
                            <div class="dx-field-label">{{'Image' | translate}}</div>
                            <div class="dx-field-value">
                                <img class="img-160-95" src="{{branch.image}}" />
                                <dx-button text="Upload" type="success" (click)="showUpload()">
                                </dx-button>
                            </div>
                        </div>


                        <!-- Edit Panel Footer -->
                        <div class="edit-panel-footer">
                            <div style="display: flex; flex-direction: row; justify-content: space-evenly;">
                                <dx-button text="İptal" (click)="popupVisible = false;" type="cancel">
                                </dx-button>
                                <dx-button icon="check" text="Kaydet" type="success" useSubmitBehavior="true">
                                    <div *dxTemplate="let data of 'content'">
                                        <dx-load-indicator class='button-indicator' [visible]="loadingVisible">
                                        </dx-load-indicator>
                                        <span class='dx-button-text'>{{'Save' | translate}}</span>
                                    </div>
                                </dx-button>
                            </div>
                        </div>
                    </div>





                    <!-- Edit Panel Footer -->
           <!--          <div class="edit-panel-footer">
                        <div style="display: flex; flex-direction: row; justify-content: space-evenly;">
                            <dx-button text="İptal" (click)="popupVisible = false;" type="cancel">
                            </dx-button>
                            <dx-button icon="check" text="Kaydet" type="success" useSubmitBehavior="true">
                                <div *dxTemplate="let data of 'content'">
                                    <dx-load-indicator class='button-indicator' [visible]="loadingVisible">
                                    </dx-load-indicator>
                                    <span class='dx-button-text'>{{'Save' | translate}}</span>
                                </div>
                            </dx-button>
                        </div>
                    </div> -->

                          <!-- Edit Panel Footer -->
                          <div class="edit-panel-footer">
                            <div style="display: flex; flex-direction: row; justify-content: space-evenly;">
                                <dx-button [height]="40" [width]="180" text="{{'Cancel' | translate}}"
                                    (click)="popupVisible = false;" type="cancel">
                                </dx-button>
                                <dx-button icon="check" text="{{'Save' | translate}}" type="success" [height]="40"
                                    [width]="180" useSubmitBehavior="true">
                                    <div *dxTemplate="let data of 'content'">
                                        <dx-load-indicator class='button-indicator' [visible]="loadingVisible">
                                        </dx-load-indicator>
                                        <span class='dx-button-text'>{{'Save' | translate}}</span>
                                    </div>
                                </dx-button>
                            </div>
                        </div>



                </form>
            </div>
        </dx-scroll-view>
    </div>
</dx-popup>