import { Injectable } from '@angular/core';
import { GenericDataService } from 'projects/cloud-components/src/lib/database/generic-data.service';
import { CloudDbService } from 'cloud-labs-core';
import { GlobalsService } from '../globals.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService extends GenericDataService {
  filteredData = []
  db = "kerzz-loyalty"
  collection = ""
  

  constructor(public cs: CloudDbService, private glb: GlobalsService ) {


    super(cs)
    this.collection = this.cs.activeDb + '-stampTransactions'


  }

  

   loadByParam(_userId: String): Promise<any> {
    return new Promise((resolve, reject) => {
      //userId: ObjectId('5df9ec24c67a580394577a85')
      this.filteredData = []
      this.collection = this.glb.activeLicance + '-stampTransactions'
      this.cs.getCollection({ _db: this.db, col: this.collection, _filter: {userId: _userId}  }).subscribe(o => {
        this.filteredData = o
        resolve(this.filteredData)
      }, e => {
        reject(e)
      })
    })
  } 

}
