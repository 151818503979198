import { Injectable } from '@angular/core';
import { BehaviorSubject, AsyncSubject } from 'rxjs';
import { ModalService } from '../modal/modal.service';

@Injectable({
  providedIn: 'root'
})
export class ClModalDialogService {

  subject = new AsyncSubject<boolean>()
  subjectPrompt = new AsyncSubject<any>()

  prompts 

  title: string = "İptal Edilecek, Onaylıyor musunuz?"
  message: string = "357982374 nolu fatura iptal edilecek, onaylıyor musunuz?"
  okButton: string = "Tamam"
  cancelButton: string = "İptal"

  constructor(private ms: ModalService) { }

  open() {
    this.prompts = undefined
    this.subject = new AsyncSubject<boolean>()
    this.ms.open("cl-modal-dialog")
  }

  close() {
    this.ms.close("cl-modal-dialog")
  }

  ok(isValid) {
    if (isValid) {
      this.ms.close("cl-modal-dialog")
    }

  }

  openWithPompt(prompts: clPrompt[]) {
    this.subjectPrompt = new AsyncSubject<any>()
    this.prompts = prompts
    this.ms.open("cl-modal-dialog")
  }

  stat(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.subject.subscribe(o => {
        if (o !== null) {
          resolve(o)
        }
      })
    })
  }


  statPrompt(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.subjectPrompt.subscribe(o => {
        if (o !== null) {
          resolve(o)
        }
      })
    })
  }

  confirm(stat) {
    this.subject.next(stat)
    this.subject.complete()
  }

  confirmPromt(stat,prompts) {
    
    this.subjectPrompt.next({stat: stat, prompts: prompts})
    this.subjectPrompt.complete()
  }
}

export class clPrompt {
  title: string
  value: any
  required? = false
}