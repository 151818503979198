import { Component, OnInit } from '@angular/core';
import { InvoiceAccountType } from '../services/invoice.service';
import DataSource from 'devextreme/data/data_source';
import { PublicApiService } from 'src/app/services/public-service/public-api.service';
import { CloudLoginService } from 'cloud-labs-core';
import { GlobalsService } from 'src/app/services/globals.service';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'app-invoice-account-types',
  templateUrl: './invoice-account-types.component.html',
  styleUrls: ['./invoice-account-types.component.scss']
})
export class InvoiceAccountTypesComponent implements OnInit {
  accountInvoiceList: InvoiceAccountType[] = []
  loadingMessage: string = "Yükleniyor..."
  loadingVisible: boolean = false;
  accountType : any
  accountList: any[] = [];
  dxAccount: DataSource;
  typeList : any
  taxNo: string = "";
  accountName: string = "";
  constructor(private publicApi: PublicApiService, private ls: CloudLoginService, private glb: GlobalsService) {
    this.typeList = [{id : 0,name : 'Stok'},{id: 1,name: 'Hizmet'}]
    this.dxAccount = new DataSource({
      store: new CustomStore({
        key: "taxNo",
        load: (loadOptions) => {
          // return this.getAccountList(
          //   this.branchCode,
          //   loadOptions.searchValue ?? this.accountCode
          return this.getAccountList( loadOptions.searchValue);
          // );
          return []
        },
        byKey: (key) => {
          console.log(key);
          return this.getAccountList(key);
        },
      }),
    });
  }

  ngOnInit(): void {
    this.getData()
  }

  accountTypeChanged(e)
  {
    console.log(e)
    this.accountType = e.selectedItem.id;
  }

  getData() {
    this.loadingVisible = true
    this.publicApi.getCollection({
      _db: this.ls.getActiveLicance().licanceId,
      col: 'invoice-account-type',
      _filter: {}
    })
      .toPromise()
      .then(async (o: any) => {
        console.log(o)
        this.loadingVisible = false;
        this.accountInvoiceList = o
      })
  }

  removeQuotesUsingReplace(str: string): string {
    return str.replace(/['"]+/g, '');
}

getAccountList( searchKey = "") {
  if(!searchKey) {
    return
  }
  if (searchKey === "")
  {
    return []
  }

  if (searchKey.length < 10)
  {
    return []
  }
  console.log('asda')
  // _filter: { $or: [{'accountName': {'$regex': searchKey}},{'taxNo': {'$regex': searchKey}}] }
  this.loadingMessage = "Yükleniyor..."
  this.loadingVisible = true
  let filterText  = '/' + searchKey+ '/'
  console.log(this.removeQuotesUsingReplace(filterText))
  return new Promise((resolve, reject) => {
    this.publicApi.getCollection({
      _db: 'helpers',
      col: 'accounts',
      _filter:  {'taxNo': {'$regex': searchKey} }
    }).subscribe(x => {
      this.loadingVisible = false
      this.accountList = x;
 resolve(x);
    })

  });
}


onRowUpdated(e) {
  this.publicApi.upsertv2({
    _db: this.ls.getActiveLicance().licanceId,
    col: 'invoice-account-type',
    data: e.data,
  }).then((o) => {
    this.getData()
    this.glb.notice("Kayıt Güncellendi.", "info")
  })
}

accountChanged(e) {
  this.accountName = e.selectedItem?.accountName
  this.taxNo = e.selectedItem?.taxNo;
}

add() {
  if (!this.taxNo) {
    this.glb.notice("Lütfen Cari Seçiniz", 'info')
    return;
  }
  let accountType: InvoiceAccountType = new InvoiceAccountType()
  accountType.taxNumber = this.taxNo
  accountType.name = this.accountName
  accountType.type = this.accountType
  console.log('accountTpye',accountType)
  // delete accountType.type
  this.publicApi.upsertv2({
    _db: this.ls.getActiveLicance().licanceId,
    col: 'invoice-account-type',
    data: accountType,
  }).then((o) => {
     this.getData()
    this.glb.notice("Kayıt Edildi.", "info")
  })
}

}
