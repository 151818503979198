import { CloudFolioService } from './../../../../../projects/cloud-folio/src/lib/cloud-folio.service';
import { product } from './../../../product/products/service/product.service';
import { ModifiersService } from '../../../product/modifiers/service/modifiers.service';
import { modifiers } from "../../../product/modifiers/service/modifiers.service";
import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Input } from "@angular/core";
import { ProductService } from "../../../product/products/service/product.service";
import { trigger, style, animate, transition, state, query, stagger, keyframes } from "@angular/animations";
import { ChangeDetectorRef } from "@angular/core";
import { ProductGroupService } from "../../../product/product-groups/service/product-group.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { Renderer2 } from "@angular/core";
import notify from 'devextreme/ui/notify';
import { GlobalsService } from '../../../services/globals.service';
import { TranslateService } from '@ngx-translate/core';
import { IdGeneratorService } from 'cloud-labs-core';
import { folioRow } from 'projects/cloud-folio/src/lib/models/folio';

@Component({
  selector: "app-select-must-group",
  templateUrl: "./select-must-group.component.html",
  styleUrls: ["./select-must-group.component.css"],
  animations: [
    trigger("listAnimation", [
      transition("* <=> *", [
        query(":enter", style({ opacity: 0 }), { optional: true }),

        query(
          ":enter",
          stagger("100ms", [
            animate(
              "500ms ease-in",
              keyframes([
                style({ opacity: 0, transform: "translateY(-75%)", offset: 0 }),
                style({
                  opacity: 0.5,
                  transform: "translateY(35px)",
                  offset: 0.3
                }),
                style({ opacity: 1, transform: "translateY(0)", offset: 1.0 })
              ])
            )
          ]),
          { optional: true }
        ),
        query(
          ":leave",
          stagger("100ms", [
            animate(
              "200ms ease-in",
              keyframes([
                style({ opacity: 1, transform: "translateY(0)", offset: 0 }),
                style({
                  opacity: 0.5,
                  transform: "translateY(50px)",
                  offset: 0.3
                }),
                style({
                  opacity: 0,
                  transform: "translateY(100%)",
                  offset: 1.0
                })
              ])
            )
          ]),
          { optional: true }
        )
      ])
    ]),
    trigger("enterAnimation", [
      transition(":enter", [
        style({ transform: "translateX(100%)", opacity: 0 }),
        animate("200ms", style({ transform: "translateX(0)", opacity: 1 }))
      ]),
      transition(":leave", [
        style({ transform: "translateX(0)", opacity: 1 }),
        animate("200ms", style({ transform: "translateX(100%)", opacity: 0 }))
      ])
    ]),
    trigger("simpleFadeAnimation", [
      transition(":enter", [
        // the "in" style determines the "resting" state of the element when it is visible.
        state("in", style({ opacity: 1 })),

        // fade in when created. this could also be written as transition('void => *')
        transition(":enter", [style({ opacity: 0 }), animate(600)]),

        // fade out when destroyed. this could also be written as transition('void => *')
        transition(":leave", animate(600, style({ opacity: 0 })))
      ])
    ]),
    trigger("toggle", [
      state("loaded", style({ opacity: 1, color: "black" })),
      state("loading", style({ opacity: 0, color: "blue" })),
      transition(":enter", animate("500ms ease-in-out")),
      transition(":leave", animate("500ms ease-in-out"))
    ]) /* ,
    trigger('products', [
      state('true',style({transform: 'translateY(-3%)',opacity: 0,position: 'static'}),
      animate('0.5s ease-in-out',style({ transform: 'translateY(0%)', opacity: 1 }))),
      state('void',style({ transform: 'translateY(0%)', opacity: 1 }),
      animate('0.2s ease-in-out',style({ transform: 'translateY(-3%)', opacity: 0 })),
      style({ position: 'fixed' })
    ) */
    //])
  ]
})
export class SelectMustGroupComponent implements OnInit {
  load = "loading";

  subParentID: string;

  nosubs: boolean = true;

  @Input() deep: string;
  @Input() parentID: string;
  @Input() group: modifiers;
  @Input() row: folioRow;
  @Input() isSub: boolean = false;
  @Input() changeModifiers: folioRow[];
  @Output() selected = new EventEmitter();
  @Output() cancel = new EventEmitter();
  subGroups;
  products;
  filteredProducts: product[] = []
  activeModifierGroupIndex;
  activeQty = 1;

  selectMustGroup: modifiers = undefined;

  timer: any = 0;
  delay = 250;
  preventSingleClick = false;

  qty: Array<{ id: number; status: boolean }> = [];

  constructor(
    private ps: ProductService,
    private pg: ProductGroupService,
    private afs: AngularFirestore,
    private renderer: Renderer2,
    private fls: CloudFolioService,
    private cd: ChangeDetectorRef,
    private glb: GlobalsService,
    private ms: ModifiersService,
    private translate: TranslateService,
    private idg: IdGeneratorService,
  ) { }

  ngAfterViewInit() {
    this.cd.detectChanges();
    this.getFolio();
  }

  ngOnInit() {
    let index = -1;
    this.group.modif.forEach(element => {
      index++;
      element["remain"] = element.maxSelect;
      element["index"] = index;
      element.filteredProducts = this.filteredProducts;
    });
    this.products = this.ps.data
    this.initModifiers(this.group.modif[0])
    this.loadAll()

    let mainFolio = this.fls.folio.rows.find(p => p.id == this.parentID);

    for (var i = 1; i <= mainFolio.qty; i++) {
      this.qty.push({ id: i, status: false });
    }
  }

  changeQty(qty) {
    this.activeQty = qty;
    this.flipIt();
  }

  allModifiers: boolean = true;
  allGroups: Array<{ name: string; data: product[] }> = [];

  loadAll() {
    this.fls.folioButtonsEnabled = false
    this.fls.footerButtonsEnabled = false
    this.group.modif.forEach(element => {
      let p = {
        name: element.group,
        maxSelect: element.maxSelect,
        index: element.index,
        data: this.initModifiers(element)
      };
      this.allGroups.push(p);
    });
    this.loadSelectedModifiers();
  }

  //#region item clicks

  itemDblClick(item) {
    this.preventSingleClick = true;

    let xp: folioRow;
    xp = this.fls.folio.rows.find(
      row =>
        row.parentID == this.parentID &&
        row.productID == item.id &&
        row.qtyID == this.activeQty
    );
    xp.qty = xp.qty - 1;
    if (xp.qty == 0) {
      this.fls.removeRow(xp.id);
    }
  }

  async itemClick(productItem: product) {
    this.glb.clickSound()
    this.preventSingleClick = false;
    this.timer = setTimeout(async () => {
      if (!this.preventSingleClick) {
        let modifier = this.group.modif[productItem.modifierIndex];
        if (
          modifier.must &&
          modifier.maxSelect != 0 &&
          this.getTotalCount(modifier.index) == modifier.maxSelect
        ) {
          productItem["forbidden"] = true;
          setTimeout(() => {
            productItem["forbidden"] = false;
          }, 1000);
          return;
        }

        //find row
        let modifierFolioRow: folioRow = this.fls.findModifierOnFolio(this.parentID,productItem.id, this.activeQty)

        //is new?
        if (modifierFolioRow == undefined) {
          modifierFolioRow = new folioRow();
          modifierFolioRow.qty = 1;
          this.fls.pushRow(modifierFolioRow);
          modifierFolioRow.id = this.idg.generateMaxi()
        } else {
          modifierFolioRow.qty = modifierFolioRow.qty + 1;
        }

        modifierFolioRow.isModifier = true;
        modifierFolioRow.modifierID = modifier.id;
        modifierFolioRow.isMustModifier = modifier.must;
        modifierFolioRow.name = productItem.name;
        modifierFolioRow.productID = productItem.id;
        modifierFolioRow.unitPrice = productItem.price;
        modifierFolioRow.parentID = this.parentID;
        modifierFolioRow.qtyID = this.activeQty;
        modifierFolioRow.modifierGroup = modifier.group;
        this.subParentID = modifierFolioRow.id;
        //this.loadMustModifiers("sub", productItem.id);
        this.selectMustGroup = await this.ms.loadMustModifiers(productItem.group, productItem.id)
        if (this.selectMustGroup.modif.length > 0) {
          this.cover()
        } else {
          this.selectMustGroup = undefined
        }
        productItem.selectCount += 1;

        setTimeout(() => {
          this.fls.scrollTo(modifierFolioRow.id);
          this.fls.saveFolio();
        }, 100);

        setTimeout(() => {
          if (modifier.maxSelect > 0) {
            modifier.remain -= 1;
            if (modifier.remain == 0) {
              if (this.group.modif.length > modifier.index) {
                this.initModifiers(this.group.modif[modifier.index + 1]);
              }
            }
          }
        }, 500);
      }
    }, 250);
  }

  //#endregion

  //#region remain functions

  getProductCountOnFolio(productItem: product) {
    let findedModifiersInFolio = this.fls.folio.rows.filter(
      p =>
        p.parentID == this.parentID &&
        p.productID == productItem.id &&
        p.qtyID == this.activeQty &&
        p.recordStatus != 'deleted'
    );

    let count = findedModifiersInFolio.reduce((sum, item) => sum + item.qty, 0);

    if (count < 0)
      count = 0

    return count;
  }

  getTotalCount(modifierGroupIndex) {
    let findedModifiersInFolio = this.fls.folio.rows.filter(
      p =>
        p.parentID == this.parentID &&
        p.modifierID == this.group.modif[modifierGroupIndex].id &&
        p.qtyID == this.activeQty &&
        p.recordStatus != 'deleted'
    );
    let count = findedModifiersInFolio.reduce((sum, item) => sum + item.qty, 0);

    if (count < 0)
      count = 0

    return count;

  }

  getGroupSelectedCount(modifierGroupIndex) {
    return this.getTotalCount(modifierGroupIndex)
  }

  getRemainCount(modifierGroupIndex) {

    let findedModifierGroup = this.fls.folio.rows.filter(
      p =>
        p.parentID == this.parentID &&
        p.modifierID == this.group.modif[modifierGroupIndex].id &&
        p.qtyID == this.activeQty
    );
    let count: number =
      this.group.modif[modifierGroupIndex].maxSelect -
      findedModifierGroup.reduce((sum, item) => sum + item.qty, 0);

    if (count < 0)
      count = 0

    return count;
  }


  getTotalRemainCount() {
    let total: number = 0;
    let j: number = 0;
    this.group.modif.forEach(f => {
      total = total + this.getRemainCount(j);
      j++;
    });
    return total;
  }

  //#endregion

  //#region load data


  //! Push Group Modifiers
  pushGroupModifiers(grp) {
    this.activeModifierGroupIndex = grp.index;
    if (grp.filteredProducts.length !== 0) {
      this.filteredProducts = grp.filteredProducts;
    } else {
      // Get modifiers product group
      this.filteredProducts = this.products.filter(p => p.group == grp.productGroup);
      this.filteredProducts.map(o => {
        if (grp.priceless) {
          o.dlPrice = 0; o.price = 0; o.slfPrice = 0
        }
        o.modifierType = "group"
        o.selectCount = 0
        o.modifierIndex = grp.index
      })
      grp["filteredProducts"] = this.filteredProducts;
      this.subGroups = this.pg.getSubGroups(grp.productGroup)
    }
    return this.filteredProducts;
  }

  //! Push Product Modifiers
  pushProductModifiers(grp) {
    this.activeModifierGroupIndex = grp.index;
    if (grp.filteredProducts.length !== 0) {
      this.filteredProducts = grp.filteredProducts;
    } else {
      let yFilter = grp.products.map(itemY => {
        return itemY.productID;
      });
      this.filteredProducts = this.products.filter(p => yFilter.includes(p.id));
      this.filteredProducts.forEach(element => {
        element.price = grp.products.find(p => p.productID == element.id).price | 0;
        element["modifierType"] = "product";
        element["selectCount"] = 0;
        element["modifierIndex"] = grp.index;
      });
    }
    grp["filteredProducts"] = this.filteredProducts;
    return this.filteredProducts;
  }

  //! Push Custom Modifiers
  pushCustomModifiers(grp) {

    this.filteredProducts = [];

    grp.products.forEach(element => {
      let el: product = new product()
      el.id = element.productName
      el.group = element.group
      el.name = element.productName
      el.price = element.price | 0
      el.selectCount = 0
      el.modifierType = "custom"
      el.dlPrice = element.dlPrice
      el.slfPrice = element.slfPrice | 0
      el.modifierIndex = grp.index
      el.vat = element.vat
      el.image = element.image
      el.printer = element.printer
      el.winStamp = 0
      el.spendPoints = 0
      this.filteredProducts.push(el);
    });
    grp["filteredProducts"] = this.filteredProducts;
    return this.filteredProducts;
  }

  initModifiers(grp) {
    if (grp.type == "group") {
      return this.pushGroupModifiers(grp);
    } else if (grp.type == "product") {
      return this.pushProductModifiers(grp);
    } else if (grp.type == "custom") {
      return this.pushCustomModifiers(grp);
    }
  }

  loadSelectedModifiers() {
    if (this.changeModifiers != undefined) {
      this.changeModifiers.forEach(el => {
        if (el.itemImage != "zone") {
          let ola = this.group.modif.find(p => p.id == el.modifierID);
          let olaFiltered = ola.filteredProducts.find(
            o => o.id == el.productID
          );
          if (olaFiltered != undefined) {
            ola.remain = ola.remain - el.qty;
            olaFiltered.selectCount = el.qty;
            el.itemImage = "zone";
          }
        }
      });
      this.changeModifiers = [];
    }
  }

  //#endregion

  //#region DOM functions

  okeyDisabled() {
    let minok: boolean = false;
    let minRequired: boolean = false;
    this.group.modif.forEach(element => {
      if (!minRequired) {
        minRequired = element.minSelect > 0;
      }
    });
    if (!minRequired) return false;

    if (minRequired) {
      this.group.modif.forEach(element => {
        if (element.minSelect != 0) {
          if (element.filteredProducts == undefined && element.minSelect != 0) {
            minok = false;
          } else {
            let selectTotal = element.filteredProducts.reduce(
              (sum, item) => sum + item.selectCount,
              0
            );
            if (!minok) {
              minok = selectTotal >= element.minSelect;
              if (!minok) return true;
            }
          }
        }
      });
    }

    if (this.getTotalRemainCount() == 0 && this.nosubs) {
      this.qty.find(i => i.id == this.activeQty).status = true

    } else {
      this.qty.find(i => i.id == this.activeQty).status = false

    }


    return ((this.qty.find(i => i.status == false) != undefined))
  }

  //#endregion

  //! Okey Button HTML
  operationComplated() {
    if (this.okeyDisabled()) {
      let toastSettings = {
        message: this.translate.instant("please finish modifiers selection."),
        type: "info",
        displayTime: 2000,
        position: {
          at: 'center',
        }
      }
      notify(toastSettings)
      return
    }
    let rows: folioRow[] = [];
    this.group.modif.forEach(element => {
      if (element.filteredProducts == undefined && element.must) {
        console.error("Missing group selection");
      }
      if (element.filteredProducts != undefined) {
        let ps = element.filteredProducts.filter(p => p.selectCount > 0);
        ps.forEach(el => {
          let xp: folioRow = new folioRow();
          xp.id = this.idg.generateMaxi()
          xp.isModifier = true;
          xp.modifierID = element.id;
          xp.isMustModifier = element.must;
          xp.name = el.name;
          xp.productID = el.id;
          xp.qty = el.selectCount;
          xp.unitPrice = el.price;
        });
      }
    });
    this.selected.emit(rows);
    this.operationButtons(true)
  }

  //! Cancel Button HTML
  operationCancelled() {
    this.fls.removeRow(this.parentID);
    this.cancel.emit();
    this.operationButtons(true)
  }


  operationButtons(status: boolean) {
    if (!this.isSub && status) {
      this.fls.footerButtonsEnabled = status
      this.fls.folioButtonsEnabled = status
    }

    if (!status) {
      this.fls.footerButtonsEnabled = status
      this.fls.folioButtonsEnabled = status
    }

  }

  //#region Sub Component Functions
  cancelModifier() {
    this.selectMustGroup = undefined;
    this.closeCover();
  }

  modifierGet(e: folioRow[]) {
    this.selectMustGroup = undefined;
    this.closeCover();
  }

  coverDiv: HTMLElement;
  masterDiv: HTMLElement;

  cover() {
    this.masterDiv = document.getElementById("master-div") as HTMLElement;
    this.renderer.setStyle(this.masterDiv, "height", "1px");
  }

  closeCover() {
    this.renderer.removeStyle(this.masterDiv, "height");
    if (this.coverDiv == undefined) {
      return;
    }
  }

  other: HTMLElement;
  folio: HTMLElement;
  okey: HTMLElement;
  cancelbutton: HTMLElement;
  getFolio() {
    /*  this.other = document.getElementById('master-div') as HTMLElement;
     this.okey = document.getElementById('okey-button') as HTMLElement;
     this.cancelbutton = document.getElementById('cancel-button') as HTMLElement;
     this.folio = document.getElementById('folio-div') as HTMLElement;
 
     console.log("folio:")
     console.log(this.folio.offsetLeft)
     console.log(this.folio.offsetWidth)
     console.log(this.folio.offsetHeight)
 
     console.log("master:")
     console.log(this.other.offsetLeft)
     console.log(this.other.offsetWidth)
     console.log(this.other.offsetHeight)
 
     this.renderer.setStyle(this.okey, 'left', ((this.other.offsetLeft + this.other.offsetWidth) - (60 * this.deep.length)) + "px")
     this.renderer.setStyle(this.okey, 'top', "calc(100vh - 250px )")
 
     //this.renderer.setStyle(this.okey, 'left', "100px") 
     //this.renderer.setStyle(this.okey, 'top', "100px")
     this.renderer.setStyle(this.cancelbutton, 'left', ((this.other.offsetLeft + this.other.offsetWidth) - 60) + "px")
     this.renderer.setStyle(this.cancelbutton, 'top', "calc(100vh - 190px )")
 
     setTimeout(() => {
       this.renderer.setStyle(this.okey, 'display', 'block')
       this.renderer.setStyle(this.cancelbutton, 'display', 'block')
 
       this.renderer.addClass(this.okey, "animated")
       this.renderer.addClass(this.okey, "bounceInRight")
 
       this.renderer.addClass(this.cancelbutton, "animated")
       this.renderer.addClass(this.cancelbutton, "bounceInLeft")
 
     }, 800) */
  }

  pGroupDiv: HTMLElement;
  pProductDiv: HTMLElement;

  flipIt() {
    this.pGroupDiv = document.getElementById("p-groups") as HTMLElement;
    this.pProductDiv = document.getElementById("p-products") as HTMLElement;

    this.renderer.addClass(this.pProductDiv, "animated");
    this.renderer.addClass(this.pProductDiv, "pulse");

    if (!this.allModifiers) {
      this.renderer.addClass(this.pGroupDiv, "animated");
      this.renderer.addClass(this.pGroupDiv, "pulse");
    }

    setTimeout(() => {
      //this.renderer.setStyle(this.coverDiv, 'display', "none")
      if (!this.allModifiers) {
        this.renderer.removeClass(this.pGroupDiv, "animated");
        this.renderer.removeClass(this.pGroupDiv, "pulse");
      }

      this.renderer.removeClass(this.pProductDiv, "animated");
      this.renderer.removeClass(this.pProductDiv, "pulse");
    }, 1000);
  }

  //#endregion



  getRemain(p) {
    return 0;
  }
}
