<div *ngIf="type=='must'" style="display: flex; flex-direction: row; justify-content: flex-start; padding-top:5px; font-size:0.8em; height:40px;  padding-bottom: 5px;">
    <div style="display: flex; flex-direction: column; width: 80px; align-items: center;  font-size:0.8em">
        {{'Free' | translate}}
        <dx-check-box [(value)]="this.item.priceless" (onValueChanged)="change()"></dx-check-box>
    </div>
    <div *ngIf="!this.item.auto" style="display: flex; flex-direction: column; width: 80px; align-items: center;  font-size:0.8em"
        [@enterAnimation]>
        {{'Must' | translate}}
        <dx-check-box [(value)]="this.item.must" (onValueChanged)="mustChange($event)"></dx-check-box>
    </div>
    <div *ngIf="!this.item.must" style="display: flex; flex-direction: column; width: 80px; align-items: center;  font-size:0.8em"
        [@enterAnimation]>
        {{'Auto' | translate}}
        <dx-check-box [(value)]="this.item.auto" (onValueChanged)="change()"></dx-check-box>
    </div>
    <div *ngIf="this.item.must" style="display: flex; flex-direction: row;  font-size:0.8em" [@enterAnimation]>
        <div style="display: flex; flex-direction: column; width: 80px;">
            {{'Min Selection' | translate}}
            <dx-number-box placeholder="Min" [(value)]="this.item.minSelect" (onValueChanged)="change()"></dx-number-box>
        </div>
        <div style="display: flex; flex-direction: column; width: 80px;">
            {{'Max Selection' | translate}}
            <dx-number-box placeholder="Max" [(value)]="this.item.maxSelect" (onValueChanged)="change()"></dx-number-box>
        </div>

    </div>
    <div *ngIf="this.item.must == false" style="display: flex; flex-direction: row;  font-size:0.8em" [@enterAnimation]>
        <div style="display: flex; flex-direction: column; width: 80px; align-items: center;">
            {{'Detachable' | translate}}
            <dx-check-box [disabled]="this.item.must" [(value)]="this.item.detachable" (onValueChanged)="change()"></dx-check-box>
        </div>
        <div style="display: flex; flex-direction: column; width: 80px; align-items: center;">
            {{'Can be added' | translate}}
            <dx-check-box [disabled]="this.item.must" [(value)]="this.item.canBeAdded" (onValueChanged)="change()"></dx-check-box>
        </div>
    </div>
</div>

<div *ngIf="type == 'group'" style="display: flex; flex-direction: row; justify-content: flex-start; 
align-items: center; 
padding-top:5px; font-size:0.8em; height:40px; padding-bottom: 5px;">
    <div style="display: flex; flex-direction: column; width: 80px; align-items: center;" [@enterAnimation]>
        {{'Free' | translate}}
        <dx-check-box [(value)]="this.item.priceless" (onValueChanged)="change()"></dx-check-box>
    </div>
    <div style="display: flex; flex-direction: column; width: 80px; align-items: center;" [@enterAnimation]>
        {{'Detachable' | translate}}
        <dx-check-box [disabled]="this.item.must" [(value)]="this.item.detachable" (onValueChanged)="change()"></dx-check-box>
    </div>
    <div style="display: flex; flex-direction: column; width: 80px; align-items: center;" [@enterAnimation]>
        {{'Can be added' | translate}}
        <dx-check-box [disabled]="this.item.must" [(value)]="this.item.canBeAdded" (onValueChanged)="change()"></dx-check-box>
    </div>
</div>

<div *ngIf="type == 'product' && showPrice" style="display: flex; flex-direction: column; justify-content: flex-start; 
align-items: center; 
padding-left:5px; font-size:0.8em;  padding-right: 5px;">
    <div style="display: flex; flex-direction: row;  font-size:0.8em; line-height:30px; text-align: center; align-items:center"
        [@enterAnimation]>
        <div class="product-mod-price">
            {{'Price' | translate}}&nbsp;:&nbsp;&nbsp;
            <dx-number-box style="width: 70px;" placeholder=" {{'Price' | translate}}" [(value)]="this.item.price" (onValueChanged)="change()"
                format="₺#,##0.##"></dx-number-box>
        </div>
    </div>
</div>