import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.css']
})
export class InfoBoxComponent implements OnInit {

  close: boolean

  @Input() message: string
  @Input() title: string
  @Input() closable: boolean
  @Input() type: string
  constructor() { }

  ngOnInit() {

  }

  closeClick() {
this.close = true;
  }
}
