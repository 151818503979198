<div style="overflow:scroll; height:100vh;">
    <div class="section-title">
        <dx-button icon="plus" (click)="new()"></dx-button>
        {{"News & Events" | translate}}
    </div>

    <div class="card">
        <dx-data-grid id="gridContainer" [dataSource]="this.ns.data" [showBorders]="true" style="margin: auto;"
           
            (onRowRemoved)="deleteRow($event)">
            <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"></dxo-editing>

            <dxi-column type="buttons" [width]="110">
                <dxi-button name="delete"></dxi-button>
                <dxi-button hint="Edit" icon="edit"  [onClick]="click1">
                </dxi-button>
            </dxi-column>

            <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-search-panel [visible]="true" [width]="240" placeholder="Ara..."></dxo-search-panel>

            <dxi-column dataField="order" [width]="100" caption="Sıra"></dxi-column>
            <dxi-column dataField="loginStatus" [width]="100" caption="Login?"></dxi-column>
            <dxi-column dataField="title" [width]="340" caption="Title"></dxi-column>
            <dxi-column dataField="creation" alignment="right" dataType="datetime" format="dd/MM/yyyy" [width]="140">
            </dxi-column>
            <dxi-column dataField="deadline" alignment="right" dataType="datetime" format="dd/MM/yyyy" [width]="140">
            </dxi-column>
        </dx-data-grid>
    </div>

    <div style="height: 200px;">

    </div>
</div>




<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingVisible" [showIndicator]="true"
    [showPane]="true" [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>


<dx-popup class="popup" [fullScreen]="true" [showTitle]="true" title="Information" [dragEnabled]="false"
    [closeOnOutsideClick]="true" [(visible)]="popupVisible">

    <div *dxTemplate="let data of 'content'" style="max-width: 600px; margin: auto;">


        <dx-scroll-view>
            <div>
               <!--  <app-upload #newsImage (start)="startUpload($event)" (uploadError)="setError($event)"
                    (url)="setURL($event)" (percent)="setPercent($event)" folder="product"></app-upload> -->


                    <app-upload #newsImage (start)="startUpload($event)" (fileInfo)="fileInfo($event)" (base64)="setbase64($event)" [saveFirebase]="false">
                    </app-upload>
            </div>

            <form (submit)="save($event)">

          

                <div class="dx-fieldset" style="height: 80%;">


                    <div class="dx-field">
                        <div class="dx-field-label">{{'Sıra' | translate}}</div>
                        <div class="dx-field-value">
                            <dx-number-box [(value)]="news.order" [readOnly]="false">
                            </dx-number-box>
                        </div>
                    </div>
    
                    <div class="dx-field">
                        <div class="dx-field-label">{{'Login?' | translate}}</div>
                        <div class="dx-field-value">
                            <dx-select-box [searchEnabled]="true" [(value)]="news.loginStatus" [dataSource]="loginStatuses" valueExpr="id" displayExpr="name">
                            </dx-select-box>
                        </div>
                    </div>

                    <div class="dx-field">
                        <div class="dx-field-label">{{'Title' | translate}}</div>
                        <div class="dx-field-value">
                            <dx-text-box [(value)]="news.title" [disabled]="false" [readOnly]="false">
                                <dx-validator>
                                    <dxi-validation-rule type="required" message="Password is required">
                                    </dxi-validation-rule>
                                </dx-validator>
                            </dx-text-box>
                        </div>
                    </div>

                    <div class="dx-field">
                        <div class="dx-field-label">{{'Subtitle' | translate}}</div>
                        <div class="dx-field-value">
                            <dx-text-area [height]="90" [maxLength]="1500" [(value)]="news.subtitle" [readOnly]="false">
                            </dx-text-area>
                        </div>
                    </div>

                    <div class="dx-field">
                        <div class="dx-field-label">{{'Message' | translate}}</div>
                        <div class="dx-field-value">
                            <dx-text-area [height]="90" [maxLength]="1500" [(value)]="news.message" [readOnly]="false">
                            </dx-text-area>
                        </div>
                    </div>

                    <div class="dx-field">
                        <div class="dx-field-label">{{'Image' | translate}}</div>
                        <div class="dx-field-value">
                            <img class="img-160-95" src="{{news.image}}" />
                            <dx-button text="Upload" type="success" (click)="showUpload()">
                            </dx-button>
                        </div>
                    </div>

                    <div class="dx-field">
                        <div class="dx-field-label">{{'Segments' | translate}}</div>
                        <div class="dx-field-value">
                            <dx-tag-box [(value)]="news.segments" [dataSource]="segments" displayExpr="name"
                                valueExpr="id" placeholder="{{'Choose Segments...' | translate}}">
                            </dx-tag-box>
                        </div>
                    </div>

                    <div class="dx-field">
                        <div class="dx-field-label">{{'Branches' | translate}}</div>
                        <div class="dx-field-value">
                            <dx-tag-box [(value)]="news.branches" [dataSource]="branches" displayExpr="name"
                                valueExpr="id" placeholder="{{'Choose Branches...' | translate}}">
                            </dx-tag-box>
                        </div>
                    </div>

                    <div class="dx-field">
                        <div class="dx-field-label">{{'Creation' | translate}}</div>
                        <div class="dx-field-value">
                            <dx-date-box [(value)]="news.creation" placeholder="16/10/2019" [showClearButton]="true"
                                [useMaskBehavior]="true" displayFormat="dd/MM/yyyy" type="date"></dx-date-box>
                        </div>
                    </div>

                    <div class="dx-field">
                        <div class="dx-field-label">{{'Deadline' | translate}}</div>
                        <div class="dx-field-value">
                            <dx-date-box [(value)]="news.deadline" placeholder="16/10/2019" [showClearButton]="true"
                                [useMaskBehavior]="true" displayFormat="dd/MM/yyyy" type="date"></dx-date-box>
                        </div>
                    </div>


                    <!-- Edit Panel Footer -->
                    <div class="edit-panel-footer">
                        <div style="display: flex; flex-direction: row; justify-content: space-evenly;">
                            <dx-button [height]="40" [width]="180" text="{{'Cancel' | translate}}"
                                (click)="popupVisible = false;" type="cancel">
                            </dx-button>
                            <dx-button icon="check" text="{{'Save' | translate}}" type="success" [height]="40"
                                [width]="180" useSubmitBehavior="true">
                                <div *dxTemplate="let data of 'content'">
                                    <dx-load-indicator class='button-indicator' [visible]="loadIndicatorVisible">
                                    </dx-load-indicator>
                                    <span class='dx-button-text'>{{'Save' | translate}}</span>
                                </div>
                            </dx-button>
                        </div>
                    </div>
                </div>

            </form>

        </dx-scroll-view>
    </div>



</dx-popup>


