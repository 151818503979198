import { Table, TableDetail } from './../../services/tables.service';
import { Component, OnInit } from '@angular/core';
import { TablesService } from 'src/app/services/tables.service';
import { IdGeneratorService } from 'cloud-labs-core';

@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.scss']
})
export class TablesComponent implements OnInit {

  activeTables: Table[] = []
  activeTableID: string
  constructor(public ts: TablesService, private ig: IdGeneratorService) { }

  ngOnInit() {
    this.ts.load().then(o => {
    
    })
  }

  initRow(e) {
    e.data.tables = []
  }

  updatedRow(e) {
    this.ts.upsert(e.data)
  }

  insertedRow(e) {
    this.ts.upsert(e.data)
  }

  deleteRow(e) {
    this.ts.delete({id : e.data.id})
  }


  t_initRow(e) {
    let name = e.data.name
    e.data = new Table()
    e.data.id = this.ig.generate()
    e.data.name = name
  }

  t_updatedRow(e) {
    let pl = this.ts.data.find(p => p.id == this.activeTableID).tables.find(o => o.id == e.data.id)
    pl = e.data
    this.ts.upsert(this.ts.data.find(p => p.id == this.activeTableID))
  }

  t_insertedRow(e) {
   
    this.ts.upsert(this.ts.data.find(p => p.id == this.activeTableID))
  }

  t_deleteRow(e) {
    this.ts.delete(e.data)
  }


  selectionChangedHandler(e) {
    this.activeTables = e.selectedRowsData[0].tables ? e.selectedRowsData[0].tables : []
    this.activeTableID = e.selectedRowsData[0].id
  }
}
