<!-- <div class="sub-header">
    <span>
        Sadakat Kullanıcıları
    </span>
</div> -->

<div style="overflow:scroll; height:100vh;">
    <div class="section-title" style="width: 1200px;">
        Loyalty Kullanıcıları
        <dx-button icon="refresh" [disabled]="false" (onClick)="refresh()">
        </dx-button>
    </div>

    <div class="card" style="width: 1200px;">
        <dx-data-grid id="gridContainer" [dataSource]="this.lus.store" [showBorders]="true" style="margin:auto; padding: 10px"
            [columnAutoWidth]="true" [remoteOperations]="true">
            <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
            <dxo-filter-panel [visible]="true"></dxo-filter-panel>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-search-panel [visible]="true" [width]="140" placeholder="Ara..."></dxo-search-panel>
            <dxi-column dataField="name" caption="{{'Name' | translate}}"></dxi-column>
            <dxi-column width="250px" dataField="userName" caption="{{'email' | translate}}"></dxi-column>

            <dxi-column dataField="totalWin" [width]="90" caption="{{'Total Win' | translate}}"></dxi-column>
            <dxi-column dataField="totalSpend" [width]="90" caption="{{'Total Spend' | translate}}"></dxi-column>
            <dxi-column dataField="balance" dataType="number" [width]="90" caption="{{'Balance' | translate}}">
            </dxi-column>
            <dxi-column dataField="segment" [width]="90" caption="{{'Segment' | translate}}"></dxi-column>
            <dxi-column dataField="pushToken" caption="{{'Transactions' | translate}}" cellTemplate="cellTemplate">
            </dxi-column>
            <dxi-column dataField="isActive" caption="{{'Active' | translate}}" >
            </dxi-column>

            <dxo-summary>
                <dxi-total-item
                    column="name"
                    summaryType="count">
                </dxi-total-item>
            </dxo-summary>

            <div *dxTemplate="let data of 'cellTemplate'">
                <button *ngIf="data.data.pushToken" class="upload-button"
                    (click)="send(data.data)">{{'Notify' | translate}}</button>
                <button class="upload-button" (click)="transactions(data.data)">{{'Transactions' | translate}}</button>
                <button class="upload-button" (click)="profile(data.data)">{{'Profile' | translate}}</button>
                <button class="upload-button" (click)="block(data.data)">{{'Block' | translate}}</button>
                <button class="upload-button" (click)="coupon(data.data)">{{'Coupon' | translate}}</button>
            </div>

        </dx-data-grid>
    </div>

    <div style="height: 200px;">

    </div>
    
</div>




<dx-popup class="popup" width="400px" height="200px" [fullScreen]="false" [showTitle]="true"
    title="{{'Profile' | translate}}" [dragEnabled]="false" [closeOnOutsideClick]="true"
    [(visible)]="profilePopupVisible">

    <div *dxTemplate="let data of 'content'" style="max-width: 900px; margin: auto;">

        <div style="display: flex;">
            <div style="color: yellow; width: 140px;">
                Ad Soyad :
            </div>
            <div>
                {{this.activeUser.displayName}}
            </div>
        </div>

        <div style="display: flex;">
            <div style="color: yellow; width: 140px;">
                Telefon :
            </div>
            <div>
                {{this.activeUser.phone}}
            </div>
        </div>


        <div style="display: flex;">
            <div style="color: yellow; width: 140px;">
                Doğum Tarihi:
            </div>
            <div>
                {{this.activeUser.profile.birthdate | date:'shortDate'}}
            </div>
        </div>


        <div style="display: flex;">
            <div style="color: yellow; width: 140px;">
                Cinsiyet :
            </div>
            <div>
                {{this.activeUser.profile.gender == 'm' ? 'Erkek' : 'Kadın'}}
            </div>
        </div>


        <div style="display: flex;">
            <div style="color: yellow; width: 140px;">
                Bildirim İzinleri:
            </div>
            <div>
                {{this.activeUser.profile.notifySms ?  ' sms ':''}}

                {{this.activeUser.profile.notifyPhone ?  ' Telefon ':''}}

                {{this.activeUser.profile.notifyEmail ?  ' Eposta ':''}}
            </div>
        </div>




    </div>
</dx-popup>



<dx-popup class="popup" [fullScreen]="false" [showTitle]="true" title="{{'Transactions' | translate}}"
    [dragEnabled]="false" [closeOnOutsideClick]="true" [(visible)]="popupVisible">

    <div *dxTemplate="let data of 'content'" style="max-width: 900px; margin: auto;">
        <dx-data-grid id="gridContainer" [masterDetail]="{ enabled: true, template: 'detail' }" [columnAutoWidth]="true"
            [dataSource]="this.transactionData" [showBorders]="true" style="margin:auto;">
            <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
            <dxo-header-filter [visible]="false"></dxo-header-filter>
            <dxo-search-panel [visible]="true" [width]="140" placeholder="Ara..."></dxo-search-panel>
            <dxi-column dataField="createDate" dataType="date" format="shortDateShortTime"
                caption="{{'Tarih' | translate}}"></dxi-column>
            <dxi-column dataField="actionDescription" caption="Açıklama"></dxi-column>
            <dxi-column dataField="quantity" dataType='number' caption="Miktar"></dxi-column>
            <dxi-column dataField="branchName" caption="Şube Adı"></dxi-column>


            <div *dxTemplate="let employee of 'detail'">
                <div style="padding: 5px; color: yellow;">
                    {{"AID:" + employee.data.basket.folioNo}}</div>
                <dx-data-grid [dataSource]="employee.data.basket.basketItems" [showBorders]="true"
                    [columnAutoWidth]="true">
                    <dxi-column dataField="itemName" caption="{{'Product Name' | translate}}"></dxi-column>
                    <dxi-column dataField="quantity" caption="{{'Qty' | translate}}"></dxi-column>"
                    <dxi-column dataField="unitPrice" caption="{{'Unit Price' | translate}}"></dxi-column>
                    <dxi-column dataField="totalPrice" caption="{{'Total Price' | translate}}"></dxi-column>
                    <dxi-column dataField="spendPoint" caption="{{'Spend Point' | translate}}"></dxi-column>
                    <dxi-column dataField="isUse" caption="{{'With Point' | translate}}"></dxi-column>
                </dx-data-grid>
            </div>

        </dx-data-grid>
    </div>
</dx-popup>