<div class="folio-master-cover">



    <div [ngClass]="{'main-container':!expanded, 'main-container-expanded':expanded}" id="master-div">

        <!--  HEADER -->
        <div class="header">
            <div style="width: 100%; display: flex; flex-direction: row; flex-wrap: wrap; padding: 10px; justify-content: space-evenly;">
                <div (click)="expand()">
                    <i class="material-icons-outlined icon-first" [ngClass]="{'icon-first': !expanded, 'icon-second': expanded}">chevron_left</i>
                    <i class="material-icons-outlined icon-second" [ngClass]="{'icon-second': !expanded, 'icon-first': expanded}">chevron_right</i>
                </div>
                <div>
                    {{fls.folio.table.name}}
                </div>
                <div>
                    {{fls.folio.creation | date:'short'}}
                </div>
            </div>

            <!--  LOYALTY HEADER -->
            <div id="benefits" style="width: 100%; display: flex; flex-direction: row; flex-wrap: wrap; padding: 10px; justify-content: space-evenly; color: yellow" *ngIf="this.fls.loyaltyServiceResult">
                <div>
                    {{"User Name" | translate}} : {{this.fls.loyaltyServiceResult.userName}}
                </div>

                <div>
                    {{"Point" | translate}} : {{this.fls.loyaltyServiceResult.totalPoint}}
                </div>

                <div>
                    {{"Balance" | translate}} : {{this.fls.loyaltyServiceResult.totalPoint - this.fls.getSpended()}}
                </div>
            </div>
        </div>

        <!-- FOLIO DIV -->
        <div id="folio-div" class="folio" [ngClass]="{'folio-folio':this.mode == 'folio', 'folio-payment':this.mode == 'payment'}">


            <div class="folio-row-cover" [ngClass]="{'select-folio-row':item.selected == true, 'folio-row-deleted':item.recordStatus == 'deleted' || item.recordStatus == 'removed'}" *ngFor="let item of fls.getRows() let j=index; " [@listAnimation]="fls.getRows().length">
                <div *ngIf="item.isGift" class="folio-row-gift" [ngClass]="{'select-folio-row':item.selected == true}" (click)="selectRow(item)">***** {{'GIFT' | translate}} *****</div>
                <div *ngIf="!item.isPayment" class="folio-row" id="folio-row-{{item.id}}" [ngClass]="{'select-folio-row':item.selected, 'paidfull-row' : item.isPaidFull }" (click)="selectRow(item)">


                    <!-- Seat Display ? Future {{item.seat}}  -->


                    <!-- is product paided? -->
                    <i *ngIf="item.isPaidFull" style="margin-right: 5px;" class="material-icons">payment</i>

                    <div class="name">

                        <!-- is loyalty freeable -->
                        <dx-switch id="switch" [disabled]="(this.fls.getBalanceStamp() < item.spendPoint) && !item.isLoyalty " *ngIf="item.isFree" (onValueChanged)="this.fls.setLoyaltyFree(item)" [(value)]="item.isLoyalty" style="margin-right: 5px;"></dx-switch>
                        <div>
                            {{item.name}}
                        </div>

                    </div>
                    <div class="qty">
                        {{item.qty}}
                    </div>
                    <div class="price" [ngClass]="{'price-line-through': item.isLoyalty}">
                        {{ this.fls.getRowTotal(item) | kerzzCurrency}}
                    </div>
                </div>

                <!-- ROW NOTE -->
                <div *ngIf="item.note" class="folio-row-note" [ngClass]="{'select-folio-row':item.selected == true}">**not: {{item.note}}</div>

                <!-- component : app/pos/folio/tools/modifiers-row  -->
                <app-modifiers-row [item]="item" (changeModifier)="changeModfier($event)"></app-modifiers-row>

            </div>

            <!-- PAYMENT, BALANCE AND DISCOUNT ROWS -->
            <div class="sticky-row">

                <!-- DISCOUNT ROWS -->
                <div class="folio-row-cover discount-row" *ngFor="let discountItem of fls.getDiscountRows() let jDiscount=index; ">

                    <div class="folio-row">
                        <div class="name">
                            {{discountItem.name}}
                        </div>
                        <div class="qty">

                        </div>
                        <div class="price">
                            {{ this.fls.getRowTotal(discountItem) | kerzzCurrency}}
                        </div>
                    </div>
                </div>



                <!-- TOTAL ROW -->
                <div class="folio-row-cover total-row">
                    <div class="folio-row">
                        <div class="name">
                            {{"Total" | translate}}
                        </div>
                        <div class="qty">

                        </div>
                        <div class="price">
                            {{ this.fls.getFolioTotal() | kerzzCurrency}}
                        </div>
                    </div>
                </div>


                <!-- PAYMENT ROWS -->
                <div class="folio-row-cover payment-row" *ngFor="let paymentItem of fls.getPaymentRows() let jPayment=index;">
                    <div class="folio-row" id="folio-row-{{paymentItem.id}}"  (click)="paymentClick(paymentItem, jPayment)">
                        <div class="name">
                            {{paymentItem.name}}
                        </div>
                        <div class="qty">

                        </div>
                        <div class="price">
                            {{ this.fls.getRowTotal(paymentItem) | kerzzCurrency}}
                        </div>
                        <div id="folio-payment-{{paymentItem.id}}"  class="payment-tools">
                            <i  class="fas fa-times-circle fl-close-icon" (click)="clear()"></i>
                        </div>
                    </div>
                    
                </div>

                <!--  BALANCE ROW -->
                <div class="folio-row-cover balance-row">
                    <div class="folio-row">
                        <div class="name">
                            {{"Balance" | translate}}
                        </div>
                        <div class="qty">

                        </div>
                        <div class="price">
                            {{ this.fls.getUnPaidTotal() | kerzzCurrency}}
                        </div>
                    </div>
                </div>

                <!-- TODO: MONEY DRAW ROW -->



            </div>
        </div>

        <!-- FOLIO FOOTER MENU : SEND, PAYMENT, CANCEL -->
        <app-button-menu [disabledAll]="footerMenuDisabled" *ngIf="mode == 'folio'" class="footer-menu" type="row" [menu]="footerMenu" (clicked)="footer_button_clicked($event)"></app-button-menu>
    </div>


    <div class="folio-menu-cover" *ngIf="mode == 'folio'">
        <div class="folio-menu-caption-content">
            <div class="folio-menu-caption">
                {{'Menu' | translate}}
            </div>
        </div>
        <app-button-menu [disabledAll]="sideMenuDisabled" type="column" (clicked)="folio_button_clicked($event)" [menu]="folioMenu"></app-button-menu>
    </div>

</div>

<!-- ROW / FOLIO NOTE ENTRY  -->
<dx-popup *ngIf="this.fls.selectedFolioRow" class="popup" [width]="300" [height]="250" [showTitle]="true" [title]="'Product Note'" [dragEnabled]="false" [closeOnOutsideClick]="true" [(visible)]="noteVisible">
    <dxo-position my="top" at="left bottom" of="#folio-row-{{this.fls.selectedFolioRow.id}}">
    </dxo-position>
    <div *dxTemplate="let data of 'content'" style="padding: 10px;">
        <div style="text-align:center;">
            <dx-text-area height="70px" maxLength="200" palecaholder="jsfkljsdk" [(value)]="this.fls.selectedFolioRow.note">
            </dx-text-area>
        </div>
        <div style="display:block">
            <button class="kerzz-save" style="width: 100%; margin-top:10px;" (click)="noteVisible = false">Tamam</button>
        </div>
    </div>
</dx-popup>


<!-- SEAT SELECTION  -->
<dx-popover target="#seat" position="bottom" [width]="40 * this.fls.folio.pax" [height]="50" [(visible)]="seatVisible">
    <div *dxTemplate="let data  of 'content'">
        <div style="display: flex; flex-direction: row; background-color:var(--primary-light)">
            <div *ngFor="let item of createArray(this.fls.folio.pax); let i = index" class="seat-button" (click)="fls.selectedFolioRow.seat = item; seatVisible = false">
                {{item}}
            </div>
        </div>
    </div>
</dx-popover>

<!-- QUANTITY SELECTION -->
<dx-popover style="border: unset;" position="bottom" [width]="280" [height]="390" [target]="numPadTarget" position="bottom" [(visible)]="qtyVisible">
    <app-numeric-buttons id="numPad-div" (valueChanged)="qtyVisible = false;" class="numericBox"></app-numeric-buttons>
</dx-popover>

<!-- PAX SELECTION -->
<dx-popover position="bottom" [width]="280" [height]="390" [target]="numPadTarget" position="bottom" [(visible)]="paxVisible">
    <app-numeric-buttons id="numPad-div" (valueChanged)="this.fls.folio.pax = $event; paxVisible = false;" class="numericBox"></app-numeric-buttons>
</dx-popover>


<!-- LOYALTY TOOLTIP 1 -->
<dx-tooltip target="#benefits" position="left" [visible]="showToolTip && mode == 'folio'" [closeOnOutsideClick]="false">
    <div *dxTemplate="let data = data of 'content'" style="font-size:14px">
        Müşteri <br> puan bilgileri burada
        <i class="fa fa-hand-point-right"></i>
    </div>
</dx-tooltip>

<!-- LOYALTY TOOLTIP 2 -->
<dx-tooltip target="#switch" position="right" [visible]="showToolTip && mode == 'folio'" [closeOnOutsideClick]="false">
    <dxo-animation>
        <dxo-show type="slide" [from]="{ top: -100, opacity: 0 }" [to]="{ opacity: 1, top: 0 }"></dxo-show>
        <dxo-hide type="pop" [from]="{ scale: 1, opacity: 1 }" [to]="{ opacity: 0, scale: 0.1 }"></dxo-hide>
    </dxo-animation>
    <div *dxTemplate="let data of 'content'" style="font-size:14px">
        <i class="fa fa-hand-point-left"></i> Puanlar ile alınabilecek ürünler <br> on/off tuşları burada
    </div>
</dx-tooltip>



<!-- SHOW QRCODE READER BOX -->
<dx-popover position="bottom" [width]="320" [height]="220" [target]="numPadTarget" position="left" [(visible)]="qrVisible" (onShown)="shown()">
    <div style="width: 100%; display: flex; flex-direction: column; padding: 10px; align-items: center">
        <div style="font-size:20px;">
            {{"Please Scan QR" | translate}}
        </div>
        <i class="fa fa-qrcode" style="font-size: 100px"></i>
        <dx-text-box style="color: var(--workspace-background)" (onChange)="fls.createLoyaltyBasket(); qrLoading = true" #qrcodetext [(value)]="this.fls.activeQrCode" mode="text" [showClearButton]="true">
        </dx-text-box>

        <dx-load-indicator class='button-indicator' [visible]="qrLoading">
        </dx-load-indicator>

        <!-- <dx-button [stylingMode]="'contained'" [type]="'success'" [width]="90" (onClick)="fls.createLoyaltyBasket(); qrLoading = true; qrcodetext.value = '' ">
            <div *dxTemplate="let data of 'content'">
            
                <span class='dx-button-text'>{{'OK' | translate}}</span>
            </div>
        </dx-button> -->
    </div>
</dx-popover>