import { CloudDbService } from 'cloud-labs-core';
import { ProductGroupService, productGroup } from '../../product-groups/service/product-group.service';
import { Injectable } from '@angular/core';
import { GenericDataService } from 'projects/cloud-components/src/lib/database/generic-data.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends GenericDataService {

  loaded: boolean = false

  data: product[] = []

  collection = "menu-products"

  constructor(
    public cs: CloudDbService,
    private pgs: ProductGroupService) {
    super(cs)
  }

  getProductCountOnGroup(groupID): number {
    let count: number = this.data.filter(prd => prd.group == groupID).length | 0
    let subGroups: productGroup[] = this.pgs.data.filter(pg => pg.parentID == groupID)
    subGroups.forEach(fr => {
      count += this.getProductCountOnGroup(fr.id)
    })
    return count
  }

  getProductsByGroup(grpID: string): product[] {
    return this.data.filter(p => p.group == grpID)
  }

  getAdvices() {
    return this.cs.getCollection({
      col: "advices"
    })
  }

  saveAdvices(_data) {
    debugger
    this.cs.upsert({ col: "advices", data: _data })
  }

  deleteAdvices(_data) {
    this.cs.delete({ col: "advice", filter: { id: _data.id } })
  }

}

export class product {
  id: string
  name: string
  group: string
  price: number
  dlPrice: number
  slfPrice: number
  vat: number
  image: string
  printer: string
  selectCount: number
  modifierType: string
  modifierIndex: number
  winStamp: number //win stamp
  scratch: number //win stamp
  spendPoints: number //spend value
  kerzzID: string
}