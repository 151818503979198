<div>
  <div class="cl-line" *ngIf="label != ''"></div>
  <div class="cl-label" *ngIf="label">
    {{label}}
  </div>
  <div class="cl-text-info-cover">
    <span class="cl-sub-header" *ngIf="ilabel != ''">
      {{ilabel}}
    </span>
    <div class="cl-text-info-cover" [ngClass]="{'cl-text-bottom-line': bottomLine}">
      <input readonly="true" id="{{id}}" (click)="showList()" [value]="dValue" class="cl-input" type="text"
        #clDropDownInput (blur)="blur($event)" (focus)="focus($event)" />
      <img *ngIf="loading " class="loading-image" src="/assets/spinner.gif" />
      <i *ngIf="!loading && showClearButton" class="fas fa-times-circle cl-close-icon" style="padding-right: 10px;"
        (click)="clear()"></i>
      <i class="fas fa-caret-down cl-down-icon" (click)="showList()"></i>
    </div>



    <div class="search-list" *ngIf="temp_list.length > 0 && show">
      <div *ngFor="let item of temp_list" class="search-item" (click)="select(item)">
        <div id="tax-{{item.taxNo}}" class="item-name" [ngClass]="{'selected': item.selected}"
          title="{{item['accountName']}}">
          <ng-container *ngTemplateOutlet="ItemTemplate ? ItemTemplate : def; context: {data: item}">
          </ng-container>
          <ng-template #def>
            <div style="display: flex; justify-content: space-evenly; align-items: center">
              <div>
                {{item[displayExpr]}}
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>