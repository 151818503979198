import { AppPermissionsService } from './../settings/services/app-permissions.service';
import { CloudLoginService } from 'cloud-labs-core';
import { GlobalsService } from './../services/globals.service';
import { Router } from '@angular/router';
import { Component, NgZone, OnInit } from '@angular/core';
import { TranslateService, LangChangeEvent } from '../../../node_modules/@ngx-translate/core';
import { StartupService } from '../services/startup.service';
import { GenericDataService } from 'projects/cloud-components/src/lib/database/generic-data.service';
import { AsyncSubject, Subject } from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import { PublicApiService } from '../services/public-service/public-api.service';


@Component({
  selector: 'app-veritcal-nav',
  templateUrl: './veritcal-nav.component.html',
  styleUrls: ['./veritcal-nav.component.css']
})
export class VeritcalNavComponent implements OnInit {

  sales = false;
  hideMenu = false;

  centralOrder: boolean = true
  showx = false;
  public menu: maimMenu[]

  public submenu: subMenu[] = []
  loading = false;

  constructor(
    public glb: GlobalsService,
    private router: Router,
    private _snackBar : MatSnackBar,
    private translate: TranslateService,
    private ss: StartupService,
    private publicApi: PublicApiService,
    public cls: CloudLoginService,
    private perm: AppPermissionsService,
    private gs: GenericDataService
  ) {

  }

  sub(item) {
    return this.submenu.filter(p => p.index === item.index)
  }

  activatesub(item) {
    if (!item.enabled) return
    item.active = true;
  }

  subactive(item) {
    if (!item.enabled) return
    return item.active;
  }


  ngAfterViewInit() {
    this.perm.observer_perissions.subscribe(o => {
      console.log('asda', o)

      if (o) {
        try {
          this.menu.find(m => m.tag == "settings").enabled = this.perm.perm.UsersAndRights
          this.menu.find(m => m.tag == "loyalty").enabled = this.perm.perm.Loyalty
          this.menu.find(m => m.tag == "invoice").enabled = this.perm.perm.eInvoice
          this.menu.find(m => m.tag == "sales").enabled = this.perm.perm.Pos
          this.menu.find(m => m.tag == "products").enabled = this.perm.perm.ProductsAndGroups
          // this.menu.find(m => m.tag == "central-order").enabled = true
          //this.menu.find(m => m.tag == "users").enabled = this.perm.perm.UsersAndRights


          this.submenu.find(m => m.tag == "invoice-list-outgoing").enabled = this.perm.perm.OutboundInvoices
          this.submenu.find(m => m.tag == "invoice-list-inbound").enabled = this.perm.perm.InboundInvoices

        } catch (error) {
          console.log(error)
        }
      }

    })
  }
  ngOnInit() {



    this.ss.observer_vertical_nav.subscribe(o => {
      this.hideMenu = o
    })

    this.ss.observer_global_events.subscribe(o => {
      if (o.id == "change-company") {
        this.glb.showLicanceSelectPopup = true
      }
    })

    this.translate.get("Settings").subscribe(o => {
      this.createMenu()
    })


    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.createMenu()
    });

    this.ss.observer_startup_loading.subscribe(p => {
      if (p == 'loading done.') {
        this.loading = false
        this.translate.setDefaultLang('tr');

        if (this.glb.activeRoute) {
          this.router.navigateByUrl(this.glb.activeRoute)
        } else {
          this.router.navigate(['main'])
        }
      }
    })

  }

  createMenu() {

    this.menu = [
      { tag: "settings", title: this.translate.instant('Settings'), route: undefined, icon: "/assets/myicons/settings.png", index: 0, active: false, micon: "settings", enabled: this.perm.perm.UsersAndRights },
      { tag: "products", title: this.translate.instant('Products'), route: undefined, icon: "/assets/myicons/products.png", index: 1, active: false, micon: "bookmarks", enabled: this.perm.perm.ProductsAndGroups },
      // { title: this.translate.instant('Tables'), route: "designed-table", icon: "/assets/myicons/tables.png", index: 2, active: false, micon : "restaurant" },
      { tag: "sales", title: this.translate.instant('Sales'), route: "menu", icon: "/assets/myicons/sales.png", index: 2, active: false, micon: "shopping_cart", enabled: this.perm.perm.Pos },
      { tag: "invoice", title: this.translate.instant('Invoice'), route: undefined, icon: "/assets/myicons/sales.png", index: 4, active: false, micon: "assignment", enabled: this.perm.perm.eInvoice },
      // { tag: "central-order", title: this.translate.instant('Central Order'), route: undefined, icon: "/assets/myicons/sales.png", index: 7, active: false, micon: "apartment",enabled:this.centralOrder}, //TODO YETKI EKLENECEK
      { tag: "loyalty", title: this.translate.instant('Loyalty'), route: undefined, icon: "/assets/myicons/check.png", index: 3, active: false, micon: "loyalty", enabled: this.perm.perm.Loyalty },
    ]

    //Settings Submnenu
    this.submenu.push({ tag: "settings", title: this.translate.instant('Company Settings'), faicon: "fas fa-toolbox", route: "settings-company", icon: "/assets/myicons/modifiers.png", index: 0, enabled: true })
    this.submenu.push({ tag: "settings", title: this.translate.instant('Branch Settings'), faicon: "fas fa-store", route: "branches", icon: "/assets/myicons/groups.png", index: 0, enabled: true })
    this.submenu.push({ tag: "users", title: this.translate.instant('Users & Rights'), faicon: "fas fa-user-shield", route: "app-users", icon: "/assets/myicons/groups.png", index: 0, enabled: true })
    this.submenu.push({ tag: "settings", title: this.translate.instant('Desing Tables'), faicon: "fas fa-user-shield", route: "designed-table", icon: "/assets/myicons/groups.png", index: 0, enabled: true })
    this.submenu.push({ tag: "settings", title: this.translate.instant('Tables'), faicon: "fas fa-user-shield", route: "settings-tables", icon: "/assets/myicons/groups.png", index: 0, enabled: true })
    this.submenu.push({ tag: "settings", title: this.translate.instant('Pay Types'), faicon: "fas fa-user-shield", route: "settings-pay-types", icon: "/assets/myicons/groups.png", index: 0, enabled: true })
    this.submenu.push({ tag: "settings", title: this.translate.instant('Printers'), faicon: "fas fa-user-shield", route: "settings-printers", icon: "/assets/myicons/groups.png", index: 0, enabled: true })



    //Products Submnenu
    this.submenu.push({ tag: "settings", title: this.translate.instant('Groups'), route: "product-groups", icon: "/assets/myicons/groups.png", index: 1, enabled: true })
    this.submenu.push({ tag: "settings", title: this.translate.instant('Products'), route: "products", icon: "/assets/myicons/sub-products.png", index: 1, enabled: true })
    this.submenu.push({ tag: "settings", title: this.translate.instant('Modifiers'), route: "modifiers", icon: "/assets/myicons/modifiers.png", index: 1, enabled: true })
    this.submenu.push({ tag: "settings", title: this.translate.instant('Advices'), route: "advices", icon: "/assets/myicons/modifiers.png", index: 1, enabled: true })



    //Loyalty
    this.submenu.push({ tag: "settings-loyalty", title: this.translate.instant('Settings'), route: "loyaltySettings", icon: "/assets/myicons/modifiers.png", index: 3, enabled: true })
    this.submenu.push({ tag: "settings-loyalty", title: this.translate.instant('News'), route: "news", icon: "/assets/myicons/modifiers.png", index: 3, enabled: true })
    this.submenu.push({ tag: "settings-loyalty", title: this.translate.instant('Branches'), route: "branches", icon: "/assets/myicons/modifiers.png", index: 3, enabled: true })
    this.submenu.push({ tag: "settings-loyalty", title: this.translate.instant('Campaigns'), route: "campaign", icon: "/assets/myicons/modifiers.png", index: 3, enabled: true })
    this.submenu.push({ tag: "settings-loyalty", title: this.translate.instant('Faq'), route: "faq", icon: "/assets/myicons/modifiers.png", index: 3, enabled: true })
    this.submenu.push({ tag: "settings-loyalty", title: this.translate.instant('Agreements'), route: "agreements", icon: "/assets/myicons/modifiers.png", index: 3, enabled: true })
    this.submenu.push({ tag: "settings-loyalty", title: this.translate.instant('Loyalty Users'), route: "loyaltyUsers", icon: "/assets/myicons/modifiers.png", index: 3, enabled: true })
    this.submenu.push({ tag: "settings-loyalty", title: this.translate.instant('Segments'), route: "segments", icon: "/assets/myicons/modifiers.png", index: 3, enabled: true })
    this.submenu.push({ tag: "settings-loyalty", title: this.translate.instant('Coupons'), route: "coupons", icon: "/assets/myicons/modifiers.png", index: 3, enabled: true })
    this.submenu.push({ tag: "settings-loyalty", title: this.translate.instant('Push Notifications'), route: "push", icon: "/assets/myicons/modifiers.png", index: 3, enabled: true })

    //Central Order SubMenu
    this.submenu.push({ tag: "new-order", title: this.translate.instant('New Order'), route: "central-order", icon: "", index: 7, enabled: true })
    // this.submenu.push({ tag: "old-orders", title: this.translate.instant('Old Orders'), route: "central-order", icon: "", index: 7, enabled: true })

    //Inovice Submnenu
    //this.submenu.push({ tag: "inovice-list-incomming", title: this.translate.instant('Incoming Invoices'), route: "invoice-list", icon: "/assets/myicons/groups.png", index: 4, enabled: true })
    this.submenu.push({ tag: "invoice-list-outgoing", title: this.translate.instant('Outgoing Invoices'), route: "invoice-list", icon: "/assets/myicons/sub-products.png", index: 4, enabled: true })
    this.submenu.push({ tag: "invoice-list-inbound", title: this.translate.instant('Incoming Invoices'), route: "e-invoices-inbound", icon: "/assets/myicons/sub-products.png", index: 4, enabled: true })
    // this.submenu.push({ tag: "e-producer", title: this.translate.instant('E Producer'), route: "e-producer", icon: "/assets/myicons/sub-products.png", index: 4, enabled: true })
    // this.submenu.push({ tag: "manual-e-producer", title: this.translate.instant('Manual E Producer'), route: "manual-e-producer", icon: "/assets/myicons/sub-products.png", index: 4, enabled: true })
    this.submenu.push({ tag: "invoice-list-create", title: this.translate.instant('Create Invoice'), route: "create-invoice", icon: "/assets/myicons/sub-products.png", index: 4, enabled: true })

    this.submenu.push({ tag: "dispatches-list-inbound", title: this.translate.instant('Incoming Dispatches'), route: "e-dispatches-inbound", icon: "/assets/myicons/sub-products.png", index: 4, enabled: true })
    // this.submenu.push({ tag: "dispatches-list-inbound", title: this.translate.instant('Outbount Dispatches'), route: "e-dispatches-outbound", icon: "/assets/myicons/sub-products.png", index: 4, enabled: true })
    this.submenu.push({ tag: "dispatches-list-inbound", title: this.translate.instant('New Outbound Dispatches'), route: "e-dispatches-outbound-form", icon: "/assets/myicons/sub-products.png", index: 4, enabled: true })


    //this.submenu.push({ tag: "invoice-list-standby", title: this.translate.instant('Standby Invoices'), route: "invoice-list", icon: "/assets/myicons/modifiers.png", index: 4, enabled: true })
    //this.submenu.push({ tag: "invoice-form", title: this.translate.instant('Invoice Form'), route: "invoice-form", icon: "/assets/myicons/modifiers.png", index: 4, enabled: true })
    //this.submenu.push({ tag: "account-form", title: this.translate.instant('Account Form'), route: "account-form", icon: "/assets/myicons/modifiers.png", index: 4, enabled: true })
    //this.submenu.push({ tag: "account-list", title: this.translate.instant('Account List'), route: "account-list", icon: "/assets/myicons/modifiers.png", index: 4, enabled: true })
    this.submenu.push({ tag: "invoice-settings", title: this.translate.instant('Invoice Settings'), route: "invoice-settings", icon: "/assets/myicons/modifiers.png", index: 0, enabled: true })
    //this.submenu.push({ tag: "invoice-entry", title: this.translate.instant('Invoice Settings'), route: "invoice-entry", icon: "/assets/myicons/modifiers.png", index: 4, enabled: true })
    this.submenu.push({ tag: "eaccounts", title: this.translate.instant('E-Accounts List'), route: "eaccounts", icon: "/assets/myicons/modifiers.png", index: 4, enabled: true })
    this.submenu.push({ tag: "erp-integration", title: this.translate.instant('ERP'), route: "erp-integration", icon: "/assets/myicons/modifiers.png", index: 4, enabled: true })
    this.submenu.push({ tag: "invoice-convert-accounts", title: this.translate.instant('Invoice Convert Accounts'), route: "invoice-convert-accounts", icon: "/assets/myicons/modifiers.png", index: 4, enabled: true })
    this.submenu.push({ tag: "invoice-account-type", title: this.translate.instant('Invoice Account Type'), route: "invoice-account-type", icon: "/assets/myicons/modifiers.png", index: 4, enabled: true })


  }

  nav(i) {

    if (!i.enabled) return

    i.active = i.active ? false : true;
    if (i.route !== undefined) {
      this.router.navigateByUrl(i.route)
      this.showx = false;
    }
    this.sales = false
    if (i.route == 'menu') {
      this.sales = true
    }
  }

  changeCompany(company) {

    this.glb.showLicanceSelectPopup = false;
    this.loading = true
    this.glb.activeLicance = company.licanceId;
    this.glb.activeBrand = company.brand
    this.cls.setActiveLicance(company.licanceId)
    this.ss.loadData();
    this.perm.loadPerms();
   this.checkVersion()

  }

  checkVersion() {
    this.publicApi
      .getCollection({
        _db: 'kerzz-contract',
        col: 'application-version',
        _filter: { appName: 'KerzzCloud' }
      })
      .toPromise()
      .then(async (o: any) => {
        debugger
        let localVersion = localStorage.getItem("application-version")
        let version = o[0].version
        if (localVersion == null) {
          localStorage.setItem("application-version", version)
        } else if (localVersion !== version) {
          // localStorage.setItem("application-version", version)

          setTimeout(() => {
            console.log("timer")
            this._snackBar.open("hello", null, {duration:5000})
          }, 1000);

            // this._snackBar.open("message", 'x');

        }
      })
  }

  searchStore(e) {
    if (e.value != "") {
      // let filterData = this.userService.user.store.filter(x=>x.id == e.value)

      this.glb.filteredStores = this.cls.userInfo.licances.filter(o =>
        Object.keys(o).some(k => o.brand.toString().toLowerCase().includes(e.value.toLowerCase())));
    }
    else {
      this.glb.filteredStores = this.cls.userInfo.licances
    }
  }

  isShow() {
    let p = this.sales && !this.hideMenu
    return p
  }

}

export class maimMenu {
  tag: string
  title: string;
  route: any;
  icon: string
  index: number
  active: boolean
  micon?: string;
  faicon?: string;
  enabled?: boolean = true

}

export class subMenu {
  tag: string
  title: string;
  route: any;
  icon: string
  index: number
  micon?: string
  faicon?: string;
  enabled?: boolean = true
}
