<div style="overflow:scroll; height:100vh;">
    <div class="section-title" id="us-label">
        Kullanıcılar
    </div>

    <div class="card">
        <dx-data-grid id="gridContainer" [dataSource]="this.auser.temp_users" [showBorders]="true" style="margin:auto;" (onInitNewRow)="initRow($event)" (onRowInserted)="insertedRow($event)" (onRowRemoved)="deleteRow($event)" (onRowUpdating)="updatedRow($event)">
            <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
            <dxo-header-filter [visible]="false"></dxo-header-filter>
            <dxo-search-panel [visible]="true" [width]="140" placeholder="Ara..."></dxo-search-panel>
            <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
            </dxo-editing>
            <dxi-column dataField="name" [width]="140" caption="{{'Name' | translate}}" cellTemplate="nameTemplata"></dxi-column>
            <dxi-column dataField="email" [width]="140" caption="{{'email' | translate}}" cellTemplate="emailTemplate"></dxi-column>

            <dxi-column dataField="roles" [width]="150" caption="{{'Roles' | translate}}" [calculateDisplayValue]="getRolesDisplay" editCellTemplate="tagBoxTemplate" cellTemplate="tagBoxTemplateCell">
            </dxi-column>

            <dxi-column type="buttons" [width]="200" caption="{{'Apps' | translate}}" cellTemplate="cellTemplate">
            </dxi-column>

            <div *dxTemplate="let data of 'cellTemplate'">
                <button [disabled]="data.row.isEditing || this.ls.userInfo.mail == data.data.email" class="upload-button" (click)="nos(data)">Edit</button>
                <button [disabled]="data.row.isEditing || this.ls.userInfo.mail == data.data.email" class="upload-button" (click)="delete(data)">Delete</button>
                <button *ngIf="!data.data.isSuspend" [disabled]="data.row.isEditing || this.ls.userInfo.mail == data.data.email" class="upload-button" (click)="suspend(true,data)">Suspend</button>
                <button *ngIf="data.data.isSuspend" class="upload-button" (click)="suspend(false,data)">Activate</button>
                <!--  --><button *ngIf="data.row.isEditing" class="upload-button" (click)="suspend(false,data)">Save</button>
            </div>

            <div *dxTemplate="let cellInfo of 'nameTemplata'">
                <div [ngStyle]="{'text-decoration': cellInfo.data.isSuspend ? 'line-through' : ''}">
                    {{cellInfo.data.name}}
                </div>
            </div>


            <div *dxTemplate="let cellInfo of 'emailTemplate'">
                <div [ngStyle]="{'text-decoration': cellInfo.data.isSuspend ? 'line-through' : ''}">
                    {{cellInfo.data.email}}
                </div>
            </div>

            <div *dxTemplate=" let cellInfo of 'tagBoxTemplateCell' ">
                <div style="font-size: 12px">
                    {{getRolesDisplay(cellInfo.data)}}
                </div>
            </div>

            <div *dxTemplate="let cellInfo of 'tagBoxTemplate' ">
                <dx-tag-box [(value)]="cellInfo.data.roles" (onValueChanged)="onTagBoxValueChanged($event, cellInfo)" [searchEnabled]="true" valueExpr="id" displayExpr="name" [dataSource]="this.auser.roles"></dx-tag-box>
            </div>
        </dx-data-grid>
    </div>

    <div style="height: 200px; ">

    </div>
</div>