<div class="cl-line" *ngIf="label != ''"></div>
<div class="cl-label" *ngIf="label != ''" [ngStyle]="{'color': theme == 'light' ? 'black' : 'none' }">
    {{label}} <span *ngIf="required">*</span>
</div>

<div class="cl-sub-cover" *ngIf="ilabel !== ''">
    <span class="cl-sub-header">
        {{ilabel}} <span *ngIf="required">*</span>
    </span>
    <!-- [ngStyle]="{'cl-text-bottom-line': bottomLine}" -->
    <div class="cl-text-info-cover"
        [ngClass]="{'cl-text-info-cover-focus': _focus, 'cl-text-info-cover-blur' : !_focus, 'cl-text-bottom-line': bottomLine}">
        <input id="{{id}}" (blur)="blur($event)" (focus)="focus($event)" (keyup)="changed($event)" class="cl-input"
            appMycurrency
            [ngClass]="{'cl-error': !isValid, 'cl-upper' : characterCase == 'upper',  'cl-lower' : characterCase == 'lower', 'cl-capitalize' : characterCase == 'capitalize'}"
            type="{{type}}" [(value)]="value" #clinput />
    </div>
    <img *ngIf="this.changeEvnt.loading && eventsEnabled" class="loading-image" src="/assets/loading-h.gif" />
    <div *ngIf="!this.changeEvnt.loading && eventsEnabled" class="loading-image"></div>
    <div class="cl-text-info" *ngIf="this.changeEvnt.info != '' && eventsEnabled">
        {{this.changeEvnt.info}}
    </div>

</div>

<div class="cl-text-info-cover" *ngIf="ilabel == ''"
    [ngClass]="{'cl-text-info-cover-focus': _focus, 'cl-text-info-cover-blur' : !_focus, 'cl-text-bottom-line': bottomLine}">



    <input [disabled]="disabled" *ngIf="type=='number'" id="{{id}}" (blur)="blur($event)" (focus)="focus($event)" (keyup)="changed($event)"
        [(value)]="value" class="cl-input" appMycurrency="type"
        [ngStyle]="{'color': theme == 'light' ? 'black' : 'none' }"
        [ngClass]="{'cl-error': !isValid, 'cl-upper' : characterCase == 'upper',  'cl-lower' : characterCase == 'lower', 'cl-capitalize' : characterCase == 'capitalize', 'cl-disabled': disabled}"
        type="text" #clinput />


    <input [disabled]="disabled" *ngIf="type=='text'" id="{{id}}" (blur)="blur($event)" (focus)="focus($event)" (keyup)="changed($event)"
        [(value)]="value" class="cl-input" 
        [ngStyle]="{'color': theme == 'light' ? 'black' : 'none' }"
        [ngClass]="{'cl-error': !isValid, 'cl-upper' : characterCase == 'upper',  'cl-lower' : characterCase == 'lower', 'cl-capitalize' : characterCase == 'capitalize', 'cl-disabled': disabled}"
        type="text" #clinput />


    <img *ngIf="this.changeEvnt.loading && eventsEnabled" class="loading-image" src="/assets/loading-h.gif" />
    <div *ngIf="!this.changeEvnt.loading && eventsEnabled" class="loading-image"></div>
    <div class="cl-text-info" *ngIf="this.changeEvnt.info != '' && eventsEnabled">
        {{this.changeEvnt.info}}
    </div>
    <div class="cl-text-pointer" *ngIf="pointer != ''">
        {{pointer}}
    </div>
</div>