<div style="overflow:scroll; height:100vh;">
    <div class="section-title">
        <dx-button icon="plus" (click)="new()"></dx-button>
        {{"Coupons List" | translate}}
    </div>

    <div class="card">
        <dx-data-grid id="gridContainer" [dataSource]="couponservice.data" [showBorders]="true" style="margin:auto;"
        (onRowRemoved)="deleteRow($event)">
        <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"></dxo-editing>

        <dxi-column type="buttons" [width]="110">
            <dxi-button name="delete"></dxi-button>
            <dxi-button hint="Edit" icon="edit"  [onClick]="click1">
            </dxi-button>
        </dxi-column>



            <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-search-panel [visible]="true" [width]="240" placeholder="Ara..."></dxo-search-panel>
           
            <dxi-column dataField="name" [width]="240" caption="{{'Name' | translate}}"></dxi-column>
            <dxi-column dataField="type" [width]="150" caption="{{'Type' | translate}}">
                <dxo-lookup [dataSource]="couponTypes" displayExpr="name" valueExpr="id">
                </dxo-lookup>
            </dxi-column>
            <dxi-column dataField="program" [width]="150" caption="{{'Program' | translate}}">
                <dxo-lookup [dataSource]="programs" displayExpr="name" valueExpr="id">
                </dxo-lookup>
            </dxi-column>

        </dx-data-grid>
    </div>

    <div style="height: 200px;">

    </div>

</div>







<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#grid' }" [(visible)]="loadingVisible" [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>

<dx-popup class="popup" [showTitle]="true" title="Information" [fullScreen]="true" [dragEnabled]="false" [closeOnOutsideClick]="true" [(visible)]="popupVisible">

    <div *dxTemplate="let data of 'content'" style="max-width: 600px; margin: auto;">
        <dx-scroll-view>

            <div>
                <app-upload #branchImage (start)="startUpload($event)" (uploadError)="setError($event)" (url)="setURL($event)" (percent)="setPercent($event)" folder="product"></app-upload>
            </div>


            <div id="content">

                <form #f (submit)="onFormSubmit($event)">

                    <div class="dx-fieldset" style="height: 80%;">

                        <div class="dx-field">
                            <div class="dx-field-label">{{'Name' | translate}}</div>
                            <div class="dx-field-value">
                                <dx-text-box [(value)]="coupon.name" [disabled]="false" [readOnly]="false">
                                    <dx-validator>
                                        <dxi-validation-rule type="required" message="name"></dxi-validation-rule>
                                    </dx-validator>
                                </dx-text-box>
                            </div>
                        </div>

                        <div class="dx-field">
                            <div class="dx-field-label">{{'Description' | translate}}</div>
                            <div class="dx-field-value">
                                <dx-text-area [(value)]="coupon.description" [height]="90" [disabled]="false" [readOnly]="false">
                                    <dx-validator>
                                        <dxi-validation-rule type="required" message="Açıklama"></dxi-validation-rule>
                                    </dx-validator>
                                </dx-text-area>
                            </div>
                        </div>

                        <div class="dx-field">
                            <div class="dx-field-label">{{'Coupon Type' | translate}}</div>
                            <div class="dx-field-value">
                                <dx-select-box #couponType displayExpr="name" valueExpr="id" [(value)]="coupon.type" [dataSource]="couponTypes"></dx-select-box>
                            </div>
                        </div>

                        <div class="dx-field" *ngIf="couponType.value == 'trigger'">
                            <div class="dx-field-label">{{'Trigger' | translate}}</div>
                            <div class="dx-field-value">
                                <dx-select-box #couponTrigger displayExpr="name" valueExpr="id" [(value)]="coupon.trigger" [dataSource]="triggers"></dx-select-box>
                            </div>
                        </div>

                        <div class="dx-field">
                            <div class="dx-field-label">{{'Coupon Program' | translate}}</div>
                            <div class="dx-field-value">
                                <dx-select-box displayExpr="name" valueExpr="id" #couponProgram [(value)]="coupon.program" [dataSource]="programs"></dx-select-box>
                            </div>
                        </div>

                        <div class="dx-field">
                            <div class="dx-field-label">{{'Valid Until' | translate}}</div>
                            <div class="dx-field-value">
                                <dx-date-box [(value)]="coupon.creation" type="datetime">
                                </dx-date-box>
                            </div>
                        </div>

                        <div class="dx-field">
                            <div class="dx-field-label">{{'Valid Thru' | translate}}</div>
                            <div class="dx-field-value">
                                <dx-date-box [(value)]="coupon.validThru" type="datetime">
                                </dx-date-box>
                            </div>
                        </div>

                        <div class="dx-field">
                            <div class="dx-field-label">{{'Segments' | translate}}</div>
                            <div class="dx-field-value">
                                <dx-tag-box [(value)]="coupon.segments" [dataSource]="this.segmentSrvc.data" displayExpr="name" valueExpr="id" placeholder="{{'Choose Segments...' | translate}}">
                                </dx-tag-box>
                            </div>
                        </div>

                        <div class="dx-field">
                            <div class="dx-field-label">{{'Branches' | translate}}</div>
                            <div class="dx-field-value">
                                <dx-tag-box [searchEnabled]="true" [(value)]="coupon.branches" [dataSource]="this.bs.data" displayExpr="name" valueExpr="id" placeholder="{{'Choose Branches...' | translate}}">
                                </dx-tag-box>
                            </div>
                        </div>

                        <div class="dx-field" *ngIf="couponProgram.value == 'free-product'">
                            <div class="dx-field-label">{{'Products' | translate}}</div>
                            <div class="dx-field-value">
                                <dx-tag-box [searchEnabled]="true" [(value)]="coupon.products" [dataSource]="this.ps.data" displayExpr="name" valueExpr="id" placeholder="{{'Choose Products...' | translate}}">
                                </dx-tag-box>
                            </div>
                        </div>

                       <!--  <div class="dx-field">
                            <div class="dx-field-label">{{'Users' | translate}}</div>
                            <div class="dx-field-value">
                                <dx-tag-box [searchEnabled]="true" [(value)]="coupon.users" [dataSource]="tagBoxData" displayExpr="name" valueExpr="id" placeholder="{{'Choose Users...' | translate}}">
                                </dx-tag-box>
                            </div>
                        </div> -->

                        <div class="dx-field" *ngIf="couponProgram.value == 'discount-basket'">
                            <div class="dx-field-label">{{'Basket Discount' | translate}}</div>
                            <div class="dx-field-value">
                                <dx-number-box format="#0%" [(value)]="coupon.basketDiscountPercent" placeholder="% Oran Girin" [disabled]="false" [readOnly]="false">
                                </dx-number-box>
                                <dx-number-box format="₺ #,##0.##" [(value)]="coupon.basketDiscountAmount" placeholder="₺ Tutar Girin" [disabled]="false" [readOnly]="false">
                                </dx-number-box>
                            </div>
                        </div>

                        <div class="dx-field">
                            <div class="dx-field-label">{{'Coupon Qty' | translate}}</div>
                            <div class="dx-field-value">
                                <dx-number-box format="#0" [(value)]="coupon.maxUsage" [disabled]="false" [readOnly]="false">
                                </dx-number-box>
                            </div>
                        </div>


                        <div class="dx-fieldset-header">{{'Image' | translate}}</div>

                        <div class="dx-field">
                            <div class="dx-field-label">{{'Image' | translate}}</div>
                            <div class="dx-field-value">
                                <img class="img-160-95" src="{{coupon.image}}" />
                                <dx-button text="Upload" type="success" (click)="showUpload()">
                                </dx-button>
                            </div>
                        </div>


                        <!-- Edit Panel Footer -->
                        <div class="edit-panel-footer">
                            <div style="display: flex; flex-direction: row; justify-content: space-evenly;">
                                <dx-button text="İptal" (click)="popupVisible = false;" type="cancel">
                                </dx-button>
                                <dx-button icon="check" text="Kaydet" type="success" useSubmitBehavior="true">
                                    <div *dxTemplate="let data of 'content'">
                                        <dx-load-indicator class='button-indicator' [visible]="loadingVisible">
                                        </dx-load-indicator>
                                        <span class='dx-button-text'>{{'Save' | translate}}</span>
                                    </div>
                                </dx-button>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </dx-scroll-view>
    </div>
</dx-popup>