import { Injectable } from "@angular/core";
import { CloudDbService } from "cloud-labs-core";

@Injectable({
  providedIn: "root"
})
export class ModifiersService {


  productModifiers: modifiers[] 
  productGroupModifiers: modifiers[] 

  _productModifiers: modifiers[] = []
  _productGroupModifiers: modifiers[] = []

  selectMustGroup: modifiers = undefined;
  loaded: boolean = false;

  constructor(
    private cs: CloudDbService
  ) { }


  getProductModifiersFromMongo() {
    this.cs.getCollection({col: "menu-product_modifiers"}).subscribe( p=> {
      this.productModifiers = Object.assign(p)
    })
  }

  getProductGroupModifiersFromMongo(){
    this.cs.getCollection({col: "menu-product_group_modifiers"}).subscribe( p=> {
      this.productGroupModifiers = Object.assign(p)
    })
  }


  createMustModifByGroup(maxSelect: number, group: [{ id: string; productGroup: string; group: string }]) {
    return { maxSelect: maxSelect, group: group };
  }

  loadModifiers(groupID, productID) {
    this.selectMustGroup = new modifiers();
    let prd: any = []
    let prdGrp: any = []
    Object.assign(prd, this.productModifiers.find(p => p.productID === productID))
    Object.assign(prdGrp, this.productGroupModifiers.find(p => p.productGroupID === groupID))

    if (prd.modif) {
      prd.modif = prd.modif.filter(m => m.must === false)
      if (prd.modif.length > 0) {
        prd.modif.forEach(element => {
          this.selectMustGroup.modif.push(element);
        })
      }
    }

    if (prdGrp.modif) {
      prdGrp.modif = prdGrp.modif.filter(m => m.must === false)
      if (prdGrp.modif.length > 0) {
        prdGrp.modif.forEach(element => {
          this.selectMustGroup.modif.push(element);
        })
      }
    }

    return this.selectMustGroup
  }

  loadMustModifiers(groupID, productID) {
    this.selectMustGroup = new modifiers();
    let prd: any = []
    let prdGrp: any = []
    Object.assign(prd, this.productModifiers.find(p => p.productID === productID))
    Object.assign(prdGrp, this.productGroupModifiers.find(p => p.productGroupID === groupID))

    if (prd.modif) {

      prd.modif = prd.modif.filter(m => m.must || m.auto)
      if (prd.modif.length > 0) {
        prd.modif.forEach(element => {
          this.selectMustGroup.modif.push(element);
        })
      }
    }


    if (prdGrp.modif) {
      prdGrp.modif = prdGrp.modif.filter(m => m.must || m.auto)
      if (prdGrp.modif.length > 0) {
        prdGrp.modif.forEach(element => {
          this.selectMustGroup.modif.push(element);
        })
      }
    }

    return this.selectMustGroup
  }

  saveProductModifier(modif: modifiers, productID: string) {
    this.cs.upsert({col:"menu-product_modifiers", data: modif })
  }

  saveProductGroupModifier(modif: modifiers, productGroupID: string) {
    this.cs.upsert({col:"menu-product_group_modifiers", data: modif })
  }

  deleteProductModifier(productID: string) {
    this.cs.delete({col: "menu-product_modifiers", filter : {id: productID} })
  }

  deleteProductGroupModifier(productGroupID: string) {
    this.cs.delete({col: "menu-product_group_modifiers", filter : {id: productGroupID} })
  }

}

export class modifiers {
  id: string = "";
  auto: boolean = false;
  maxSelect: number = 0;
  minSelect: number = 0;
  remain: number = 0;
  productID: string = "";
  productGroupID: string = "";
  modif: Array<{
    type: string;
    id: string;
    index: number;
    productGroup: string;
    group: string;
    maxSelect: number;
    minSelect: number;
    remain: number;
    must: boolean;
    auto: boolean;
    filteredProducts: any[];
    detachable: boolean
    canBeAdded: boolean
    priceless: boolean
    products: Array<{
      productID: string;
      productName: string;
      price: number;
      priceless: boolean;
      detachable: boolean;
      canBeAdded: boolean;
    }>;
  }> = [];
}


