import { EventEmitter } from '@angular/core';
import { Component, OnInit, Input, Output } from '@angular/core';
import { GlobalsService } from '../../services/globals.service';

@Component({
  selector: 'app-footer-button',
  templateUrl: './footer-button.component.html',
  styleUrls: ['./footer-button.component.css']
})
export class FooterButtonComponent implements OnInit {

  @Input() icon: string
  @Input() text: string
  @Input() disabled: boolean
  @Input() selected: boolean

  @Output() clicked = new EventEmitter()

  constructor(private glb: GlobalsService) { }

  ngOnInit() {

  }

  _clicked() {
    if (!this.disabled) {
      this.glb.clickSound()
      this.clicked.emit("click")
    }
  
  }
}
