import { Component, OnInit } from '@angular/core';
import { CloudDbService } from 'cloud-labs-core';
import { AccountService } from 'src/app/accounts/services/account.service';
import { ClStore, CloudDataStoreService } from 'projects/cloud-components/src/public-api';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'app-eaccounts',
  templateUrl: './eaccounts.component.html',
  styleUrls: ['./eaccounts.component.scss']
})
export class EaccountsComponent implements OnInit {


  data : CustomStore

  public clStoreAccounts: ClStore
  
  public columns

  constructor(private cs: CloudDbService, public cloudds: CloudDataStoreService, public ac: AccountService) { }


  ngOnInit() {
    

   this.data = this.cloudds.createdDXSource("taxNo","helpers","accounts")

    
/*     this.clStoreAccounts = new ClStore(this.cs, "accounts", "helpers")
    this.clStoreAccounts.clDataOptions.page.limit = 15
    this.clStoreAccounts.clDataOptions.sort.field = "accountName"
    this.columns = [
      { name: 'taxNo', caption: 'Vergi No', width: 200, type: 'text', filter: null },
      { name: 'accountName', caption: 'Ünvan', width: 250, type: 'text', filter: null },
    ]

    this.clStoreAccounts.pageLoad().then(o => {

    }) */
  }

  select(e) {

  }

}

