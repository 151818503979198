import { GlobalsService } from '../services/globals.service';
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'kerzzCurrency'
})
export class KerzzCurrencyPipe implements PipeTransform {
  constructor(private glb: GlobalsService) {

  }
  transform(value: number) {
    return new CurrencyPipe(this.glb.lang).transform(value, this.glb.currency)
  }

}
