import { Injectable } from '@angular/core';
import { GenericDataService } from 'projects/cloud-components/src/lib/database/generic-data.service';
import { CloudDbService } from 'cloud-labs-core';

@Injectable({
  providedIn: 'root'
})
export class InvoiceSettingsService extends GenericDataService {

  collection = "invoice-settings"
  // db="218"

  serials: InvoiceSerial[] = []

  data: invoiceSettings[] = []
  settings: invoiceSettings = new invoiceSettings()
  constructor(public cs: CloudDbService) {
    super(cs)



  }

  changeDatabase() {
    this.settings = new invoiceSettings()
    this.data = []
    this.pagedData = []
    if (this.subject.action = "loaded") {
      this.superLoad()
    } 
  }

  superLoad() {
    this.load().then(o => {
      this.loadSerials()
      if (o.length > 0) {
        this.settings = o[0]

        if (!this.settings.documentDesign) {
          let invDesign = new DocumentDesign()
          this.settings["documentDesign"] = invDesign
        }

        if (!this.settings.branches) {
          let invBranch: invoiceBranch = new invoiceBranch()
          this.settings["branches"] = []
          this.settings.branches.push(invBranch)
        }

        if (this.settings.branches.length == 0) {
          let invBranch: invoiceBranch = new invoiceBranch()
          this.settings["branches"] = []
          this.settings.branches.push(invBranch)
        }


        if (!this.settings.mailServer) {
          this.settings.mailServer = {
            host: "smtp.mail.com",
            port: 587,
            secure: false,
            auth: { user: "username", pass: "passwrod" }
          }
        }


      } else {
        this.settings = new invoiceSettings()
      }
    })
  }

  loadSerials() {
    this.cs.getCollectionToPromise({
      _db: this.db,
      col: "invoice-serials"
    }).then(o => {
      
      this.serials = o
    })
  }

  saveSerials() {

    return new Promise(async (resolve, reject) => {

      for (const iterator of this.serials) {
        debugger

        await this.cs.upsert({
          data: iterator,
          _db: this.db,
          col: "invoice-serials"
        })

      }

      resolve("ok")



    })


  }
}


export class invoiceSettings {
  id = "settings"
  wsuserName = ""
  wspassword = ""
  wslink = ""

  companyName = ""
  city = ""
  town = ""
  taxOffice = ""
  mail = ""
  taxNumber = ""
  phone = ""
  webSite = ""
  address = ""
  backofficeLicanceId =""
  registerNumber = "" // ticaret sicil
  mersisNo = ""
  licanceId = "" // kerzz licance id
  branches: invoiceBranch[] = []

  wsProvider: 'ibm' | 'uyumsoft' = "ibm"
  autoSendeArchive
  autoSendeInvoice
  autoSendEFolio
  invoiceFolioRowDetail
  erpApp: "kerzz" | "netsis" | "logo" = "logo"
  documentDesign = new DocumentDesign()
  eArchiveDefaultMail = ""
  mailServer = {
    host: "",
    port: 587,
    secure: false,
    auth: { user: "action@orwi.app", pass: "ccc" }
  }

  constructor() {
    if (this.branches.length == 0) {
      let invBranch: invoiceBranch = new invoiceBranch()
      this["branches"] = []
      this.branches.push(invBranch)
    }
  }
}

export class invoiceBranch {
  id: ""
  branchCode = "0"
  invoiceDesignCode = ""
}



export class DocumentDesign {
  showFolioDate = false //adisyon tarihi göster    
  showEcrInfo = false // yazarkasa bilgileri göster    
  showFolioNote = false // adisyon notu göster    
  bankAccountNo = "" // hesap numarası(iban)
  eDispatchShowTotals = false
}



export class InvoiceSerial {
  id = ""
  prefix = ""
  lastNumber = 0
  lastUse = new Date()
  branchCode = "0"
  documentType = "eArchive"
  default = false
  source = "erp"
  desc = "merkez"
  orjSerial = ""
  serial = ""
  year = ""
  editDate = new Date()
  creationDate = new Date()
}