<div class="mb-1-cover">
    <div class="mb-1-logo">Bex Coffee</div>
    <div class="mb-1-group">
        <div class=mb-1-group-name>Kahveler</div>
        <div class="mb-1-product" *ngFor="let item of filteredProduct">
            <div class="mb-1-product-name-cover">
                <div class=mb-1-group-name>{{item.name}}</div>
                <div class=mb-1-group-price>{{item.price}}₺</div>
            </div>
            <div class="mb-1-product-description">
                {{item.description}}
            </div>
        </div>
    </div>
</div>