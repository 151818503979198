import { GenericDataService } from 'projects/cloud-components/src/lib/database/generic-data.service';
import { Injectable } from '@angular/core';
import { CloudDbService } from 'cloud-labs-core';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService extends GenericDataService {

  public pretty_data : customer = new customer()
  constructor(public cs: CloudDbService) {
    super(cs)
  }




}

export class customer {
  id: string
  phone: string
  email: string
  name: string
  address: address[]
}

export class address {
  name: string
  street: string
  reciepe: string
  state: string
  city: string
  town: string
  code: string
}