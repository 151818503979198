import { GenericDataService } from '../../../../projects/cloud-components/src/lib/database/generic-data.service';
import { CloudDbService } from 'cloud-labs-core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SegmentsService extends GenericDataService  {

  collection = "loyalty-segments"

  data: segment[]

  constructor(public cs: CloudDbService) {
    super(cs)
  }

}


export class segment {
  id: string = ""
  name: string = ""
  minPoint: number = 0
  maxPoint: number = 0
  multipler: number = 0

}

