<div style="overflow:scroll; height:100vh;">
    <div class="card">

        <dx-tree-list id="afaqs" [dataSource]="this.faqService.data" keyExpr="id" parentIdExpr="parentID" [showRowLines]="true" [showBorders]="true" [columnAutoWidth]="true" [expandedRowKeys]="[1]" (onRowInserting)="initRow($event)" (onRowRemoving)="deleteRow($event)" (onRowUpdated)="updateRow($event)"
            (onRowInserted)="insertRow($event)">
            <dxo-selection mode="single"></dxo-selection>
            <dxo-editing [useIcons]="true" mode="popup" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
                <dxo-popup title="Group Info" [showTitle]="true" [position]="{ my: 'top', at: 'top', of: 'window' }">
                </dxo-popup>
            </dxo-editing>

            <dxi-column type="buttons" [width]="110">
                <dxi-button name="edit"></dxi-button>
                <dxi-button name="delete"></dxi-button>
                <dxi-button name="add"></dxi-button>
            </dxi-column>

            <dxi-column dataField="id" caption="ID" [formItem]="{visible: true}" [visible]="false">
            </dxi-column>

            <dxi-column dataField="question" caption="{{'Question' | translate}}">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>


            <dxi-column dataField="answer" caption="{{'Answer' | translate}}">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>

            <dxi-column dataField="hide" dataType="boolean" caption="{{'Hide' | translate}}">
            </dxi-column>

            <dxi-column dataField="order" dataType="number" caption="{{'Order' | translate}}">
            </dxi-column>

            <dxi-column dataField="parentID" [formItem]="{visible: true}" caption="parent ID" [visible]="false">
            </dxi-column>

            <dxi-column dataField="image" [width]="60" caption="{{'Image' | translate}}" [allowFiltering]="false" [visible]="true" [formItem]="{visible: false}" [hidingPriority]="4" [allowSorting]="false" cellTemplate="cellTemplate"></dxi-column>

            <div *dxTemplate="let data of 'cellTemplate'">
                <img class="avatar" [src]="data.data.image" (click)="imageClick(data)" />
                <button class="upload-button" (click)="uploadImage(data)">Y</button>
            </div>

        </dx-tree-list>

    </div>

    <div style="height: 200px;">

    </div>
</div>