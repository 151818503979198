import { Component, ElementRef, Input, OnInit, OnDestroy } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ModalService } from "../modal.service";

@Component({
  selector: "jw-modal",
  styleUrls: ["./modal.component.scss"],
  template: `<div class="jw-modal animated fadeIn">
      <div class="jw-modal-body">
        <ng-content></ng-content>
      </div>
    </div>
    <div class="jw-modal-background"></div>`,
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  private element: HTMLElement;
  isVisible = false;
  modalState = new BehaviorSubject(false);

  constructor(private modalService: ModalService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    let modal = this;

    // ensure id attribute exists
    if (!this.id) {
      console.error("modal must have an id");
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener("click", function (e: any) {
      if (e.target.className === "jw-modal") {
        modal.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open modal
  open(className?: string): void {
    this.element.style.display = "block";
    document.body.classList.add("jw-modal-open");
    console.log("Type of", typeof this.element);
    if (className)
      this.element.classList.add(...className.trim().split(" "));
    this.isVisible = true;
    this.modalState = new BehaviorSubject(false);
  }

  // close modal
  close(): void {
    this.element.style.display = "none";
    document.body.classList.remove("jw-modal-open");
    this.isVisible = false;
    this.modalState.next(true);
    this.modalState.complete();
  }

  onDidMiss() {
    return this.modalState.toPromise();
  }
}
