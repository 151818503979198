import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ModalService } from '../modal/modal.service';
import { UploadComponent } from '../upload/upload.component';

@Component({
  selector: 'app-cl-image-upload',
  templateUrl: './cl-image-upload.component.html',
  styleUrls: ['./cl-image-upload.component.scss']
})
export class ClImageUploadComponent implements OnInit {

  @Input() value: string
  @Output() valueChange = new EventEmitter<string>()
  @Input() initalWidth: string
  @Input() initalHeight: string
  @Input() allowedExtensions = []
  @Input() maxSize = 500 * 1024

  @Output() error = new EventEmitter();
  @Output() success = new EventEmitter();

  @ViewChild('imageUpload', { static: true }) upload: UploadComponent
  @ViewChild('itemImage', { static: true }) itemImage: ElementRef;


  type  ="image/png"
  image_ok = false
  error_message = ""

  divWith = "500px"
  divHeight = "500px"


  constructor(private modalService: ModalService) { }

  ngOnInit() {
    
  }
  
  ngAfterViewInit() {
   
  }

  init() {
    
    this.error_message = ""

    if (this.initalWidth) {
      let w = parseInt( this.initalWidth.replace("px",""))
      let h = parseInt( this.initalHeight.replace("px",""))
      h = h + 180
      w = w + 50
      this.divWith = w + "px"
      this.divHeight = h + "px"
    } else {
      this.divWith =  "500px"
      this.divHeight =   "500px"
    }
   



    if (this.value && this.value.startsWith("data:")) {
      this.image_ok = true
    } else {
      this.image_ok = true
    }


  }

  closeUpload() {
    this.success.emit(this.value)
    this.modalService.close("image-upload")
  }


  cancelUpload() {
    this.modalService.close("image-upload")
  }

  setbase64(e) {
    this.value = "data:" + this.type  +";base64," +  e
  }

  clearImage() {
    this.value = ""
    this.success.emit(this.value)
    this.modalService.close("image-upload")
  }


  fileInfo(e) {

    this.type = e.type
    this.image_ok = true
    if (e.size > this.maxSize) {
      this.image_ok = false
      this.error_message = "file size is big.."
      this.error.emit(this.error_message)
    } else {
      this.error_message = ""
    }

  }

  uploadImage() {
    this.upload.showUpload()
  }

  itemImageOnload(e) {

    let w = (this.itemImage.nativeElement as HTMLImageElement).width + "px"
    let h = (this.itemImage.nativeElement as HTMLImageElement).height + "px"

    if (this.initalWidth) {
      if (((w == this.initalWidth) && (h == this.initalHeight)) == false) {
        this.error_message = "image must be W: " + this.initalWidth + " H: " + this.initalHeight
        this.error_message += " it's W:" + w + " H:" + h 
        this.error.emit(this.error_message)
        this.image_ok = false
      } else {
        this.error_message = ""
      }
    }
    
  }




}
