<div>


  <table class="cl-grid" style="table-layout: fixed" role="presentation">
    <colgroup style>
      <col [ngStyle]="{'width': colitem.width + 'px'}" *ngFor="let colitem of cols; index as cindex; last as last">
    </colgroup>
    <tbody role="presentation">
      <tr class="cl-grid-row" style="height: 40px; font-size: 14px; border:1px solid gray;" role="row"
        aria-selected="false">
        <td (click)="sort(colitem)" *ngFor="let colitem of cols; index as cindex; last as last">
          <div class="cl-header-col">
            <div class="cl-grid-header-text">
              {{colitem.caption}}
            </div>
            <div *ngIf="sortField == colitem.name" style="padding-right: 5px;">
              <i *ngIf="sortDirection == 1" class="fas fa-sort-amount-down-alt"></i>
              <i *ngIf="sortDirection == -1" class="fas fa-sort-amount-up-alt"></i>
            </div>
          </div>
        </td>
      </tr>


      <tr class="cl-grid-row" style="border: 1px solid gray;" role="row" aria-selected="false">
        <td *ngFor="let colitem of cols; index as cindex; last as last">
          <div class="cl-header-col">
            <i style="margin-right: 5px;" class="fas fa-filter"></i>
            <cl-drop-box [bottomLine]="false" style="width: 100%" [ngStyle]="{'min-width': colitem.width + 'px'}"
              [(value)]="colitem.filter" [list]="colitem.data.source" [displayExpr]="colitem.data.displayExpr"
              [valueExpr]="colitem.data.valueExpr" [id]="cindex +  '-filter'"
              (valueChange)="filter(colitem.name,$event)" *ngIf="colitem.type == 'combo'">
            </cl-drop-box>

            <app-cl-text-box [bottomLine]="false" style="width: 100%" [ngStyle]="{'min-width': colitem.width + 'px'}"
              *ngIf="colitem.type !== 'combo' && colitem.type !== 'image'" [eventsEnabled]="false"
              (objChanged)="filter(colitem.name,$event)" [id]="cindex +  '-filter'" [(value)]="colitem.filter">
            </app-cl-text-box>
          </div>
        </td>
      </tr>

    </tbody>
  </table>

  <div style="width: 100%; height: 500px; overflow: auto;  will-change: transform">
    <table class="cl-grid" style="table-layout: fixed" role="presentation">
      <colgroup style>
        <col [ngStyle]="{'width': colitem.width + 'px'}" *ngFor="let colitem of cols; index as cindex; last as last">
      </colgroup>
      <tbody role="presentation">
        <tr class="cl-grid-row" *ngFor="let item of this.clStore.pagedData; index as jindex; last as lastrow" role="row"
          aria-selected="false">
          <td *ngFor="let colitem of cols; index as cindex; last as last">

            <div *ngIf="!readonly && pageSize <= 15">

              <div *ngIf="colitem.type == 'image'" style="display: flex; margin: auto;">
                <button class="cl-image-column-button" (click)="showUpload(item,colitem.name)">Resim</button>
              </div>

              <cl-drop-box [bottomLine]="false" style="width: 100%" [ngStyle]="{'min-width': colitem.width + 'px'}"
                [(value)]="item[colitem.name]" [list]="colitem.data.source" [displayExpr]="colitem.data.displayExpr"
                [valueExpr]="colitem.data.valueExpr" [id]="cindex +  '-' + jindex" (onFocus)="focus(cindex, jindex)"
                *ngIf="colitem.type == 'combo'">
              </cl-drop-box>

              <app-cl-text-box [bottomLine]="false" style="width: 100%" [ngStyle]="{'min-width': colitem.width + 'px'}"
                *ngIf="colitem.type !== 'combo' && colitem.type !== 'image'" [eventsEnabled]="false"
                (objChanged)="obj($event, item)" [id]="cindex +  '-' + jindex" (onFocus)="focus(cindex, jindex)"
                [(value)]="item[colitem.name]"></app-cl-text-box>
            </div>

            <div *ngIf="readonly || pageSize > 15">
              {{item[colitem.name]}}
            </div>

          </td>
        </tr>
        <tr [style.height.px]="500 - (this.clStore.pagedData.length*31)">
          <td *ngFor="let colitem of cols; index as cindex; last as last">
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <table class="cl-grid" style="table-layout: fixed" role="presentation">
    <colgroup style>
      <col [ngStyle]="{'width': colitem.width + 'px'}" *ngFor="let colitem of cols; index as cindex; last as last">
    </colgroup>
    <tbody role="presentation">
      <tr style="border: 1px solid gray">
        <td [attr.colspan]="this.cols.length">
          <div style="display: flex;
          justify-content: space-between;
          height: 30px;
          align-items: center;
          /* padding: 10px;">
            <div>
              {{totalCount}} kayıt
            </div>
            <img *ngIf="loading" class="loading-image" src="/assets/loading-h.gif" />





            <div style="display: flex;">
              <i (click)="gotoPrev()" style="font-size: 20px; padding-right: 15px;" class="fas fa-caret-left "></i>

              <div>
                <select #selecti (change)="gotoPage($event)">
                  <option [attr.selected]="item==this.activePage ? true : null" [value]="item"
                    *ngFor="let item of pages">{{item}}</option>
                </select>
              </div>

              <!-- <div *ngIf="pages.length < 11111" style="display: flex;" style="margin-right: 10px">
                <div *ngFor="let item of pages">
                  <div (click)="gotoPage(item)" class="cl-grid-pager-number"
                    [ngClass]="{'cl-page-selected': item == activePage}">
                    {{item}}
                  </div>
                </div>
              </div> -->

              <i (click)="gotoNext()" class="fas fa-caret-right " style="font-size: 20px; padding-left: 15px;"></i>
            </div>

          </div>
        </td>
      </tr>
    </tbody>
  </table>


  <div class="cl-flex-grid-cover" *ngIf="cols">


    <!-- HEADER -->

    <!-- <div class="cl-flex-grid-row cl-header-row">
      <div (click)="sort(colitem)" class="cl-flex-grid-col cl-header-col cl-v-line-left cl-h-line-top cl-h-line-bottom"
        [ngClass]="{ 'cl-v-line-right':last }" [ngStyle]="{'min-width': colitem.percent + '%'}"
        *ngFor="let colitem of cols; index as cindex; last as last">
        <div class="cl-grid-header-text">
          {{colitem.caption}}
        </div>
        <div *ngIf="sortField == colitem.name" style="padding-right: 5px;">
          <i *ngIf="sortDirection == 1" class="fas fa-sort-amount-down-alt"></i>
          <i *ngIf="sortDirection == -1" class="fas fa-sort-amount-up-alt"></i>
        </div>
      </div>
    </div> -->


    <!-- FILTER -->
    <!-- <div class="cl-flex-grid-row" style="margin-top:2px">
      <div class="cl-flex-grid-col cl-v-line-left cl-h-line-bottom" [ngStyle]="{'min-width': colitem.percent + '%'}"
        [ngClass]="{ 'cl-v-line-right':last }" *ngFor="let colitem of cols; index as cindex; last as last">
        <i style="margin-left: 10px;" class="fas fa-filter"></i>
        <cl-drop-box [bottomLine]="false" style="width: 100%" [ngStyle]="{'min-width': colitem.width + 'px'}"
          [(value)]="colitem.filter" [list]="colitem.data.source" [displayExpr]="colitem.data.displayExpr"
          [valueExpr]="colitem.data.valueExpr" [id]="cindex +  '-filter'" (valueChange)="filter(colitem.name,$event)"
          *ngIf="colitem.type == 'combo'">
        </cl-drop-box>

        <app-cl-text-box [bottomLine]="false" style="width: 100%" [ngStyle]="{'min-width': colitem.width + 'px'}"
          *ngIf="colitem.type !== 'combo' && colitem.type !== 'image'" [eventsEnabled]="false"
          (objChanged)="filter(colitem.name,$event)" [id]="cindex +  '-filter'" [(value)]="colitem.filter">
        </app-cl-text-box>
      </div>
    </div> -->

    <!-- DATA -->
    <!--     <div style="max-height: 500px; width: 100%; overflow:auto;">

      <div class="cl-flex-grid-row" *ngFor="let item of this.clStore.pagedData; index as jindex; last as lastrow">

        <div class="cl-flex-grid-col cl-v-line-left" [ngStyle]="{'min-width': colitem.percent + '%'}"
          [ngClass]="{ 'cl-v-line-right':last, 'cl-h-line-bottom': lastrow }"
          *ngFor="let colitem of cols; index as cindex; last as last; first as first">

          <div *ngIf="colitem.type == 'image'" style="display: flex; margin: auto;">
            <button class="cl-image-column-button" (click)="showUpload(item,colitem.name)">Resim</button>
          </div>

          <cl-drop-box [bottomLine]="false" style="width: 100%" [ngStyle]="{'min-width': colitem.width + 'px'}"
            [(value)]="item[colitem.name]" [list]="colitem.data.source" [displayExpr]="colitem.data.displayExpr"
            [valueExpr]="colitem.data.valueExpr" [id]="cindex +  '-' + jindex" (onFocus)="focus(cindex, jindex)"
            *ngIf="colitem.type == 'combo'">
          </cl-drop-box>

          <app-cl-text-box [bottomLine]="false" style="width: 100%" [ngStyle]="{'min-width': colitem.width + 'px'}"
            *ngIf="colitem.type !== 'combo' && colitem.type !== 'image'" [eventsEnabled]="false"
            (objChanged)="obj($event, item)" [id]="cindex +  '-' + jindex" (onFocus)="focus(cindex, jindex)"
            [(value)]="item[colitem.name]"></app-cl-text-box>
        </div>

      </div>

    </div> -->
  </div>



  <!-- 
  <table class="cl-grid">
    <col width="{{item.width}}" *ngFor="let item of cols">
    <thead>
      <tr>
        <th *ngFor="let item of cols">{{item.caption}}</th>
      </tr>
    </thead>
    <tbody>


      <tr *ngFor="let item of this.dataService.pagedData; index as jindex" class="cl-grid-row">
        <td *ngFor="let colitem of cols; index as cindex; last as last" (click)="select(item)">
          <div *ngIf="colitem.type == 'image'"><button class="cl-image-column-button"
              (click)="showUpload(item,colitem.name)">Resim</button></div>
          <cl-drop-box [(value)]="item[colitem.name]" [list]="colitem.data.source"
            [displayExpr]="colitem.data.displayExpr" [valueExpr]="colitem.data.valueExpr"
            *ngIf="colitem.type == 'combo'">
          </cl-drop-box>
          <app-cl-text-box *ngIf="colitem.type !== 'combo' && colitem.type !== 'image'" [eventsEnabled]="true"
            (objChanged)="obj($event, item)" [id]="cindex +  '-' + jindex" (onFocus)="focus(cindex, jindex)"
            [(value)]="item[colitem.name]"></app-cl-text-box>
        </td>
      </tr>
    </tbody>

    
    
    <ng-container *ngTemplateOutlet="ItemTemplate ? ItemTemplate : def; context: {data: item}">
  </ng-container>
  
  <ng-template #def> 
    <div (click)="select(item)">
      <div style="padding: 5px;">
        {{item.name}} 
      </div>
      <div style="padding: 5px;">
        
      </div>
    </div> -->
  <!-- </ng-template>


  </div> -->
  <!--     
  </table> -->

  <!--   <div class="cl-grid-pager">

    <div style="margin-left: 10px;">
      {{totalCount}} kayıt

    </div>
    <img *ngIf="loading" class="loading-image" src="/assets/loading-h.gif" />
    <div *ngIf="pages.length < 11" style="display: flex;" style="margin-right: 10px">
      <div *ngFor="let item of pages">
        <div (click)="gotoPage(item)" class="cl-grid-pager-number" [ngClass]="{'cl-page-selected': item == activePage}">
          {{item}}
        </div>
      </div>
    </div>


    <div *ngIf="pages.length > 10 && pages.length < 51">
      <select #selecti (change)="gotoPage(selecti.value)">
        <option *ngFor="let item of pages" value="{{item}}">{{item}}</option>
      </select>
    </div>

    <div *ngIf="pages.length > 50">

    </div>

  </div> -->
</div>
<jw-modal id="image-upload">
  <app-cl-image-upload [(value)]="activeImage" #clUpload initalWidth="400px" initalHeight="300px" [maxSize]="500*1024"
    (error)="error($event)" (success)="success($event)"></app-cl-image-upload>
</jw-modal>