<div style="overflow:scroll; height:100vh;">
    <div class="section-title">
        {{"Pay Types" | translate}}
    </div>

    <div class="card">

        <dx-data-grid id="gridContainer" [dataSource]="prnService.data" [showBorders]="true" style="margin:auto;" (onInitNewRow)="initRow($event)" (onRowInserted)="insertedRow($event)" (onRowUpdated)="updatedRow($event)">
            <dxo-filter-row [visible]="true" [applyFilter]=""></dxo-filter-row>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-search-panel [visible]="true" [width]="240" placeholder="Ara..."></dxo-search-panel>
            <dxo-editing mode="row" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
            </dxo-editing>
            <dxi-column dataField="name" [width]="200" caption="{{'Name' | translate}}"></dxi-column>
            <dxi-column dataField="type" [width]="150" caption="{{'Type' | translate}}"></dxi-column>
            <dxi-column dataField="path" [width]="200" caption="{{'Path' | translate}}"></dxi-column>
            <dxi-column dataField="description" [width]="150" caption="{{'Description' | translate}}"></dxi-column>
        </dx-data-grid>

    </div>

    <div style="height: 200px;">

    </div>
</div>