import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { NumericButtonsService } from '../../services/tools/numeric-buttons.service';
import { Component, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'app-numeric-buttons',
  templateUrl: './numeric-buttons.component.html',
  styleUrls: ['./numeric-buttons.component.css']
})
export class NumericButtonsComponent implements OnInit {

  @Input() type: string
  @Input() saveEnabled: boolean = false
  @Output() valueChanged = new EventEmitter()
  @Output() save = new EventEmitter()

  @Input() value: number = 0
  sValue: string = "0"
  constructor(public ns: NumericButtonsService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.value = this.ns.value
      this.sValue = String(this.ns.value)
    }, 1);

  }

  clicked(n) {

    if (n == "OK") {
      this.valueChanged.emit(this.ns.value)
      this.sValue = "0"
      this.ns.value = 0
    } else if (n == "SAVE") {
      this.save.emit(this.ns.value)
      this.sValue = "0"
      this.ns.value = 0
    } else {
      this.sValue = this.ns.value == 0 ? "0" : this.sValue
      this.sValue += n
      this.value = parseFloat(this.sValue)
      this.ns.value = this.value
    }

  }

  del(p) {
    this.sValue = "0"
    this.value = parseFloat(this.sValue)
    this.ns.value = 0
  }
}
