<div class="modif-container" [ngClass]="{'modif-selected':item.selected == true}" *ngFor="let modItem of getModifRows(item.id) let i = index;" [@listAnimation]="getModifRows(item.id).length">

    <div *ngIf="i == 0 && item.qty > 1">
        <div class="group-selector"> {{'1. Product' | translate}}</div>
    </div>

    <div *ngIf="i > 0 && item.qty > 1">
        <div *ngIf="getModifRows(item.id).length  && getModifRows(item.id)[i-1].qtyID !== modItem.qtyID" class="group-selector">{{modItem.qtyID}}. {{'Product' | translate}}</div>
    </div>

    <div class="modif" (click)="_changeModifier(item, modItem)" id="folio-row-{{modItem.id}}">
        <span class="name">
            {{modItem.qty}} * {{modItem.name}}
        </span>
        <span *ngIf="modItem.qty != 0" class="price">
            {{0 | kerzzCurrency}}
        </span>
    </div>


    <div class="modif-container" [ngClass]="{'modif-selected':item.selected == true}" *ngFor="let modItem2 of getModifRows(modItem.id)">
        <div class="modif" (click)="_changeModifier(modItem, modItem2)">
            <span class="name" id="folio-row-{{modItem2.id}}">
                {{modItem2.qty}} * {{modItem2.name}}
            </span>
            <span *ngIf="modItem.qty != 0" class="price">
                {{0 | kerzzCurrency}}
            </span>
        </div>

        <div class="modif-container" [ngClass]="{'modif-selected':item.selected == true}" *ngFor="let modItem3 of getModifRows(modItem2.id)">
            <div class="modif" (click)="_changeModifier(modItem2, modItem3)" id="folio-row-{{modItem3.id}}">
                <span class="name">
                    {{modItem3.qty}} *  {{modItem3.name}}
                </span>
                <span *ngIf="modItem.qty != 0" class="price">
                    {{0 | kerzzCurrency}}
                </span>
            </div>
        </div>
    </div>

</div>