import { Component, OnInit, ContentChildren, QueryList, ElementRef } from '@angular/core';
import { ClTextBoxComponent } from '../cl-text-box/cl-text-box.component';
import { ClSelectBoxComponent } from '../cl-select-box/cl-select-box.component';
import { ClDropBoxComponent } from '../cl-drop-box/cl-drop-box.component';
import { ClTextAreaComponent } from '../cl-text-area/cl-text-area.component';

@Component({
  selector: 'app-cl-form',
  template: '<ng-content></ng-content>'
  /* templateUrl: './cl-form.component.html',
  styleUrls: ['./cl-form.component.scss'] */
})
export class ClFormComponent implements OnInit {

  @ContentChildren(ClTextBoxComponent, { descendants: true }) clText: QueryList<ClTextBoxComponent>
  @ContentChildren(ClSelectBoxComponent, { descendants: true }) clSelect: QueryList<ClSelectBoxComponent>
  @ContentChildren(ClDropBoxComponent, { descendants: true }) clDrop: QueryList<ClDropBoxComponent>
  @ContentChildren(ClTextAreaComponent, { descendants: true }) clTextArea: QueryList<ClTextAreaComponent>


  textValid = true
  textAreaValid = true
  selectValid = true

  isValid = false

  constructor() { }

  ngOnInit() {



  }


  ngAfterViewInit() {

  }




  validate(): boolean {
    this.clText.forEach(el => {
      this.textValid = el.validate(el.value)
      if (!this.textValid) {
        return false
      }
    })

    this.clSelect.forEach(el => {
      this.selectValid = el.validate(el.value)
      if (!this.selectValid) {
        return false
      }
    })

    this.clTextArea.forEach(el => {
      this.textAreaValid = el.validate(el.value)
      if (!this.textAreaValid) {
        return false
      }
    })


    this.clDrop.forEach(el => {
      //el.validate(el.value)
    })


    this.isValid = this.textValid && this.textAreaValid && this.selectValid

    return this.isValid
  }

}
