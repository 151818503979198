import { map } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';
import { Component, OnInit, Input, Output } from '@angular/core';
import { GlobalsService } from '../../services/globals.service';

@Component({
  selector: 'app-button-menu',
  templateUrl: './button-menu.component.html',
  styleUrls: ['./button-menu.component.css']
})
export class ButtonMenuComponent implements OnInit {

  @Input() disabledAll: boolean = false
  @Input() type: string
  @Input() menu: menu[]
  @Input() selectionMode: string = "mutli"
  @Output() clicked = new EventEmitter



  constructor(private glb: GlobalsService) { }

  ngOnInit() {
  }

  _clicked(item: menu) {

    if (item.disabled || this.disabledAll) {
      return
    }

    this.glb.clickSound()
    this.clicked.emit(item)

    this._performSelection(item)
  }

  _performSelection(item: menu) {

    if (item.selectable) {

      if (this.selectionMode = "single") {
        this.menu.filter(c => c.tag != item.tag).map(p => {
          p.selected = false
        })
      }

      this.menu.filter(s => s.selectable == true).filter(c => c.tag == item.tag).map(p => {
        p.selected = p.selected ? false : true
      })

    }
  }
}


export class menu {
  tag: string
  title: any
  icon: string
  faicon?: string
  selected?: boolean
  disabled?: boolean
  selectable?: boolean
  color?: string
  type?: type = "payment"
  objectTag?: any
}

export type type = "payment" | "discount" | "save" | "cancel" | "close"