import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericDataService } from 'projects/cloud-components/src/lib/database/generic-data.service';
import { CloudDbService, IdGeneratorService } from 'cloud-labs-core';

@Injectable({
  providedIn: 'root'
})
export class CompanySettingsService extends GenericDataService {

  countries_loading : boolean = false
  cities_loading: boolean = false

  countries_list: any[] = []
  cities_list: any[] = []
  collection = "company-settings"
  data_object: company_settings = new company_settings()
  constructor(public cs: CloudDbService, private http: HttpClient, private ig: IdGeneratorService) {
    super(cs)
    this.load_countries()
  }


  load_countries() {
    this.countries_loading = true
    this.cs.getCollection({ _db: "helpers", col: "countries" }).subscribe(o => {
      this.countries_list = o
      this.countries_loading = false
    })
  }

  load_cities(id) {
    this.cities_loading = true
    this.cs.getCollection({ _db: "helpers", col: "cities", _filter: { iso2: id } }).subscribe(o => {
      this.cities_list = o
      this.cities_loading = false
    })
  }


  countries() {
    



  }

}

export class company_settings {

  legalName: string
  brand: string
  country: string
  city: string

}