import { Injectable } from "@angular/core";
import { ModalComponent } from "./modal/modal.component";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  constructor() {}

  private modals: any[] = [];

  add(modal: any) {
    // add modal to array of active modals
    this.modals.push(modal);
  }

  remove(id: string) {
    // remove modal from array of active modals
    this.modals = this.modals.filter((x) => x.id !== id);
  }

  open(id: string, className?: string) {
    // open modal specified by id
    let modal: ModalComponent = this.modals.filter((x) => x.id === id)[0];
    modal.open(className);

    return modal;
  }

  close(id: string) {
    // close modal specified by id
    let modal: any = this.modals.filter((x) => x.id === id)[0];
    modal.close();
  }
}
