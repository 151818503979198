<div class="master-div" id="master-div">

    <div *ngIf="qty.length > 1 && selectMustGroup == undefined" class="qty-cover">
        <app-info-box type="info" message="Her ürün için varyasyon seçmelisiniz, alt sıradaki butonlar kaçıncı ürün olduğunu gösterir!"></app-info-box>
        <div class="product-qty">
            <div *ngFor="let item of qty" class="animated  lightSpeedIn ">
                <dx-button class="qtyButton" [ngStyle]="{'background-color':activeQty === item.id ? 'var(--secondary)': 'var(--secondary-light)' }" [ngClass]="{'pulse':item.id == 1 }" text="{{item.id}}" (onClick)="changeQty(item.id)">
                </dx-button>
            </div>
        </div>
    </div>

    <!--  Modifier Groups -->
    <div id="p-groups" class="modifier-group" *ngIf="group.modif.length > 1 && allModifiers">
        <div *ngFor="let item of group.modif">
            <dx-button *ngIf="getRemainCount(item.index) > 0" class="group-button" [ngClass]="{'pulse':getRemain(item.index) == 0 }" icon="/assets/myicons/cancel.png" text="{{item.group}} ({{getGroupSelectedCount(item.index)}} /  {{item.maxSelect}} )" (onClick)="getData(item)">
            </dx-button>
            <dx-button *ngIf="getRemainCount(item.index) <= 0" class="group-button" [ngClass]="{'pulse':getRemain(item.index) == 0 }" icon="/assets/myicons/ok.png" text="{{item.group}} ({{getGroupSelectedCount(item.index)}} / {{item.maxSelect}} )" (onClick)="getData(item)">
            </dx-button>
        </div>
    </div>

    <!--  Sub Groups -->
    <div *ngIf="subGroups as sGroups; ">
        <div class="grp" *ngIf="sGroups.length; else noSubGroup" [@listAnimation]="sGroups.length">
            <div *ngFor="let item of sGroups">
                <div class="grp-item" (click)="subGroupClick(item)">
                    {{item.name}}
                </div>
            </div>
        </div>
    </div>

    <div class="okey-cancel-button">

        <div (click)="operationComplated()" class="menu-button" [ngStyle]="{'background-color':okeyDisabled() == true ? 'var(--primary-light)': 'var(--primary)' }" [ngClass]="{'animated flipInY':group != undefined}">
            <i class="material-icons md-24">note_add</i> {{'Ok' | translate}}
        </div>

        <div (click)="operationCancelled()" class="menu-button" [ngClass]="{'animated flipInY':group != undefined}">
            <i class="material-icons md-24">delete_forever</i> {{'Cancel' | translate}}
        </div>

    </div>


    <!--  Products -->
    <div id="p-products" *ngFor="let item of allGroups; let i = index;" [ngStyle]="{'margin-top': qty.length > 1 && i == 0 ? '190px' : '45px'}" style="width:100%;">

        <div class="modifier-group-title" id="modifier-group{{item.index}}">
            {{item.name}} ({{getGroupSelectedCount(item.index)}} / {{item.maxSelect}} )
        </div>

        <div class="product">
            <div *ngFor="let productItem of item.data" [ngClass]="{'animated flipInY':group != undefined}">
                <div class="product-item" [ngClass]="{'badge1': getProductCountOnFolio(productItem)> 0, 'selected-product' : getProductCountOnFolio(productItem) > 0, 'shake': productItem.forbidden}" [attr.data-badge]="getProductCountOnFolio(productItem)" hammertime (dblclick)="itemDblClick(productItem)"
                    (click)="itemClick(productItem)" (swipe)="itemDblClick(productItem)">
                    <div>
                        {{productItem.name}}
                    </div>
                    <div style="text-align:right">
                        {{productItem.price | kerzzCurrency}}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--  Products -->
    <div id="p-products" *ngIf="!allModifiers && filteredProducts  as prdct;" [ngClass]="{'animated zoomOutUp':selectMustGroup != undefined}">
        <div class="product" *ngIf="prdct.length" [@listAnimation]="prdct.length">
            <div *ngFor="let productItem of prdct">
                <div class="product-item" [ngClass]="{'badge1': getProductCountOnFolio(productItem)> 0, 'selected-product' : getProductCountOnFolio(productItem) > 0, 'shake': productItem.forbidden}" [attr.data-badge]="getProductCountOnFolio(productItem)" (click)="itemClick(productItem)"
                    (dblclick)="itemDblClick(productItem)">
                    {{productItem.name}}
                    <br> {{productItem.price | kerzzCurrency}}
                </div>
            </div>
        </div>
    </div>




</div>
<!--  Sub Modifiers -->
<div *ngIf="selectMustGroup != undefined" [ngClass]="{'animated zoomOutUp':selectMustGroup == undefined}">
    <app-select-must-group (selected)=modifierGet($event) (cancel)="cancelModifier()" [parentID]="subParentID" [group]="selectMustGroup" [deep]="deep + '1'" [isSub]="true" [changeModifiers]="editModifiers"></app-select-must-group>
</div>