import { Component, OnInit, Input, Output, ContentChild, TemplateRef } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-cl-base',
  template: '<ng-content></ng-content>'
})
export class ClBaseComponent implements OnInit {

  _focus = false
  oldValue
  isValid = true
  changeEvnt = new changeEvent()

  //@ContentChild(TemplateRef, { static: true })
  @Input() ItemTemplate: TemplateRef<any>
  
  @Input() id
  @Input() value: any
  @Input() label = ""
  @Input() ilabel = ""
  @Input() required = false
  @Input() eventsEnabled = false
  @Input() characterCase: characterCase = "none"
  @Input() bottomLine = true
  @Input() theme :theme = "none"

  @Output() valueChange = new EventEmitter<any>()
  @Output() objChanged = new EventEmitter<changeEvent>()

  @Output() onBlur = new EventEmitter<any>()
  @Output() onFocus = new EventEmitter<any>()

  constructor() { }


  ngOnInit() {

  }

  validate(val) : boolean  {
    this.isValid = true //this.required ? !isNullOrUndefined(val) && (val !== '') : true
    return this.isValid
  }

  blur(e) {
    this.onBlur.emit(e.target.value)
    this._focus = false
  }

  focus(e) {
    this.onFocus.emit(e.target.value)
    this._focus = true
  }
}

export type characterCase = 'none' | 'lower' | 'upper' | 'capitalize'

export class changeEvent {
  previousValue
  value
  info = ""
  loading = false
  valid = true
}

export type theme = 'none' | 'light' | 'dark'