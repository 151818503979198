<!-- <div class="sub-header">
    <span>
        {{'Modifier definition' | translate}}
    </span>
</div> -->


<div style="overflow:scroll; height:100vh;">
    <div class="section-title" style="height:100px !important;">
        {{'Modifiers' | translate}}
    </div>


    <div class="card" style="width: 900px !important">
        <div style="display:flex; flex-direction: row; justify-content:space-evenly;">
            <div class="dx-fieldset" style="margin:0">
                <div class="dx-fieldset-header" style="width: 250px;"> {{'Select Product' | translate}}</div>
                <div class="dx-field">
                    <dx-lookup #productLookup [(value)]="lookupProduct" [dataSource]="this.ps.data" [closeOnOutsideClick]="true" [showPopupTitle]="false"
                        displayExpr="name" (onValueChanged)="productOrGroupSelected($event,'product')">
                    </dx-lookup>

                </div>
            </div>

            <div class="dx-fieldset" style="margin:0">
                <div class="dx-fieldset-header" style="width: 250px;"> {{'Select Group' | translate}}</div>
                <div class="dx-field">
                    <dx-lookup #productGroupLookup [(value)]="lookupProductGroup" [dataSource]="this.pg.data" [closeOnOutsideClick]="true" [showPopupTitle]="false"
                        displayExpr="name" (onValueChanged)="productOrGroupSelected($event,'productGroup')">
                    </dx-lookup>
                </div>
            </div>
        </div>





        <div class="modifiers-view">





            <div *ngIf="showAll">


                <div *ngIf="false" style="padding-top:3px;padding-left:20px;padding-right:20px;padding-bottom:5px;">
                    <dx-tabs [dataSource]="tabs" [selectedIndex]="0" (onItemClick)="selectTab($event)"></dx-tabs>
                </div>


                <div *ngIf="showSimpleModifiers">
                    Simple Modifiers
                </div>

                <div *ngIf="showGroupModifiers">


                    <div class="group-modifier-container">
                        <div style="display: flex;
                        justify-content: space-around;
                        padding: 15px;
                        border-radius: 8px;
                        margin: 15px;">
                            <dx-button icon="/assets/myicons/groups.png" text=" {{'Add Group Modifiers' | translate}}" (onClick)="addGroupModifier()">
                            </dx-button>
                            <dx-button icon="/assets/myicons/products.png" text="{{'Add Product Modifiers' | translate}}" (onClick)="addProductModifier()">
                            </dx-button>
                            <dx-button icon="/assets/myicons/custom.png" text=" {{'Add Custom Modifiers' | translate}}" (onClick)="addCustomModifier()">
                            </dx-button>
                        </div>



                        <div [@listAnimation]="modifiers.modif.length" style="
                        border-radius: 8px;
                        padding: 5px;
                        margin: 15px;">

                            <div *ngFor="let item of modifiers.modif let modiferIndex=index">

                                <!-- Group Modifier -->
                                <div class="modifier-grid" *ngIf="item.type == 'group'">
                                    <div class="item-command">
                                        <dx-button icon="/assets/myicons/delete.png" text="" (onClick)="removeModifier(modiferIndex)">
                                        </dx-button>
                                    </div>
                                    <div class="item-type">
                                        {{item.type}}
                                    </div>
                                    <div class="item-name">
                                        <dx-text-box [(value)]="item.group" placeholder=" {{'Group Name' | translate}}" [showClearButton]="true">
                                        </dx-text-box>

                                        <app-must-auto-selection [item]="item"></app-must-auto-selection>

                                    </div>
                                    <div class="item-data">

                                        <dx-lookup width=100% valueExpr="id" [dataSource]="this.pg.data" [closeOnOutsideClick]="true" [(value)]="item.productGroup"
                                            [showPopupTitle]="false" displayExpr="name">
                                        </dx-lookup>


                                    </div>

                                </div>

                                <!-- Product Modifier -->
                                <div class="modifier-grid" *ngIf="item.type == 'product'">
                                    <div class="item-command">
                                        <dx-button icon="/assets/myicons/delete.png" text="" (onClick)="removeModifier(modiferIndex)">
                                        </dx-button>
                                    </div>
                                    <div class="item-type">
                                        {{item.type}}
                                    </div>
                                    <div class="item-name">
                                        <dx-text-box [(value)]="item.group" placeholder=" {{'Group Name' | translate}}" [showClearButton]="true">
                                        </dx-text-box>
                                        <app-must-auto-selection [item]="item"></app-must-auto-selection>
                                    </div>
                                    <div class="item-data">
                                        <div *ngFor="let prdoductItem of item.products let prdIndex=index ">
                                            <div class="modifier-product-lookup">
                                                <div>
                                                    <dx-button icon="/assets/myicons/add.png" text="" (onClick)="addProduct(item.products)">
                                                    </dx-button>
                                                </div>
                                                <div>
                                                    <dx-button icon="/assets/myicons/delete.png" text="" (onClick)="removeProduct(item.products, prdIndex)">
                                                    </dx-button>
                                                </div>
                                                <div style="display:flex; flex-direction: row">
                                                    <dx-lookup style="width:200px" valueExpr="id" [dataSource]="this.ps.data" [closeOnOutsideClick]="true" [(value)]="prdoductItem.productID"
                                                        [showPopupTitle]="false" (onSelectionChanged)="price.value = $event.selectedItem.price"
                                                        displayExpr="name">
                                                    </dx-lookup>
                                                    <app-must-auto-selection type="product" [showPrice]="!item.priceless" [item]="prdoductItem"></app-must-auto-selection>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Custom Modifier -->
                                <div class="modifier-grid" *ngIf="item.type == 'custom'">
                                    <div class="item-command">
                                        <dx-button icon="/assets/myicons/delete.png" text="" (onClick)="removeModifier(modiferIndex)">
                                        </dx-button>
                                    </div>

                                    <div class="item-type">
                                        {{item.type}}
                                    </div>
                                    <div class="item-name">
                                        <dx-text-box [(value)]="item.group" placeholder="Grup Adı" [showClearButton]="true">
                                        </dx-text-box>
                                        <app-must-auto-selection [item]="item"></app-must-auto-selection>
                                    </div>
                                    <div class="item-data">
                                        <div *ngFor="let prdoductItem of item.products let customIndex=index ">
                                            <div class="modifier-product-lookup">
                                                <div>
                                                    <dx-button icon="/assets/myicons/add.png" text="" (onClick)="addProduct(item.products)">
                                                    </dx-button>
                                                </div>
                                                <div>
                                                    <dx-button icon="/assets/myicons/delete.png" text="" (onClick)="removeProduct(item.products, customIndex)">
                                                    </dx-button>
                                                </div>
                                                <dx-text-box style="width:200px" [(value)]="prdoductItem.productName" placeholder="Varyasyon Girin..." [showClearButton]="true">
                                                    <dxi-validation-rule type="required">
                                                    </dxi-validation-rule>>
                                                </dx-text-box>
                                                <app-must-auto-selection type="product" [showPrice]="!item.priceless" [item]="prdoductItem"></app-must-auto-selection>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div style="padding:5px;display:flex;flex-direction:row;justify-content:flex-end">
                            <dx-button  type="danger" icon="fas fa-folder-minus" text=" {{'Delete All' | translate}}" (onClick)="deleteModifiers()">
                            </dx-button>&nbsp;&nbsp;
                            <dx-button  type="default" icon="fas fa-check-circle" text=" {{'Save' | translate}}" (onClick)="saveModifiers()">
                            </dx-button>&nbsp;&nbsp;
                            <!--  <dx-button stylingMode="outlined" icon="fas fa-trash" text="İptal" (onClick)="bos()">
                            </dx-button> -->
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
    <div style="height: 300px;">

    </div>


</div>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#grid' }" [(visible)]="loadingVisible" [showIndicator]="true"
    [showPane]="true" [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>