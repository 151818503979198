<div class="app-cover">
    <div>
        <app-veritcal-nav></app-veritcal-nav>
    </div>
    <div style="width: 100%;">
        <app-header></app-header>
        <router-outlet></router-outlet>
        <router-outlet name="print"></router-outlet>
    </div>
</div>


<lib-cl-modal-dialog></lib-cl-modal-dialog>