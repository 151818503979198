import { CloudLoginService } from 'cloud-labs-core';
import { GlobalsService } from './../globals.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class GuardService implements CanActivate {

  constructor(private router: Router, private glb: GlobalsService, private cls: CloudLoginService) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.cls.authStatus) { return true; }
    console.log('access denied!', state.url)
    this.glb.activeRoute = state.url
    this.router.navigate(['/login']);
    return false
  }
}
