import { folio } from "projects/cloud-folio/src/lib/models/folio"


export class stampWinLogs {
    uid: string
    folio: folio
    product() {
    }
}

export class stampCampaign {
    id: string
    name: string
    active: boolean
    stampNick: string
    pointNick: string
    conversionRate: number
    bannerStyle: string
  }

  export class bonusCampaign {
    id: string
    name: string
    active: boolean
    bonusNick: string
  }

