<div class="main-container" [ngClass]="{'zero-width' : this.hideMenu}">
    <div style="height: 50px;">

    </div>
    <div *ngFor="let item of menu">
        <div (click)="nav(item)" [ngClass]="{'menu-item' : item.enabled, 'menu-item-disabled' : !item.enabled}">
            <i *ngIf="!item.faicon" class="material-icons-outlined" style="padding-left:10px">{{item.micon}}</i>
            <i *ngIf="item.faicon" class="{{item.faicon}}"></i>
            <span class="menu-text">
                {{item.title}}
            </span>
        </div>
        <div *ngFor="let subitem of sub(item)">
            <div [ngClass]="{'sub-menu-item' : item.enabled, 'sub-menu-item-disabled' : !subitem.enabled}" [ngClass]="{'animated slideInLeftt': subitem.title != 'a'}" [ngClass]="{'animated slideOutLeftt': subitem.title != 'a'}" [ngStyle]="{'display':subactive(item) ? 'flex' : 'none' }"
                (click)="nav(subitem)">
                <i class="material-icons-outlined md-18" style="padding-left:35px">radio_button_checked</i>
                <span class="menu-text">
                    {{subitem .title}}
                </span>
            </div>
        </div>
    </div>
<!--     <div class="footer" style="font-size: 12px; text-align: center;">
        <img class="menu-icon" src="/assets/icons/092-shield.png" />
        <div style="margin: auto; padding:10px">
            1.8.0
        </div>
    </div> -->
</div>

<dx-popup class="popup" [width]="450" [height]="450" [showTitle]="true" title="{{'Select Company' | translate }}" [dragEnabled]="false" [closeOnOutsideClick]="true" [(visible)]="glb.showLicanceSelectPopup">
    <div *dxTemplate="let data of 'content'" style="overflow: scroll;">   
        <dx-scroll-view>
            <dx-text-box placeholder="Firma Arama..." width="100%" valueChangeEvent="keyup"
            (onValueChanged)="searchStore($event)">
        </dx-text-box>
            <div class="licance-select" *ngFor="let item of this.glb.filteredStores" (click)="changeCompany(item)">
                {{item.brand}}
            </div>
        </dx-scroll-view>     
    </div>
</dx-popup>


<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ my: 'center', at: 'center' }" [(visible)]="loading" [showIndicator]="true" [showPane]="true" [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>