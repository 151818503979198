import { Injectable } from "@angular/core";
import CustomStore from "devextreme/data/custom_store";
import { CloudDbService } from "cloud-labs-core";

@Injectable({
  providedIn: "root",
})
export class CloudDataStoreService {
  //dataSource: any = {};
  //store: CustomStore

  constructor(private cs: CloudDbService) {}

  createdDXSource(xKey, xDb, xCol): CustomStore {
    let store: CustomStore;
    let that = this;
    store = new CustomStore({
      key: xKey,
      load: function (loadOptions: any) {
        console.log("loadopt", loadOptions);

        let sort = that.createSort(loadOptions);
        let filter = that.createFilter(loadOptions);

        console.log(filter, sort);

        return new Promise((resolve, reject) => {
          that.cs
            .getCollectionToPromise({
              _limit: loadOptions.take,
              _skip: loadOptions.skip,
              _db: xDb,
              col: xCol,
              _filter: filter,
              _sort: sort,
              _withTotalCount: true,
            })
            .then((o) => {
              o["summary"] = [o.totalCount]; // total summary results
              console.log(o);
              resolve(o);
            })
            .catch((e) => {
              console.log(e);
              reject(e);
            });
        });
      },
      byKey: function (key) {
        return new Promise((resolve, reject) => {
          that.cs
            .getCollectionToPromise({
              _db: xDb,
              col: xCol,
              _filter: { TaxNo: key },
              _withTotalCount: false,
            })
            .then((o) => resolve(o));
        });
      },
    });

    return store;
  }

  private createSort(loadOptions: any) {
    let sort = {};
    if (loadOptions.sort) {
      sort[loadOptions.sort[0].selector] = loadOptions.sort[0].desc ? -1 : 1;
    }
    return sort;
  }

  private createFilter(loadOptions: any) {
    let filter = {};
    if (loadOptions.filter) {
      if (loadOptions.filter[0] instanceof Array) {
        filter = this.multipleFilter(loadOptions);
      } else if (loadOptions.filter[0] instanceof Array == false) {
        let field = loadOptions.filter[0];
        let condition = loadOptions.filter[1];
        let value = loadOptions.filter[2];
        filter = this.singleFilter(field, condition, value);
      }
    }

    if (loadOptions.searchExpr) {
      let search = {};
      if (loadOptions.searchOperation == "contains") {
        search = {
          $regex: ".*" + loadOptions.searchValue + ".*",
          $options: "i",
        };
      } else if (loadOptions.searchOperation == "startswith") {
        search = { $regex: "^" + loadOptions.searchValue, $options: "i" };
      }
      filter[loadOptions.searchExpr] = search;
    }

    return filter;
  }

  multipleFilter(loadOptions) {
    let filter = [];
    for (const element of loadOptions.filter) {
      if (element instanceof Array) {
        let field = element[0];
        let condition = element[1];
        let value = element[2];
        filter.push(this.singleFilter(field, condition, value));
      } else {
        console.log(element);
      }
    }
    let ps = {
      $and: filter,
    };
    return ps;
  }

  singleFilter(field, condition, value) {
    let search = {};
    let filter = {};

    if (condition == "contains") {
      search = { $regex: ".*" + value + ".*", $options: "i" };
    } else if (condition == "startswith") {
      search = { $regex: "^" + value, $options: "i" };
    } else if (condition == "endswith") {
      search = { $regex: value + "$", $options: "i" };
    } else if (condition == "=") {
      search = { $regex: "^" + value + "$", $options: "i" };
    } else if (condition == "notcontains") {
      search = { $regex: "^((?!" + value + ").)*$", $options: "i" };
    } else if (condition == ">") {
      search = { $gte: 1 };
    }

    filter[field] = search;
    return filter;
  }
}
