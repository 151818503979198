import { Injectable } from '@angular/core';
import { GenericDataService } from 'projects/cloud-components/src/lib/database/generic-data.service';
import { CloudDbService } from 'cloud-labs-core';

@Injectable({
  providedIn: 'root'
})
export class TownService extends GenericDataService {

  collection = "towns_tr"
  db =  "helpers"
  constructor(public cs: CloudDbService) {
    super(cs)
  }
}
