import { CloudFolioService } from './../../../../projects/cloud-folio/src/lib/cloud-folio.service';
import { Table } from '../../services/tables.service';
import { ActivatedRoute } from '@angular/router';
import { NumericButtonsService } from '../../services/tools/numeric-buttons.service';
import { GlobalsService } from '../../services/globals.service';
import { ProductGroupService, productGroup } from '../../product/product-groups/service/product-group.service';
import { ProductService, product } from '../../product/products/service/product.service';
import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { trigger, style, animate, transition, query, stagger, keyframes } from '@angular/animations';
import { AngularFirestore } from '@angular/fire/firestore';
import { modifiers, ModifiersService } from '../../product/modifiers/service/modifiers.service';
import { FolioComponent } from '../folio/folio.component';
import 'rxjs/add/operator/observeOn';
import { StartupService } from 'src/app/services/startup.service';
import { folio, folioRow } from 'projects/cloud-folio/src/lib/models/folio';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('200ms', style({ transform: 'translateX(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('200ms', style({ transform: 'translateX(100%)', opacity: 0 }))
      ])
    ]
    ),
    trigger('listAnimation', [
      transition('* <=> *', [
        query(':enter', style({ opacity: 0 }), { optional: true }),

        query(':enter', stagger('50ms', [
          animate('100ms ease-in', keyframes([
            style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
            style({ opacity: .5, transform: 'translateY(35px)', offset: 0.3 }),
            style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 }),
          ]))]), { optional: true })
        ,
        transition(':leave', [
          style({ transform: 'translateX(0%)' }),
          animate('100ms ease-in', style({ transform: 'translateX(-100%)' }))
        ])
      ])
    ]),
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('800ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('100ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ]),
    trigger('slideInOutFooter', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ])

    ])

  ]
})
export class MenuComponent implements OnInit, OnDestroy {
  @ViewChild('appfolio') appFolio: FolioComponent
  @ViewChild('container') container: ElementRef;
  @ViewChild('grpitem') grpItem: ElementRef;

  loadingVisible = true

  folio: folio
  activeOperation = "table"
  activeGroup: Array<{ id: number, group: string, groupID: string }> = []
  itemActive: boolean = false
  parentID: string
  editModifiers: any
  activeFoliorRow: folioRow
  productActive: boolean = true;
  selectMustGroup: modifiers
  selectRow: folioRow
  subGroups: productGroup[]
  products: product[]
  productGroups: productGroup[]
  products_no


  observable: Observable<any>
  constructor(
    public ms: ModifiersService,
    public ps: ProductService,
    public glb: GlobalsService,

    private ss: StartupService,
    private fls: CloudFolioService,
    private fs: AngularFirestore,
    private ns: NumericButtonsService,
    private pg: ProductGroupService,
    private route: ActivatedRoute,
    private eref: ElementRef,
  ) {


    this.route.params.subscribe(params => {
      if (params['type']) {
        if (params['type'] == "table") {
          let tbl = params['tableID']
        }
      }
    });

  }


  ngOnInit() {
    this.ps.load().then(() => {
      this.products = this.ps.data.filter(p => p.id == "na")
      this.products_no = this.ps.data
      this.pg.load().then(() => {
        this.productGroups = this.pg.data.filter(p => p.parentID == "0" && !p.hide)
        this.subGroups = this.pg.getSubGroups('')
        this.loadingVisible = false
      })
    })

    this.ms.getProductGroupModifiersFromMongo()
    this.ms.getProductModifiersFromMongo()

    //? POS SCREEN OSERVER
    this.ss.observer_pos_screen.subscribe(o => {
      if (o.id == "change-view") {
        this.activeOperation = o.data
        return
      }

      if (o.id == "expand-folio") {
        this.calculateGroupWitdh()
        return
      }
    })

  }

  ngOnDestroy() {

  }



  public levelGroups: Array<{ id: number, groups: productGroup[] }> = []

  groupClick(grp) {
    this.glb.clickSound()
    this.levelGroups = []

    this.subGroups = this.pg.getSubGroups(grp.id)
    this.products = this.ps.getProductsByGroup(grp.id)

    this.levelGroups.push({ id: 2, groups: this.subGroups })

    this.itemActive = true
    this.activeGroup = []
    this.activeGroup.push({ id: 1, group: grp.name, groupID: grp.id })
  }

  subGroupClick(grp, levelID) {
    this.glb.clickSound()
    let level: productGroup[] = this.pg.getSubGroups(grp.id)
    this.products = this.ps.getProductsByGroup(grp.id)

    this.levelGroups = this.levelGroups.filter(p => p.id != levelID + 1)
    this.activeGroup = this.activeGroup.filter(p => p.id < levelID)

    this.activeGroup.push({ id: levelID, group: grp.name, groupID: grp.id })
    this.levelGroups.push({ id: levelID + 1, groups: level })
    this.products = this.ps.getProductsByGroup(grp.id)
  }

  groupSelectorClick(item) {
    this.glb.clickSound()
    let ag = this.activeGroup.filter(o => o.id < item.id)
    if (ag.length == 0) {
      this.activeGroup = ag
      this.itemActive = false;
      this.products = this.ps.getProductsByGroup("")
    } else {
      this.activeGroup = ag
      let pg = ag[ag.length - 1].groupID
      this.products = this.ps.getProductsByGroup(pg)
    }
  }

  productClick(item) {
    this.glb.clickSound()
    

    // this.fls.createRow(this.fs.createId(), item.id, item.name, this.ns.getValue(),0,item.price,item.image)

    this.activeFoliorRow = new folioRow()
    this.activeFoliorRow.id = this.fs.createId()
    this.activeFoliorRow.name = item.name
    this.activeFoliorRow.qty = this.ns.getValue()
    this.activeFoliorRow.unitPrice = item.price
    this.activeFoliorRow.productID = item.id
    this.activeFoliorRow.itemImage = item.image
    this.activeFoliorRow.parentID = "0"
    this.fls.pushRow(this.activeFoliorRow)
    this.editModifiers = undefined
    this.selectRow = this.activeFoliorRow
    this.parentID = this.activeFoliorRow.id

    setTimeout(() => {
      this.fls.scrollTo(this.activeFoliorRow.id)
      this.fls.saveFolio()
    }, 100);

    this.productHasMustorAutoModifiers(item)
  }

  productHasMustorAutoModifiers(item: product) {
    this.selectMustGroup = this.ms.loadMustModifiers(item.group, item.id)

    this.initModifierSelection()
  }

  showModifiers(e: folioRow) {
    let xx = this.products_no.find(p => p.id == e.productID)
    this.parentID = e.id
    this.activeFoliorRow = e
    this.selectMustGroup = this.ms.loadModifiers(xx.group, e.productID)
    if (this.selectMustGroup.modif.length == 0) {
      this.selectMustGroup = undefined
      this.glb.notice("Bu ürünün varyasyonu yok!", "info")
    }
  }

  modifiersFinished(e: folioRow[]) {
    e.forEach(element => {
      element.parentID = this.activeFoliorRow.id
      this.fls.folio.rows.push(element)
    });
    this.selectMustGroup = undefined
    this.productActive = true
    //this.appFolio.closeCover()
    this.ss.subject_pos_screen.next({ id: "modifier-selection-active", data: false })

  }

  modifiersCancelled() {
    //this.appFolio.closeCover()
    this.selectMustGroup = undefined
    this.productActive = true
    this.ss.subject_pos_screen.next({ id: "modifier-selection-active", data: false })
  }

  folioDiv: HTMLElement;
  numpadButton: HTMLElement;



  searchProducts
  search(e) {
    if (e.length < 1) {
      this.searchProducts = undefined;
      return
    }
    this.searchProducts = this.products_no.filter(p => p.name != undefined).filter(p => p.name.toLowerCase().indexOf(e.toLowerCase()) !== -1)
  }

  selectedTable: Table
  tableSelected(tbl: Table) {
    this.selectedTable = tbl
    this.fls.createOrOpenFolio("table", tbl.id, tbl.name)
    setTimeout(() => {
      this.activeOperation = "menu"
      setTimeout(() => {
        this.calculateGroupWitdh()
      }, 100);
    }, 50);
  }

  calculateGroupWitdh() {
    return
    let width = this.grpItem.nativeElement.offsetWidth
    let mt = Math.floor(this.container.nativeElement.offsetWidth / width)
    let op = width * mt
    let kalan = this.container.nativeElement.offsetWidth - op
    let eklenecek = kalan / mt
    let xp = (width - 20) + eklenecek
    this.eref.nativeElement.style.setProperty('--group-button-width', xp + "px")
  }

  changeModifiers(e) {
    console.log(e)
    this.editModifiers = e.modifierRows
    this.selectMustGroup = this.ms.loadMustModifiers("nope", e.folioRow.productID)
    this.parentID = e.parentID

    this.initModifierSelection()
  }

  changeSubModifiers(e) {
    console.log(e)
    this.editModifiers = e.modifierRows
    this.selectMustGroup = this.ms.loadMustModifiers("nope", e.folioRow.productID)
    this.parentID = e.parentID
    //this.appFolio.cover()

    this.initModifierSelection()
  }


  initModifierSelection() {
    if (this.selectMustGroup.modif.length == 0) {
      //! modifiers not found
      this.selectMustGroup = undefined
    } else {
      this.productActive = false
      this.ss.subject_pos_screen.next({ id: "modifier-selection-active", data: true })
      this.ss.subject_veritcal_nav.next(true)
    }
  }

}
