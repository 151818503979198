import { CloudDbService } from 'cloud-labs-core';
import { GenericDataService } from '../../../../projects/cloud-components/src/lib/database/generic-data.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AgreementsService extends GenericDataService {
  data: agreements[] = []
  collection = "loyalty-agrements"
  constructor(public cs: CloudDbService) {
    super(cs)
  }
}


export class agreements {
  id: string = ""
  name: string = ""
  description: string = ""
}

export class selectBox {
  id: number
  name: string
}