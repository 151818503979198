import { CloudDbService, CloudLoginService } from "cloud-labs-core";

export class ClStore {
  constructor(
    private cs: CloudDbService,
    private collection: string,
    private db?: string,
    documentType?: any
  ) {
    this.documentType = documentType;
    this.initClStore().then((ic) => {
      if (this.clDataOptions.strategy == "load-all") {
        this.pageLoad().then((o) => {
          this.allData = o.data;
          this.filter();
        });
      }
    });
  }

  allData: any[] = [];
  pagedData: any[] = [];
  clDataOptions: clData = new clData();
  clDatasource: { totalCount: 0; data: [] };
  totalCount = 0;
  status: status = "none";
  documentType: any;

  filter(data?: undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.clDataOptions.strategy == "load-all") {
        this.filterLoaded(data).then((fd) => {
          resolve(fd);
        });
      } else {
        this.pageLoad().then((pd) => {
          resolve(pd);
        });
      }
    });
  }

  filterLoaded(filter): Promise<any> {
    return new Promise((resolve, reject) => {
      let fd = this.allData;

      if (filter) {
        fd = this.allData.filter((fd) => {
          let ops = String(
            fd[this.clDataOptions.filter.field]
          ).toLocaleUpperCase();
          return ops.indexOf(filter.toLocaleUpperCase()) > -1;
        });
      }

      if (this.clDataOptions.sort.field != "") {
        fd = fd.sort((a, b) =>
          a[this.clDataOptions.sort.field].localeCompare(b.name)
        );
      }

      console.log("fd", fd);

      if (this.clDataOptions.page.limit > 0) {
        let start: number = this.clDataOptions.page.start;
        let end: number =
          this.clDataOptions.page.start + this.clDataOptions.page.limit;
        fd = fd.slice(start, end);
        //this.clDataOptions.page.start = end
      }

      let resp = { data: fd, totalCount: this.allData.length };
      this.pagedData = fd;
      resolve(resp);
    });
  }

  pageLoad(init?): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.collection != undefined) {
        let filter = this.createFilter(this.clDataOptions.filter);
        let sort = this.createSort(this.clDataOptions.sort);

        let getParam = {
          _db: this.db,
          col: this.collection,
          _filter: {
            ...filter,
            ...{
              ...((this.collection == "accounts" && this.documentType)
                ? { documentType: this.documentType }
                : {}),
            },
          },
          _sort: sort,
          _limit: this.clDataOptions.page.limit,
          _skip: this.clDataOptions.page.start,
          _withTotalCount: true,
        };

        if (this.clDataOptions.strategy == "load-all") {
          getParam._limit = 0;
          getParam._filter = {};
        }
        if (!this.db) delete getParam._db;

        this.cs.getCollection(getParam).subscribe(
          (d) => {
            this.clDatasource = d;
            this.allData = d.data;

            this.totalCount = d.totalCount;
            console.log("totalCount", this.totalCount);
            resolve(d);
          },
          (e) => {
            this.clDatasource = { data: null, totalCount: 0 };
            reject(e);
          }
        );
      }
    });
  }

  private initClStore(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.db) return resolve(true);
      let filter = this.createFilter(this.clDataOptions.filter);
      let sort = this.createSort(this.clDataOptions.sort);

      console.log(this.db);
      let getParam = {
        _db: this.db,
        col: this.collection,
        _filter: {
          ...filter,
          ...{
            ...((this.collection == "accounts" && this.documentType)
              ? { documentType: this.documentType }
              : {}),
          },
        },
        _sort: sort,
        _limit: 1,
        _skip: this.clDataOptions.page.start,
        _withTotalCount: true,
      };

      if (!this.db) delete getParam._db;

      this.cs.getCollection(getParam).subscribe(
        (d) => {
          if (d.totalCount <= 1000) this.clDataOptions.strategy = "load-all";
          console.log("totalCount", this.totalCount);
          resolve(this.clDataOptions.strategy);
        },
        (e) => {
          this.clDatasource = { data: null, totalCount: 0 };
        }
      );
    });
  }

  private createSort(loadOptions: clSort) {
    let sort = {};

    if (loadOptions.field != "") {
      sort[loadOptions.field] = loadOptions.direction;
    }

    return sort;
  }

  private createFilter(loadOptions: clFilter) {
    let filter = {};
    if (loadOptions.condition != "none") {
      let search = {};
      let field = loadOptions.field;
      let condition = loadOptions.condition;
      let value = loadOptions.value;

      if (condition == "contains") {
        search = { $regex: ".*" + value + ".*", $options: "i" };
      } else if (condition == "startswith") {
        search = { $regex: "^" + value, $options: "i" };
      } else if (condition == "endswith") {
        search = { $regex: value + "$", $options: "i" };
      } else if (condition == "equal") {
        search = { $regex: "^" + value + "$", $options: "i" };
      } else if (condition == "notcontains") {
        search = { $regex: "^((?!" + value + ").)*$", $options: "i" };
      }
      filter[field] = search;
    }

    return filter;
  }
}

export class clData {
  page: clPage = new clPage();
  filter: clFilter = new clFilter();
  sort: clSort = new clSort();
  strategy: strategy = "load-partial";
}

export class clPage {
  start: number = 0;
  limit: number = 10;
}

export class clFilter {
  condition: condition = "none";
  field: string = "";
  value: any = undefined;
}

export class clSort {
  direction: number = 1; //1 -1
  field: string = "";
}

export class dataServiceSubject {
  db: string;
  collection: string;
  action: subjectActions = "none";
}

export type status = "none" | "loading" | "loaded" | "error";
export type strategy = "load-all" | "load-partial";

export type condition =
  | "contains"
  | "startswith"
  | "endswith"
  | "equal"
  | "notcontains"
  | "none";

export declare type subjectActions = keyof typeof subjectActionStateMap;
declare const subjectActionStateMap: {
  none: string;
  upsert: string;
  delete: string;
  loading: string;
  loaded: string;
  error: string;
};
