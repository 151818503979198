import { NgModule } from '@angular/core';
import { ClBaseComponent } from './cl-base/cl-base.component';
import { ClFormComponent } from './cl-form/cl-form.component';
import { ClTextBoxComponent } from './cl-text-box/cl-text-box.component';
import { ClSelectBoxComponent } from './cl-select-box/cl-select-box.component';
import { ClDropBoxComponent } from './cl-drop-box/cl-drop-box.component';
import { ClTextAreaComponent } from './cl-text-area/cl-text-area.component';
import { FooterComponent } from './footer/footer.component';
import { ModalComponent } from './modal/modal/modal.component';
import { UploadComponent } from './upload/upload.component';
import { ClImageUploadComponent } from './cl-image-upload/cl-image-upload.component';
import { ClGridComponent } from './cl-grid/cl-grid.component';
import { ClToggleComponent } from './cl-toggle/cl-toggle.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ClCollapsableTitleComponent } from './cl-collapsable-title/cl-collapsable-title.component';
import { ClModalDialogComponent } from './cl-modal-dialog/cl-modal-dialog.component';
import { PrintLayoutComponent } from './printing/print-layout/print-layout.component';
import { MycurrencyDirective } from './directive/mycurrency.directive';
import { MycurrencyPipe } from './pipe/mycurrency.pipe';
import { MypercentPipe } from './pipe/mypercent.pipe';
import { MypercentDirective } from './directive/mypercent.directive';

@NgModule({
  declarations: [
    ClBaseComponent,
    ClFormComponent,
    ClTextBoxComponent,
    ClSelectBoxComponent,
    ClDropBoxComponent,
    ClTextAreaComponent,
    FooterComponent,
    ModalComponent,
    UploadComponent,
    ClImageUploadComponent,
    ClGridComponent,
    ClToggleComponent,
    ClCollapsableTitleComponent,
    ClModalDialogComponent,
    PrintLayoutComponent,
    MycurrencyDirective,
    MycurrencyPipe,
    MypercentPipe,
    MypercentDirective
  ],
  imports: [
    CommonModule,
    TranslateModule,
  
  ],
  exports: [
    ClBaseComponent,
    ClFormComponent,
    ClTextBoxComponent,
    ClSelectBoxComponent,
    ClDropBoxComponent,
    ClTextAreaComponent,
    FooterComponent,
    ModalComponent,
    UploadComponent,
    ClImageUploadComponent,
    ClGridComponent,
    ClToggleComponent,
    ClModalDialogComponent,
    ClCollapsableTitleComponent,
  ]
})
export class CloudComponentsModule { }
