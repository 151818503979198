import { CloudDbService } from 'cloud-labs-core';
import { Injectable } from '@angular/core';
import { GenericDataService } from 'projects/cloud-components/src/lib/database/generic-data.service';

@Injectable({
  providedIn: 'root'
})
export class PrintersService extends GenericDataService {

  collection = "pos-printers"
  //data: printer[] = []

  constructor(public cs : CloudDbService) {
    super(cs)
   }

}

export class printer {
  id: string
  name: string
  path: string
  type: string
  description: string
}