export class folioRow {
    id: string;
    name: string;
    productID: string;
    qty: number = 0;
    unitPrice: number = 0;
    new: boolean = true;
    itemImage: string = "";
    parentID: string = "0";
    isModifier: boolean;
    modifierID: string;
    isMustModifier: boolean;
    modifierGroup: string;
    qtyID: number;
    note: string;
  
    isGift: boolean;
    isPayment: boolean;
    isDiscount: boolean;
    isPaidFull: boolean = false
  
    price: number = 0;
    discount: number = 0;
    selected: boolean = false;
    seat: number = 0;
    creator: { userID: string; userName: string } = { userID: "", userName: "" };
    updaters: Array<{ userID: string; userName: string; time: Date }> = [];
    deleters: Array<{ userID: string; userName: string; time: Date, qty: number, reasonID: string, reason: string }> = [];
    recordStatus: string = "new"; // 'new', 'old', 'deleted', 'removed'
    payStatus: payStatus[] = []
  
    isLoyalty: boolean = false
    isFree: boolean = false
    spendPoint: number = 0
  
  
    getRowTotal(): number {
      let total = this.unitPrice * this.qty;
      try {
        return total;
      } catch (error) {
        return 0;
      }
    }
  
  
  }
  
  export class payStatus {
    id: string
    name: string
    amount: number
  }
  
  export class folio {
    id: string;
    creation: Date;
    lastChange: Date;
    rows: folioRow[] = [];
    discount: number = 0;
    service: number = 0;
    total: number = 0;
    grandTotal: number = 0;
    parentID: string = "0";
    status: string = "open"; // 'open', 'closed', 'cancelled', 'splitted'
    type: string; // 'self', 'table', 'delivery', 'takeAway'
    table: { id: string; name: string } = { id: "", name: "" };
    lock: { status: string; time: Date; userName: string; userID: string } = {
      status: "unlocked",
      time: new Date(),
      userName: "",
      userID: ""
    };
  
    creator: { userID: string; userName: string } = { userID: "", userName: "" };
    updaters: Array<{ userID: string; userName: string; time: Date }> = [];
    pax: number = 4;
  
    getRows(): folioRow[] {
      return this.rows.filter(p => p.recordStatus != 'deleted')
    }
  
    getTotal() {
      let total: number = 0;
      this.getRows().forEach(rw => {
        total = total + rw.getRowTotal();
      });
      return total
    }
  }