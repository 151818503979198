import { CloudDbService } from 'cloud-labs-core';
import { GenericDataService } from '../../../../projects/cloud-components/src/lib/database/generic-data.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BranchService extends GenericDataService {


  public data: branch[]
  collection = "company-branches"

  constructor(public cs: CloudDbService) {
    super(cs)
  }

  getBranch(id) {
    return this.data.find(p => p.id == id)
  }


}

export class branch {

  id: string
  name: string
  geopoint: firebase.default.firestore.GeoPoint
  phone: string
  address: string
  country: string
  city: string
  county: string
  wifi: boolean
  park: boolean
  reservation: boolean
  image: string

}