import { BehaviorSubject } from 'rxjs';
import { CloudLoginService } from 'cloud-labs-core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppPermissionsService {



  subject_permissions = new BehaviorSubject<any>(false);
  observer_perissions = this.subject_permissions.asObservable();

  perm: appperm = new appperm()
  constructor(private ls: CloudLoginService) {
  
  }

  loadPerms() {
    this.perm = new appperm()
    console.log('this.ls.getActiveLicance().allPermissions',this.ls.getActiveLicance().allPermissions)
    this.ls.getActiveLicance().allPermissions.forEach(o => {
       if (this.perm[o.permission] != "undefined") {
        this.perm[o.permission] = true
      } 
    })

    setTimeout(() => {
      this.subject_permissions.next(true)
    }, 2000);
  }

}

export class appperm {

  //Folio
  ChangePrice: boolean = false
  CloseFolio: boolean = false
  DeleteRow: boolean = false
  DiscountRow: boolean = false
  DiscountTotal: boolean = false
  MakeGift: boolean = false
  OpenFolio: boolean = false
 

  //Loyalty
  LoyaltyCouponsSet: boolean = false
  LoyaltyUsersSet: boolean = false
  LoyaltyPushNotificationSet: boolean = false

  //Modules
  ProductsAndGroups: boolean = false
  Loyalty: boolean = false
  UsersAndRights = false
  Pos = false
  eInvoice = false

  //eInvoice

  InboundInvoices = false
  OutboundInvoices = false
  eArchiveModule = false
  eInvoiceModule = false
  eFolioModule = true

  SendToProvider = false
  PreviewInvoice = false
  SendEmail = false
  SendErp = false
  EditInvoice = false

  POSInvoices = false
  ERPInvoices = false

  ShowAllInvoices = false
  ApproveInboundInvoice = false
  RejectInboundInvoice = false

 

  

  



}
