<div style="
    width: 95%;
    padding: 20px;
    margin: auto;
    display: flex;
    justify-content: space-between;
  " class="form-container">
  <div style="width: 40%">

    <dx-form id="form" class="form" [colCount]="1" [showValidationSummary]="true" #form>

      <dxi-item dataField="documentType" editorType="dxSelectBox" [editorOptions]="{
        dataSource: documentTypes,
        onSelectionChanged : documentTypeChanged,
        valueExpr: 'id',
        displayExpr: 'name'
      }">
      <dxo-label text="Belge Tipi"></dxo-label>
      <dxi-validation-rule type="required" message="Belge Tipi zorunlu bir alandır.">
      </dxi-validation-rule>
    </dxi-item>

    <dxi-item dataField="stopaj" editorType="dxNumberBox" style="text-align: right;"  [visible]="isVisible" [editorOptions]="{onValueChanged:chanceStopage,format : '# %'}" >
      <dxo-label text="Stopaj Oranı"></dxo-label>
      <dxi-validation-rule type="required">
      </dxi-validation-rule>  
    </dxi-item>

    <dxi-item dataField="sgkPrim" editorType="dxNumberBox" style="text-align: right;"  [visible]="isVisible" [editorOptions]="{onValueChanged:changeSGK,format : '#,###.00'}" >
      <dxo-label text="SGK Primi"></dxo-label>
      <dxi-validation-rule type="required">
      </dxi-validation-rule>  
    </dxi-item>

    <dxi-item  dataField="borsaTescilBedeli" style="text-align: right;" editorType="dxNumberBox" [visible]="isVisible" [editorOptions]="{onValueChanged:changeStopaj,format: '#,###.00'}">
      <dxo-label text="Borsa Tescil"></dxo-label>
      <dxi-validation-rule type="required">
      </dxi-validation-rule>  
    </dxi-item>


      <dxi-item dataField="typeScenario" editorType="dxSelectBox" [editorOptions]="{
          dataSource: scenarios,
          valueExpr: 'id',
          displayExpr: 'name'
        }">
        <dxo-label text="Senaryo"></dxo-label>
        <dxi-validation-rule type="required" message="Senaryo zorunlu bir alandır.">
        </dxi-validation-rule>
      </dxi-item>

      <dxi-item *ngIf="this.invoiceService.branchList.length>0"  dataField="branchCode" editorType="dxSelectBox" [editorOptions]="{
        dataSource: this.invoiceService.branchList,
        valueExpr: 'branchCode',
        displayExpr: 'branchName',
        searchEnabled : true
      }">
      <dxo-label text="Şube Seçimi"></dxo-label>
      <dxi-validation-rule type="required" message="Şube Seçimi Zorunlu Alandır.">
      </dxi-validation-rule>   
    </dxi-item>
     
      <dxi-item dataField="invoiceType" editorType="dxSelectBox" [editorOptions]="{
          dataSource: invoiceTypes,
          valueExpr: 'id',
          displayExpr: 'name'
        }">
        <dxo-label text="Fatura Tipi"></dxo-label>
        <dxi-validation-rule type="required" message="Fatura Tipi zorunlu bir alandır.">
        </dxi-validation-rule>
      </dxi-item>

      <dxi-item dataField="account.taxNumber" [editorOptions]="{onValueChanged:taxno}">
        <dxo-label text="TCKN/VKN"></dxo-label>
        <dxi-validation-rule type="required" message="TCKN/VKN zorunlu bir alandır.">
        </dxi-validation-rule>
        <dxi-validation-rule type="stringLength" [min]="vknTc" [max]="11"
          message="Girilen değer çok uzun."></dxi-validation-rule>
        <dxi-validation-rule type="custom" [validationCallback]="validationCallbackTCKN"
          message="Lütfen geçerli bir TCKN giriniz.">
        </dxi-validation-rule>
      </dxi-item>

      <dxi-item dataField="account.accountName">
        <dxo-label text="Cari Hesap Adı"></dxo-label>
        <dxi-validation-rule type="required" message="Cari Hesap Adı zorunlu bir alandır.">
        </dxi-validation-rule>
      </dxi-item>

      <dxi-item dataField="invoiceDate" editorType="dxDateBox" [editorOptions]="{'type':'datetime'}">
        <dxo-label text="Fatura Tarihi"></dxo-label>
        <dxi-validation-rule type="required" message="Fatura Tarihi zorunlu bir alandır.">
        </dxi-validation-rule>
      </dxi-item>

      <dxi-item *ngIf="form.formData?.invoiceType === 'stoppage'" dataField="stoppageId" editorType="dxSelectBox"
        [editorOptions]="{
          dataSource: stoppages,
          valueExpr: 'id',
          displayExpr: 'displayValue'
        }">
        <dxo-label text="Tevkifat Oranı"></dxo-label>
        <dxi-validation-rule type="required" message="Tevkifat Oranı zorunlu bir alandır.">
        </dxi-validation-rule>
      </dxi-item>

      <dxi-item *ngIf="form.formData?.invoiceType === 'exemptions'" dataField="exemptionReason" editorType="dxSelectBox"
        [editorOptions]="{
        dataSource: exemptions,
        valueExpr: 'id',
        displayExpr: 'displayValue'
      }">
        <dxo-label text="İstisna Sebebi"></dxo-label>
        <dxi-validation-rule type="required" message="Tevkifat Oranı zorunlu bir alandır.">
        </dxi-validation-rule>
      </dxi-item>

      <!-- <dxi-item
      *ngIf="form.formData?.invoiceType === 'stoppage'"
      dataField="stoppageReason"
      editorType="dxSelectBox"
      [editorOptions]="{
        dataSource: stoppages,
        valueExpr: 'id',
        displayExpr: 'name'
      }"
    >
      <dxo-label text="Tevkifat Açıklama"></dxo-label>
      <dxi-validation-rule
        type="required"
        message="Tevkifat Oranı zorunlu bir alandır."
      >
      </dxi-validation-rule>
    </dxi-item> -->
    </dx-form>
  </div>
  <div style="width: 20%">
    <dx-button stylingMode="contained" text="Kaydet" type="success" [width]="120" (onClick)="save()">
    </dx-button>
  </div>
</div>

<dx-data-grid width="90%" #grid style="
    font-size: 12px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    width: 90%;
   
    border-radius: 10px;
  " id="gridContainer" [dataSource]="rows" [hoverStateEnabled]="true" [columnHidingEnabled]="false" keyE
  [showBorders]="true" xpr="code" [allowColumnReordering]="true" [allowColumnResizing]="true"
  columnResizingMode="widget" (onRowInserted)="onRowInserted($event)" (onRowValidating)="onRowValidating($event)"
  (onInitNewRow)="onInitNewRow($event)" (onEditingStart)="onEditingStart($event)">
  <dxo-export [enabled]="true" fileName="faturalar" [allowExportSelectedData]="true"></dxo-export>
  <dxo-selection mode="multiple"></dxo-selection>
  <dxo-scrolling columnRenderingMode="virtual" mode="virtual"></dxo-scrolling>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-filter-row [visible]="false" [applyFilter]=""></dxo-filter-row>
  <dxo-search-panel [visible]="true" [width]="240" placeholder="Ara..."></dxo-search-panel>
  <dxo-header-filter [visible]="false"></dxo-header-filter>
  <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
  <dxo-paging [pageSize]="15"></dxo-paging>
  <dxo-editing mode="batch" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true">
  </dxo-editing>

  <dxi-column dataField="name" [width]="100" caption="Ürün Adı">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <!-- <dxi-column [width]="90" dataField="unitName" caption="Birimi"></dxi-column> -->

  <dxi-column dataField="unitName" [width]="150" caption="Birimi">
    <dxo-lookup [dataSource]="units" displayExpr="name" valueExpr="id">
    </dxo-lookup>
    <dxi-validation-rule type="required"> </dxi-validation-rule>
  </dxi-column>

  <dxi-column [width]="100" dataType="number" dataField="quantity" caption="Miktarı">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxi-validation-rule type="range" [min]="0.000000001" message="Miktar 0 dan büyük olmalıdır."></dxi-validation-rule>
  </dxi-column>

  <dxi-column [width]="100" dataType="number" dataField="unitPrice" caption="Brm Fiyat">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxi-validation-rule type="range" [min]="0.000000001"
      message="Brm Fiyat 0 dan büyük olmalıdır."></dxi-validation-rule>
    <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
  </dxi-column>

  <dxi-column [width]="100" dataType="number" dataField="discount" caption="İndirim Oranı"
    [calculateCellValue]="calculateCellValueDiscount">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <dxi-column [width]="100" dataType="number" dataField="discountPrice" caption="İndirim Tutarı" [allowEditing]="false"
    [calculateCellValue]="calculateCellValueDiscountPrice">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
  </dxi-column>

  <dxi-column [width]="100" dataType="number" dataField="total" caption="Net Tutar" [allowEditing]="false"
    [calculateCellValue]="calculateCellValueNetTutar">
    <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <dxi-column [width]="100" dataType="number" [allowEditing]="!isVisible" dataField="taxRate" caption="Vergi Oranı">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <!-- <dxi-validation-rule
      type="range"     
      message="KDV Oranı 0 dan büyük olmalıdır."
    ></dxi-validation-rule> -->
  </dxi-column>

  <dxi-column [width]="175" dataType="number" [allowEditing]="!isVisible" dataField="cityTaxRate" caption="Konaklama Vergi Oranı">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <dxi-column [width]="175" dataType="number" [allowEditing]="false" dataField="cityTaxTotal" caption="Konaklama Vergi Tutarı" [calculateCellValue]="calculateCellValueKonaklamaTutar">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <dxi-column [width]="100" dataType="number" dataField="taxTotal" caption="KDV Tutarı"
    [calculateCellValue]="calculateCellValueKDVTutar">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
  </dxi-column>

  <dxi-column *ngIf="dxForm?.formData?.invoiceType === 'stoppage'" [width]="100" dataType="number"
    dataField="stoppageAmount" caption="Tevkif Edilecek KDV Tutarı" [calculateCellValue]="calculateCellValueTekifKDV">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
  </dxi-column>

  <dxi-column [width]="100" dataType="number" dataField="grandTotal" caption="Toplam Tutar" [allowEditing]="false"
    [calculateCellValue]="calculateCellValueToplamTutar">
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
  </dxi-column>
  <!-- <dxi-column dataField="branchName" caption="{{'Şube' | translate}}" [width]="80"></dxi-column> -->

  <!-- <div *dxTemplate="let data of 'erpTemplate'">
        <i *ngIf="data.data.erp == 'success'" class="far fa-check-circle" style="font-size: 18px"></i>
        <i *ngIf="data.data.erp == 'new'" class="far fa-times-circle" style="font-size: 18px"></i>
        <i *ngIf="data.data.erp == 'error'" class="fas fa-exclamation" style="font-size: 18px"></i>
    </div>

    <dxo-summary>
        <dxi-total-item column="grandTotal" summaryType="sum" valueFormat="#,###.00" displayFormat="{0}">
        </dxi-total-item>
        <dxi-total-item column="invoiceNumber" summaryType="count">
        </dxi-total-item>
    </dxo-summary> -->
</dx-data-grid>


<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loadingVisible" [showIndicator]="true"
  [showPane]="true" [message]="loadingMessage" [shading]="true" [closeOnOutsideClick]="false">
</dx-load-panel>