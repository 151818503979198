import { Component, OnInit, ContentChild, TemplateRef, Input, Output, ViewChild, ElementRef, HostListener } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { fromEvent } from 'rxjs';
import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter,
  merge
} from "rxjs/operators";
import { ClBaseComponent } from '../cl-base/cl-base.component';

@Component({
  selector: 'cl-drop-box',
  templateUrl: './cl-drop-box.component.html',
  styleUrls: ['./cl-drop-box.component.scss']
})
export class ClDropBoxComponent extends ClBaseComponent implements OnInit {

  //@ContentChild(TemplateRef, { static: true })
  @Input() ItemTemplate: TemplateRef<any>


  @Input() type: type = "text"
  @Input() label = ""
  @Input() ilabel = ""
  @Input() required = false
  @Input() list: any = []
  @Input() valueExpr
  @Input() displayExpr
  @Input() showClearButton = false

  isValid = true
  loading = false
  show = false
  temp_list: any = []
  orginal_value
  dValue

  constructor(private _elementRef: ElementRef) {
    super()
  }

  @Output('clickOutside') clickOutside: EventEmitter<any> = new EventEmitter();

  @HostListener('document:click', ['$event.target']) onMouseEnter(targetElement) {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.clickOutside.emit(null);
      this.temp_list = []
    }
  }

  @ViewChild('clDropDownInput', { static: true }) clDropDownInput: ElementRef;

  ngOnInit() {
    if (!isNullOrUndefined(this.value)) {
      if (this.value != '') {
        let val1 = this.list.find(p => p[this.valueExpr] == this.value)
        if (val1) {
          //this.clDropDownInput.nativeElement.value = this.list.find(p => p[this.valueExpr] == this.value)[this.displayExpr]
          this.dValue = val1[this.displayExpr]
        }
      }
    }
  }

  ngAfterViewInit() {
    this.temp_list = this.list
    fromEvent(this.clDropDownInput.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value.toLocaleUpperCase();
      })
      , debounceTime(500)
      , distinctUntilChanged()
    ).subscribe((text: string) => {
      this.temp_list = this.list.filter(p =>
        p[this.displayExpr].indexOf(text.toLocaleUpperCase()) > -1
      )
    })
  }

  changed(e) {
    let val = e.target.value
    this.valueChange.emit(e.target.value)
    this.isValid = this.required ? (!isNullOrUndefined(e.target.value)) : true
    if (this.type == "email") {
      this.validateEmail(val)
    }
  }

  validateEmail(val): boolean {
    if (val == "") {
      this.isValid = true
      return true
    }
    let reg = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)i/
    this.isValid = RegExp(reg).test(val)
    return this.isValid
  }

  clear() {
    this.value = ""
    this.valueChange.emit("")
    this.clDropDownInput.nativeElement.value = ""
  }

  select(item) {
    this.orginal_value = item[this.valueExpr]
    this.dValue = item[this.displayExpr]
    this.show = false
     this.clDropDownInput.nativeElement.value = item[this.valueExpr]
     this.value = item[this.valueExpr]
     this.valueChange.emit(item[this.valueExpr])
  }

  showList() {
    this.show = true
    this.temp_list = this.list
  }

  getDisplay() {
    if (this.list.length > 0 && this.orginal_value !== '') {
      let finded = this.list.find(o => o[this.valueExpr] == this.orginal_value)
      if (finded) {
        return this.list.find(o => o[this.valueExpr] == this.orginal_value)[this.displayExpr]
      } else {
        return "?*"
      }
    } else {
      return ""
    }

  }



}

type type = 'text' | 'email' | 'number' 