<div class="cloud-form">
  <div class="cloud-header">E-Fatura Ayarları</div>

  <app-cl-form>
    <app-cl-text-box [(value)]="invSetSrvc.settings.companyName" label="{{'Company Name' | translate}}">
    </app-cl-text-box>


    <!-- CONTACT INFORMATION  -->
    <lib-cl-collapsable-title [collapsed]="true" title="{{'Contact Information' | translate}}">
      <cl-text-area [(value)]="invSetSrvc.settings.address" label="{{'Address' | translate}}"></cl-text-area>
      <app-cl-text-box [(value)]="invSetSrvc.settings.city" label="{{'City' | translate}}">
      </app-cl-text-box>
      <app-cl-text-box [(value)]="invSetSrvc.settings.town" label="{{'Town' | translate}}">
      </app-cl-text-box>

      <div style="display: flex; width: 100%; justify-content: space-between;">
        <app-cl-text-box style="width: 48%" [(value)]="invSetSrvc.settings.phone" label="{{'Phone' | translate}}">
        </app-cl-text-box>
        <app-cl-text-box style="width: 48%" [(value)]="invSetSrvc.settings.mail" label="{{'EMail' | translate}}">
        </app-cl-text-box>
      </div>
      <app-cl-text-box [(value)]="invSetSrvc.settings.webSite" label="{{'Web Site' | translate}}">
      </app-cl-text-box>
    </lib-cl-collapsable-title>

    <!-- MERCHANT INFORMATION  -->
    <lib-cl-collapsable-title [collapsed]="true" title="Cari Bilgiler">
      <div style="display: flex; justify-content: space-between; width: 100%;">
        <app-cl-text-box label="Vergi No" style="width: 48%" [(value)]="invSetSrvc.settings.taxNumber">
        </app-cl-text-box>
        <app-cl-text-box label="Vergi Dairesi" style="width: 48%" [(value)]="invSetSrvc.settings.taxOffice">
        </app-cl-text-box>
      </div>

      <div style="display: flex; justify-content: space-between; width: 100%;">
        <app-cl-text-box style="width: 48%" [(value)]="invSetSrvc.settings.registerNumber"
          label="{{'Merchant ID' | translate}}">
        </app-cl-text-box>
        <app-cl-text-box style="width: 48%" [(value)]="invSetSrvc.settings.mersisNo"
          label="{{'Mersis ID' | translate}}">
        </app-cl-text-box>
      </div>
    </lib-cl-collapsable-title>

    <lib-cl-collapsable-title [collapsed]="true" title="Servis Hesapları">
      <div style="display: flex; justify-content: space-between; width: 100%; ">
        <app-cl-text-box style="width: 48%" [(value)]="invSetSrvc.settings.wsuserName"
          label="{{'Username' | translate}}">
        </app-cl-text-box>
        <app-cl-text-box style="width: 48%" [(value)]="invSetSrvc.settings.wspassword"
          label="{{'Password' | translate}}">
        </app-cl-text-box>
      </div>

      <div style="display: flex; justify-content: space-between; width: 100%;">
        <app-cl-text-box style="width: 48%" [(value)]="invSetSrvc.settings.wslink"
          label="{{'Service Address' | translate}}">
        </app-cl-text-box>
        <app-cl-text-box style="width: 48%" [(value)]="invSetSrvc.settings.licanceId"
          label="{{'Kerzz ID' | translate}}">
        </app-cl-text-box>
      </div>

    </lib-cl-collapsable-title>

    <lib-cl-collapsable-title [collapsed]="true" title="Parametreler">

      <div style="display: flex; justify-content: space-around;">
        <app-cl-toggle width="32%" label="E-Arşivi Otomatik Gönder" [(value)]="invSetSrvc.settings.autoSendeArchive">
        </app-cl-toggle>
        <app-cl-toggle width="32%" label="E-Faturayı Otomatik Gönder" [(value)]="invSetSrvc.settings.autoSendeInvoice">
        </app-cl-toggle>
        <app-cl-toggle width="32%" label="E-Adisyonu Otomatik Gönder" [(value)]="invSetSrvc.settings.autoSendEFolio">
        </app-cl-toggle>
        <app-cl-toggle width="32%" label="Kalem Detaylı (Kerzz)" [(value)]="invSetSrvc.settings.invoiceFolioRowDetail">
        </app-cl-toggle>  
        <app-cl-text-box style="width: 48%" [(value)]="invSetSrvc.settings.backofficeLicanceId"
        label="{{'Back Office Lisans ID' | translate}}">
      </app-cl-text-box>      
      </div>

      <div style="display: flex; justify-content: space-around;">
        <cl-drop-box width="40%" displayExpr="name" valueExpr="id" [(value)]="invSetSrvc.settings.wsProvider"
          [list]="wsProviders" label="Entegratör"></cl-drop-box>

        <cl-drop-box width="40%" displayExpr="name" valueExpr="id" [(value)]="invSetSrvc.settings.erpApp"
          [list]="erpApps" label="Erp Uygulaması"></cl-drop-box>
      </div>

    </lib-cl-collapsable-title>

    <lib-cl-collapsable-title [collapsed]="true" title="Döküman Dizaynı">
      <div style="display: flex; justify-content: space-around;">
        <app-cl-toggle width="40%" label="ECR Bilgisini Göster"
          [(value)]="invSetSrvc.settings.documentDesign.showEcrInfo">
        </app-cl-toggle>
        <app-cl-toggle width="40%" label="Folyo Tarihini Göster"
          [(value)]="invSetSrvc.settings.documentDesign.showFolioDate">
        </app-cl-toggle>
      </div>

      <div style="display: flex; justify-content: space-around;">
        <app-cl-toggle width="40%" label="Folyo Notunu Göster"
          [(value)]="invSetSrvc.settings.documentDesign.showFolioNote">
        </app-cl-toggle>
         <app-cl-toggle width="40%" label="İrsaliyede Toplamları Göster"
          [(value)]="invSetSrvc.settings.documentDesign.eDispatchShowTotals">
        </app-cl-toggle>
        <app-cl-text-box width="40%" [(value)]="invSetSrvc.settings.documentDesign.bankAccountNo"
          label="{{'Banka Hesap No' | translate}}">
        </app-cl-text-box>
      </div>
    </lib-cl-collapsable-title>


    <lib-cl-collapsable-title [collapsed]="true" title="Mail Sunucusu">

      <div style="display: flex; justify-content: space-around;">
        <app-cl-text-box width="e0%" [(value)]="invSetSrvc.settings.mailServer.host" label="{{'Host' | translate}}">
        </app-cl-text-box>

        <app-cl-text-box width="30%" [(value)]="invSetSrvc.settings.mailServer.port" label="{{'Port' | translate}}">
        </app-cl-text-box>

        <app-cl-toggle width="30%" label="Secure" [(value)]="invSetSrvc.settings.mailServer.secure">
        </app-cl-toggle>
      </div>


      <div style="display: flex; justify-content: space-around;">
        <app-cl-text-box width="40%" [(value)]="invSetSrvc.settings.mailServer.auth.user" label="Kullanıcı Adı">
        </app-cl-text-box>

        <app-cl-text-box width="40%" [(value)]="invSetSrvc.settings.mailServer.auth.pass" label="Şifre">
        </app-cl-text-box>
        <app-cl-text-box width="40%" [(value)]="invSetSrvc.settings.eArchiveDefaultMail" label="E Arşiv Varysayılan Mail">
        </app-cl-text-box>
      </div>

    </lib-cl-collapsable-title>

    <lib-cl-collapsable-title [collapsed]="true" title="Şube Ayarları">

      <div (click)="addBranch()" class="cl-ok-button"  style="width: 110px;">
        <i style="font-size:20px; margin-right: 5px;" class="fas fa-plus"></i>
        Şube Ekle</div>

      <div style="padding-left: 20px;
    padding-right: 20px;" *ngFor="let item of this.invSetSrvc.settings.branches; index as branch_index ">

        <lib-cl-collapsable-title [collapsed]="false" title="{{'Branch' | translate}} #{{branch_index + 1}}">
          <div (click)="removeBranch(branch_index)" class="cl-cancel-button" style="width: 110px;">
            <i style="font-size:20px; margin-right: 5px;" class="fas fa-minus"></i>
            Şube Sil {{item.branchCode}}
          </div>
          <div style="display: flex; justify-content: space-between; width: 100%; ">
            <app-cl-text-box style="width: 48%" [(value)]="item.branchCode" label="{{'Branch Code' | translate}}">
            </app-cl-text-box>
            <app-cl-text-box style="width: 48%" [(value)]="item.invoiceDesignCode"
              label="{{'Design Code' | translate}}">
            </app-cl-text-box>
          </div>

          <!--<div class="cloud-sub-group">E-Fatura</div>
          <div style="display: flex; justify-content: space-between; width: 100%;">
            <app-cl-text-box style="width: 32%" [(value)]="item.eInvoice.prefix" label="{{'Prefix' | translate}}">
            </app-cl-text-box>
            <app-cl-text-box style="width: 32%" [(value)]="item.eInvoice.lastNumber"
              label="{{'Last Number' | translate}}">
            </app-cl-text-box>
            <app-cl-text-box style="width: 32%" [(value)]="item.eInvoice.lastUse" label="{{'Last Use' | translate}}">
            </app-cl-text-box>
          </div>

          <div class="cloud-sub-group">E-Arşiv</div>
          <div style="display: flex; justify-content: space-between; width: 100%;">
            <app-cl-text-box style="width: 32%" [(value)]="item.eArchive.prefix" label="{{'Prefix' | translate}}">
            </app-cl-text-box>
            <app-cl-text-box style="width: 32%" [(value)]="item.eArchive.lastNumber"
              label="{{'Last Number' | translate}}">
            </app-cl-text-box>
            <app-cl-text-box style="width: 32%" [(value)]="item.eArchive.lastUse" label="{{'Last Use' | translate}}">
            </app-cl-text-box>
          </div>-->
        </lib-cl-collapsable-title>
      </div>

    </lib-cl-collapsable-title>


    <lib-cl-collapsable-title [collapsed]="true" title="Fatura Serileri">
      <dx-data-grid #dataGrid id="grid-container"  style="margin-top: 20px;"  [showRowLines]="true" [allowColumnResizing]="true"
      columnResizingMode="widget"   [allowColumnReordering]="true"  [showColumnLines]="true" [dataSource]="this.invSetSrvc.serials"
      [keyExpr]="'id'" [showBorders]="true" [columnAutoWidth]="true"  (onEditingStart)="editintSerial($event)"  (onRowUpdating)="onRowUpdating($event)" (onEditorPreparing)="onEditorPreparing($event)" (onRowInserted)="addNewSerial($event)" (onInitNewRow)="initRow($event)" (onRowUpdated)="updateSerial($event)" (onRowRemoved)="deleteSerial($event)">
      <dxo-paging [pageSize]="20"></dxo-paging>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-export [enabled]="true" fileName="fatura-serileri" [allowExportSelectedData]="true"></dxo-export>
      <dxo-editing mode="row" [confirmDelete]="true" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"></dxo-editing>
      <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[20, 50, 100, 200]" [showInfo]="true"></dxo-pager>
      <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
      <!-- <dxo-selection [selectAllMode]="'allPages'" [showCheckBoxesMode]="'always'" mode="multiple"></dxo-selection> -->
      <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
      <dxi-column dataField="desc" [fixed]="true" fixedPosition="left" cssClass="verticalCenter" caption="Açıklama"></dxi-column>
      <dxi-column dataField="serial"  cssClass="verticalCenter"  [editorOptions]="{ maxLength: '3'}" caption="Seri">
        <dxi-validation-rule
        type="pattern"
        pattern="^[a-zA-Z]+$"
        message="Sadece Harf Girebilirsiniz."
      ></dxi-validation-rule>
      </dxi-column>
      <dxi-column dataField="lastNumber" [allowEditing]="allowSerialNoEditing" dataType='number' cssClass="verticalCenter" caption="Son Numara"></dxi-column>
      <dxi-column dataField="documentType" cssClass="verticalCenter" caption="ERP Uygulaması">
        <dxo-lookup [dataSource]="serialTypes" displayExpr="name" valueExpr="id"></dxo-lookup>
      </dxi-column>
      <dxi-column dataField="year"  cssClass="verticalCenter" caption="Yıl"></dxi-column>
      <dxi-column dataField="source" cssClass="verticalCenter" caption="Kaynak">
        <dxo-lookup [dataSource]="sourceList" displayExpr="name" valueExpr="id"></dxo-lookup>
      </dxi-column>
      <dxi-column dataField="orjSerial" [allowEditing]="allowEditing"  [editorOptions]="{ maxLength: '3'}" cssClass="verticalCenter" caption="Orj Seri">
        <dxi-validation-rule
        type="pattern"
        pattern="^[a-zA-Z]+$"
        message="Sadece Harf Girebilirsiniz."
      ></dxi-validation-rule>
      </dxi-column>
      <dxi-column dataField="branchCode"  cssClass="verticalCenter" caption="Şube Kodu"></dxi-column>
      <dxi-column dataField="default" dataType="boolean" cssClass="verticalCenter" caption="Varsayılan"></dxi-column>
    </dx-data-grid>
  
      <!-- <div (click)="addSerial()" class="cl-ok-button"style="width: 110px;">
        <i style="font-size:20px; margin-right: 5px;" class="fas fa-plus"></i>
        Seri Ekle</div> -->

      <!-- <div style="padding-left: 20px;
    padding-right: 20px;" *ngFor="let item of this.invSetSrvc.serials; index as serial_index ">

        <lib-cl-collapsable-title [collapsed]="false" title="{{'Branch' | translate}} #{{serial_index + 1}}">
          <div (click)="removeSerial(serial_index)" class="cl-cancel-button" style="width: 110px;">
            <i style="font-size:20px; margin-right: 5px;" class="fas fa-minus"></i>
            Seri Sil {{serial_index}}
          </div>

          <div style="display: flex;    justify-content: space-between;">
            <app-cl-text-box [(value)]="item.serial" label="Seri">
            </app-cl-text-box>
            <app-cl-text-box [disabled]="item.id != ''"  [(value)]="item.lastNumber" label="Son Numara">
            </app-cl-text-box>
            <app-cl-text-box [(value)]="item.branchCode" label="Şube Kodu">
            </app-cl-text-box>
          </div>

          <div style="display: flex;    justify-content: space-between;">
            <app-cl-text-box [(value)]="item.desc" label="Açıklama">
            </app-cl-text-box>
            <app-cl-text-box [(value)]="item.source" label="Kaynak">
            </app-cl-text-box>
            <cl-drop-box width="40%" displayExpr="name" valueExpr="id" [(value)]="item.documentType" [list]="serialTypes"
            label="Erp Uygulaması"></cl-drop-box>
          </div>

          <div style="display: flex;    justify-content: space-between;">
            <app-cl-text-box [(value)]="item.year" label="Yıl">
            </app-cl-text-box>
            <app-cl-text-box [(value)]="item.orjSerial" [disabled]="true"  label="Orj Seri">
            </app-cl-text-box>
            <app-cl-toggle width="40%" label="Varsayılan"
            [(value)]="item.default">
          </app-cl-toggle>
          </div>

          
          
          



        </lib-cl-collapsable-title>
      </div> -->

    </lib-cl-collapsable-title>




    <div style="    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 30px;
    background: #1a1e31;
    border-radius: 4px;
">
      <button class="cl-cancel-button" (click)="cancel()">İptal</button>
      <button class="cl-ok-button" (click)="save()">Kaydet</button>
    </div>

  </app-cl-form>
</div>