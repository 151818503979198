import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outbound-dispatch',
  templateUrl: './outbound-dispatch.component.html',
  styleUrls: ['./outbound-dispatch.component.scss']
})
export class OutboundDispatchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
