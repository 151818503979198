import { GenericDataService } from '../../../../../projects/cloud-components/src/lib/database/generic-data.service';
import { SegmentsService } from './../../segments/segments.service';
import { Injectable } from '@angular/core';
import { product } from 'src/app/product/products/service/product.service';
import { loyaltyUserObject } from './loyalty-users';
import { CloudDbService } from 'cloud-labs-core';
import { CloudDataStoreService } from 'projects/cloud-components/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class LoyaltyUsersService extends GenericDataService {


  collection = "users"
  data: loyaltyUserObject[] = []
  store 

  constructor(public cs: CloudDbService, private caa: CloudDataStoreService, private sgs: SegmentsService) {
    super(cs)
  }


  block(id, value) {


    let tCol = this.cs.getDb(undefined) + "-users"
    this.cs.upsert({
      _db: "kerzz-loyalty",
      col: tCol,
      data: {
        id: id,
        isActive: value
      }
    })
  }


 dxLoad() {
  this.store =  this.caa.createdDXSource("id","kerzz-loyalty",this.cs.getDb(undefined) + "-users")
 }

  pretty_load() {
    this.store =  this.caa.createdDXSource("id","kerzz-loyalty",this.cs.getDb(undefined) + "-users")
    this.load().then(px => {
      this.data.map(el => {
        el.totaStamp = el.totaStamp | 0
        el.totalSpend = el.totalSpend | 0
        el.totalWin = el.totalWin | 0
      })

      let tCol = this.cs.getDb(undefined) + "-users"

      this.cs.getCollection({ _db: "kerzz-loyalty", col: tCol }).subscribe(o => {
        let udata = Object.assign(o)
        this.data.forEach(e => {

          let totalWin: number = e.totalWin | 0
          let segment = this.sgs.data.find(segment => totalWin >= segment.minPoint && totalWin <= segment.maxPoint)
          if (segment) {
            e.segment = segment.name
          }

          let user = udata.find(pp => pp.userName == e.userName)

          if (user) {

            e.totaStamp = udata.find(pp => pp.userName == e.userName).totaStamp | 0
            e.totalSpend = udata.find(pp => pp.userName == e.userName).totalSpend | 0
            e.totalWin = udata.find(pp => pp.userName == e.userName).totalWin | 0
            e.isActive = udata.find(pp => pp.userName == e.userName).isActive
            e['balance'] = e.totalWin + e.totalSpend | 0
          }





        })

      })
    })

  }


  // TODO Combine users
  /*   getLoyaltyUsersFromMongo() {
      this.cs.readCollectionFromMongo("users", undefined, "loyalty").subscribe(o => {
        this.data = Object.assign(o)
        console.log("ldata", this.data)
        this.cs.readCollectionFromMongo("users", undefined).subscribe(u => {
          let udata = Object.assign(u)
          console.log("udata", udata)
          console.log("segments", this.sgs.data)
          this.data.forEach(e => {
  
  
  
            let segment = this.sgs.data.find(segment => segment.minPoint <= e.totalWin && segment.maxPoint >= e.totalWin)
  
            if (segment) {
              e.segment = segment.name
            }
  
            if (e.pushToken) {
              e.pushToken = udata.find(pp => pp.id == e.id).pushToken
  
            }
          })
  
        })
  
      })
    } */

}

export class loyalty_user {
  id: string
  name: string
  email: string
  phone: string
  avatar: string
  social: loyalty_user_social
  birthDate: Date
  adress: loyalty_user_address[]
}

export class loyalty_user_address {
  id: string
  name: string
  country: { id: string, name: string }
  county: { id: string, name: string }
  street: string
  reciepe: string
}

export class loyalty_user_social {
  facebookID: string
  instagramID: string
  twitterID: string
  googleID: string
}


export class stamp_transactions {
  id: string
  userID: string
  date: Date
  posID: string
  checkID: string
  products: product[]
  earnStamp: number
  spendPoint: number
}