<div class="login-box" *ngIf="status == 'login'">



    <!-- <cloudie-login (loginStatus)="o($event)" [apikey]="cloudApiKey"></cloudie-login> -->

    <!-- <lib-cloud-login-web (loginStatus)="loginEvent($event)" [apikey]="cloudApiKey"></lib-cloud-login-web> -->


    <lib-cloud-labs-login (loginStatus)="loginEvent($event)" [apikey]="cloudApiKey"></lib-cloud-labs-login>
    



    <!--  <div class="header">

        {{'Kerzz Cloud Login' | translate}}
    </div>
    <div *ngIf="!loaded" style="text-align:center; width:100%">
        <img src="/assets/myicons/103.gif" />
    </div>
    <form autocomplete="disabled" role="presentation">
        <div *ngIf="!waitlogin" class="content">

            <input type="text" name="prevent_autofill" id="prevent_autofill" value="" style="display:none;" />
            <input type="password" name="password_fake" id="password_fake" value="" style="display:none;" />


            <div class="error" *ngIf="error != undefined">
                {{error}}
            </div>
            <div class="field">
                <dx-text-box [stylingMode]="'filled'" #username1 [inputAttr]="{ autocomplate: 'none' }" [(value)]="user" [showClearButton]="true">
                </dx-text-box>
            </div>

            <div class="field">
                <dx-text-box [stylingMode]="'outlined'" #password1 [inputAttr]="{ autocomplate: 'none' }" [(value)]="pass" mode="password" [showClearButton]="true">
                </dx-text-box>
            </div>

            <div>
                <dx-button [width]="90" type="success" text="{{'Login' | translate}}" (onClick)="login()">
                </dx-button>
            </div>

        </div>
        <div *ngIf="waitlogin" style="text-align:center; width:100%">
            <dx-load-indicator id="large-indicator" height="160" width="160"></dx-load-indicator>
        </div>
    </form> -->
</div>
<div class="login-box" *ngIf="status == 'loadingData'">
    <div class="header">
        {{'Loading data...' | translate}}<br /> {{this.loadedData}}
        <br />
        <img src="/assets/myicons/103.gif" />
    </div>
</div>