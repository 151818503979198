import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { GenericDataService } from 'projects/cloud-components/src/lib/database/generic-data.service';
import { CloudDbService } from 'cloud-labs-core';
import { HttpClient } from '@angular/common/http';
import { StartupService } from 'src/app/services/startup.service';

@Injectable({
  providedIn: 'root'
})
export class PayTypesService extends GenericDataService {

  data: paymentType[] = []
  collection = "pos-pay-types"
  sort = { order: 1 }
  rates: rate
  collections = [{ id: "cash", name: this.translate.instant("Cash") }, { id: "creditcard", name: this.translate.instant("Credit Card") }, { id: "foodcheck", name: this.translate.instant("Food Check") }, { id: "account", name: this.translate.instant("Account") }, { id: "other", name: this.translate.instant("Other") }]

  currencies = [{ id: "TRY", name: "TRY" }, { id: "USD", name: "USD" }, { id: "EUR", name: "EUR" }, { id: "CHF", name: "CHF" }, { id: "GBP", name: "GBP" }]

  constructor(public cs: CloudDbService, private translate: TranslateService, private http: HttpClient) {
    super(cs)
    this.getExchangeRates()
  }

  getExchangeRates() {
    this.load().then(() => {
      this.http.get("https://api.exchangeratesapi.io/latest?base=TRY").subscribe(o => {
        this.rates = Object.assign(o).rates
        this.data.filter(px => px.paytype == "cash").forEach(py => {
          py.exchangeRate = this.rates[py.currency]
        })
      })
    })
    
  }

}


export class paymentType {
  id: string
  order: number
  name: string
  description: string
  paytype: string
  currency: string
  bank: string
  foodCheck: string
  account: string
  exchangeRate: number
}



export class rate {
  USD: number
  TRY: number
  EUR: number
}
