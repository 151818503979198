import { AgreementsService } from './../loyalty/agreements/agreements.service';
import { CouponService } from './../loyalty/coupons/coupon.service';
import { FaqService } from '../loyalty/faq/faq.service';
import { CampaignsService } from './campaigns/campaigns.service';
import { ProductGroupService } from '../product/product-groups/service/product-group.service';
import { Injectable } from '@angular/core';
import { ProductService } from '../product/products/service/product.service';
import { ModifiersService } from '../product/modifiers/service/modifiers.service';
import { TablesService } from './tables.service';
import { BranchService } from '../loyalty/branches/branch.service';
import { NewsService } from '../company/news/services/news.service';
import { BehaviorSubject } from 'rxjs';
import { SettingsService } from '../loyalty/settings/service/settings.service';
import { LoyaltyUsersService } from '../loyalty/users/service/loyalty-users.service';
import { SegmentsService } from '../loyalty/segments/segments.service';
import { CloudDbService } from 'cloud-labs-core';
import { invoiceSettings, InvoiceSettingsService } from '../invoice/services/invoice-settings.service';
import { InvoiceService } from '../invoice/services/invoice.service';
import { AppUsersService } from '../settings/services/app-users.service';
import { AsyncSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StartupService {

  status = ""
  first = false
  loadedData = ""

  subject_pos_screen = new BehaviorSubject<any>({id: "", data: ""});
  observer_pos_screen = this.subject_pos_screen.asObservable();

  public subject_veritcal_nav = new BehaviorSubject<boolean>(false);
  observer_vertical_nav = this.subject_veritcal_nav.asObservable();

  private subject_startup_loading = new BehaviorSubject<string>("");
  observer_startup_loading = this.subject_startup_loading.asObservable();

  subject_global_events = new BehaviorSubject<any>({id: "", data: ""});
  observer_global_events = this.subject_global_events.asObservable();


  changeCompanySubject = new AsyncSubject()


  constructor(
    private settings: SettingsService,
    private ps: ProductService,
    private pg: ProductGroupService,
    private ms: ModifiersService,
    private ts: TablesService,
    private bs: BranchService,
    private cs: CampaignsService,
    private ns: NewsService,
    private ffs: FaqService,
    private lus: LoyaltyUsersService,
    private couponService: CouponService,
    private agreementService: AgreementsService,
    private segmentService: SegmentsService,
    private invSetSrv: InvoiceSettingsService,
    private invSrvc: InvoiceService

    ) { }

  async loadData() {

    this.invSetSrv.changeDatabase()
    

   /*  this.invset.load({},true)
    this.invserv.data.slice(0,this.invserv.data.length)
    this.invserv.pagedData.slice(0,this.invserv.data.length) */

    


    //this.bs.loadBranchesFromMongo()
    //this.ns.loadNewsFromMongo()

    //this.cs.loadStampCampaignFromMongo()
    //this.cs.loadBonusCampaignFromMongo()
    
    //this.ps.getProductsFromMongo()
    //this.ms.getProductGroupModifiersFromMongo()
    //this.ms.getProductModifiersFromMongo()
    //this.pg.getProductsFromMongo()
    //this.ffs.getFaqsFromMongo()
    //this.settings.getSettings()
    //this.lus.getLoyaltyUsersFromMongo()
    //this.couponService.load()
    //this.agreementService.load()
    //this.segmentService.load()


    setTimeout(() => {
      this.subject_startup_loading.next("loading done.")
    }, 3000);

 

  }
}

export class subjectObject {

  new(id,o) {
    this.id = id
    this.o = o
    return this
  }

  id: string = undefined
  o: any
}
